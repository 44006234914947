
import React from 'react'
import ReactToggle from 'react-toggle'

import { TOGGLE_CHECKED, TOGGLE_UNCHECKED } from '../../utils/icons'

import 'react-toggle/style.css'
import './style.scss'

export default function Toggle (props) {
  return <ReactToggle className='Toggle' icons={{ checked: TOGGLE_CHECKED, unchecked: TOGGLE_UNCHECKED }} {...props} />
}
