import React, { Component } from 'react'
import { createFilter } from 'react-search-input'
import _ from 'lodash'

import I18n from '../../../../utils/i18n'
import { isLeaf } from '../../../../utils/helpers'
import TeamCell from '../../../TeamCell'
import Breadcrumbs from '../../../Breadcrumbs'
import { SidebarSearch } from '../../../SidebarView'
import SidebarIcon from '../../../HeaderMenu/components/SidebarIcon'
import ContentPortal from '../../../HeaderMenu/components/ContentPortal'

import './style.scss'

function organisationTeam () {
  return {
    id: undefined,
    name: I18n.t('shared.organisation')
  }
}
const emptyFn = () => {}

function initExpandedTeams (teams, ids) {
  return _.chain(teams)
    .filter(it => ids.indexOf(it.id) >= 0)
    .flatMap(it => [it.id, ..._.map(it.parents, 'id')])
    .compact()
    .value()
}

export default class Teams extends Component {
  constructor (props) {
    super(props)

    const expanded = initExpandedTeams(props.teams, props.selectedIds)
    this.state = {
      search: '',
      expanded
    }
  }

  componentWillMount () {
    this.retrieve(this.props)
  }

  componentWillReceiveProps (nextProps) {
    if (!this.props.teams && nextProps.teams) {
      this.setState({
        expanded: initExpandedTeams(nextProps.teams, nextProps.selectedIds)
      })
    }

    if (nextProps.selectedMetadata !== this.props.selectedMetadata) {
      this.retrieve(nextProps)
    }
  }

  retrieve ({ teams, selectedMetadata }) {
    const hasTeams = teams && teams.length > 0
    if (!hasTeams) {
      this.props.retrieve(selectedMetadata)
    }
  }

  onSelect (event, team) {
    event.preventDefault()
    this.onExpand(event, team, true)
    this.redirect(team)
  }

  redirect (team) {
    let redirect

    if (team.url) {
      redirect = team.url
    } else if (typeof this.props.url === 'string') {
      redirect = this.props.url.replace(':teamId', team.id || '')
    } else {
      redirect = this.props.url.call(this, team)
    }

    if (redirect && redirect !== this.props.match.url) {
      this.props.history.push(redirect)
    }
  }

  onExpand (event, team, override = false) {
    event.preventDefault()

    if (team.id && isLeaf(team)) {
      return
    }

    const isExpandable = !isLeaf(team) && this.state.expanded.indexOf(team.id) === -1

    if (team.id === undefined) {
      this.setState({ expanded: [] })
    } else if (override || isExpandable) {
      this.setState({
        expanded: _.compact([team.id, team.parentId])
      })
    } else {
      this.setState({
        expanded: _.filter(this.state.expanded, it => it !== team.id)
      })
    }
  }

  isTeamSelected (team) {
    if (!team.id) {
      return this.props.selectedIds.indexOf(undefined) >= 0 || this.props.selectedIds.indexOf('organisation') >= 0
    }

    return this.props.selectedIds.indexOf(team.id) >= 0
  }

  renderTeam (team, maxLevel) {
    if (team.parentId === undefined || this.state.expanded.indexOf(team.parentId) >= 0) {
      const isCollapsed = this.state.expanded.indexOf(team.id) < 0
      const isSelected = this.isTeamSelected(team)
      const isInPath = _.some(this.props.teamPath, { id: team.id })
      const isDisabled = _.get(this.props, ['disabledIds', team.id], false)

      const onMouseOver = this.props.onMouseOver || emptyFn
      const onMouseOut = this.props.onMouseOut || emptyFn

      return (
        <TeamCell
          key={`team_${team.id}`}
          isCollapsed={isCollapsed}
          isSelected={isSelected}
          isInPath={isInPath}
          team={team}
          nested={(maxLevel - team.level) || 0}
          onMouseOver={e => onMouseOver(team)}
          onMouseOut={e => onMouseOut(team)}
          onClick={this.props.url || team.url ? e => this.onSelect(e, team) : undefined}
          onExpand={e => this.onExpand(e, team)}
          pin={this.renderPin(team)}
          disabled={isDisabled}
        />
      )
    }
  }

  renderPin (team) {
    if (!this.props.renderPin) {
      return null
    }

    return this.props.renderPin(team)
  }

  getCurrentPath () {
    if (this.props.teamPath.length > 0) {
      return this.props.teamPath.map(t => {
        return {
          id: t.id,
          text: t.name,
          link: t.id
        }
      }).reverse()
    }

    return [{
      id: 'organisation',
      text: I18n.t('shared.organisation'),
      link: 'organisation'
    }]
  }

  renderBreadCrumbs () {
    return <ContentPortal><Breadcrumbs path={this.getCurrentPath()} /></ContentPortal>
  }

  render () {
    const { teams, excludeOrganisation } = this.props
    const searchFilter = createFilter(this.state.search, ['name', 'teams.name'])
    const filteredTeams = (teams || []).filter(searchFilter)
    const maxLevel = _.max(filteredTeams.map(it => it.level))

    return (
      <div className='TeamList'>
        <SidebarIcon />
        { this.renderBreadCrumbs() }
        <SidebarSearch onChange={search => this.setState({ search })} />
        {!excludeOrganisation ? this.renderTeam(organisationTeam(), maxLevel) : null}
        {filteredTeams.map(team => this.renderTeam(team, maxLevel))}
      </div>
    )
  }
}
