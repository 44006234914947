import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'

import ContentWidth from '../../../components/ContentWidth'
import { hasParsing } from './utils'
import { calculationTypeName } from '../../../utils/calculations'
import { pathFromSurveys } from '../../../utils/surveys'
import { pathFromMetadata, getDefaultMetadata } from '../../../utils/metadata'
import { navigate, reloadPage } from '../../../utils/dom'

import './style.scss'

function longDate (value) {
  return moment(value).format('dddd, MMMM Do YYYY, HH:mm:ss')
}

function shortDate (value) {
  return moment(value).format('DD/MM/YYYY HH:mm')
}

function hasInterpretationKey (summary) {
  if (!summary.files) {
    return 'N/A'
  }

  if (summary.files.interpretationKey) {
    return 'Yes'
  }

  return 'No'
}

function hasLocalisationFile (summary) {
  if (!summary.files) {
    return 'N/A'
  }

  if (summary.files.localisation) {
    return 'Yes'
  }

  return 'No'
}

export default class SurveyList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedImport: null
    }
  }

  componentWillMount () {
    this.props.retrieve()
  }

  componentWillReceiveProps (nextProps) {
    clearTimeout(this.timeout)
    if (hasParsing(nextProps.surveys)) {
      this.timeout = setTimeout(() => {
        this.props.retrieve(true)
      }, 1000)
    }
  }

  isCurrentSurvey (surveyId) {
    if (!this.props.selectedSurveys) {
      return false
    }

    return this.props.selectedSurveys[0].id === surveyId
  }

  isPreviousSurvey (surveyId) {
    if (!this.props.selectedSurveys || !this.props.selectedSurveys[1]) {
      return false
    }

    return this.props.selectedSurveys[1].id === surveyId
  }

  sameHierarchy (imprt) {
    const newHierarchy = imprt.summary.hierarchy
    if (!newHierarchy) {
      return false
    }

    return _.every(this.props.metadata, level => {
      return _.find(newHierarchy, {
        id: level.id
      })
    })
  }

  reloadPageWithoutHierarchy (imprt) {
    const hierarchies = getDefaultMetadata(imprt.summary.hierarchy)
    const url = `${pathFromSurveys(this.props.selectedSurveys)}/${pathFromMetadata(hierarchies)}${this.currentUrl()}`
    navigate(url)
  }

  reloadPageWithoutSurveys () {
    const url = `${this.currentUrl()}`
    navigate(url)
  }

  currentUrl () {
    return this.props.history.location.pathname
  }

  activate (event, imprt, survey) {
    event.preventDefault()

    this.props.activate(survey.id, imprt.id)
      .then(response => {
        if (!response.payload || response.payload.status !== 200) {
          return
        }

        if (this.isCurrentSurvey(survey.id) && !this.sameHierarchy(imprt)) {
          return this.reloadPageWithoutHierarchy(imprt)
        }

        reloadPage()
      })
  }

  remove (event, survey) {
    event.preventDefault()
    if (window.confirm('Are you sure?')) {
      this.props.remove(survey.id).then(response => {
        if (!response.payload || response.payload.status !== 200) {
          return
        }

        if (this.isCurrentSurvey(survey.id) || this.isPreviousSurvey(survey.id)) {
          return this.reloadPageWithoutSurveys()
        }

        reloadPage()
      })
    }
  }

  selectImport (event, importId) {
    event.preventDefault()
    this.setState({
      selectedImport: importId
    })
  }

  statusSummary (row) {
    const summary = row.summary

    if (!summary) {
      return <td colSpan='4' />
    }

    if (summary.message) {
      return <td colSpan='4'>{summary.message}</td>
    }

    if (summary.respondents) {
      return (
        <Fragment>
          <td>{shortDate(row.importDate)}</td>
          <td>{summary.respondents}</td>
          <td>{hasInterpretationKey(summary)}</td>
          <td>{hasLocalisationFile(summary)}</td>
        </Fragment>)
    }
  }

  renderQuestionsByType (summary) {
    if (!summary.questionsByType) {
      return
    }

    return _.map(summary.questionsByType, (number, type) => {
      return <li style={{ textTransform: 'capitalize' }} key={`question-type-${type}`}>{type} questions: {number}</li>
    })
  }

  importFilesReport (summary) {
    if (!summary.files) {
      return <li>Original files: unknown</li>
    }

    const content = [
      <li key='file-main'>Main file: {summary.files.main}</li>
    ]

    if (summary.files.interpretationKey) {
      content.push(<li key='file-interpretation-key'>Interpretation key: {summary.files.interpretationKey}</li>)
    } else {
      content.push(<li key='file-interpretation-key'>Interpretation key: not provided</li>)
    }

    if (summary.files.localisation) {
      content.push(<li key='file-interpretation-key'>Localisation: {summary.files.localisation}</li>)
    } else {
      content.push(<li key='file-interpretation-key'>Localisation: not provided</li>)
    }

    return content
  }

  statusFullReport (row) {
    const summary = row.summary
    if (!summary) {
      return null
    }

    if (summary.message) {
      return summary.message
    }

    return (
      <td colSpan='4'>
        <ul>
          <li>Import date: {longDate(row.importDate)}</li>
          <li>Imported by: {summary.uploader || 'N/A'}</li>
          {this.importFilesReport(summary)}
          <li>Calculation type: {calculationTypeName(row.calculation)}</li>
          <li>Respondents: {summary.respondents}</li>
          <li>Questions: {summary.questions}</li>
          {this.renderQuestionsByType(summary)}
          <li>
            Main hierarchy:
            <ul>
              {
                _.chain(summary.hierarchy)
                  .filter({ main: true })
                  .map(level => <li key={`hierarchy-${level.level}`}>{level.level} - {level.name}</li>)
                  .value()
              }
            </ul>
          </li>
          <li>
            Metadata:
            <ul>
              {
                _.chain(summary.hierarchy)
                  .filter({ main: false })
                  .map(level => <li key={`metadata-${level.level}`}>{level.name}</li>)
                  .value()
              }
            </ul>
          </li>
        </ul>
      </td>)
  }

  renderStatus (row) {
    if (row.id === this.state.selectedImport) {
      return this.statusFullReport(row)
    }

    return this.statusSummary(row)
  }

  renderImportSelector (row) {
    if (row.status !== 'active' && row.status !== 'completed') {
      return null
    }

    if (row.id === this.state.selectedImport) {
      return (<a href='#deselect' onClick={ev => this.selectImport(ev, null)}>Hide info </a>)
    }

    return (<a href='#select' onClick={ev => this.selectImport(ev, row.id)}>More info </a>)
  }

  renderDetailsLink (survey) {
    if (!this.props.metadata || this.props.metadata.length === 0) {
      return null
    }

    const activeImport = _.find(survey.imports, { status: 'active' })
    if (!activeImport) {
      return null
    }

    const hierarchies = _.filter(this.props.metadata, level => {
      return !!_.find(activeImport.summary.hierarchy, { main: level.main, level: level.level })
    })

    if (this.props.metadata.length !== hierarchies.length) {
      return null
    }

    return <Link to={`/admin/surveys/${survey.id}/questions`}>View details</Link>
  }

  renderImport (imprt, survey, idx1, idx2) {
    return (
      <tr key={`import-${imprt.id}`} className={imprt.status}>
        <th>v{idx1}.{idx2}</th>
        <td>{imprt.status}</td>
        {this.renderStatus(imprt)}
        <td>{this.renderImportSelector(imprt)}</td>
        <td>
          { imprt.status === 'completed'
            ? <button onClick={event => this.activate(event, imprt, survey)}>Activate</button>
            : undefined
          }
        </td>
      </tr>
    )
  }

  renderSurvey (survey, idx) {
    return (
      <article key={`survey-${survey.id}`} className='SurveyList--survey'>
        <h1>{idx + 1} {survey.name}</h1>
        <h2>{survey.description}</h2>
        <nav>
          {this.renderDetailsLink(survey)}
          <Link to={`/admin/surveys/${survey.id}`}>Edit</Link>
          <button onClick={(e) => this.remove(e, survey)}>Delete</button>
        </nav>
        <table>
          <colgroup>
            <col style={{ width: '35px' }} />
            <col style={{ width: '100px' }} />
            <col style={{ width: '180px' }} />
            <col style={{ width: '120px' }} />
            <col />
            <col />
            <col style={{ width: '100px' }} />
            <col style={{ width: '100px' }} />
          </colgroup>
          <thead>
            <tr>
              <th />
              <th style={{ textAlign: 'center' }}>Status</th>
              <th>Import date</th>
              <th>Respondents</th>
              <th>Interpretation key?</th>
              <th>Localisation file?</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {survey.imports.map((row, idx2) => this.renderImport(row, survey, idx + 1, idx2))}
          </tbody>
        </table>
        <footer>
          <Link to={`/admin/surveys/${survey.id}/import`}>Add version</Link>
        </footer>
      </article>
    )
  }

  render () {
    const { surveys } = this.props

    return (
      <ContentWidth layout='full'>
        <div className='SurveyList'>
          <h1>Surveys</h1>

          {surveys.map((row, idx) => this.renderSurvey(row, idx))}

          <Link to='/admin/surveys/new'>New survey</Link>
        </div>
      </ContentWidth>
    )
  }
}
