import React from 'react'
import Component from './Component'
import { LevelsContainer } from '../../containers'

export default props => {
  return (
    <LevelsContainer>
      <Component {...props} />
    </LevelsContainer>
  )
}
