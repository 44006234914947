
import React from 'react'
import _ from 'lodash'

import * as ICONS from '../../../../utils/icons'
import { useTranslation } from 'react-i18next'
import { isNotAsked, isNotAnswered } from '../../../../utils'

import './style.scss'

function leverIds (groups) {
  return _.chain(groups)
    .map('groups')
    .flatten()
    .map('id')
    .value()
}

function hasNAs (scores, groups) {
  for (let teamId in scores) {
    const team = scores[teamId]
    if (isNotAsked(team.total) || isNotAnswered(team.total)) {
      return true
    }

    for (let groupId of groups) {
      if (isNotAsked(team[groupId]) || isNotAnswered(team[groupId])) {
        return true
      }
    }
  }

  return false
}

export default function HeatmapLegend (props) {
  if (!props.scores) {
    return null
  }

  if (!hasNAs(props.scores, leverIds(props.groups))) {
    return null
  }

  const { t } = useTranslation()

  return (
    <div className='HeatmapLegend'>
      <h3>{t('heatmap.legend')}</h3>
      <ul>
        <li>
          <div className='scoreNa'>{ICONS.SCORE_NA}</div>
          <span>{t('shared.not_aplicable')}</span>
        </li>
        <li>
          <div className='scoreNotAsked' />
          <span>{t('shared.not_asked')}</span>
        </li>
      </ul>
    </div>)
}
