
import React from 'react'
import _ from 'lodash'

import ProgressScore from '../ProgressScore'
import Scale from '../SimpleScale'

import './style.scss'

export default function ProgressIndicator (props) {
  const value = props.value

  const label = !props.hideLabel ? <span className='ProgressIndicator--label'>{_.get(value, 'progressScore.label')}</span> : null

  return (
    <div className='ProgressIndicator'>
      <ProgressScore value={value} />
      <Scale
        level={_.get(value, 'score.progress')}
        history={_.get(value, 'historyScore.progress')}
        index={_.get(value, 'score.index')}
        progressIndex={_.get(value, 'progressScore.index')}
        highlightProgress
      />
      {label}
    </div>
  )
}
