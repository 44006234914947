
import React, { PureComponent, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import Card from '../Card'
import ProgressIndicator from '../../../../components/ProgressIndicator'
import { formatScore, isNotAnswered, isNotAsked } from '../../../../utils'
import { CARD_ICONS } from '../../../../utils/icons'

import './style.scss'

function scoreClass (score) {
  if (isNotAsked(score)) {
    return 'Group--scoreNotAsked'
  }

  if (isNotAnswered(score)) {
    return 'Group--scoreNa'
  }

  return `Group--score${score.index}`
}

function getAcronym (name) {
  const words = name.split(' ')
  const letters = [...words.map(w => w.charAt(0)), ...words.map(w => w.charAt(1))]

  return letters.slice(0, 2).join('').toUpperCase()
}

function getGroupIcon (group) {
  const key = group.name.toLowerCase()
  if (key in CARD_ICONS) {
    return CARD_ICONS[key]
  }

  return <div className='Group--icon-placeholder'>{getAcronym(group.name)}</div>
}

export class GroupPlain extends PureComponent {
  renderScore () {
    const group = this.props.group

    if (this.props.highlightProgress) {
      return <ProgressIndicator value={group} />
    }

    return <div className={`Group--score ${scoreClass(group.score)}`}>{formatScore(group.score)}</div>
  }

  render () {
    const { type, team, group, subGroups, questions, filter } = this.props
    const icon = getGroupIcon(group)
    const subGroup = group.isTQ ? subGroups : subGroups[0].groups
    return (
      <Fragment>
        <div className='Group'>
          {icon}
          <span className='Group--title'>{group.name}</span>
          {this.renderScore()}
          <Link to={`/teams-score/${this.props.type.key}/quadrant/${group.id}`}>{this.props.t('team_details.view_leaders')}</Link>
        </div>

        {_.filter(subGroup, filter).map(subGroup => {
          const subGroupQuestions = _.chain(group.isTQ
            ? _.get(questions, [this.props.type.key, group.name, subGroup.name], [])
            : _.get(questions, [this.props.type.key, group.name, subGroups[0].name, subGroup.name], []))
            .values().flatten().value()
          return (
            <Card
              key={`group_${subGroup.name}`}
              type={type}
              team={team}
              group={subGroup}
              questions={subGroupQuestions}
              highlightedLever={this.props.highlightedLever}
              highlightProgress={this.props.highlightProgress}
            />
          )
        })}
      </Fragment>
    )
  }
}

const Group = withTranslation()(GroupPlain)

export default class Groups extends PureComponent {
  isLoading () {
    return !this.props.team || !_.get(this.props, 'totals.groups') || !this.props.questions
  }

  render () {
    if (this.isLoading()) {
      return null
    }

    const { type, team, totals } = this.props
    const groups = _.get(totals, 'groups', [])
    const questions = mapQuestions(this.props.questions || [])
    const filterPath = this.props.filter ? this.props.filter.name.split('/') : null
    const filter = filterPath ? { name: filterPath[0] } : null
    const subFilter = filterPath && filterPath[1] ? { name: filterPath[1] } : null
    const arr = _.filter(groups, filter || {}).map(group => {
      return (
        <Group
          key={`group_${group.name}`}
          type={type}
          team={team}
          group={group}
          filter={subFilter}
          subGroups={group.groups}
          questions={questions}
          highlightProgress={this.props.highlightProgress}
          highlightedLever={this.props.highlightedLever}
        />
      )
    })

    return (
      <Fragment>{arr}</Fragment>
    )
  }
}

function mapQuestions (questions) {
  const results = {}
  _.each(questions, question => {
    const path = [question.type, question.grouping3, question.grouping2, question.grouping1]
    const current = _.get(results, path, [])
    _.set(results, path, current.concat(question))
  })

  return results
}

export { Group }
