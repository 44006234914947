
import React, { PureComponent, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import ChangeSymbol from '../ChangeSymbol'
import { formatScore, formatProgress, isNotAnswered, isNotAsked } from '../../../../utils'
import { PROGRESS } from '../../../../utils/icons'

import './style.scss'

const MAX_SUBGROUPS = 10

function notAsked (item) {
  return !isNotAsked(item.score)
}

function scoreClass (score) {
  if (isNotAsked(score)) {
    return 'Tile--scoreNotAsked'
  }

  if (isNotAnswered(score)) {
    return `Tile--scoreNa`
  }

  return `Tile--score${score.index}`
}

function scoreClassProgress (score) {
  if (!score || score.responses === 0) {
    return `Tile--scoreNa`
  }

  return `Tile--score${score.index}`
}

function formatProgressScore (score) {
  if (!score || score.responses === 0) {
    return 'N/A'
  }

  return formatProgress(score.value)
}

export class Tile extends PureComponent {
  onClick (event) {
    event.preventDefault()
    if (!this.props.onClick) {
      return
    }

    this.props.onClick(this.props.group.name)
  }

  isSelected () {
    const { group, filter, lever, children } = this.props
    const [parent, child] = filter ? filter.split('/') : [null, null]
    return (filter && ((parent === group.name) ||
      (child === group.name))) ||
      lever === group.name ||
      (children && children.length && children.includes(lever))
  }

  hasFilter () {
    return !!this.props.filter
  }

  getScoreClass () {
    const classes = []

    if (this.props.highlightProgress) {
      classes.push(scoreClassProgress(this.props.progress))
    } else {
      classes.push(scoreClass(this.props.group.score))
    }

    if (this.props.isColored) {
      classes.push('Tile--colored')
    } else {
      classes.push('Tile--default')
    }

    return classes.join(' ')
  }

  renderScore () {
    const { group, history, progress } = this.props

    if (this.props.highlightProgress) {
      return formatProgressScore(progress)
    }

    return (
      <Fragment>
        {formatScore(group.score)}
        <ChangeSymbol
          score={group.score}
          hist={history}
        />
      </Fragment>)
  }

  renderProgress () {
    if (!this.props.highlightProgress) {
      return null
    }

    const score = _.get(this.props.group, 'score.value', 'N/A')
    const history = _.get(this.props, 'history.value', 'N/A')
    const progress = _.get(this.props, 'progress.index', 'Na')

    return (
      <span className='Tile--progress'>
        <span>{history}</span>
        {PROGRESS[progress]}
        <span>{score}</span>
      </span>)
  }

  render () {
    const { group, includeSubgroups } = this.props
    const classNames = ['Tile', this.getScoreClass()]

    if (this.props.highlightProgress) {
      classNames.push('Tile--highlightProgress')
    }

    if (this.props.isSubtile) {
      classNames.push('Tile--Child')
    } else {
      classNames.push('Tile--Parent')
    }

    if (this.isSelected()) {
      classNames.push('Tile--selected')
    } else if (this.hasFilter() && !this.props.parentSelected) {
      classNames.push('Tile--dimmed')
    }

    const subGroups = group.isTQ || !group.groups || !group.groups.length
      ? group.groups
      : group.groups[0].groups
    return (
      <div className={`${this.props.isColored || (subGroups && subGroups.length) ? 'TileGroup' : ''}`}>
        <div >
          <div className='TileRow'>
            <div className={classNames.join(' ')} onClick={e => this.onClick(e)}>
              <div className='Tile--tooltip'>{group.name}</div>
              <div>
                <div>
                  <span className='Tile--score'>
                    {this.renderScore()}
                  </span>
                  {this.renderProgress()}
                  <span className='Tile--name'>{group.name}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='Tile--subTiles'>
            {includeSubgroups && group.isTQ && subGroups && subGroups.map(subGroup => {
              return (<Tile
                group={{ ...subGroup, groups: [], isSelected: this.isSelected() }}
                history={subGroup.historyScore}
                progress={subGroup.progressScore}
                filter={this.props.filter}
                parentSelected={this.isSelected()}
                onClick={name => this.props.onClick(`${group.name}/${name}`)}
                key={`tile_${subGroup.name}`}
                highlightProgress={this.props.highlightProgress}
                lever={this.props.lever}
                children={subGroup.groups.map(g => g.name)}
                isSubtile
              />
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export class Tiles extends PureComponent {
  isLoading () {
    return !_.get(this.props, 'totals.groups')
  }

  onChange (name) {
    if (!this.props.onChange) {
      return
    }

    this.props.onChange(name)
  }

  render () {
    if (this.isLoading()) {
      return null
    }

    const { totals } = this.props
    const score = _.get(totals, 'score')
    const groups = _.chain(totals).get(['groups'], {}).values().value()
    const subGroupCount = _.chain(groups).flatMap('groups').filter(g => !g.name.match(/.*~none~.*/)).value().length
    return (
      <div>
        <div className='clearfix Tiles'>
          <Tile
            group={{ score, name: this.props.t('team_details.overall') }}
            history={totals.historyScore}
            progress={totals.progressScore}
            highlightProgress={this.props.highlightProgress}
            onClick={() => this.onChange(null)}
            isColored
          />

          {_.chain(groups).filter(notAsked).map(group => {
            return (
              <Tile
                group={group}
                history={group.historyScore}
                progress={group.progressScore}
                filter={this.props.filter}
                onClick={name => this.onChange(name)}
                key={`tile_${group.name}`}
                highlightProgress={this.props.highlightProgress}
                lever={this.props.highlightedLever}
                includeSubgroups={subGroupCount <= MAX_SUBGROUPS}
              />
            )
          }).value()}
        </div>
      </div>
    )
  }
}

export default withTranslation()(Tiles)
