import Component from './Component'
import { connect } from 'react-redux'
import * as actions from '../../../../services/questions/actions'

function mapStateToProps (state, ownProps) {
  const isSingleQuestion = ownProps.questions.length <= 1

  if (isSingleQuestion) {
    const question = ownProps.questions[0]
    const hasResponseText = question.responsesText && question.responsesText.length > 0
    const hasQuestionText = !!question.question_text
    const isExpandable = hasResponseText || hasQuestionText

    return {
      isExpandable,
      checkQuestion: question,
      responsesText: hasResponseText && question.responsesText,
      questionText: hasQuestionText && question.question_text,
      title: ownProps.group.name,
      questions: undefined,
      selected: state.questions.selected,
      thresholds: state.profile.thresholds[question.type],
      limits: state.profile.limits[question.type]
    }
  }

  return {
    isExpandable: true,
    checkQuestion: null,
    title: ownProps.group.name,
    questions: ownProps.questions,
    selected: state.questions.selected,
    thresholds: state.profile.thresholds[ownProps.questions[0].type],
    limits: state.profile.limits[ownProps.questions[0].type]
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleQuestion: (...args) => dispatch(actions.toggleQuestion(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
