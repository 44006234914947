
import React from 'react'

import Nav from '../../../Nav'
import { routeBuilder } from '../../../../utils/routing'

function path (type) {
  return routeBuilder('heatmap', { type: type.key })
}

export default function HeatmapNav (props) {
  const links = props.questionTypes.map(type => {
    return {
      text: type.plural,
      href: path(type),
      selected: props.selected === type.key
    }
  })

  return <Nav links={links} />
}
