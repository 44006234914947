import React, { Component } from 'react'
import EmptyScene from '../EmptyScene'
import ContentWidth from '../../components/ContentWidth'
import Heatmap from '../../components/Heatmap'
import ThresholdsLegend from '../../components/ThresholdsLegend'
import HeatmapLegend from '../../components/Heatmap/components/Legend'
import HeatmapSurveys from '../../components/Heatmap/components/Surveys'
import Sidebar from '../../components/Sidebar/right'
import LegendsIcon from '../../components/HeaderMenu/components/LegendsIcon'
import ContentPortal from '../../components/HeaderMenu/components/ContentPortal'
import Nav from '../../components/Heatmap/components/Nav'

export default class PatternsScene extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentSurvey: 'latest'
    }
  }

  onCurrentSurveyChange (currentSurvey) {
    this.setState({ currentSurvey })
  }

  render () {
    const { type } = this.props
    const { currentSurvey } = this.state

    return (
      <ContentWidth layout='left' always={this.props.totalColumns > 16}>
        <EmptyScene>
          <ContentPortal right>
            <Nav selected={this.props.type.key} questionTypes={this.props.questionTypes} />
          </ContentPortal>
          <Heatmap type={type} currentSurvey={currentSurvey} />
          <Sidebar>
            <LegendsIcon />
            <HeatmapSurveys currentSurvey={currentSurvey} onChange={value => this.onCurrentSurveyChange(value)} />
            <ThresholdsLegend showProgress={currentSurvey === 'progress' || currentSurvey === 'benchmark'} type={type} />
            <HeatmapLegend type={type} currentSurvey={currentSurvey} />
          </Sidebar>
        </EmptyScene>
      </ContentWidth>
    )
  }
}
