import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import qs from 'query-string'

import * as actions from '../../services/scores/actions'
import * as navActions from '../../services/navigation/actions'

import Component from './Component'
import { scoresSelector } from './selectors'

function findGroups (groups, level, value) {
  if (level === 'quadrant') {
    return [_.find(groups, { id: value })]
  }

  if (level === 'lever') {
    for (let quadrant of groups) {
      const lever = _.find(quadrant.groups, { id: value })
      if (lever) {
        return [quadrant, lever]
      }
    }
  }

  if (level === 'question') {
    for (let quadrant of groups) {
      for (let lever of quadrant.groups) {
        const question = _.find(lever.questions, { id: value })
        if (question) {
          return [quadrant, lever]
        }
      }
    }
  }

  return null
}

function findTeamLevel (state, teamId) {
  if (!state.scores.current || !state.scores.current.teams) {
    return null
  }

  if (!teamId) {
    return null
  }

  for (let level in state.scores.current.teams) {
    if (level === '0') {
      continue
    }

    const teams = level === '1' ? state.scores.current.teams[level][0].teams : state.scores.current.teams[level]
    if (_.find(teams, { id: teamId })) {
      return level
    }
  }

  return null
}

function mapStateToProps (state, ownProps) {
  const type = _.find(state.profile.questionTypes, { key: ownProps.match.params.type }) || state.profile.questionTypes[0]
  const { level, value } = ownProps.match.params
  const { order, sortDir } = state.scores
  const hierarchies = _.sortBy(state.metadata.hierarchies, 'level').reverse()
  const { teamId, grouped: qsGrouped } = qs.parse(ownProps.location.search)
  const grouped = qsGrouped || findTeamLevel(state, teamId) || '1'

  const scores = scoresSelector(state, type.key, level, value, grouped)
  const currentGroups = state.profile.groups && findGroups(state.profile.groups[type.key], level, value)

  return {
    type,
    level,
    value,
    order,
    direction: sortDir,
    grouped,
    scores,
    hierarchies,
    groups: state.profile.groups,
    routerHistory: ownProps.history,
    currentGroups,
    highlightedTeamId: teamId,

    highlightProgress: state.navigation.highlightProgress,
    selectedSurveys: state.profile.selectedSurveys || [],
    metadata: state.metadata.selectedMetadata,
    questionTypes: state.profile.questionTypes
  }
}

function mapDispatchToProps (dispatch) {
  return {
    retrieve: (...args) => {
      return Promise.all([
        dispatch(actions.retrieve(...args)),
        dispatch(actions.retrieveHistory(...args))
      ])
    },
    sort: (...args) => dispatch(actions.sort(...args)),
    changeHighlightProgress: (value) => dispatch(navActions.highlightProgress(value))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))
