
import React from 'react'
import { SORT } from '../../../../utils/icons'

import './style.scss'

export default function SortIcon (props) {
  const { active, direction } = props
  const classes = ['SortIcon'].concat(props.className || [])

  if (active) {
    classes.push(direction)
  }

  return <button className={classes.join(' ')}>{SORT}</button>
}
