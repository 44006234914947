import React, { Component } from 'react'
import RadioSelect from '../../../../components/RadioSelect'

export default class GroupByList extends Component {
  render () {
    const { team } = this.props
    const level = (team && team.level) || 4 // 4 - for organisation, above 3rd level
    const hierarchies = this.props.metadata
      .map((it, index) => {
        return {
          name: it.name,
          value: String(index + 1),
          disabled: it.level >= level
        }
      })

    return (
      <div className='GroupByList'>
        <RadioSelect
          options={hierarchies}
          selected={String(this.props.grouped)}
          onChange={hierarchy => this.props.group(parseInt(hierarchy, 10))}
        />
      </div>
    )
  }
}
