
import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import I18n from '../../utils/i18n'
import Head from './components/Head'
import Sticky from './components/Sticky'
import TeamCells from './components/TeamCells'
import Animation from './components/Animation'
import TeamHead from './components/TeamHead'
import { isLeaf } from '../../utils/helpers'
import './style.scss'

const TeamHeadDelayed = Animation(TeamHead)
const TeamCellsDelayed = Animation(TeamCells)
const DEFAULT_ROWS_TO_SHOW = 70

// these are not static const so that i18n picks latest localisation when generating
function organisationTeam () {
  return {
    id: undefined,
    name: I18n.t('shared.organisation')
  }
}

export class Heatmap extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: {},
      rowsToShow: DEFAULT_ROWS_TO_SHOW,
      expandRowsToShow: 0,
      collapsedQuadrants: []
    }

    this.hoverTeam = this.hoverTeam.bind(this)
    this.handleScrollTop = this.handleScrollTop.bind(this)
    this.handleScrollBottom = this.handleScrollBottom.bind(this)
  }

  componentWillMount () {
    const { metadata, teams } = this.props
    if (!teams.length) {
      this.props.retrieveTeams(metadata)
    }

    this.loadTopTeams()
  }

  componentDidMount () {
    this.top = ReactDOM.findDOMNode(this.topContainer)
    this.top.addEventListener('scroll', this.handleScrollTop, true)

    this.bottom = ReactDOM.findDOMNode(this.bottomContainer)
    this.bottom.addEventListener('scroll', this.handleScrollBottom, true)
  }

  componentWillUnmount () {
    this.top.removeEventListener('scroll', this.handleScrollTop)
    this.bottom.removeEventListener('scroll', this.handleScrollBottom)
  }

  handleScrollTop (event) {
    this.bottom.scrollLeft = event.target.scrollLeft
  }

  handleScrollBottom (event) {
    this.top.scrollLeft = event.target.scrollLeft
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.type !== nextProps.type || this.props.currentSurvey !== nextProps.currentSurvey || this.props.teams !== nextProps.teams) {
      this.loadTopTeams(nextProps.teams, nextProps.type, nextProps.currentSurvey)
    }
  }

  showBenchmark () {
    return this.hasBenchmarks() && this.props.currentSurvey !== 'progress' && this.props.currentSurvey !== 'benchmark'
  }

  hasBenchmarks () {
    return this.props.benchmark &&
      this.props.benchmark.length
  }

  isVisible (team) {
    return team.parentId === undefined || !!this.state.expanded[team.parentId]
  }

  hoverTeam (row, hover) {
    if (!hover) {
      this.removeHoverClass()
      return
    }

    this.addHoverClass(row.getAttribute('data-team-id'))
  }

  addHoverClass (key) {
    const rows = this.el.querySelectorAll(`.Heatmap--row[data-team-id="${key}"]`)
    _.each(rows, r => r.classList.add('hover'))
  }

  removeHoverClass () {
    const rows = this.el.querySelectorAll(`.Heatmap--row.hover`)
    _.each(rows, r => r.classList.remove('hover'))
  }

  loadTopTeams (teams = this.props.teams, type = this.props.type, currentSurvey = this.props.currentSurvey) {
    if (teams.length) {
      const topTeams = teams.filter(it => !it.parentId)
      this.load([{ id: 'org' }, ...topTeams], type, currentSurvey)
      this.preload(topTeams, type, currentSurvey, teams)
    }
  }

  load (teams, type = this.props.type, currentSurvey = this.props.currentSurvey) {
    const { metadata } = this.props
    this.props.retrieveDetails(currentSurvey, type.key, metadata, _.map(teams, 'id'))
  }

  preload (teams, type = this.props.type, currentSurvey = this.props.currentSurvey, allTeams = this.props.teams) {
    const teamsToLoad = allTeams.filter((team) => {
      return _.some(teams, { id: team.parentId })
    })

    this.load(teamsToLoad, type, currentSurvey)
  }

  expandableTeams () {
    return this.props.teams.filter(team => !isLeaf(team))
  }

  toggleCollapsedQuadrant () {
    return (e, quadrant) => {
      e.preventDefault()
      e.stopPropagation()
      const isCollapsed = this.state.collapsedQuadrants.includes(quadrant)
      this.setState({
        collapsedQuadrants: isCollapsed
          ? this.state.collapsedQuadrants.filter(q => q !== quadrant)
          : [...this.state.collapsedQuadrants, quadrant]
      })
    }
  }

  expandAll (event) {
    event.preventDefault()
    const teams = this.expandableTeams()
    const expanded = teams.reduce((obj, team) => (obj[team.id] = true) && obj, {})

    this.setState({ expanded })
    this.preload(teams)
  }

  collapseAll (event) {
    event.preventDefault()
    this.setState({
      expanded: {}
    })
  }

  isExpandedAll () {
    return !_.some(this.expandableTeams(), team => !this.state.expanded[team.id])
  }

  onClick (team) {
    if (team.id && isLeaf(team)) {
      return
    }

    const childrenLength = (team.teams && team.teams.length) || 0
    if (this.state.expanded[team.id]) {
      const closeIds = [team.id].concat((team.teams || []).map(it => it.id))
      this.setState({
        expanded: _.omit(this.state.expanded, closeIds),
        expandRowsToShow: this.state.expandRowsToShow - childrenLength
      })
    } else {
      this.setState({
        expanded: {
          ...this.state.expanded,
          [team.id]: true,
          [team.parentId || team.id]: true
        },
        expandRowsToShow: this.state.expandRowsToShow + childrenLength
      })

      this.preload([team])
    }
  }

  renderExpandAll () {
    if (this.isExpandedAll()) {
      return (
        <a className='Heatmap--CollapseAll ml1' onClick={e => this.collapseAll(e)} href='#collapse'>
          {this.props.t('heatmap.collapse_all')}
        </a>
      )
    } else {
      return (
        <a className='Heatmap--ExpandAll ml1' onClick={e => this.expandAll(e)} href='#expand'>
          {this.props.t('heatmap.expand_all')}
        </a>
      )
    }
  }

  renderTeam (team, maxLevel) {
    const isVisible = this.isVisible(team)
    const head = <TeamHeadDelayed
      key={`team_${team.id || team}`}
      team={team}
      maxLevel={maxLevel}
      type={this.props.type}
      isCollapsed={!this.state.expanded[team.id]}
      delayTime={500}
      isMounted={isVisible}
      onClick={() => this.onClick(team)}
      hoverTeam={this.hoverTeam}
      collapsedQuadrants={this.state.collapsedQuadrants}
    />

    const cells = <TeamCellsDelayed
      key={`team_${team.id || team}`}
      team={team}
      currentSurvey={this.props.currentSurvey}
      type={this.props.type}
      isMounted={isVisible}
      delayTime={500}
      hoverTeam={this.hoverTeam}
      collapsedQuadrants={this.state.collapsedQuadrants}
    />

    return {
      team,
      isVisible,
      head,
      cells
    }
  }

  renderTeams (maxLevel) {
    const { teams } = this.props
    const limit = this.state.rowsToShow + this.state.expandRowsToShow
    const views = []

    let teamsRendered = 0
    for (let team of teams) {
      const view = this.renderTeam(team, maxLevel)
      views.push(view)

      if (view.isVisible) {
        teamsRendered++
      }

      if (teamsRendered > limit) {
        return [views, true]
      }
    }

    return [views, false]
  }

  renderHead (maxLevel) {
    const organisation = this.renderTeam(organisationTeam(), maxLevel)
    const benchmark = this.showBenchmark()
      ? this.props.benchmark.map((b, i) => this.renderTeam({ ...b, id: `benchmark-${i}` }, maxLevel).head)
      : undefined
    return (
      <Fragment>
        <div className='Heatmap--HeadHierarchy'>
          <div>
            {this.renderExpandAll()}
          </div>
        </div>
        {benchmark}
        {organisation.head}
      </Fragment>
    )
  }

  renderCellsHead (maxLevel) {
    const organisation = this.renderTeam(organisationTeam(), maxLevel)
    const benchmark = this.showBenchmark()
      ? this.props.benchmark.map((b, i) => this.renderTeam({ ...b, id: `benchmark-${i}` }, maxLevel, true).cells)
      : undefined
    return (
      <Fragment>
        <Head type={this.props.type}
          collapsedQuadrants={this.state.collapsedQuadrants}
          toggleCollapsedQuadrant={this.toggleCollapsedQuadrant(this)} />
        {benchmark}
        {organisation.cells}
      </Fragment>
    )
  }

  loadMore () {
    this.setState({
      rowsToShow: this.state.rowsToShow + DEFAULT_ROWS_TO_SHOW
    })
  }

  renderLoadMore () {
    return (
      <div className='Heatmap--actions'>
        <div onClick={e => this.loadMore()}>Load more</div>
      </div>
    )
  }

  render () {
    const { teams } = this.props
    const maxLevel = _.max(teams.map(it => it.level))
    const [views, limitedView] = this.renderTeams(maxLevel)

    const headClasses = ['Heatmap--Head']
    const stickyClasses = ['Heatmap--Sticky']
    if (this.showBenchmark()) {
      headClasses.push('Heatmap--benchmark')
      stickyClasses.push('Heatmap--benchmark')
    }

    return (
      <div className='Heatmap' ref={el => { this.el = el }}>
        <Sticky height={49} className={stickyClasses.join(' ')}>
          <div className={headClasses.join(' ')}>
            <div className='Heatmap--Teams'>
              {this.renderHead(maxLevel)}
            </div>
            <div className='Heatmap--Cells' ref={el => { this.topContainer = el }}>
              {this.renderCellsHead(maxLevel)}
            </div>
          </div>
        </Sticky>
        <div className='Heatmap--Body'>
          <div className='Heatmap--Teams'>
            {views.map(view => view.head)}
          </div>
          <div className='Heatmap--Cells' ref={el => { this.bottomContainer = el }}>
            {views.map(view => view.cells)}
          </div>
        </div>
        {limitedView ? this.renderLoadMore() : null}
      </div>
    )
  }
}

export default withTranslation()(Heatmap)
