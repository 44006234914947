
import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import GroupsList from '../../../../components/GroupsList'
import * as ICONS from '../../../../utils/icons'
import { isProgress, baseType } from '../../../../utils/type'

class Dropdown extends Component {
  constructor (props) {
    super(props)

    this.state = {
      tab: 'main'
    }

    this.url = this.url.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.type !== this.props.type) {
      this.show('main')
    }
  }

  url (type, level, id) {
    return this.props.url(this.state.tab, level, id)
  }

  show (tab) {
    this.setState({ tab })
  }

  navigate (ev, tab) {
    ev.preventDefault()
    ev.stopPropagation()

    this.show(tab)
  }

  renderGroupList () {
    const type = baseType(this.state.tab)
    const selectedType = this.props.type

    const actualType = _.find(this.props.questionTypes, { key: type })

    return (
      <div className='AxisLegend--container'>
        <GroupsList
          groups={this.props.groups}
          type={actualType}
          url={this.url}
          value={this.state.tab === selectedType ? this.props.value : 'no-selection'}
        />
      </div>
    )
  }

  renderBack () {
    const [type, progress] = this.state.tab.split('-')
    const actualType = _.find(this.props.questionTypes, { key: type })
    const back = progress ? this.props.t('shared.groups.overall_progress', { type: actualType.plural }) : actualType.plural

    return (
      <div className='AxisLegend--back' onClick={ev => this.navigate(ev, 'main')}>
        {ICONS.PARENT}{back}
      </div>)
  }

  renderTab () {
    return (
      <Fragment>
        {this.renderBack()}
        {this.renderGroupList()}
      </Fragment>
    )
  }

  renderProgressSelectors () {
    if (!this.props.showProgress) {
      return null
    }

    const key = this.props.type
    const surveyName = this.props.surveys.map(s => s.name).join(' > ')

    return this.props.questionTypes.map(type => {
      return (
        <li
          key={`${type.key}-progress`}
          className={isProgress(key) && baseType(key) === type.key ? 'selected' : null}
          onClick={ev => this.navigate(ev, `${type.key}-progress`)}
        >
          <span>{this.props.t('shared.groups.overall_progress', { type: type.plural })}</span>
          <span className='AxisLegend--surveyName'>{surveyName}</span>
          {ICONS.PARENT}
        </li>)
    })
  }

  renderMain () {
    const key = this.props.type
    const surveyName = this.props.surveys[0].name

    const items = this.props.questionTypes.map(type => {
      return (
        <li
          key={type.key}
          className={type.key === key ? 'selected' : null}
          onClick={ev => this.navigate(ev, type.key)}
        >
          <span>{type.plural}</span>
          <span className='AxisLegend--surveyName'>{surveyName}</span>
          {ICONS.PARENT}
        </li>)
    })

    return (
      <ul className='AxisLegend--list'>
        {items}
        {this.renderProgressSelectors()}
      </ul>
    )
  }

  render () {
    if (this.state.tab === 'main') {
      return this.renderMain()
    }

    return this.renderTab()
  }
}

export default withTranslation()(Dropdown)
