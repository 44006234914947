import * as types from './types'
import { idFromMetadata } from '../../utils/metadata'
import { prepareSurveyId } from '../../utils/surveys'

export function retrieve (surveyId, metadata, teamId, type) {
  const ignoreNoSurvey = surveyId === 'previous'
  const ignore404 = ignoreNoSurvey

  return {
    types: [
      types.RETRIEVE_REQUEST,
      types.RETRIEVE_SUCCESS,
      types.RETRIEVE_FAILURE
    ],
    payload: {
      request: {
        url: `/data/${prepareSurveyId(surveyId)}/${idFromMetadata(metadata)}/team/${teamId}/profile/${type}`,
        ignoreNoSurvey,
        ignore404
      },
      teamId,
      surveyId,
      type
    }
  }
}
