import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import Tick from '../../../../components/Sidebar/components/Teams/Tick'
import ProgressIndicator from '../../../../components/ProgressIndicator'
import Questions from '../Questions'
import QuestionDetailsVertical from '../QuestionDetailsVertical'
import Scale from '../../../../components/SimpleScale'

import { CHILD, EXPAND } from '../../../../utils/icons'
import { formatScore, isNotAsked, isNotAnswered } from '../../../../utils'
import { routeBuilder } from '../../../../utils/routing'

import './style.scss'

function label (score) {
  if (isNotAsked(score) || isNotAnswered(score)) {
    return ''
  }

  return score.label
}

function getGrouping1 (props) {
  if (props.checkQuestion) {
    return props.checkQuestion.grouping1
  }

  if (props.questions) {
    return props.questions[0].grouping1
  }

  return null
}

export function RegularScore (props) {
  const group = props.group

  return (
    <Fragment>
      <span className='Card--score-value'>{formatScore(group.score)}</span>
      <Scale
        level={_.get(group, 'score.progress')}
        history={_.get(group, 'historyScore.progress')}
        index={_.get(group, 'score.index')}
      />
      <span className='Card--label'>{label(group.score)}</span>
    </Fragment>)
}

export class Card extends Component {
  constructor (props) {
    super(props)
    const expandedChild = props.group.groups && props.group.groups.some(g => g.name === this.props.highlightedLever)
    this.state = {
      isExpanded: (!!props.group.name && props.group.name === this.props.highlightedLever) || expandedChild,
      expandedItems: []
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.highlightedLever && nextProps.highlightedLever !== this.props.highlightedLever) {
      this.setState({
        isExpanded: getGrouping1(nextProps) === nextProps.highlightedLever
      })
    }
  }

  toggleExpandedItems (event, itemId) {
    event.preventDefault()
    event.stopPropagation()
    const isExpanded = this.state.expandedItems.includes(itemId)
    const expandedItems = isExpanded
      ? this.state.expandedItems.filter(i => i !== itemId)
      : [...this.state.expandedItems, itemId]
    this.setState({ expandedItems })
  }

  onExpand (event, group) {
    if (!this.props.isExpandable) {
      return this.onTick(event, group)
    }

    event.preventDefault()
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  onTick (event, group) {
    event.preventDefault()
    event.stopPropagation()

    const { selected, toggleQuestion, checkQuestion, questions } = this.props

    if (checkQuestion) {
      toggleQuestion(checkQuestion)
    } else {
      const groupSelectedQuestions = this.getGroupQuestions(selected, group)
      const qs = groupSelectedQuestions.length ? groupSelectedQuestions : this.getGroupQuestions(questions, group)
      qs.forEach(question => {
        toggleQuestion(question)
      })
    }
  }

  renderDetails (question) {
    if (!(this.props.responsesText || (question && question.responsesText)) &&
      !(this.props.questionText || (question && question.questionText))) {
      return null
    }
    let opts
    if (question) {
      const hasResponseText = question.responsesText && question.responsesText.length > 0
      const hasQuestionText = !!question.question_text
      opts = {
        score: question.score,
        responsesText: hasResponseText && question.responsesText,
        questionText: hasQuestionText && question.question_text,
        thresholds: this.props.thresholds,
        limits: this.props.limits,
        plainTextResponse: this.props.type.style !== 'maturity',
        showAll: this.props.type.style !== 'maturity'
      }
    } else {
      opts = {
        score: this.props.group.score,
        responsesText: this.props.responsesText,
        questionText: this.props.questionText,
        thresholds: this.props.thresholds,
        limits: this.props.limits,
        plainTextResponse: this.props.type.style !== 'maturity',
        showAll: this.props.type.style !== 'maturity'
      }
    }
    return <QuestionDetailsVertical {...opts} />
  }

  renderChildQuestions (subgroup) {
    if (!subgroup || subgroup.length <= 1) {
      return null
    }
    const questions = subgroup.reduce((prev, curr) => {
      if (!(curr.selection && curr.category)) {
        prev.push(curr)
      } else {
        const masterQuestion = prev.find(q => q.questionId === curr.questionId)
        if (masterQuestion) {
          if (!masterQuestion.subQuestions) {
            masterQuestion.subQuestions = []
          }
          masterQuestion.subQuestions.push(curr)
        } else {
          prev.push({ ...curr, subQuestions: [curr] })
        }
      }
      return prev
    }, [])

    return (
      <div className='Card--child-questions'>
        <Questions
          team={this.props.team}
          type={this.props.type}
          questions={questions}
          highlightProgress={this.props.highlightProgress}
        />
      </div>)
  }

  renderExpanded (subGroup) {
    if (!this.state.isExpanded || !this.props.isExpandable) {
      return
    }
    return (
      <React.Fragment>
        {this.renderDetails(subGroup.length === 1 && !this.props.checkQuestion ? subGroup[0] : null)}
        {this.renderChildQuestions(subGroup)}
      </React.Fragment>)
  }

  isSelected () {
    const { selected, checkQuestion } = this.props

    if (!checkQuestion) {
      return false
    }

    return _.some(selected, { id: checkQuestion.id })
  }

  isChildSelected (group) {
    const { selected, questions } = this.props

    if (_.isEmpty(questions) || _.isEmpty(selected)) {
      return false
    }
    const subQuestions = this.getGroupQuestions(questions, group)

    const selectedQuestionCount = _.intersectionBy(selected, _.chain(subQuestions).values().flatten().value(), 'id').length
    if (selectedQuestionCount === 0) {
      return false
    }

    return selectedQuestionCount === subQuestions.length ? true : 2
  }

  checkStatus (group) {
    return this.isSelected() || this.isChildSelected(group)
  }

  leadersUrl () {
    const options = {}
    if (this.props.team.id) {
      options.query = { teamId: this.props.team.id }
    }

    return routeBuilder('listing', {
      type: this.props.type.key,
      level: 'lever',
      value: this.props.group.id
    }, options)
  }

  renderScore (group) {
    if (this.props.highlightProgress) {
      return <ProgressIndicator value={group || this.props.group} />
    }

    return <RegularScore group={group || this.props.group} />
  }

  getGroupQuestions (questions = [this.props.checkQuestion], group = this.props.group) {
    return questions.filter(q => [q.grouping1, q.grouping2, q.grouping3].includes(group.name))
  }

  renderSubgroup (group) {
    // If no subgroups, render the questions in this group
    if (!group.groups) {
      return this.renderExpanded(this.getGroupQuestions(this.props.questions || [this.props.checkQuestion], group))
    }
    // Render each group (these will be the lowest tier, i.e. grouping1)
    return (
      this.props.group.groups.map(subGroup => {
        const checkStatus = this.checkStatus(subGroup)

        const classNames = ['Card']
        const isExpanded = this.state.expandedItems.includes(subGroup.id)
        if (isExpanded) {
          classNames.push('Subgroup--expanded')
        }

        return (
          <div className={classNames.join(' ')} key={subGroup.id} onClick={e => this.toggleExpandedItems(e, subGroup.id)}>
            <div className='Card--main Subgroup'>
              <div className='Card--selectors'>
                <Tick onChange={e => this.onTick(e, subGroup)} checked={checkStatus} className='Card--tick' />
                {CHILD}
                {this.props.isExpandable ? EXPAND : null}
              </div>
              <div className='Card--title'>
                <div>{subGroup.name}</div>
              </div>
              <div className='Card--score'>
                {this.renderScore(subGroup)}
              </div>
              <div className='Card--link'>
                <Link to={this.leadersUrl()}>{this.props.t('team_details.view_leaders')}</Link>
              </div>
            </div>
            {isExpanded && this.renderExpanded(this.getGroupQuestions(this.props.questions, subGroup))}
          </div>
        )
      }))
  }

  render () {
    const { title, group, isExpandable } = this.props
    const checkStatus = this.checkStatus(group)
    const classNames = ['Card']
    if (checkStatus) {
      classNames.push('Card--selected')
    }
    if (isExpandable) {
      classNames.push('Card--expandable')
    }
    if (this.state.isExpanded) {
      classNames.push('Card--expanded')
    }

    return (
      <div className={classNames.join(' ')} onClick={e => this.onExpand(e, group)}>
        <div className='Card--main'>
          <div className='Card--selectors main'>
            <Tick onChange={e => this.onTick(e, group)} checked={checkStatus} className='Card--tick' />
            {CHILD}
            {isExpandable ? EXPAND : null}
          </div>
          <div className='Card--title'>
            <div>{title}</div>
          </div>
          <div className='Card--score'>
            {this.renderScore()}
          </div>
          <div className='Card--link'>
            <Link to={this.leadersUrl()}>{this.props.t('team_details.view_leaders')}</Link>
          </div>
        </div>
        {this.state.isExpanded && this.renderSubgroup(this.props.group)}
      </div>
    )
  }
}

export default withTranslation()(Card)
