
import React from 'react'
import { Link } from 'react-router-dom'
import { support } from '@mid/sdk'

import { routeBuilder } from '../../../../utils/routing'
import { downloadFile } from '../../../../utils/files'
import RestrictTo from '../../../../components/RestrictTo'
import { withTranslation } from 'react-i18next'

class ShareMenu extends React.Component {
  constructor (props) {
    super(props)
    this.navigate = this.navigate.bind(this)
    this.downloadExcel = this.downloadExcel.bind(this)
    this.state = {
      tenant: support.getTenant()
    }
  }

  navigate () {
    this.props.onNavigate && this.props.onNavigate()
  }

  downloadExcel () {
    this.props.download(this.props.metadata).then((response) => {
      if (response.payload && response.payload.data) {
        downloadFile(response.payload.data, this.state.tenant.displayName, 'xlsx')
      }
    })
  }

  render () {
    const { t } = this.props

    return (
      <ul className='Header--menu-links'>
        <RestrictTo userRole='presenter'>
          <li><Link to={routeBuilder('powerpoint')} onClick={this.navigate}>{t('share_menu.ppt')}</Link></li>
        </RestrictTo>
        <RestrictTo userRole='downloader'>
          <li><button disabled={this.props.excelLoading} onClick={this.downloadExcel}>{t('share_menu.xlsx')}</button></li>
        </RestrictTo>
      </ul>
    )
  }
}

export default withTranslation()(ShareMenu)
