
import React from 'react'
import SearchInput from 'react-search-input'
import { useTranslation } from 'react-i18next'

import './style.scss'

export default function SidebarSearch (props) {
  const { t } = useTranslation()
  return (
    <SearchInput
      placeholder={t('shared.search')}
      className='SidebarSearch'
      inputClassName='form-control'
      onChange={props.onChange}
    />
  )
}
