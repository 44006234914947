
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'

import { nextAnimationFrame } from '../../utils/dom'

import './style.scss'

function sidebarActuallyVisible (el) {
  return el.offsetLeft === 0 || el.offsetLeft + el.offsetWidth === document.body.offsetWidth
}

let listentScroll = false
let lastScroll = 0

const MAX_TOP = 98
const MIN_TOP = 48

function onScroll () {
  lastScroll = document.documentElement.scrollTop
  nextAnimationFrame(updateSidebars)
}

function updateSidebars () {
  const sidebars = document.getElementsByClassName('js-sidebar-scroll')
  const newTop = Math.max(MIN_TOP, Math.min(MAX_TOP - lastScroll, MAX_TOP)) + 'px'

  for (let sidebar of sidebars) {
    sidebar.style.top = newTop
  }
}

function addScrollListener (fun) {
  if (!listentScroll) {
    window.addEventListener('scroll', onScroll)
    listentScroll = true
  }
}

function removeScrollListener () {
  const sidebars = document.getElementsByClassName('js-sidebar-scroll')

  if (sidebars.length === 0) {
    window.removeEventListener('scroll', onScroll)
    listentScroll = false
  }
}

export default class Sidebar extends Component {
  constructor (props) {
    super(props)

    this._onWindowClick = this._onWindowClick.bind(this)
    addScrollListener()
  }

  componentDidMount () {
    window.addEventListener('click', this._onWindowClick)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this._onWindowClick)
    removeScrollListener()
  }

  _onWindowClick (event) {
    const sidebar = findDOMNode(this)
    if (sidebar !== event.target && !sidebar.contains(event.target)) {
      this.close()
    }
  }

  close () {
    const sidebar = findDOMNode(this)
    if (this.props.isOpen && sidebarActuallyVisible(sidebar)) {
      this.toggle()
    }
  }

  toggle () {
    this.props.togglePopup()
  }

  render () {
    const { isOpen } = this.props

    const classNames = ['Sidebar', 'js-sidebar-scroll']

    if (isOpen) {
      classNames.push('show')
    } else {
      classNames.push('close')
    }

    if (this.props.right) {
      classNames.push('right-hand')
    } else {
      classNames.push('left-hand')
    }

    return (
      <aside className={classNames.join(' ')}>
        {this.props.children}
      </aside>
    )
  }
}
