
import React, { Component } from 'react'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

import './style.scss'

export class ShareView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      success: false
    }
  }

  componentWillUnmount () {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  onClick (event) {
    event.preventDefault()
    const ids = this.props.questions.map(it => it.id)
    const teamId = this.props.team.id
    const metadata = this.props.metadata

    this.props.email('latest', metadata, teamId, ids)
      .then(response => {
        if (response.payload && response.payload.status === 200) {
          this.setState({ success: true })
          _.each(this.props.questions, this.props.toggleQuestion)
          this.timer = setTimeout(() => this.setState({ success: false }), 5000)
        }
      })
  }

  renderButton () {
    return (
      <button className='ShareView--button' onClick={e => this.onClick(e)}>{this.props.t('email.share')}</button>
    )
  }

  renderLoading () {
    return (
      <p className='ShareView--notice'>
        {this.props.t('email.loading')}
      </p>
    )
  }

  renderError () {
    return (
      <p className='ShareView--notice'>
        {this.props.t('email.error', { error: this.props.error })}
      </p>
    )
  }

  renderSuccess () {
    return (
      <p className='ShareView--notice'>
        {this.props.t('email.success')}
      </p>
    )
  }

  render () {
    const className = this.props.questions.length > 0 || this.state.success ? '' : 'hide'

    let label
    if (this.state.success) {
      label = this.renderSuccess()
    } else if (this.props.loading) {
      label = this.renderLoading()
    } else if (this.props.error) {
      label = this.renderError()
    } else {
      label = this.renderButton()
    }

    return (
      <div className={`ShareView--container center ${className}`}>
        {label}
      </div>
    )
  }
}

export default withTranslation()(ShareView)
