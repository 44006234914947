
export function nextAnimationFrame (fn) {
  (window.requestAnimationFrame && window.requestAnimationFrame(fn)) || window.setTimeout(fn, 1000 / 60)
}

export function getWidth (el) {
  return el.getBoundingClientRect().width
}

export function getEndPosition (el) {
  const bodyRect = document.body.getBoundingClientRect()
  const elRect = el.getBoundingClientRect()

  const left = elRect.left - bodyRect.left
  const width = elRect.width

  return left + width
}

export function easeInOut (time, start, change, duration) {
  return -change / 2 * (Math.cos(Math.PI * time / duration) - 1) + start
}

export function throttle (fun, duration) {
  let timer

  function executor () {
    timer = null
    fun()
  }

  return function throttled () {
    if (!timer) {
      timer = window.setTimeout(executor, duration)
    }
  }
}

export function scrollToTop (container, button) {
  const startTime = new Date().getTime()
  const startScroll = container.scrollTop
  const duration = 300

  function move () {
    const currentTime = new Date().getTime()
    const pointInTime = currentTime - startTime

    if (pointInTime >= duration) {
      container.scrollTo(0, 0)
      checkScrollVisibility(container, button)
      return
    }

    const pos = easeInOut(pointInTime, startScroll, -startScroll, duration)

    container.scrollTo(0, pos)
    nextAnimationFrame(move)
  }

  nextAnimationFrame(move)
}

export function scrollToHighlighted (container, button, highlighted) {
  if (highlighted) {
    container.scrollTo(0, highlighted.offsetTop)
    checkScrollVisibility(container, button)
  }
}

export function checkScrollVisibility (container, button) {
  if (container.scrollTop > 0) {
    button.classList.add('visible')
    return
  }

  button.classList.remove('visible')
}

export function getPathname () {
  const htmlBase = getHtmlBase()

  if (htmlBase === '/') {
    return window.location.pathname
  }

  if (window.location.pathname + '/' === htmlBase) {
    return '/'
  }

  return window.location.pathname.replace(htmlBase, '/')
}

export function getHtmlBase () {
  const base = document.getElementsByTagName('base')[0]

  if (!base) {
    return '/'
  }

  return base.getAttribute('href')
}

export function fullBasename (basename) {
  const htmlBase = getHtmlBase()

  if (htmlBase.startsWith('/')) {
    return htmlBase + basename
  }

  return '/' + htmlBase + basename
}

export function reloadPage () {
  return window.location.reload()
}

export function navigate (location) {
  const url = getHtmlBase() + location
  window.location = url.replace(/\/\//gi, '/')
}
