
import React, { Fragment } from 'react'
import Scale from '../SelectionScale'

import './style.scss'

export default function CompoundQuestion ({ question }) {
  let questionEl = null
  if (question.question_text) {
    questionEl = (
      <Fragment>
        <h3>{question.question_text}</h3>
      </Fragment>)
  }

  const responses = (
    <Fragment>
      <div className='CompoundQuestionSection'>
        <div className='SubQuestion'>
          <div className='SubQuestion--text' />
          <div className='SubQuestion--selections'>
            {
              question.subQuestions[0].selections.slice(0, question.possibleResponseCount).map((s, i) => {
                const displayValue =
                  question.responsesText && question.responsesText[i]
                    ? question.responsesText[i].text
                    : i + 1
                return <div key={`${question.id}-header-${i}`} className='QuestionCard--header'>{displayValue}</div>
              })
            }
          </div>
        </div>
      </div>
      <div className='CompoundQuestionSection'>
        {
          question.subQuestions.map(q => {
            return <div key={`${question.id}-${q.id}`} className='SubQuestion'>
              <div className='SubQuestion--text'>
                {q.selection}
              </div>
              <div className='SubQuestion--selections'>
                {q.selections.slice(0, q.possibleResponseCount).map((s, i) => {
                  const score = s ? Math.round(s) : 0
                  return (<Fragment key={`${question.id}-${i}`}>
                    <div className='QuestionCard--score'>{score}%</div>
                    <Scale
                      level={score}
                      index={q.selectionCount}
                    />
                  </Fragment>)
                })}
              </div>
            </div>
          })
        }
      </div>
    </Fragment>
  )

  return (
    <div key={`q-${question.id}`} className='CompoundQuestion'>
      {questionEl}
      {responses}
    </div>
  )
}
