
import React from 'react'
import _ from 'lodash'

import './style.scss'

let id = 0

function generateId () {
  id += 1

  return `custom-radio-${id}`
}

export default function Radio (props) {
  const className = props.className || ''
  const id = props.id || generateId()
  props = _.omit(props, ['className', 'id'])

  return (
    <span className={`Radio ${className}`}>
      <input id={id} type='radio' {...props} />
      <label htmlFor={id} />
    </span>
  )
}
