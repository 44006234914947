
import FileDownload from 'js-file-download'

export function escapeFsName (name) {
  return name.toLowerCase().replace(/[?<>:*|"\\ ]/g, '_')
}

export function fileNameWithDate (name) {
  const tenantName = name.replace(/[^a-z0-9]/gmi, '')
  const dateParts = new Date().toDateString().split(' ')
  const date = `${dateParts[1]}${dateParts[2]}`
  const locale = new Date().toLocaleTimeString('en-US').split(' ')
  const time = locale[0].split(':').slice(0, 2).join('_')
  return `${tenantName}_Agile360_${date}_${time}${locale[1]}`
}

export function downloadFile (content, name, extension) {
  const fileName = fileNameWithDate(name)
  FileDownload(content, `${escapeFsName(fileName)}.${extension}`)
}
