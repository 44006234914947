
import _ from 'lodash'

import { isNotAsked, isNotAnswered } from './index'

const CALCULATIONS = [{
  name: 'Legacy',
  key: 'legacy'
}, {
  name: 'V2',
  key: '2'
}, {
  name: 'Legacy Linear Average',
  key: 'linear-avg-1-5'
}, {
  name: 'Linear Average Default',
  key: 'linear-avg-1-5-alternate',
  active: true
}, {
  name: 'NPS Default',
  key: 'nps-outcome-1-10',
  active: true
}, {
  name: 'TQ',
  key: 'tq',
  active: true
}, {
  name: 'DnAQ',
  key: 'dnaq',
  active: true
}]

export const DELTA_LABELS = [
  'High Decrease',
  'Decrease',
  'Stable',
  'Increase',
  'High Increase'
]

export function calculationTypeName (key) {
  const calc = _.find(CALCULATIONS, { key })
  return calc && calc.name
}

export function calculateProgress (latestScore, previousScore, thresholds) {
  if (isNotAsked(latestScore)) {
    return null
  }

  if (isNotAnswered(latestScore) || isNotAsked(previousScore) || isNotAnswered(previousScore)) {
    return { isProgress: true, responses: 0 }
  }

  const value = Math.round((latestScore.value - previousScore.value) * 10) / 10
  const responses = Math.min(latestScore.responses, previousScore.responses)

  let i = 0
  for (; i < thresholds.length; i++) {
    if (value < thresholds[i]) {
      break
    }
  }

  return {
    value,
    responses,
    index: i + 1,
    progress: 0,
    label: DELTA_LABELS[i],
    isProgress: true
  }
}
