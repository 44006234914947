import { connect } from 'react-redux'
import Component from './Component'
import * as actions from '../../../services/surveys/actions'

function mapDispatchToProps (dispatch) {
  return {
    upload: (survey, formData) => dispatch(actions.upload(survey, formData))
  }
}

export default connect(null, mapDispatchToProps)(Component)
