
import Immutable from 'seamless-immutable'
import _ from 'lodash'

import * as types from './types'
import { flattenTeams } from '../../utils/helpers'

const initialState = Immutable({
  order: 'score',
  sortDir: 'desc',
  grouped: '1',
  current: null,
  history: null
})

function toggleDir (dir) {
  if (dir === 'asc') {
    return 'desc'
  }

  return 'asc'
}

function prepareTeams (data) {
  return {
    type: data.type,
    level: data.level,
    value: data.value,
    teams: structurizeTeams(data.teams)
  }
}

function setSorting (state, order) {
  const direction = state.order === order ? toggleDir(state.sortDir) : 'asc'
  return state.merge({
    order,
    sortDir: direction
  })
}

function structurizeTeams (teams) {
  const results = {}

  const iterate = function (teams, parents = []) {
    for (let team of teams) {
      if (!results[team.level]) {
        results[team.level] = []
      }

      const t = team.id === undefined ? _.omit(team, 'id') : team
      results[team.level].push({
        ...t,
        parents
      })

      if (team.teams) {
        iterate(team.teams, [...parents, team.name])
      }
    }
  }

  if (teams) {
    iterate(teams)
  }

  // hack so that top level always show organisation, even if there are only two levels
  results[1] = [{
    ...results[0][0],
    ...{ teams: results[1] }
  }]

  return results
}

function prepareHistoryTeams (data) {
  const flat = flattenTeams(data.teams)
  const history = _.chain(flat)
    .groupBy('id')
    .mapValues(value => value[0].score)
    .value()

  return {
    type: data.type,
    level: data.level,
    value: data.value,

    teams: history
  }
}

export default function scores (state = initialState, action) {
  switch (action.type) {
    case types.RETRIEVE_REQUEST:
      return state.set('current', null)

    case types.RETRIEVE_SUCCESS:
      return state
        .set('raw', action.payload.data)
        .set('current', prepareTeams(action.payload.data))

    case types.RETRIEVE_FAILURE:
      return state.set('current', null)

    case types.RETRIEVE_HISTORY_REQUEST:
      return state.set('history', null)

    case types.RETRIEVE_HISTORY_SUCCESS:
      return state
        .set('history', prepareHistoryTeams(action.payload.data))

    case types.RETRIEVE_HISTORY_FAILURE:
      return state.set('history', null)

    case types.SORT:
      return setSorting(state, action.order)

    case types.GROUP:
      return state.set('grouped', action.group)

    default:
      return state
  }
}
