import App from './App'

import '@mds/mds-bootstrap-theme/dist/mck-bootstrap.css'
import '@cs/mid-react/style.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Mid } from '@mid/sdk'
import {
  makeMidConfig,
  LoginWrapper,
  LoginWelcome,
  LoginTenants,
  LoginLoading
} from '@cs/mid-react'
import config from './config'

async function render () {
  const midConfig = await makeMidConfig({
    apiUrl: config.apiUrl,
    basePath: config.baseUrl,
    enableCookieAuth: config.enableCookieAuth
  })
  const mid = new Mid(midConfig)

  ReactDOM.render(
    <LoginWrapper
      mid={mid}
      apiUrl={config.apiUrl}
      loadingNode={LoginLoading}
      tenantSelectorNode={LoginTenants}
      loginNode={(props) => <LoginWelcome title='Welcome to My Solution' {...props} />}
    >
      <App mid={mid} />
    </LoginWrapper>,
    document.getElementById('root')
  )
}

ReactDOM.render(<LoginLoading />, document.getElementById('root'))
render().catch((err) => {
  console.error(err)
  ReactDOM.render(<div>Error: {err.message}</div>, document.getElementById('root'))
})
