import * as types from './types'
import Immutable from 'seamless-immutable'
import _ from 'lodash'

import { flattenTeams } from '../../utils/helpers'

const initialState = Immutable({
  loading: false,
  logo: null,
  error: null,

  questions: {},
  teams: {},
  parentMap: {}
})

export default function admin (state = initialState, action) {
  switch (action.type) {
    case types.QUESTIONS_RETRIEVE_REQUEST:
      return state.merge({
        loading: true
      })

    case types.QUESTIONS_RETRIEVE_SUCCESS:
      return state
        .set('loading', false)
        .setIn(['questions', action.meta.previousAction.surveyId], action.payload.data.questions, { deep: true })

    case types.QUESTIONS_RETRIEVE_FAILURE:
      return state.merge({
        loading: false,
        error: _.get(action.error, 'response.data.message') || action.error.message
      })

    case types.TEAMS_RETRIEVE_REQUEST:
      return state.merge({
        loading: true
      })

    case types.TEAMS_RETRIEVE_SUCCESS:
      const teams = flattenTeams(action.payload.data.teams)
      const parentMap = buildParentMap(teams)
      return state
        .set('loading', false)
        .setIn(['teams', action.meta.previousAction.surveyId], teams, { deep: true })
        .setIn(['parentMap', action.meta.previousAction.surveyId], parentMap, { deep: true })

    case types.TEAMS_RETRIEVE_FAILURE:
      return state.merge({
        loading: false,
        error: _.get(action.error, 'response.data.message') || action.error.message
      })

    default:
      return state
  }
}

const buildParentMap = teams => {
  const result = {}
  teams.forEach(team => {
    result[team.id] = team.parentId
  })

  return result
}
