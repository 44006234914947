
import React, { Component } from 'react'
import { Route, Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { support } from '@mid/sdk'

import ShareMenu from './components/ShareMenu'
import ProfileMenu from './components/ProfileMenu'
import TenantMenu from './components/TenantMenu'
import Logo from './components/Logo'

import Dropdown from '../Dropdown'
import RestrictTo from '../RestrictTo'
import * as ICONS from '../../utils/icons'

import { nextAnimationFrame, getWidth, getEndPosition } from '../../utils/dom'

import './style.scss'

export class Header extends Component {
  constructor (props) {
    super(props)

    this.state = {
      tenant: support.getTenant()
    }

    this.onResize = this.onResize.bind(this)
    this.checkSize = this.checkSize.bind(this)
  }

  componentDidMount () {
    window.addEventListener('resize', this.onResize)
    window.addEventListener('orientationchange', this.onResize)
    this.onResize()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('orientationchange', this.onResize)
  }

  onResize () {
    if (!this.resizeTimer) {
      this.resizeTimer = true
      nextAnimationFrame(this.checkSize)
    }
  }

  checkSize () {
    this.resizeTimer = false

    const totalWidth = getWidth(this.refs.header)
    const lastElement = this.refs.header.querySelector('nav > :last-child')
    const finalPosition = getEndPosition(lastElement)

    this.setState({
      profileWidth: totalWidth - finalPosition
    })
  }

  closeDropdowns () {
    if (this.refs.tenantMenu) {
      this.refs.tenantMenu.close()
    }
    if (this.refs.profileMenu) {
      this.refs.profileMenu.close()
    }
    if (this.refs.shareMenu) {
      this.refs.shareMenu.close()
    }
  }

  renderLink (path, name, route = undefined) {
    return (
      <Route key={path} path={route || path} exact children={({ match }) => (
        <Link className={match ? 'active' : undefined} to={path}><span>{name}</span></Link>
      )} />)
  }

  renderTenantIcon () {
    const name = this.state.tenant ? this.state.tenant.displayName : ''

    return (
      <div className='Header--menu-icon'>
        {name.substring(0, 2)}
      </div>
    )
  }

  renderProfileIcon () {
    return (
      <div className='Header--menu-icon'>
        {ICONS.PROFILE}
      </div>
    )
  }

  renderShareIcon () {
    return (
      <div className='Header--menu-icon'>
        {ICONS.SHARE}
      </div>
    )
  }

  renderTenantName () {
    return this.state.tenant && this.state.tenant.displayName
  }

  renderTenantMenu () {
    return (
      <Dropdown className='Header--menu' text={this.renderTenantIcon()} ref='tenantMenu' tooltip={this.props.t('tenant_menu.tooltip')}>
        <TenantMenu onNavigate={e => this.closeDropdowns()} />
      </Dropdown>)
  }

  renderProfileMenu () {
    return (
      <Dropdown className='Header--menu' text={this.renderProfileIcon()} ref='profileMenu' tooltip={this.props.t('profile_menu.tooltip')}>
        <ProfileMenu onNavigate={e => this.closeDropdowns()} logout={() => this.props.logout()} />
      </Dropdown>)
  }

  renderShareMenu () {
    if (!this.props.isDataAvailable) {
      return null
    }

    return (
      <RestrictTo userRoles={['downloader', 'presenter']}>
        <Dropdown className='Header--menu' text={this.renderShareIcon()} ref='shareMenu' tooltip={this.props.t('share_menu.tooltip')}>
          <ShareMenu onNavigate={e => this.closeDropdowns()} />
        </Dropdown>
      </RestrictTo>)
  }

  openTenantDropdown (ev) {
    if (ev) {
      ev.preventDefault()
      ev.stopPropagation()
    }

    if (!this.refs.tenantMenu) {
      this.closeDropdowns()
      return
    }

    if (this.refs.tenantMenu.isOpen()) {
      this.closeDropdowns()
      return
    }

    this.closeDropdowns()
    this.refs.tenantMenu.open()
  }

  render () {
    const { t, type } = this.props

    const profileStyle = {}
    if (this.state.profileWidth) {
      profileStyle.width = `${this.state.profileWidth}px`
    }

    return (
      <header className='Header' ref='header'>
        <div className='Header--logo'>
          <Logo />
        </div>
        <div className='Header--profile' style={profileStyle}>
          <div className='Header--tenant' onClick={(ev) => this.openTenantDropdown(ev)}>
            {this.renderTenantName()}
          </div>
          {this.renderTenantMenu()}
          {this.renderProfileMenu()}
          {this.renderShareMenu()}
        </div>
        <nav>
          {this.renderLink('/results', t('header.teams'), '/results/:type?/:team?')}
          {this.renderLink(`/patterns/${type}`, t('header.heatmap'), '/patterns/:type')}
          {this.renderLink(`/teams-score/${type}/total`, t('header.leaders'), '/teams-score/:type/total')}
          {this.props.showDistribution ? this.renderLink('/distribution', t('header.distribution')) : null}
        </nav>
      </header>
    )
  }
}

export default withTranslation()(Header)
