import _ from 'lodash'

import * as types from './types'

import { idFromMetadata } from '../../utils/metadata'
import { prepareSurveyId } from '../../utils/surveys'

const LOAD_CHUNK_SIZE = 50

export function retrieve (surveyId = 'latest', type, metadata, teamIds = []) {
  return (dispatch, getState) => {
    if (surveyId === 'progress') {
      dispatch(retrieve('latest', type, metadata, teamIds))
      dispatch(retrieve('previous', type, metadata, teamIds))
      return
    }

    if (surveyId === 'benchmark') {
      surveyId = 'latest'
    }

    const state = getState()
    const { deltaThresholds } = state.profile

    const currentData = _.get(state.organisationDetails, ['data', surveyId, type], [])
    const currentIds = _.keys(currentData)
    const teamsToLoad = _.difference(teamIds, currentIds)

    if (teamsToLoad.length === 0) {
      return Promise.resolve()
    }

    const tasks = []

    while (teamsToLoad.length) {
      tasks.push(dispatch(retrieveCall(surveyId, type, metadata, teamsToLoad.splice(0, LOAD_CHUNK_SIZE), deltaThresholds)))
    }

    return Promise.all(tasks)
  }
}

function retrieveCall (surveyId, type, metadata, teamIds, deltaThresholds) {
  const url = `/data/${prepareSurveyId(surveyId)}/${idFromMetadata(metadata)}/details/${type}/partial`
  return {
    types: [
      types.RETRIEVE_REQUEST,
      types.RETRIEVE_SUCCESS,
      types.RETRIEVE_FAILURE
    ],
    params: {
      type,
      surveyId,
      deltaThresholds,
      teams: teamIds
    },
    payload: {
      surveyId,
      request: {
        method: 'POST',
        url,
        data: { teamIds },
        skipLoading: true,
        ignoreNoSurvey: surveyId === 'previous'
      }
    }
  }
}
