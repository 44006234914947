
import _ from 'lodash'
import * as types from './types'

export function retrieve (skipLoading = false) {
  return {
    types: [ types.RETRIEVE_REQUEST, types.RETRIEVE_SUCCESS, types.RETRIEVE_FAILURE ],
    payload: {
      request: {
        method: 'GET',
        url: `/surveys`,
        skipLoading
      }
    }
  }
}

export function save (survey) {
  return {
    types: [ types.SAVE_REQUEST, types.SAVE_SUCCESS, types.SAVE_FAILURE ],
    payload: {
      request: {
        method: 'POST',
        url: `/surveys`,
        data: survey
      }
    }
  }
}

export function update (survey) {
  return {
    types: [ types.SAVE_REQUEST, types.SAVE_SUCCESS, types.SAVE_FAILURE ],
    payload: {
      request: {
        method: 'PUT',
        url: `/surveys/${survey.id}`,
        data: _.omit(survey, 'id')
      }
    }
  }
}

export function upload (surveyId, formData) {
  return {
    types: [ types.UPLOAD_REQUEST, types.UPLOAD_SUCCESS, types.UPLOAD_FAILURE ],
    payload: {
      request: {
        method: 'POST',
        url: `/surveys/${surveyId}/imports`,
        data: formData,
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
    }
  }
}

export function activate (surveyId, id) {
  return {
    types: [ types.ACTIVATE_REQUEST, types.ACTIVATE_SUCCESS, types.ACTIVATE_FAILURE ],
    payload: {
      request: {
        method: 'POST',
        url: `/surveys/${surveyId}/imports/${id}/activate`
      }
    }
  }
}

export function remove (surveyId) {
  return {
    types: [ types.REMOVE_REQUEST, types.REMOVE_SUCCESS, types.REMOVE_FAILURE ],
    payload: {
      request: {
        method: 'DELETE',
        url: `/surveys/${surveyId}`
      }
    },
    surveyId: surveyId
  }
}
