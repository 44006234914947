import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'

import ContentWidth from '../../../components/ContentWidth'
import { Alert } from '../../../components'
import Autocomplete from '../../../components/AutocompleteInput'
import { routeBuilder } from '../../../utils/routing'

const MONTHS = [{
  id: 1,
  name: 'January'
}, {
  id: 2,
  name: 'February'
}, {
  id: 3,
  name: 'March'
}, {
  id: 4,
  name: 'April'
}, {
  id: 5,
  name: 'May'
}, {
  id: 6,
  name: 'June'
}, {
  id: 7,
  name: 'July'
}, {
  id: 8,
  name: 'August'
}, {
  id: 9,
  name: 'September'
}, {
  id: 10,
  name: 'October'
}, {
  id: 11,
  name: 'November'
}, {
  id: 12,
  name: 'December'
}]

const THIS_YEAR = new Date().getFullYear()
const YEARS = _.times(6, index => {
  const year = THIS_YEAR + 1 - index
  return {
    id: year,
    name: String(year)
  }
})

function getSurveyNameFromDate (date) {
  return moment(date).format('MMM YYYY')
}

function getSurveyName (month, year) {
  return getSurveyNameFromDate(new Date(year, month - 1))
}

function createEmptySurvey () {
  const now = new Date()
  return {
    name: getSurveyNameFromDate(now),
    description: '',
    month: now.getMonth() + 1,
    year: now.getFullYear()
  }
}

function prepareSurvey (survey) {
  if (!survey) {
    return createEmptySurvey()
  }

  return {
    id: survey.id,
    name: survey.name,
    description: survey.description,
    month: survey.month,
    year: survey.year
  }
}

export default class SurveyForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      nameChanged: !!props.survey,
      survey: prepareSurvey(props.survey)
    }
  }

  onChange (name, value) {
    this.setState({
      error: null,
      survey: Object.assign(this.state.survey, {
        [name]: value
      })
    })
  }

  changeName (value) {
    this.setState({
      error: null,
      nameChanged: true,
      survey: Object.assign(this.state.survey, {
        name: value
      })
    })
  }

  changeDate (name, value) {
    const newSurvey = Object.assign(this.state.survey, {
      [name]: value
    })

    if (!this.state.nameChanged) {
      newSurvey.name = getSurveyName(newSurvey.month, newSurvey.year)
    }

    this.setState({
      error: null,
      survey: newSurvey
    })
  }

  onSubmit (event) {
    event.preventDefault()

    if (this.validate()) {
      this.props.save(this.state.survey)
        .then(resp => {
          const { status } = resp.payload || {}
          if (status === 200) {
            this.props.history.push(routeBuilder('adminSurveys'))
          }
        })
    }
  }

  validate () {
    const { survey } = this.state
    if (!survey.name) {
      this.setState({ error: 'Name can\'t be empty' })
      return false
    }

    if (!survey.month) {
      this.setState({ error: 'Choose a month' })
      return false
    }

    if (!survey.year) {
      this.setState({ error: 'Choose a year' })
      return false
    }

    return true
  }

  renderError () {
    const error = this.state.error || this.props.error
    if (!error) {
      return null
    }

    return (
      <div className='Form--error'>
        <Alert message={error} />
      </div>)
  }

  renderLabel () {
    if (this.state.survey.id) {
      return 'Edit Survey'
    }

    return 'Create a survey'
  }

  render () {
    return (
      <ContentWidth layout='full'>
        <div>
          <h1>{this.renderLabel()}</h1>
          <form onSubmit={(event) => this.onSubmit(event)} className='Form'>
            {this.renderError()}
            <div className='clearfix'>
              <div className='Form--input col col-9'>
                <label htmlFor='form_name'>
                  Name
                </label>
                <input type='text'
                  id='form_name'
                  value={this.state.survey.name}
                  onChange={event => this.changeName(event.target.value)}
                />
              </div>
              <div className='Form--input col col-2'>
                <label htmlFor='form_period'>
                  Month
                </label>
                <Autocomplete
                  value={MONTHS[this.state.survey.month - 1]}
                  options={MONTHS}
                  onChange={value => this.changeDate('month', value.id)}
                  hideNull
                  hideSearch
                  defaultStyle
                  sortBy='id'
                />
              </div>
              <div className='Form--input col col-1'>
                <label htmlFor='form_period'>
                  Year
                </label>
                <Autocomplete
                  value={_.find(YEARS, { id: this.state.survey.year })}
                  options={YEARS}
                  onChange={value => this.changeDate('year', value.id)}
                  hideNull
                  hideSearch
                  defaultStyle
                />
              </div>
            </div>
            <div className='clearfix'>
              <div className='Form--input col col-12'>
                <label htmlFor='form_description'>
                  Description (optional)
                </label>
                <input type='text'
                  id='form_description'
                  spellCheck='true'
                  value={this.state.survey.description}
                  onChange={event => this.onChange('description', event.target.value)}
                />
              </div>
            </div>
            <div className='Form--actions'>
              <Link className='Form--cancel' to='/admin/surveys'>Cancel</Link>
              <input type='submit' value='Save' className='Form--button' />
            </div>
          </form>
        </div>
      </ContentWidth>
    )
  }
}
