
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import { support } from '@mid/sdk'

import MetadataSelector from '../../../../components/MetadataSelector'
import SurveysSelector from '../../../../components/SurveysSelector'
import RestrictTo from '../../../../components/RestrictTo'
import { routeBuilder } from '../../../../utils/routing'
import * as ICONS from '../../../../utils/icons'

import './style.scss'

export class TenantMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      tab: 'home',
      tenant: support.getTenant()
    }

    this.navigate = this.navigate.bind(this)
  }

  navigate () {
    this.props.onNavigate && this.props.onNavigate()
  }

  show (tab) {
    this.setState({ tab })
  }

  metadataList () {
    return this.props.metadata.map(m => m.name).join(', ')
  }

  clickBack (ev) {
    ev.stopPropagation()
    ev.preventDefault()

    this.show('home')
  }

  clickMetadata (ev) {
    ev.stopPropagation()
    ev.preventDefault()

    this.show('metadata')
  }

  clickSurvey (ev) {
    ev.stopPropagation()
    ev.preventDefault()

    this.show('surveys')
  }

  renderMetadata () {
    return (
      <Fragment>
        <div className='TenantMenu--back' onClick={e => this.clickBack(e)}>{ICONS.PARENT} {this.props.t('tenant_menu.back')}</div>
        <MetadataSelector />
      </Fragment>
    )
  }

  renderSurveys () {
    return (
      <Fragment>
        <div className='TenantMenu--back' onClick={e => this.clickBack(e)}>{ICONS.PARENT} {this.props.t('tenant_menu.back')}</div>
        <SurveysSelector />
      </Fragment>
    )
  }

  renderTenant () {
    const tenant = this.state.tenant || {}
    return (
      <li className='inactive'>
        {ICONS.TENANT}
        <label>{this.props.t('tenant_menu.tenant')}</label>
        <div className='content'>{tenant.displayName}</div>
      </li>
    )
  }

  surveyLabelText () {
    if (this.props.surveys.length === 0) {
      return this.props.t('tenant_menu.no_survey')
    }

    if (this.props.surveys.length === 1) {
      return this.props.surveys[0].name
    }

    return <Fragment>{this.props.surveys[0].name}{ICONS.RIGHT_ARROW}{this.props.surveys[1].name}</Fragment>
  }

  renderSurveyLabel () {
    if (_.isEmpty(this.props.availableSurveys)) {
      return null
    }

    if (this.props.availableSurveys.length < 2) {
      return (<li>
        {ICONS.SURVEY}
        <label>{this.props.t('tenant_menu.survey')}</label>
        <div className='content'>{this.surveyLabelText()}</div>
      </li>)
    }

    return (
      <li>
        {ICONS.SURVEY}
        <label>{this.props.t('tenant_menu.survey')}</label>
        <div className='content metadata' onClick={e => this.clickSurvey(e)}>{this.surveyLabelText()}{ICONS.PARENT}</div>
      </li>)
  }

  renderMetadataLabel () {
    if (!this.props.isDataAvailable) {
      return null
    }

    return (
      <li>
        {ICONS.HIERARCHY_LEVELS.L1}
        <label>{this.props.t('tenant_menu.metadata')}</label>
        <div className='content metadata' onClick={e => this.clickMetadata(e)}>{this.metadataList()}{ICONS.PARENT}</div>
      </li>)
  }

  renderRespondentsLink () {
    if (!this.props.isDataAvailable) {
      return null
    }

    if (_.isEmpty(this.props.availableSurveys)) {
      return null
    }

    const survey = this.props.surveys[0]
    const respondents = _.isNumber(survey.respondents) ? survey.respondents : 0
    const url = routeBuilder('respondents', {
      surveyId: survey.id
    })

    return (
      <RestrictTo userRole='admin'>
        <li>
          {ICONS.COMPLIANCE}
          <label>{this.props.t('tenant_menu.compliance')}</label>
          <Link className='content' to={url} onClick={() => this.navigate()}>{this.props.t('tenant_menu.respondents', { respondents })}</Link>
        </li>
      </RestrictTo>)
  }

  renderHome () {
    return (
      <ul className='TenantMenu--blocks'>
        {this.renderTenant()}
        {this.renderSurveyLabel()}
        {this.renderMetadataLabel()}
        {this.renderRespondentsLink()}
      </ul>
    )
  }

  render () {
    if (this.state.tab === 'metadata') {
      return this.renderMetadata()
    }

    if (this.state.tab === 'surveys') {
      return this.renderSurveys()
    }

    return this.renderHome()
  }
}

export default withTranslation()(TenantMenu)
