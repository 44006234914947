
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { isNotAsked, isNotAnswered } from '../../../../utils'
import { routeBuilder } from '../../../../utils/routing'
import * as ICONS from '../../../../utils/icons'

import './style.scss'

function scoreClass (score) {
  if (isNotAsked(score)) {
    return 'scoreNotAsked'
  }

  if (isNotAnswered(score)) {
    return 'scoreNA'
  }

  return `score${score.index}`
}

function formatScore (score) {
  if (isNotAsked(score)) {
    return ''
  }

  if (isNotAnswered(score)) {
    return ICONS.SCORE_NA
  }

  return score.value
}

export function Score (props) {
  const classes = ['Results--score']
  if (props.highlighted) {
    classes.push('Results--score--highlighted')
  }

  const divProps = {}

  if (props.onClick) {
    classes.push('Results--score--clickable')
    divProps.onClick = (ev) => {
      ev.stopPropagation()
      props.onClick()
    }
  }

  divProps.className = classes.join(' ')

  return (
    <div {...divProps}>
      <div className={scoreClass(props.score)}>{formatScore(props.score)}</div>
      <label>{props.label}</label>
    </div>)
}

export class Scores extends PureComponent {
  navigateToOrg () {
    this.props.history.push(routeBuilder('team', {}))
  }

  hasOnlyCurrent () {
    return !(this.props.previous !== undefined || this.props.organisation !== undefined)
  }

  currentLabel () {
    const { t } = this.props

    if (this.hasOnlyCurrent()) {
      return t('shared.organisation')
    }

    if (!this.props.surveys || !this.props.surveys[0]) {
      return t('teams.scores.current')
    }

    return this.props.surveys[0].name
  }

  previousLabel () {
    if (!this.props.surveys || !this.props.surveys[1]) {
      return this.props.t('teams.scores.previous')
    }

    return this.props.surveys[1].name
  }

  render () {
    const props = this.props
    const scores = []

    if (props.previous !== undefined) {
      scores.push(<Score key='history' score={props.previous} label={this.previousLabel()} />)
    }

    if (props.current !== undefined) {
      scores.push(<Score key='current' score={props.current} label={this.currentLabel()} />)
    }

    if (props.organisation !== undefined) {
      scores.push(<Score key='organisation' score={props.organisation} label={this.props.t('shared.organisation')} highlighted onClick={() => this.navigateToOrg()} />)
    }

    return (
      <div className='Results--scores'>
        {scores}
      </div>)
  }
}

export default withRouter(withTranslation()(Scores))
