
import { idFromMetadata } from './metadata'

export function isProgress (type) {
  return type.split('-')[1] === 'progress'
}

export function baseType (type) {
  return type.split('-')[0]
}

export function totalsPath (surveyId, metadata, type, level, value) {
  return [surveyId, idFromMetadata(metadata), type, level, value].join('-')
}
