import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import Teams from './Component'
import * as actions from '../../../../services/teams/actions'

function mapStateToProps (state, ownProps) {
  const selectedIds = ownProps.selectedIds || [_.get(ownProps, 'match.params.teamId')]
  const teams = ownProps.teams || state.teams.flatTeams

  return {
    selectedIds,
    teams: teams,
    teamPath: teamWithParents(teams || [], selectedIds[0]),
    loading: state.teams.loading,
    error: state.teams.error,
    selectedMetadata: state.metadata.selectedMetadata
  }
}

function mapDispatchToProps (dispatch) {
  return {
    retrieve: (selectedMetadata) => dispatch(actions.retrieve(selectedMetadata))
  }
}

function teamWithParents (teams, teamId) {
  const map = {}
  teams.forEach(team => {
    if (team.id) {
      map[team.id] = team
    }
  })

  const results = []
  let tempTeam = teamId && map[teamId]
  while (tempTeam) {
    results.push(tempTeam)
    tempTeam = map[tempTeam.parentId]
  }

  return results
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Teams))
