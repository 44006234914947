import * as types from './types'
import Immutable from 'seamless-immutable'
import _ from 'lodash'

import { idFromSurvey } from '../../utils/surveys'

const ADMIN_ROLE = 'admin'

const initialState = Immutable({
  loading: false,
  user: null,
  isAdmin: false,
  compareOrganisation: false,
  calculation: 'legacy',
  respondents: 'N/A',

  format: 'integer',
  headers: [],
  hierarchies: [],
  thresholds: {},
  deltaThresholds: {},

  selectedSurveyIds: ['latest', 'previous'],

  questionTypes: [{
    key: 'type1',
    style: 'maturity',
    label: 'Capability',
    plural: 'Capabilities'
  }]
})

export default function profile (state = initialState, action) {
  switch (action.type) {
    case types.RETRIEVE_REQUEST:
      return state.merge({
        loading: true
      })

    case types.RETRIEVE_SUCCESS:
      const { data } = action.payload
      const isAdmin = data.roles.indexOf(ADMIN_ROLE) >= 0

      return state.merge({
        loading: false,
        user: data,
        isAdmin
      })

    case types.RETRIEVE_FAILURE:
      return state.merge({
        loading: false
      })

    case types.LOGOUT:
      return state.merge({
        user: null,
        tenants: []
      })

    case types.SETTINGS_REQUEST:
      return state.merge({
        loading: true
      })

    case types.SETTINGS_SUCCESS:
      const newState = state.merge(action.payload.data).merge({ loading: false })

      const selectedSurveys = _.chain(newState.selectedSurveyIds)
        .map((id) => {
          if (id === 'latest') {
            return newState.surveys[0]
          }
          if (id === 'previous') {
            return newState.surveys[1]
          }

          return _.find(newState.surveys, survey => idFromSurvey(survey) === id)
        })
        .compact()
        .value()

      return newState.merge({
        selectedSurveys,
        selectedSurveyIds: _.map(selectedSurveys, idFromSurvey)
      })

    case types.SETTINGS_FAILURE:
      return state.merge({
        loading: false
      })

    case types.SET_SURVEYS:
      return state.merge({
        selectedSurveyIds: action.surveys || ['latest', 'previous']
      })

    case types.SET_COMPARISON_TYPE:
      return state.merge({
        comparisonType: action.value
      })

    default:
      return state
  }
}
