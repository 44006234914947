
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'

import Cell from '../Cell'
import { routeBuilder } from '../../../../utils/routing'

import './style.scss'

export class TeamCells extends PureComponent {
  navigate (quadrant, lever) {
    const options = {}
    const query = {}
    if (quadrant) {
      query.filter = quadrant.name
    }
    if (lever) {
      query.lever = lever.name
    }
    if (quadrant || lever) {
      options.query = query
    }

    const url = routeBuilder('teamDetails', {
      type: this.props.type.key,
      teamId: this.props.team.id || 'organisation'
    }, options)

    this.props.history.push(url)
  }

  renderGrouping1 (group, parent) {
    const score = this.props.scores[group.id]
    return (
      <Cell
        key={`group_${group.id}`}
        score={score}
        isLoading={this.props.isLoading}
        onClick={() => this.navigate(parent, group)}
      />)
  }

  renderCollapsedGroup (group, quadrant) {
    const score = this.props.scores && this.props.scores[group.id]
      ? this.props.scores[group.id]
      : null
    return (
      <Cell
        key={`group_${group.id}`}
        score={score}
        isLoading={this.props.isLoading}
        onClick={() => this.navigate(quadrant, group)}
      />)
  }

  renderGrouping2TQ (group, quadrant, collapedGroups) {
    const toRender = group.groups || [group]
    return (
      <div className='TeamCells--Group' key={`group_${group.id}`}>
        {
          collapedGroups.includes(group.id)
            ? this.renderCollapsedGroup(group, quadrant)
            : toRender.map(child => this.renderGrouping1(child, quadrant))
        }
      </div>
    )
  }

  renderGrouping2 (group, quadrant, collapedGroups) {
    return (
      <div className='TeamCells--Group' key={`group_${group.id}`}>
        {
          collapedGroups.includes(group.id)
            ? this.renderCollapsedGroup(group, quadrant)
            : group.map(child => this.renderGrouping1(child, quadrant))
        }
      </div>
    )
  }

  render () {
    const { team, scores, groups, hoverTeam, collapsedQuadrants } = this.props
    const teamId = team.id
    const grouping3 = groups

    const classNames = ['TeamCells Heatmap--row']
    if (/benchmark-/.test(team.id)) {
      classNames.push('Heatmap--row--benchmark')
    }

    return (
      <div className={classNames.join(' ')}
        data-team-id={teamId || 'organisation'}
        onMouseEnter={e => hoverTeam(e.currentTarget, true)}
        onMouseLeave={e => hoverTeam(e.currentTarget, false)}>
        <div className='TeamCells--Overall'>
          <Cell
            score={scores.total}
            isLoading={this.props.isLoading}
            onClick={() => this.navigate()} />
        </div>

        {grouping3.reduce((prev, curr) => {
          if (collapsedQuadrants.includes(curr.id)) {
            prev.push(<span className='TeamCells--Group-Container' key={`${curr.id}`}>
              {this.renderGrouping2(curr, curr, collapsedQuadrants)}
            </span>)
            return prev
          }
          const nextLevel = curr.isTQ ? curr.groups : curr.groups[0].groups
          if (curr.isTQ) {
            prev.push(<span className='TeamCells--Group3-Container' key={`${curr.id}`}>
              {nextLevel.map(g => this.renderGrouping2TQ(g, curr, collapsedQuadrants))}
            </span>)
          } else {
            prev.push(<span className='TeamCells--Group3-Container' key={`${curr.id}`}>
              {this.renderGrouping2(nextLevel, curr, collapsedQuadrants)}
            </span>)
          }
          return prev
        }, [])
        }

      </div>
    )
  }
}

export default withRouter(TeamCells)
