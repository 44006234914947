import * as types from './types'

export function error (error, status, action) {
  const payload = { error, status, action }
  return { type: types.ERROR_REQUEST, payload: payload }
}

export function noSurvey () {
  return { type: types.ERROR_NO_SURVEY }
}

export function dismiss () {
  return { type: types.ERROR_DISMISS }
}

export function longRequest (requestId) {
  return { type: types.ERROR_LONG_REQUEST, payload: { requestId } }
}

export function dismissLongRequest (requestIds) {
  return { type: types.ERROR_DISMISS_LONG_REQUEST, payload: { requestIds } }
}
