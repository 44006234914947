import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Component from './Component'
import * as actions from '../../../../services/metrics/actions'
import { getSelectedAxisData, parseParams } from '../../utils'

function mapStateToProps (state, ownProps) {
  const params = parseParams(state, ownProps.match.params)

  return {
    params,
    ...getSelectedAxisData(state, params),
    metadata: state.metadata.selectedMetadata,
    selectedSurveys: state.profile.selectedSurveys,
    grouped: state.metrics.grouped,
    groups: state.profile.groups,
    questionTypes: state.profile.questionTypes,
    benchmark: state.profile.benchmark
  }
}

function mapDispatchToProps (dispatch) {
  return {
    group: (...args) => dispatch(actions.group(...args))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))
