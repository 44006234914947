
import _ from 'lodash'

export const SURVEY_PATH_REGEXP = '[a-z0-9]{8}-[a-z0-9]{8}|[a-z0-9]{8}|latest-previous|latest'

export function idFromSurvey (survey) {
  return survey.id.substring(0, 8)
}

export function pathFromSurveys (surveys) {
  return surveys.map(idFromSurvey).join('-')
}

export function surveysFromPath (path) {
  return path.split('-')
}

export function prepareSurveyId (surveyId) {
  if (surveyId === 'latest' || surveyId === 'previous') {
    return `{${surveyId}}`
  }

  return surveyId
}

export function surveyFullName (s) {
  if (_.isNumber(s.respondents)) {
    return `${s.name} | ${s.respondents} respondents`
  }

  if (_.isEmpty(s.respondents)) {
    return s.name
  }

  return `${s.name} | ${s.respondents}`
}
