
import * as types from './types'
import * as profileTypes from '../profile/types'
import Immutable from 'seamless-immutable'
import _ from 'lodash'

import { calculateProgress } from '../../utils/calculations'
import { isNoSurveyError } from '../../utils'

const initialState = Immutable({
  data: {}
})

function parseTeam (teamObject) {
  if (!teamObject.groups) {
    return {}
  }

  const state = {}
  state.total = teamObject.score

  for (let group of teamObject.groups) {
    for (let subGroup of group.groups) {
      state[subGroup.id] = subGroup.score
      for (let subGroup2 of subGroup.groups) {
        state[subGroup2.id] = subGroup2.score
      }
    }
    state[group.id] = group.score
  }

  return state
}

export default function heatmap (state = initialState, action) {
  switch (action.type) {
    case types.RETRIEVE_REQUEST: return state
    case types.RETRIEVE_SUCCESS:
      const { type, surveyId, deltaThresholds, teams } = action.meta.previousAction.params
      const receivedTeams = action.payload.data
      let newState = primeWithEmptyScores(state, surveyId, type, teams)

      for (let teamId in receivedTeams) {
        const scores = parseTeam(receivedTeams[teamId])
        newState = newState.setIn(['data', surveyId, type, teamId], scores)
        newState = prepareProgress(newState, type, teamId, deltaThresholds[type])
        newState = prepareBenchmark(newState, type, teamId, deltaThresholds[type])
      }

      return newState

    case types.RETRIEVE_FAILURE:
      if (isNoSurveyError(action.error)) {
        const { surveyId, type, teams } = action.meta.previousAction.params
        let newState = primeWithEmptyScores(state, surveyId, type, teams)
        newState = primeWithEmptyScores(newState, 'progress', type, teams)
        return newState
      }

      return state

    case profileTypes.SETTINGS_SUCCESS:
      const benchmark = action.payload.data.benchmark
      if (!benchmark) {
        return state
      }

      return state.set('benchmark', parseBenchmarks(benchmark))

    default: return state
  }
}

function parseBenchmarks (benchmarks) {
  const benchmarkArray = Array.isArray(benchmarks) ? benchmarks : [benchmarks]
  return benchmarkArray.map(parseBenchmark)
}

function parseBenchmark (benchmark) {
  const questions = benchmark.questions
  const result = {}
  for (let questionType in questions) {
    result[questionType] = {
      total: questions[questionType].score
    }

    for (let quadrant of questions[questionType].groups) {
      for (let lever of quadrant.groups) {
        result[questionType][lever.id] = lever.score
      }
    }
  }

  return result
}

function primeWithEmptyScores (state, surveyId, type, teams) {
  if (!teams) {
    return state
  }

  for (const teamId of teams) {
    const key = ['data', surveyId, type, teamId]
    if (!_.get(state, key)) {
      state = state.setIn(key, {})
    }
  }

  return state
}

function prepareProgress (state, type, teamId, deltaThresholds) {
  const latest = _.get(state, ['data', 'latest', type, teamId])
  const previous = _.get(state, ['data', 'previous', type, teamId])
  if (!latest) {
    return state
  }

  let newState = state.setIn(['data', 'progress', type, teamId], {})
  for (let groupId in latest) {
    const latestScore = latest[groupId]
    const previousScore = previous && previous[groupId]
    const score = calculateProgress(latestScore, previousScore, deltaThresholds)

    newState = newState.setIn(['data', 'progress', type, teamId, groupId], score)
  }

  return newState
}

function prepareBenchmark (state, type, teamId, deltaThresholds) {
  if (!state.benchmark) {
    return state
  }

  const benchmark = state.benchmark[type] || {}
  const latest = _.get(state, ['data', 'latest', type, teamId])
  if (!latest) {
    return state
  }

  let newState = state.setIn(['data', 'benchmark', type, teamId], {})
  for (let groupId in latest) {
    const latestScore = latest[groupId]
    const benchmarkScore = benchmark[groupId]
    const score = calculateProgress(latestScore, benchmarkScore, deltaThresholds)

    newState = newState.setIn(['data', 'benchmark', type, teamId, groupId], score)
  }

  return newState
}
