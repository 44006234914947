import React, { Component, Fragment } from 'react'

import { select, event as lastD3Event } from 'd3-selection'
import { scaleLinear } from 'd3-scale'
import { axisLeft, axisBottom } from 'd3-axis'
import { brush } from 'd3-brush'
import { zoom } from 'd3-zoom'
import { format } from 'd3-format'
import { line, symbol, symbolCircle } from 'd3-shape'
import _ from 'lodash'

import { TooltipTeams, TooltipTeam } from '../Tooltip'
import { findPin } from '../../utils'
import * as icons from '../../../../utils/icons'

import 'classlist-polyfill'
import './style.scss'

const SYMBOL = symbol().size(60).type(symbolCircle)
const SELECTED_SYMBOL = symbol().size(260).type(symbolCircle)
const DOT_RADIUS = 4
const LABEL_MARGIN = 3

const ZOOM_FACTOR = 0.8 // on dblclick zoom 20%

function ceil (value, limit) {
  const divider = limit === 100 ? 10 : 1
  return Math.ceil(value / divider) * divider
}

function floor (value, limit) {
  const divider = limit === 100 ? 10 : 1
  return Math.floor(value / divider) * divider
}

function shouldShowReference (reference) {
  return !_.isUndefined(reference)
}

function getPositions (d, xScale, yScale) {
  let xValue = _.get(d, 'x.value', 0)
  let yValue = _.get(d, 'y.value', 0)

  const [xMin, xMax] = xScale.domain()
  if (xValue === xMax) {
    xValue -= xMax / 250
  } else if (xValue === xMin) {
    xValue += xMin / 250
  }

  const [yMin, yMax] = yScale.domain()
  if (yValue === yMax) {
    yValue -= yMax / 250
  } else if (yValue === yMin) {
    yValue += yMax / 250
  }

  return {
    x: xScale(xValue),
    y: yScale(yValue)
  }
}

function dotTransform (x, y) {
  return function (d) {
    const pos = getPositions(d, x, y)
    return `translate(${pos.x}, ${pos.y})`
  }
}

function outofScreen (current, pos, screen) {
  const rect = current.rect

  return (
    pos.y - rect.height / 2 < 0 ||
    pos.y + rect.height / 2 > screen.y ||
    pos.x < 0 ||
    pos.x + rect.width > screen.x)
}

function overlapsWithDots (current, pos, dots) {
  const rect = current.rect

  for (let i = 0, len = dots.length; i < len; i++) {
    const other = dots[i]

    if (other.id === current.id) {
      continue
    }

    const overlapX =
      pos.x <= (other.pos.x + DOT_RADIUS) &&
      pos.x + rect.width >= (other.pos.x - DOT_RADIUS)
    const overlapY =
      pos.y - rect.height / 2 <= (other.pos.y + DOT_RADIUS) &&
      pos.y + rect.height / 2 >= (other.pos.y - DOT_RADIUS)

    if (overlapX && overlapY) {
      return true
    }
  }

  return false
}

function overlapsWithLabels (current, pos, placed, labels) {
  const rect = current.rect

  for (let i = 0, len = labels.length; i < len; i++) {
    const other = labels[i]

    if (other.id === current.id) {
      continue
    }

    const place = placed[other.id]
    const placeRect = other.rect
    if (!place) {
      continue
    }

    const overlapX =
        pos.x <= place.x + placeRect.width &&
        pos.x + rect.width > place.x

    const overlapY =
        pos.y - (rect.height / 2) <= place.y + (placeRect.height / 2) &&
        pos.y + (rect.height / 2) > place.y - (placeRect.height / 2)

    if (overlapX && overlapY) {
      return true
    }
  }

  return false
}

function showLabelTooltip (data) {
  return function actualShowLabelTooltip (d) {
    const dot = this
    const tooltip = document.getElementById('labelTooltip')

    const names = _.chain(data)
      .filter((item) => item.x.value === d.x.value && item.y.value === d.y.value)
      .map('name')
      .value()

    tooltip.innerText = names.join(', ')
    tooltip.classList.add('visible')

    const { top: offsetTop, left: offsetLeft } = tooltip.parentNode.getBoundingClientRect()
    const { top, left, height, width } = dot.getBoundingClientRect()

    tooltip.style.top = `${(top + height - offsetTop)}px`
    tooltip.style.left = `${(left + width / 2 - offsetLeft)}px`
  }
}

function hideLabelTooltip (d) {
  document.getElementById('labelTooltip').classList.remove('visible')
}

export function place (labels, positions, dots, screen) {
  const rightToLeft = _.orderBy(labels, ['pinned', 'pos.x', 'pos.y', 'name.length'], ['desc', 'desc', 'desc', 'asc'])
  const leftToRight = _.orderBy(labels, ['pinned', 'pos.x', 'pos.y', 'name.length'], ['desc', 'asc', 'desc', 'asc'])
  const placed = {}

  for (let i = 0, len = rightToLeft.length; i < len; i++) {
    const label = rightToLeft[i]

    if (placed[label.id]) {
      continue
    }

    for (let j = 0, lenj = positions[label.id].right.length; j < lenj; j++) {
      const pos = positions[label.id].right[j]

      if (outofScreen(label, pos, screen)) {
        continue
      }

      if (overlapsWithDots(label, pos, dots)) {
        continue
      }

      if (overlapsWithLabels(label, pos, placed, labels)) {
        continue
      }

      placed[label.id] = pos
      break
    }
  }

  for (let i = 0, len = leftToRight.length; i < len; i++) {
    const label = leftToRight[i]

    if (placed[label.id]) {
      continue
    }

    for (let j = 0, lenj = positions[label.id].left.length; j < lenj; j++) {
      const pos = positions[label.id].left[j]

      if (outofScreen(label, pos, screen)) {
        continue
      }

      if (overlapsWithDots(label, pos, dots)) {
        continue
      }

      if (overlapsWithLabels(label, pos, placed, labels)) {
        continue
      }

      placed[label.id] = pos
      break
    }
  }

  return placed
}

function domainBoundaries (currentDomain, newDomain, limits) {
  const delta = Math.min(currentDomain[1] - currentDomain[0], limits[1] - limits[0])

  if (newDomain[0] <= limits[0]) {
    return [limits[0], limits[0] + delta]
  } else if (newDomain[1] >= limits[1]) {
    return [limits[1] - delta, limits[1]]
  } else {
    return newDomain
  }
}

function limitZoom (domain) {
  const ZOOM_LIMIT = 1
  if (domain[1] - domain[0] >= ZOOM_LIMIT) {
    return domain
  }

  const limit = domain[0] + (domain[1] - domain[0]) / 2 - ZOOM_LIMIT / 2

  return [limit, limit + ZOOM_LIMIT]
}

export class DotsChart extends Component {
  constructor (props) {
    super(props)
    this.state = {
      cursor: 'crop'
    }

    this.xDomain = props.limits.x
    this.yDomain = props.limits.y

    this.xScale = scaleLinear().range([0, props.width]).domain(this.xDomain)
    this.yScale = scaleLinear().range([props.height, 0]).domain(this.yDomain)
    this.xAxis = axisBottom().scale(this.xScale).tickPadding(10).tickFormat(this.xFormat())
    this.yAxis = axisLeft().scale(this.yScale).tickPadding(10).tickFormat(this.yFormat())

    this.chartZoom = zoom()
      .on('start', () => {
        this.storeCurrentPosition()
      })
      .on('zoom', (u) => {
        const { xDomain, yDomain } = this.movementDomains(lastD3Event.transform)

        this.move(xDomain, yDomain)
      })

    this.chartBrush = brush().extent([[0, 0], [props.width, props.height]]).on('end', () => {
      const s = lastD3Event.selection
      if (!s) {
        return
      }

      this.svgContainer.select('.brush').call(this.chartBrush.move, null)
      const newXDomain = [
        this.widthToScore(s[0][0]) + this.xDomain[0],
        this.widthToScore(s[1][0]) + this.xDomain[0]
      ]
      const newYDomain = [
        this.yDomain[1] - this.heightToScore(s[1][1]),
        this.yDomain[1] - this.heightToScore(s[0][1])
      ]

      this.zoom(newXDomain, newYDomain)
    })
  }

  storeCurrentPosition () {
    if (!this._xDomain && !this._yDomain) {
      this._xDomain = this.xDomain
      this._yDomain = this.yDomain
    }
  }

  move (xDomain, yDomain) {
    this.xDomain = xDomain
    this.yDomain = yDomain
    this.xScale.domain(this.xDomain)
    this.yScale.domain(this.yDomain)
    this.update(this.props, false)
  }

  setDomains (xDomain, yDomain) {
    this.xDomain = xDomain
    this.yDomain = yDomain
    this._xDomain = xDomain
    this._yDomain = yDomain
    this.xScale.domain(this.xDomain)
    this.yScale.domain(this.yDomain)
  }

  zoom (xDomain, yDomain) {
    this.setDomains(limitZoom(xDomain), limitZoom(yDomain))
    this.update()
  }

  xLabel () {
    if (!this.props.selected.x) {
      return
    }

    return this.props.selected.x.name
  }

  yLabel () {
    if (!this.props.selected.x) {
      return
    }

    return this.props.selected.y.name
  }

  xFormat () {
    if (this.props.xProgress) {
      return format('+')
    }

    return null
  }

  yFormat () {
    if (this.props.yProgress) {
      return format('+')
    }

    return null
  }

  movementDomains ({ x, y }) {
    const xDelta = (this._xDomain[1] - this._xDomain[0]) / this.props.width
    const yDelta = (this._yDomain[1] - this._yDomain[0]) / this.props.height

    const xDomain = domainBoundaries(this.xDomain, [
      this._xDomain[0] - (x * xDelta),
      this._xDomain[1] - (x * xDelta)
    ], this.props.limits.x)

    const yDomain = domainBoundaries(this.yDomain, [
      this._yDomain[0] + (y * yDelta),
      this._yDomain[1] + (y * yDelta)
    ], this.props.limits.y)

    return { xDomain, yDomain }
  }

  componentDidMount () {
    this.base()
  }

  componentWillReceiveProps (nextProps) {
    const isDataDifferent = !_.isEqual(this.props.data, nextProps.data)
    const areDimensionsDifferent = this.props.width !== nextProps.width || this.props.height !== nextProps.height
    const isReferenceDifferent = this.props.xReference !== nextProps.xReference || this.props.yReference !== nextProps.yReference
    const areLimitsDifferent = !_.isEqual(this.props.limits, nextProps.limits)
    const arePinnedDifferent = !_.isEqual(this.props.pinned, nextProps.pinned)

    const shouldDoFullUpdate = isDataDifferent || areDimensionsDifferent || areLimitsDifferent

    if (areDimensionsDifferent) {
      this.setRanges(nextProps)
    }

    if (areLimitsDifferent) {
      this.setDomains(nextProps.limits.x, nextProps.limits.y)
    }

    if (shouldDoFullUpdate) {
      const shouldAnimate = !areLimitsDifferent
      this.update(nextProps, shouldAnimate)
    }

    if (isReferenceDifferent && !shouldDoFullUpdate) {
      this.updateAxis(nextProps.width, nextProps.height, nextProps.xReference, nextProps.yReference, nextProps.limits.x[1], nextProps.limits.y[1], false)
    }

    if (arePinnedDifferent && !shouldDoFullUpdate) {
      this.updateDotsClassesAndPosition(nextProps)
    }
  }

  setRanges (props = this.props) {
    this.xScale.range([0, props.width])
    this.yScale.range([props.height, 0])

    this.chartBrush.extent([0, 0], [props.width, props.height])
    this.svgContainer.selectAll('.brush .overlay')
      .attr('width', props.width)
      .attr('height', props.height)
  }

  originalDomain () {
    return {
      xDomain: this.props.limits.x,
      yDomain: this.props.limits.y
    }
  }

  base () {
    const { height } = this.props
    const node = select(this.node)
    const svgContainer = node
      .append('g')
      .attr('class', 'container')

    node
      .append('use')
      .attr('xlink:href', '#selectedDot')

    node
      .append('use')
      .attr('xlink:href', '#selectedLabel')

    this.svgContainer = svgContainer
    const background = svgContainer.append('g')
      .attr('class', 'background')

    background.append('rect')
      .attr('width', this.props.width)
      .attr('height', this.props.height)
      .attr('x', 0)
      .attr('y', 0)

    svgContainer.append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(this.xAxis)

    svgContainer.append('g')
      .attr('class', 'y axis')
      .call(this.yAxis)

    svgContainer.append('g')
      .attr('class', 'xGrid')
      .attr('transform', 'translate(0, ' + height + ')')
      .call(this.grid(this.props.height, this.xDomain, this.props.limits.x[1]))

    svgContainer.append('g')
      .attr('class', 'yGrid')
      .attr('clip-path', 'url(#clip)')
      .call(this.grid(this.props.width, this.yDomain, this.props.limits.y[1], 'left'))

    svgContainer.append('g')
      .attr('class', 'xReferenceGrid')
      .attr('transform', 'translate(0, ' + height + ')')
      .style('opacity', this.props.xProgress ? 1.0 : 0)
      .call(this.referenceGrid(this.props.height, this.props.xReference))

    svgContainer.append('g')
      .attr('class', 'yReferenceGrid')
      .style('opacity', this.props.yProgress ? 1.0 : 0)
      .call(this.referenceGrid(this.props.width, this.props.yReference, 'left'))

    svgContainer.append('g')
      .attr('class', 'labels')
      .attr('clip-path', 'url(#clip)')

    svgContainer.append('g')
      .attr('class', 'xThresholds')
      .attr('clip-path', 'url(#xThresholdClip)')

    svgContainer.append('g')
      .attr('class', 'yThresholds')
      .attr('clip-path', 'url(#yThresholdClip)')

    this.labels = svgContainer
      .append('g')
      .attr('clip-path', 'url(#clip)')
      .classed('labels', true)

    svgContainer.append('g').attr('class', 'brush').call(this.chartBrush)

    this.dots = svgContainer
      .append('g')
      .attr('clip-path', 'url(#clip)')
      .classed('dots', true)

    svgContainer.on('dblclick', d => {
      this.onZoomOnScore(lastD3Event.layerX, lastD3Event.layerY)
    })

    this.update()
  }

  enableMove () {
    const brush = this.svgContainer.selectAll('.brush')
    brush.on('.brush', null)
    brush.call(this.chartZoom)
    brush.selectAll('.overlay').attr('class', 'overlay grab')

    this.setState({ cursor: 'move' })
  }

  disableMove () {
    const brush = this.svgContainer.selectAll('.brush')
    brush.on('.zoom', null)
    brush.call(this.chartBrush)
    brush.selectAll('.overlay').attr('class', 'overlay')

    this.setState({ cursor: 'crop' })
  }

  widthToScore (width) {
    const delta = this.xDomain[1] - this.xDomain[0]
    return (width / this.props.width) * delta
  }

  heightToScore (height) {
    const delta = this.yDomain[1] - this.yDomain[0]
    return (height / this.props.height) * delta
  }

  grid (size, domain, limit, direction = 'bottom') {
    const divider = limit === 100 ? 10 : 1
    const values = []
    let [start, end] = domain
    while (start <= end) {
      values.push(Math.ceil(start / divider) * divider)
      start += divider
    }

    const axis = direction === 'bottom' ? axisBottom : axisLeft
    const scale = direction === 'bottom' ? this.xScale : this.yScale
    return axis(scale)
      .tickValues(values)
      .tickSize(-size)
      .tickFormat('')
  }

  referenceGrid (size, value, direction = 'bottom') {
    const axis = direction === 'bottom' ? axisBottom : axisLeft
    const scale = direction === 'bottom' ? this.xScale : this.yScale

    return axis(scale)
      .tickValues([value || 0])
      .tickSize(-size)
      .tickFormat('')
  }

  selectTeam (team) {
    if (!team) {
      this.dots.selectAll('.item.selected')
        .classed('selected', false)
        .attr('d', SYMBOL())
        .style('filter', null)

      this.dots.selectAll('.item')
        .classed('not-selected', false)
        .attr('id', '')

      this.labels.selectAll('.label')
        .classed('not-selected', false)
        .attr('id', '')
      return
    }

    this.dots.selectAll('.item')
      .classed('selected', (d) => d.id === team.id)
      .classed('not-selected', (d) => d.id !== team.id)
      .style('filter', (d) => d.id === team.id ? 'url(#selectedShadow)' : null)
      .attr('d', (d) => d.id === team.id ? SELECTED_SYMBOL() : SYMBOL())
      .attr('id', (d) => d.id === team.id ? 'selectedDot' : null)

    this.labels.selectAll('.label')
      .classed('not-selected', (d) => d.id !== team.id)
      .attr('id', d => d.id === team.id ? 'selectedLabel' : '')
  }

  updateAxis (width, height, xReference, yReference, xTopLimit, yTopLimit, hasTransitions) {
    const node = select(this.node)
    let xAxis = node.select('.x.axis')
    let yAxis = node.select('.y.axis')
    let xGrid = node.select('.xGrid')
    let yGrid = node.select('.yGrid')
    let xReferenceGrid = node.select('.xReferenceGrid')
    let yReferenceGrid = node.select('.yReferenceGrid')
    let background = node.select('.background rect')

    if (hasTransitions) {
      xAxis = xAxis.transition('xAxis')
      yAxis = yAxis.transition('yAxis')
      xGrid = xGrid.transition('xGrid')
      yGrid = yGrid.transition('yGrid')
      xReferenceGrid = xReferenceGrid.transition('xReferenceGrid')
      yReferenceGrid = yReferenceGrid.transition('yReferenceGrid')
      background = background.transition('background')
    }

    this.xAxis.tickFormat(this.xFormat())
    this.yAxis.tickFormat(this.yFormat())

    xAxis
      .attr('transform', 'translate(0, ' + height + ')')
      .call(this.xAxis)
    yAxis
      .call(this.yAxis)

    xGrid
      .attr('transform', 'translate(0, ' + height + ')')
      .call(this.grid(height, this.xDomain, xTopLimit))
    yGrid
      .call(this.grid(width, this.yDomain, yTopLimit, 'left'))

    if (shouldShowReference(xReference)) {
      xReferenceGrid
        .style('opacity', 1.0)
        .attr('transform', 'translate(0, ' + height + ')')
        .call(this.referenceGrid(height, xReference))
    } else {
      xReferenceGrid.style('opacity', 0)
    }

    if (shouldShowReference(yReference)) {
      yReferenceGrid
        .style('opacity', 1.0)
        .call(this.referenceGrid(width, yReference, 'left'))
    } else {
      yReferenceGrid.style('opacity', 0)
    }

    background
      .attr('width', width)
      .attr('height', height)
  }

  updateThresholds (width, height, xLimit, yLimit, xThresholds, yThresholds) {
    const x = this.xScale
    const y = this.yScale

    // Rendering / updating thresholds
    this.renderThresholds(
      this.svgContainer.select('.xThresholds'),
      [
        xLimit[0],
        ...xThresholds,
        xLimit[1]
      ],
      value => {
        return ({
          x: x(value),
          y: height + 10
        })
      }
    )

    this.renderThresholds(
      this.svgContainer.select('.yThresholds'),
      [
        yLimit[0],
        ...yThresholds,
        yLimit[1]
      ],
      value => {
        return ({
          x: -10,
          y: y(value)
        })
      }
    )
  }

  updateLabels (labels, hasTransitions, dots, screen) {
    labels = (labels || [])
    const labelItems = this.labels.selectAll('.label')
      .data(labels, d => d.id)

    const labelsUpdate = labelItems
      .enter()
      .append('text')
      .text(d => d.name)
      .style('text-anchor', 'start')
      .style('alignment-baseline', 'middle')
      .style('font-size', '12px')
      .attr('class', 'label')

    let allLabels = labelsUpdate.merge(labelItems)

    allLabels.each(function (d) {
      if (!d.rect) {
        const r = this.getBoundingClientRect()
        d.rect = { width: r.width, height: r.height }
      }
    })

    const positions = {}
    for (let i = 0, len = labels.length; i < len; i++) {
      const label = labels[i]

      positions[label.id] = {
        right: [{
          x: label.pos.x + DOT_RADIUS / 2 + LABEL_MARGIN,
          y: label.pos.y - DOT_RADIUS - LABEL_MARGIN
        }, {
          x: label.pos.x + DOT_RADIUS + LABEL_MARGIN,
          y: label.pos.y
        }, {
          x: label.pos.x + DOT_RADIUS / 2 + LABEL_MARGIN,
          y: label.pos.y + DOT_RADIUS + LABEL_MARGIN
        }],
        left: [{
          x: label.pos.x - DOT_RADIUS / 2 - LABEL_MARGIN - label.rect.width,
          y: label.pos.y - DOT_RADIUS - LABEL_MARGIN
        }, {
          x: label.pos.x - DOT_RADIUS - LABEL_MARGIN - label.rect.width,
          y: label.pos.y
        }, {
          x: label.pos.x - DOT_RADIUS / 2 - LABEL_MARGIN - label.rect.width,
          y: label.pos.y + DOT_RADIUS + LABEL_MARGIN
        }]
      }
    }

    const newPositions = place(labels, positions, dots, screen)

    allLabels.each(function (d) {
      const label = select(this)
      const alreadyOnChart = label.classed('positioned')
      const animationLabel = (hasTransitions && alreadyOnChart)
        ? label.transition('labels')
        : label

      label.classed('positioned', true)

      if (newPositions[d.id]) {
        animationLabel.attr('transform', `translate(${newPositions[d.id].x},${newPositions[d.id].y})`)
        animationLabel.style('opacity', 1)
        label.classed('overlap', false)
        label.classed('no-overlap', true)
      } else {
        animationLabel.attr('transform', `translate(${d.pos.x},${d.pos.y})`)
        animationLabel.style('opacity', 0)
        label.classed('overlap', true)
        label.classed('no-overlap', false)
      }
    })

    labelItems.exit().remove()
  }

  updateDots (data, hasTransitions) {
    const circles = this.dots.selectAll('.item')
      .data(data || [], it => it.id)

    const newCircles = circles
      .enter()
      .append('path')
      .classed('item', true)
      .on('click', d => this.showTooltip({}, d.x, d.y))
      .on('mouseover', showLabelTooltip(data))
      .on('mouseout', hideLabelTooltip)
      // preposition new items to avoid animation from 0, 0
      .attr('transform', dotTransform(this.xScale, this.yScale))
      .attr('d', SYMBOL())

    let allCircles = newCircles.merge(circles)
    if (hasTransitions) {
      allCircles = allCircles.transition()
    }
    allCircles.attr('transform', dotTransform(this.xScale, this.yScale))

    circles.exit().remove()

    this.updateDotsClassesAndPosition()
  }

  updateDotsClassesAndPosition ({ pinned } = this.props) {
    const dots = this.dots.selectAll('.item')
    dots.attr('class', d => {
      const pin = findPin(d, pinned)
      d.pinned = !!pin
      d.pos = getPositions(d, this.xScale, this.yScale)
      return `Dot--Symbol level${d.level} item color${pin ? pin.color : 'None'}`
    })
  }

  update ({ data, labels, width, height, xReference, yReference, limits, thresholds } = this.props, hasTransitions = true) {
    const xTopLimit = limits.x[1]
    const yTopLimit = limits.y[1]

    this.updateAxis(width, height, xReference, yReference, xTopLimit, yTopLimit, hasTransitions)

    if (!data) {
      return
    }

    this.updateThresholds(width, height, limits.x, limits.y, thresholds.x, thresholds.y)

    this.updateDots(data, hasTransitions)
    this.updateLabels(data, hasTransitions, data, { x: width, y: height })

    if (this.state.current || !_.isEmpty(this.state.teams)) {
      this.selectTeam(this.state.current || this.state.teams[0])
    }
  }

  renderThresholds (container, thresholds, mapFn) {
    let previous = thresholds[0]
    const data = thresholds.slice(1).map((value, i) => {
      const result = {
        p: [ previous, value ],
        name: `threshold${i}`
      }

      previous = value
      return result
    })

    const linePath = line()
      .x(d => d.x)
      .y(d => d.y)

    const items = container
      .selectAll('.threshold')

    items
      .data(data, it => it.name)
      .enter()
      .append('path')
      .attr('class', (d, index) => `threshold thresholdColor${index + 1}`)
      .attr('stroke-width', 4)
      .merge(items)
      .attr('d', d => linePath(d.p.map(mapFn)))
  }

  showTooltip (team = {}, x = null, y = null) {
    if (team.id) {
      return this.showTeamTooltip(team)
    }

    const { data } = this.props
    const teams = data.filter(it => {
      return it.x.value === x.value && it.y.value === y.value
    })

    if (teams.length > 1) {
      this.showTeamsTooltip(teams)
    } else if (teams.length === 1) {
      this.showTeamTooltip(teams[0])
    }
  }

  showTeamTooltip (team) {
    this.selectTeam(team)
    this.setState({ current: team, teams: null })
  }

  showTeamsTooltip (teams) {
    if (teams.length) {
      this.selectTeam(teams[0])
    }
    this.setState({ current: null, teams })
  }

  dismissTooltip (d) {
    this.selectTeam(null)
    this.setState({
      current: null,
      teams: null
    })
  }

  onScale () {
    let xDomain, yDomain
    if (this.isZoomed()) {
      const domain = this.originalDomain()
      xDomain = domain.xDomain
      yDomain = domain.yDomain

      this.disableMove()
    } else {
      let xMin, xMax, yMin, yMax
      for (const item of this.props.data) {
        const xValue = _.get(item, 'x.value', 0)
        if (!xMin || xMin > xValue) {
          xMin = xValue
        }
        if (!xMax || xMax < xValue) {
          xMax = xValue
        }

        const yValue = _.get(item, 'y.value', 0)
        if (!yMin || yMin > yValue) {
          yMin = yValue
        }
        if (!yMax || yMax < yValue) {
          yMax = yValue
        }
      }

      const xLimit = this.props.limits.x[1]
      const yLimit = this.props.limits.y[1]

      xDomain = [floor(xMin, xLimit), ceil(xMax, xLimit)]
      yDomain = [floor(yMin, yLimit), ceil(yMax, yLimit)]
    }

    this.zoom(xDomain, yDomain)
  }

  onZoomIn () {
    const { width, height } = this.props
    this.onZoomOnScore(width / 2, height / 2)
  }

  onZoomOut () {
    const { width, height } = this.props
    this.onZoomOnScore(width / 2, height / 2, 1 / ZOOM_FACTOR)
  }

  onZoomOnScore (clickX, clickY, zoomFactor = ZOOM_FACTOR) {
    // Current scores
    const [ currentScoreX1, currentScoreX2 ] = this.xDomain
    const [ currentScoreY1, currentScoreY2 ] = this.yDomain

    // Score point that was clicked on
    const scoreX = this.widthToScore(clickX) + this.xDomain[0]
    const scoreY = this.yDomain[1] - this.heightToScore(clickY)

    // Determine how to zoom
    const moveX = Math.min(scoreX - currentScoreX1, currentScoreX2 - scoreX) * zoomFactor
    const moveY = Math.min(scoreY - currentScoreY1, currentScoreY2 - scoreY) * zoomFactor

    const xDomain = [
      Math.max(scoreX - moveX, this.props.limits.x[0]),
      Math.min(scoreX + moveX, this.props.limits.x[1])
    ]

    const yDomain = [
      Math.max(scoreY - moveY, this.props.limits.y[0]),
      Math.min(scoreY + moveY, this.props.limits.y[1])
    ]

    this.zoom(xDomain, yDomain)
  }

  isZoomed () {
    return !_.isEqual({
      xDomain: this.xDomain,
      yDomain: this.yDomain
    }, this.originalDomain())
  }

  renderCursorIcons () {
    const isMove = this.state.cursor === 'move'

    return (
      <div className='DotsChart--CursorIcons'>
        <div
          key='zoom-crop'
          className={!isMove ? 'selected' : ''}
          onClick={() => this.disableMove()}
        >
          {icons.ZOOM_CROP}
        </div>
        <div
          key='zoom-move'
          className={isMove ? 'selected' : ''}
          onClick={() => this.isZoomed() && this.enableMove()}
        >
          {icons.ZOOM_MOVE}
        </div>
      </div>
    )
  }

  renderTooltip () {
    const { current, teams } = this.state
    if (current) {
      return (
        <TooltipTeam
          team={current}
          x={current.x}
          y={current.y}
          onClose={e => this.dismissTooltip()}
          selected={this.props.selected}
        />
      )
    } else if (teams && teams.length) {
      return (
        <TooltipTeams
          teams={teams}
          x={teams[0].x}
          y={teams[0].y}
          onClose={e => this.dismissTooltip()}
          onTeam={team => this.showTooltip(team)}
          selected={this.props.selected}
        />
      )
    }
  }

  render () {
    const { width, height } = this.props

    return (
      <Fragment>
        <div className='DotsChart'>
          <div className='DotsChart--yLabel'>
            <div>{this.yLabel()}</div>
          </div>
          <div className='DotsChart--xLabel'>
            <div>{this.xLabel()}</div>
          </div>
          <svg ref={node => (this.node = node)}
            width={width}
            height={height}
            className='mx-auto block'
            viewBox={`0 0 ${width} ${height}`}>
            <defs>
              <clipPath id='clip'>
                <rect width={width} height={height} x='0' y='0' />
              </clipPath>
              <clipPath id='xThresholdClip'>
                <rect width={width} height={height + 12} x='0' y='0' />
              </clipPath>
              <clipPath id='yThresholdClip'>
                <rect width={width + 12} height={height} x='-12' y='0' />
              </clipPath>
              <filter id='selectedShadow' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                <feComponentTransfer in='SourceAlpha'>
                  <feFuncA type='linear' slope='0.2' />
                  <feFuncR type='discrete' tableValues='0.07' />
                  <feFuncG type='discrete' tableValues='0.06' />
                  <feFuncB type='discrete' tableValues='0.04' />
                </feComponentTransfer>
                <feGaussianBlur stdDeviation='2' />
                <feOffset dx='0' dy='0' result='shadow' />
                <feComposite in='SourceGraphic' in2='shadow' operator='over' />
              </filter>
            </defs>
          </svg>
          {this.renderCursorIcons()}

          <div className='DotsChart--ScaleIcons'>
            <div onClick={event => this.onScale(event)}>
              {icons.ZOOM}
            </div>
          </div>

          <div className='DotsChart--ZoomIcons'>
            <div className='DotsChart--IconZoomIn' onClick={() => this.onZoomIn()}>
              {icons.ZOOM_IN}
            </div>
            <div className='DotsChart--IconZoomOut' onClick={() => this.onZoomOut()}>
              {icons.ZOOM_OUT}
            </div>
          </div>

          <div id='labelTooltip' />
        </div>
        {this.renderTooltip()}
      </Fragment>
    )
  }
}

class DotsChartContainer extends Component {
  render () {
    // 250 is based on the height of the fixed height header / subheader and footer
    const height = (window.innerHeight || window.document.innerHeight) - 250
    // 85 is a magic number that comes from css margins
    const width = this.props.containerWidth - 85

    return (
      <DotsChart
        ref='chart'
        width={width}
        height={height}
        {...this.props}
      />
    )
  }
}

export default DotsChartContainer
