import React from 'react'
import { Redirect } from 'react-router-dom'
import ErrorView from '../Error'

export default function RestrictTo (props) {
  if (!props.allowed) {
    switch (props.action) {
      case 'error':
        return <ErrorView status={403} error={'No access'} action={{}} />
      case 'redirect':
        return <Redirect to='/results' />
      case 'nothing':
      default:
        return null
    }
  }

  return props.children
}
