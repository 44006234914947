import React, { PureComponent, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import Scale from '../../../../components/SimpleScale'
import Tick from '../../../../components/Sidebar/components/Teams/Tick'
import QuestionDetailsVertical from '../QuestionDetailsVertical'
import CompoundQuestion from '../CompoundQuestion'

import ProgressIndicator from '../../../../components/ProgressIndicator'

import { EXPAND } from '../../../../utils/icons'
import { formatScore } from '../../../../utils'
import { routeBuilder } from '../../../../utils/routing'

import './style.scss'

export function RegularScore (props) {
  const question = props.question

  return (
    <Fragment>
      <span className='Question--score-value'>{formatScore(question.score)}</span>
      <Scale
        level={_.get(question, 'score.progress')}
        index={_.get(question, 'score.index')}
        history={_.get(question, 'historyScore.progress')}
      />
      <span className='Question--label'>{_.get(question, 'score.label')}</span>
    </Fragment>)
}

export class Question extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  toggleOpen (event) {
    event.stopPropagation()

    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  onSelect (event) {
    event.preventDefault()
    event.stopPropagation()

    const { question } = this.props
    this.props.toggleQuestion(question)
  }

  isSelected () {
    return _.some(this.props.selected, { id: this.props.question.id })
  }

  leadersUrl () {
    const question = this.props.question
    const options = {}
    if (this.props.team.id) {
      options.query = { teamId: this.props.team.id }
    }

    return routeBuilder('listing', {
      type: question.type,
      level: 'question',
      value: question.id
    }, options)
  }

  renderScore () {
    if (this.props.highlightProgress) {
      return <ProgressIndicator value={this.props.question} />
    }

    return <RegularScore question={this.props.question} />
  }

  renderDetails () {
    if (!this.state.isOpen) {
      return
    }

    const question = this.props.question
    if (!question.responsesText && !question.question_text) {
      return
    }
    if (this.props.question.category) {
      return <CompoundQuestion question={this.props.question} />
    }

    const opts = {
      score: this.props.question.score,
      responsesText: question.responsesText,
      questionText: question.question_text,
      thresholds: this.props.thresholds,
      limits: this.props.limits,
      plainTextResponse: this.props.type.style !== 'maturity',
      showAll: this.props.type.style !== 'maturity'
    }

    return <QuestionDetailsVertical {...opts} />
  }

  render () {
    const question = this.props.question

    const expandClassNames = ['Question--expand']
    if (this.state.isOpen) {
      expandClassNames.push('Question--expanded')
    }
    const isTQ = !question.grouping2.match(/.*~none~.*/)
    const indent = isTQ ? '60px' : '30px'
    return (
      <li>
        <div className='Question' onClick={e => this.toggleOpen(e)}>
          <div className='Question--main' style={{ paddingLeft: indent }}>
            <div className='Question--selectors'>
              <Tick onChange={e => this.onSelect(e)} checked={this.isSelected()} className='Question--tick' />
            </div>
            <div className='Question--title'>{question.question_title}</div>
            <div className='Question--score' style={{ visibility: question.category ? 'hidden' : 'visible' }}>
              {this.renderScore()}
            </div>
            <div className='Question--link' style={{ visibility: question.category ? 'hidden' : 'visible' }}>
              <Link to={this.leadersUrl()}>{this.props.t('team_details.view_leaders')}</Link>
            </div>
            <div className={expandClassNames.join(' ')} onClick={e => this.toggleOpen(e)}>
              {EXPAND}
            </div>
          </div>
        </div>
        {this.renderDetails()}
      </li>
    )
  }
}

export default withTranslation()(Question)
