import { combineReducers } from 'redux'
import admin from '../services/admin/reducer'
import app from '../services/app/reducer'
import errors from '../services/errors/reducer'
import metadata from '../services/metadata/reducer'
import metrics from '../services/metrics/reducer'
import profile from '../services/profile/reducer'
import questions from '../services/questions/reducer'
import surveys from '../services/surveys/reducer'
import teams from '../services/teams/reducer'
import teamProfile from '../services/team-profile/reducer'
import organisationDetails from '../services/organisation-details/reducer'
import scores from '../services/scores/reducer'
import navigation from '../services/navigation/reducer'
import { reducerBuilder as midReducer } from '@cs/mid-react-saga'
import exportReducer from '../services/export/reducer'

export default function rootReducer (mid) {
  return combineReducers({
    mid: midReducer(mid),
    admin,
    app,
    errors,
    metadata,
    metrics,
    teamProfile,
    organisationDetails,
    profile,
    questions,
    surveys,
    scores,
    navigation,
    teams,
    export: exportReducer
  })
}
