import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import './style.scss'

export default class Dropdown extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }

    this._onWindowClick = this._onWindowClick.bind(this)
  }

  componentDidMount () {
    window.addEventListener('click', this._onWindowClick)
    window.addEventListener('touchstart', this._onWindowClick)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this._onWindowClick)
    window.removeEventListener('touchstart', this._onWindowClick)
  }

  _onWindowClick (event) {
    const dropdownElement = findDOMNode(this)
    if (event.target !== dropdownElement && !dropdownElement.contains(event.target) && this.state.open) {
      this.close()
    }
  }

  toggle () {
    this.setState({
      open: !this.state.open
    })
  }

  isOpen () {
    return this.state.open
  }

  open () {
    this.setState({
      open: true
    })
  }

  close () {
    this.setState({
      open: false
    })
  }

  renderTooltip () {
    if (!this.props.tooltip) {
      return
    }

    return <div className='Dropdown--tooltip'>{this.props.tooltip}</div>
  }

  render () {
    const classes = ['Dropdown']
    if (this.props.className) {
      classes.push(this.props.className)
    }
    if (this.state.open) {
      classes.push('open')
    }

    const ddClasses = ['Dropdown-content']
    if (this.props.contentClass) {
      ddClasses.push(this.props.contentClass)
    }

    return (
      <div className={classes.join(' ')}>
        <button onClick={() => this.toggle()}>{this.props.text}</button>
        <div className={ddClasses.join(' ')}>
          {this.props.children}
        </div>
        {this.renderTooltip()}
      </div>
    )
  }
}
