export const UPLOAD_REQUEST = 'logo.UPLOAD_REQUEST'
export const UPLOAD_SUCCESS = 'logo.UPLOAD_SUCCESS'
export const UPLOAD_FAILURE = 'logo.UPLOAD_FAILURE'

export const QUESTIONS_RETRIEVE_REQUEST = 'admin.QUESTIONS_RETRIEVE_REQUEST'
export const QUESTIONS_RETRIEVE_SUCCESS = 'admin.QUESTIONS_RETRIEVE_SUCCESS'
export const QUESTIONS_RETRIEVE_FAILURE = 'admin.QUESTIONS_RETRIEVE_FAILURE'

export const TEAMS_RETRIEVE_REQUEST = 'admin.TEAMS_RETRIEVE_REQUEST'
export const TEAMS_RETRIEVE_SUCCESS = 'admin.TEAMS_RETRIEVE_SUCCESS'
export const TEAMS_RETRIEVE_FAILURE = 'admin.TEAMS_RETRIEVE_FAILURE'
