
import _ from 'lodash'
import Immutable from 'seamless-immutable'

import * as types from './types'

const initialState = Immutable({
  powerpoint: {
    loading: false,
    error: null
  },
  excel: {
    loading: false,
    error: null
  }
})

export default function admin (state = initialState, action) {
  switch (action.type) {
    case types.POWERPOINT_REQUEST:
      return state.merge({
        powerpoint: {
          loading: true,
          error: null
        }
      })

    case types.POWERPOINT_SUCCESS:
      return state.merge({
        powerpoint: {
          loading: false,
          error: null
        }
      })

    case types.POWERPOINT_FAILURE:
      return state.merge({
        powerpoint: {
          loading: true,
          error: _.get(action.error, 'response.data.message') || action.error.message
        }
      })

    case types.EXCEL_REQUEST:
      return state.merge({
        excel: {
          loading: true,
          error: null
        }
      })

    case types.EXCEL_SUCCESS:
      return state.merge({
        excel: {
          loading: false,
          error: null
        }
      })

    case types.EXCEL_FAILURE:
      return state.merge({
        excel: {
          loading: true,
          error: _.get(action.error, 'response.data.message') || action.error.message
        }
      })

    default:
      return state
  }
}
