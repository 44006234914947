import { connect } from 'react-redux'
import _ from 'lodash'
import Component from './Component'
import * as actions from '../../services/team-profile/actions'
import { questionsProgressScore, totalsProgressScore } from './selectors'

function mapStateToProps (state, ownProps) {
  const { type, team } = ownProps
  const questions = questionsProgressScore(state, type.key, team.id)
  const totals = totalsProgressScore(state, type.key, team.id)

  const hasTotals = _.get(state.teamProfile, ['totals', type.key, team.id, 'latest', 'totals'], false)
  const hasHistory = _.get(state.teamProfile, ['totals', type.key, team.id, 'previous', 'totals'], false)

  return {
    team,
    totals,
    questions,
    hasTotals,
    hasHistory,

    shouldHaveHistory: state.profile.selectedSurveys.length > 1,
    metadata: state.metadata.selectedMetadata
  }
}

function mapDispatchToProps (dispatch) {
  return {
    retrieve: (...args) => dispatch(actions.retrieve(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
