
import { connect } from 'react-redux'
import qs from 'query-string'
import _ from 'lodash'

import Component from './Component'
import I18n from '../../utils/i18n'
import { findURLTeam } from '../../utils/helpers'
import * as actions from '../../services/questions/actions'
import * as navActions from '../../services/navigation/actions'

function organisationTeam () {
  return {
    id: 'organisation',
    name: I18n.t('shared.organisation')
  }
}

function mapStateToProps (state, ownProps) {
  const team = findURLTeam(state.teams.teams, ownProps.match.params) || organisationTeam()
  const type = _.find(state.profile.questionTypes, { key: ownProps.match.params.type }) || state.profile.questionTypes[0]
  const { filter, lever } = qs.parse(ownProps.location.search)

  return {
    team,
    type,
    filter,
    lever,
    calculation: state.profile.calculation,
    selectedSurveys: state.profile.selectedSurveys,
    highlightProgress: state.navigation.highlightProgress,
    questionTypes: state.profile.questionTypes
  }
}

function mapDispatchToProps (dispatch) {
  return {
    reset: () => dispatch(actions.update('filter', null)),
    resetSelectedQuestions: () => dispatch(actions.resetQuestions()),
    changeHighlightProgress: (value) => dispatch(navActions.highlightProgress(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
