import React, { PureComponent } from 'react'
import Question from '../Question'
import './style.scss'

export default class Questions extends PureComponent {
  render () {
    return (
      <div className='Questions'>
        {/* <p>Detailed results (may only reflect a subset of the team):</p> */}
        <ul className='Questions--list'>
          {this.props.questions.map(question => {
            return (
              <Question
                key={`question_${question.id}`}
                type={this.props.type}
                question={question}
                team={this.props.team}
                highlightProgress={this.props.highlightProgress}
              />
            )
          })}
        </ul>
      </div>
    )
  }
}
