
import { formatProgress, isNotAsked, isNotAnswered } from '../../../../utils'
import * as ICONS from '../../../../utils/icons'

export function getScoreClass (score) {
  if (isNotAsked(score)) {
    return 'scoreNotAsked'
  }

  if (isNotAnswered(score)) {
    return 'scoreNa'
  }

  return `score${score.index}`
}

export function formatScore (score) {
  if (isNotAsked(score)) {
    return ''
  }

  if (isNotAnswered(score)) {
    return ICONS.SCORE_NA
  }

  if (score.isProgress) {
    return formatProgress(score.value)
  }

  return String(score.value)
}
