import { connect } from 'react-redux'
import Component from './Component'
import * as actions from '../../../../services/questions/actions'

function mapStateToProps (state, ownProps) {
  return {
    questions: state.questions.selected,
    error: state.questions.emailError,
    loading: state.questions.isEmailSending,

    metadata: state.metadata.selectedMetadata
  }
}

function mapDispatchToProps (dispatch) {
  return {
    email: (...args) => dispatch(actions.email(...args)),
    toggleQuestion: (...args) => dispatch(actions.toggleQuestion(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
