import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as actions from '../../services/metrics/actions'
import { findURLTeam } from '../../utils/helpers'
import Component from './Component'

function mapStateToProps (state, ownProps) {
  const team = findURLTeam(state.teams.teams, ownProps.match.params)

  return {
    team: team,

    levels: state.metrics.levels,
    prevLevels: state.metrics.prevLevels,
    orgLevels: state.metrics.orgLevels,

    metadata: state.metadata.selectedMetadata,
    surveys: state.profile.selectedSurveys
  }
}

function mapDispatchToProps (dispatch) {
  return {
    retrieve: (...args) => dispatch(actions.retrieveLevels(...args)),
    preloadOrg: (...args) => dispatch(actions.retrieveOrgLevels(...args))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))
