
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import Autocomplete from '../AutocompleteInput'
import * as ICONS from '../../utils/icons'
import { routeBuilder } from '../../utils/routing'
import { pathFromSurveys } from '../../utils/surveys'
import { pathFromMetadata } from '../../utils/metadata'
import { fullBasename } from '../../utils/dom'

import './style.scss'

function isSelectionValid (surveys) {
  if (_.uniq(surveys).length !== surveys.length) {
    return false
  }

  if (surveys.length === 0) {
    return false
  }

  return true
}

class SurveysSelector extends Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  url () {
    const match = this.props.match
    if (!match) {
      return routeBuilder.root()
    }

    const options = { noTeam: true }
    const params = _.omit(match.params, 'teamId')
    return routeBuilder(match.route.name, params, options)
  }

  buildPageUrl (surveys) {
    const metadataPath = this.hasSameMetadata(surveys) ? '/' + pathFromMetadata(this.props.selectedMetadata) : ''

    return fullBasename(`${pathFromSurveys(surveys)}${metadataPath}${this.url()}`)
  }

  currentSelection () {
    return this.state.selected || this.props.selectedSurveys
  }

  select (index, value) {
    if (value === null) {
      const selected = this.currentSelection().slice(0, index)
      this.setState({ selected })
      return
    }

    const metadata = _.clone(this.currentSelection())
    metadata[index] = value
    const selected = _.compact(metadata)

    this.setState({ selected })
  }

  renderLink (currentSelection = this.currentSelection()) {
    if (isSelectionValid(currentSelection)) {
      return <a className='SurveysSelector--link' href={this.buildPageUrl(currentSelection)}>{this.props.t('survey_selector.apply')}</a>
    }
  }

  renderDefaultReset () {
    return (<p className='SurveysSelector--reset'>
      <a href={this.buildPageUrl(this.props.defaultSurveys)}>{this.props.t('survey_selector.reset')}</a>
    </p>)
  }

  renderReferenceSelector (currentSelection) {
    const disabledOptions = _.reject(currentSelection, { id: currentSelection[0].id })

    return (
      <li>
        {ICONS.SURVEY}
        <label>{this.props.t('survey_selector.main')}</label>
        <Autocomplete
          value={currentSelection[0]}
          options={this.props.surveys}
          onChange={value => this.select(0, value)}
          disabledOptions={disabledOptions}
          hideNull
          defaultStyle
        />
      </li>)
  }

  renderAdditionalSelector (currentSelection) {
    const disabledOptions = currentSelection[1] ? _.reject(currentSelection, { id: currentSelection[1].id }) : currentSelection

    return (
      <li>
        {ICONS.SURVEY}
        <label>{this.props.t('survey_selector.comparison')} <small>({this.props.t('survey_selector.optional')})</small></label>
        <Autocomplete
          value={currentSelection[1]}
          options={this.props.surveys}
          onChange={value => this.select(1, value)}
          disabledOptions={disabledOptions}
          defaultStyle
        />
      </li>)
  }

  hasSameMetadata (surveys = this.currentSelection()) {
    const nextMetadata = surveys[0].hierarchy
    return _.every(this.props.selectedMetadata, level => _.find(nextMetadata, { main: level.main, level: level.level }))
  }

  renderMetadataWarning () {
    if (this.hasSameMetadata()) {
      return null
    }

    return (
      <div className='SurveySelector--warning'>
        <p>{this.props.t('survey_selector.default_hierarchy_warning')}</p>
      </div>)
  }

  render () {
    const currentSelection = this.currentSelection()

    return (
      <div className='SurveysSelector'>
        <ul>
          {this.renderReferenceSelector(currentSelection)}
          {this.renderAdditionalSelector(currentSelection)}
        </ul>
        {this.renderMetadataWarning()}
        <div className='SurveysSelector--actions'>
          {this.renderDefaultReset()}
          {this.renderLink(currentSelection)}
        </div>
      </div>)
  }
}

export default withTranslation()(SurveysSelector)
