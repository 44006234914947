
import React, { Component, PureComponent, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import Scale from '../../../../components/SimpleScale'
import ProgressIndicator from '../../../../components/ProgressIndicator'
import SortIcon from '../SortIcon'

import { formatScore, isNotAsked, isNotAnswered } from '../../../../utils'
import { throttle, scrollToTop, scrollToHighlighted, checkScrollVisibility } from '../../../../utils/dom'
import * as ICONS from '../../../../utils/icons'

import './style.scss'

const HEAD_HEIGHT = 0
const ROW_HEIGHT = 44
const PAGE_SIZE = 25

export class RegularScore extends PureComponent {
  renderChange () {
    const team = this.props.team
    if (isNotAsked(team.score) || isNotAnswered(team.score)) {
      return undefined
    }

    const history = team.historyScore
    if (!history) {
      return <span className='change' />
    }

    if (team.score.value === history.value) {
      return <span className='change' />
    }

    const change = team.score.value > history.value ? 'increase' : 'decrease'
    return <span className={`change ${change}`}>{ICONS.CHANGE}</span>
  }

  renderScale () {
    const team = this.props.team
    if (isNotAsked(team.score) || isNotAnswered(team.score)) {
      return undefined
    }

    const history = team.historyScore
    if (history) {
      return <div className='scale'><Scale level={team.score.progress} index={team.score.index} history={history.progress} /></div>
    }

    return <div className='scale'><Scale level={team.score.progress} index={team.score.index} /></div>
  }

  render () {
    const team = this.props.team

    return (
      <Fragment>
        <span className='score'>{formatScore(team.score)}</span>
        {this.renderChange()}
        {this.renderScale()}
      </Fragment>)
  }
}

function isOdd (n) {
  return n % 2 !== 0
}

function isHome (team) {
  return !team.id
}

function isSameTeam (teamA, teamB) {
  if (_.isNil(teamA) && _.isNil(teamB)) {
    return true
  }

  return teamA === teamB
}

function mapParent (team, parent, level, visible) {
  return {
    id: team.id,
    name: team.name,
    score: team.score,
    historyScore: team.historyScore,
    progressScore: team.progressScore,
    parents: team.parents,
    benchmark: team.benchmark,
    toSelect: parent,
    level: level || 0,
    header: true,
    visible: true
  }
}

function mapChild (level, isChild, parent) {
  return function mapper (team) {
    return {
      id: team.id,
      name: team.name,
      score: team.score,
      historyScore: team.historyScore,
      progressScore: team.progressScore,
      parents: team.parents,
      benchmark: team.benchmark,
      toSelect: team.id,
      level: level || 0,
      isChild: (team.teams && team.teams.length) || isChild,
      header: false,
      parent: parent
    }
  }
}

function buildTeamTree (selectedTeam, parent, level) {
  function builder (accumulator, team) {
    if (team.id === selectedTeam || (!team.id && !selectedTeam)) {
      accumulator.push(mapParent(team, parent, level, true))
      if (team.teams) {
        accumulator.push(...team.teams.map(mapChild(level + 1, true, team.id)))
      }

      return accumulator
    }

    const result = _.reduce(team.teams, buildTeamTree(selectedTeam, team.id, level + 1), [])

    if (!_.isEmpty(result)) {
      accumulator.push(mapParent(team, parent, level, true), ...result)
      return accumulator
    }

    return accumulator
  }

  return builder
}

function mapTeams (teams, selectedTeam) {
  if (!teams) {
    return []
  }

  const result = _.reduce(teams, buildTeamTree(selectedTeam, undefined, 0), [])

  if (_.isEmpty(result)) {
    return teams.map(mapChild(0, false))
  }

  return result
}

function teamClasses (team) {
  if (team.header) {
    return ['header']
  }

  if (team.isChild && team.level > 1) {
    return ['tabbed']
  }

  return []
}

function teamIcon (team) {
  if (isHome(team)) {
    return ICONS.HOME
  }

  if (team.benchmark) {
    return ICONS.SCORE_NA
  }

  if (team.header) {
    return ICONS.BACK
  }

  if (team.isChild && team.level > 1) {
    return [ICONS.PARENT, ICONS.CHILD]
  }

  if (team.isChild) {
    return ICONS.PARENT
  }

  if (team.teams) {
    return ICONS.PARENT
  }
}

function getHighlightedTeamPage (teams, teamId) {
  if (!teams) {
    return null
  }

  if (!teamId) {
    return null
  }

  const index = _.findIndex(teams, { id: teamId })

  if (index < 0) {
    return null
  }

  if (index === 0) {
    return 1
  }

  return Math.ceil((index + 1) / PAGE_SIZE)
}

export class GroupedTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      page: getHighlightedTeamPage(props.teams, props.highlightedTeamId) || 1,
      direction: false,
      selectedTeam: null,
      previousTeams: [],
      teamsToShow: mapTeams(props.teams)
    }

    this.highlightedRef = React.createRef()
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.teams !== nextProps.teams) {
      this.setState({
        direction: false,
        previousTeams: [],
        teamsToShow: mapTeams(nextProps.teams, this.state.selectedTeam)
      })
    }

    if (this.props.teams !== nextProps.teams || this.props.highlightedTeamId !== nextProps.highlightedTeamId) {
      if (nextProps.highlightedTeamId) {
        this.setState({
          page: getHighlightedTeamPage(nextProps.teams, nextProps.highlightedTeamId) || 1
        })
      }
    }
  }

  componentDidMount () {
    scrollToHighlighted(this.refs.container, this.refs.backToTop, this.refs.highlighted)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.teams !== this.props.teams || prevProps.highlightedTeamId !== this.props.highlightedTeamId) {
      scrollToHighlighted(this.refs.container, this.refs.backToTop, this.refs.highlighted)
    }
  }

  selectTeam (team, direction) {
    if (isSameTeam(this.state.selectedTeam, team)) {
      return
    }

    const t = _.find(this.state.teamsToShow, { id: team })
    if (!direction && !t.isChild) {
      return
    }

    this.setState({
      selectedTeam: team,
      previousTeams: this.state.teamsToShow,
      direction: !!direction,
      teamsToShow: mapTeams(this.props.teams, team, this.state.teamsToShow)
    })
  }

  nextPage () {
    this.setState({
      page: this.state.page + 1
    })
  }

  sort (order) {
    this.props.sort(order)
  }

  getHistory (team) {
    return this.props.history && this.props.history[team.id]
  }

  renderHeadCell (name, order) {
    const active = this.props.order === order
    const classes = ['pointer']
    if (active) {
      classes.push('active')
    }

    return (
      <th className={classes.join(' ')} onClick={e => this.sort(order)}>
        <div>
          {name}
          <SortIcon
            active={active}
            direction={this.props.direction}
            className='GroupedTable--SortIcon'
          />
        </div>
      </th>)
  }

  hierarchyName (index) {
    const { hierarchies, grouped } = this.props
    const parents = hierarchies.slice(0, grouped - 1)
    return _.get(parents, [index, 'name'])
  }

  parentsNumber () {
    return this.props.grouped - 1
  }

  renderParentCell (index) {
    const order = `parent${index + 1}`
    const active = this.props.order === order

    const classes = ['pointer']
    if (active) {
      classes.push('active')
    }

    return (
      <th
        key={`column_${index}`}
        className={classes.join(' ')}
        onClick={e => this.sort(order)}
      >
        <div>
          {this.hierarchyName(index)}
          <SortIcon
            active={active}
            direction={this.props.direction}
            className='GroupedTable--SortIcon'
          />
        </div>
      </th>
    )
  }

  renderHead () {
    let numberOfColumns = _.chain(this.state)
      .get('teamsToShow', [])
      .map('parents.length')
      .max()
      .value() || 0

    if (numberOfColumns > 0) {
      numberOfColumns -= 1
    }

    const t = this.props.t

    return (
      <thead>
        <tr>
          {this.renderHeadCell(t('leaders.name'), 'name')}
          {this.renderHeadCell(this.props.highlightProgress ? t('leaders.progress') : t('leaders.score'), 'score')}
          {_.times(numberOfColumns).map(it => this.renderParentCell(it))}
        </tr>
      </thead>
    )
  }

  teamLink (ev, team) {
    if (this.props.onNavigate) {
      ev.preventDefault()
      ev.stopPropagation()

      this.props.onNavigate(team)
    }
  }

  renderTeamName (team) {
    const classNames = teamClasses(team).join(' ')
    const icon = teamIcon(team)

    let openLink = null
    if (!team.benchmark) {
      openLink = <div className='link' onClick={e => this.teamLink(e, team)}>
        {ICONS.OPEN}
      </div>
    }

    return (
      <th key={`team-row-name-${team.id || 'org'}`} className={classNames}>
        <div className={!icon ? 'withoutIcon' : ''}>
          {icon}
          <span className='name'>{team.name}</span>
          {openLink}
        </div>
      </th>
    )
  }

  renderScore (team) {
    if (this.props.highlightProgress) {
      return <ProgressIndicator value={team} hideLabel />
    }

    return <RegularScore team={team} />
  }

  renderTeam (team, direction) {
    const parents = team.parents || []

    const props = {
      key: `team-row-${team.id || 'org'}`
    }

    if (!team.benchmark) {
      props.onClick = e => {
        this.selectTeam(team.toSelect, direction)
      }
    }

    if (this.props.highlightedTeamId && team.id === this.props.highlightedTeamId) {
      props.className = 'highlighted'
      props.ref = 'highlighted'
    }

    if (team.benchmark) {
      props.className = 'benchmark'
    }

    return (<tr {...props}>
      {this.renderTeamName(team)}
      <td>
        {this.renderScore(team)}
      </td>
      {this.renderParentColumns(parents.slice(1), this.parentsNumber())}
    </tr>)
  }

  renderParentColumns (parents, length) {
    const cells = []
    for (let i = 0; i < length; i++) {
      if (!parents[i]) {
        cells.push(<td key={`column_${i}`} />)
        continue
      }

      cells.push(<td key={`column_${i}`}>{parents[i]}</td>)
    }

    return cells
  }

  renderHeaderTeams () {
    const teams = _.filter(this.state.teamsToShow, { header: true })
    const prevHeaders = _.filter(this.state.previousTeams, { header: true })
    const previous = _.filter(prevHeaders, t => !_.find(teams, { id: t.id }))

    const result = []

    for (let team of teams) {
      const classes = [`level${team.level}`, 'selected']
      if (_.find(prevHeaders, { id: team.id })) {
        classes.push('still')
      } else if (!this.state.direction) {
        classes.push('backward')
      }

      if (isOdd(team.level)) {
        classes.push('odd')
      }

      result.push(<tbody className={classes.join(' ')} key={`team-header-${team.id || 'org'}`}>
        {this.renderTeam(team, true)}
      </tbody>)
    }

    for (let team of previous) {
      const classes = [`level${team.level}`]
      if (!this.state.direction) {
        classes.push('backward')
      }

      if (isOdd(team.level)) {
        classes.push('odd')
      }

      result.push(<tbody className={classes.join(' ')} key={`team-header-${team.id || 'org'}`}>
        {this.renderTeam(team, true)}
      </tbody>)
    }

    return result
  }

  renderChildrenTeams () {
    const parent = _.chain(this.state.teamsToShow).filter({ header: true }).last().value() || { id: 'org', level: -1 }
    const teams = _.filter(this.state.teamsToShow, { header: false }).slice(0, this.state.page * PAGE_SIZE)
    const previousTeams = _.filter(this.state.previousTeams, { header: false })

    const classes = [`level${parent.level + 1}`, `selected`]
    const previousClasses = []

    if (!this.state.direction) {
      classes.push('backward')
      previousClasses.push('backward')
    }

    if (isOdd(parent.level + 1)) {
      classes.push('odd')
    }

    if (previousTeams.length) {
      previousClasses.push(`level${previousTeams[0].level}`)

      if (isOdd(previousTeams[0].level)) {
        previousClasses.push('odd')
      }
    }

    return (
      <Fragment>
        {previousTeams.length
          ? <tbody className={previousClasses.join(' ')} key={`team-body-${previousTeams[0].parent || 'org'}`} attr={`team-body-${previousTeams[0].parent || 'org'}`}>
            {previousTeams.map(t => this.renderTeam(t))}
          </tbody>
          : undefined}
        <tbody className={classes.join(' ')} key={`team-body-${parent.id || 'org'}`} attr={`team-body-${parent.id || 'org'}`}>
          {teams.map(t => this.renderTeam(t))}
        </tbody>
      </Fragment>)
  }

  renderTeams () {
    if (!this.props.teams) {
      return undefined
    }

    return (<Fragment>
      {this.renderHeaderTeams()}
      {this.renderChildrenTeams()}
    </Fragment>)
  }

  tableHeight () {
    if (!this.props.teams) {
      return
    }

    const height = HEAD_HEIGHT + this.state.teamsToShow.slice(0, this.state.page * PAGE_SIZE).length * ROW_HEIGHT
    return `${height}px`
  }

  renderLoadMore () {
    if (this.state.teamsToShow.length > this.state.page * PAGE_SIZE) {
      return (
        <div className='TeamList--GroupedTable--actions'>
          <div onClick={e => this.nextPage()}>Load more</div>
        </div>
      )
    }
  }

  render () {
    const classes = ['TeamsList--GroupedTable']
    const parents = this.parentsNumber()
    if (parents > 0) {
      classes.push(`parents${parents}`)
    }

    return (
      <div className='TeamsList--container'>
        <div className='TeamsList--head'>
          <table key={`table_${this.props.grouped}`} className={classes.join(' ')}>
            {this.renderHead()}
          </table>
          <div className={`TeamsList--back-to-top parents${parents}`} ref='backToTop' onClick={() => scrollToTop(this.refs.container, this.refs.backToTop)}>
            <span>Back to top</span>{ICONS.TOP}
          </div>
        </div>
        <div className='TeamsList--main' ref='container' onScroll={throttle(() => checkScrollVisibility(this.refs.container, this.refs.backToTop), 150)}>
          <table key={`table_${this.props.grouped}`} className={classes.join(' ')} style={{ height: this.tableHeight() }}>
            {this.renderTeams()}
          </table>
          {this.renderLoadMore()}
        </div>
      </div>
    )
  }
}

export default withTranslation()(GroupedTable)
