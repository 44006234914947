
import _ from 'lodash'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { DELTA_LABELS } from '../../utils/calculations'
import { formatProgress } from '../../utils'

import './style.scss'

const LABELS = _.clone(DELTA_LABELS).reverse()

export class ThresholdsLegend extends React.PureComponent {
  header (index) {
    const header = _.find(this.props.headers, { index })
    return header && header.content
  }

  renderThresholds () {
    const { thresholds } = this.props
    const length = thresholds.length
    return (
      <Fragment>
        {this.renderLi(`score${length + 1}`, this.header(thresholds.length + 1), `Over ${thresholds[thresholds.length - 1]}`)}
        {_.chain(thresholds)
          .tail()
          .map((value, i) => this.renderLi(`score${i + 2}`, this.header(i + 2), `${thresholds[i] || 0} to ${value}`))
          .reverse()
          .value()}
        {this.renderLi(`score1`, this.header(1), `1 to ${thresholds[0]}`)}
      </Fragment>
    )
  }

  renderLi (className, label, values) {
    return (
      <li key={className} className={className}>
        <span className='ThresholdsLegend--label'>{label || values}</span>
        <span className='ThresholdsLegend--values'>{label ? values : null}</span>
      </li>
    )
  }

  renderDeltaThresholds () {
    const { deltaThresholds, limits } = this.props
    const length = deltaThresholds.length
    return (
      <Fragment>
        {this.renderLi(`score${length + 1}`, LABELS[0], `${formatProgress(deltaThresholds[length - 1])} to ${formatProgress(limits[1])}`)}
        {_.chain(deltaThresholds)
          .tail()
          .map((value, i) => this.renderLi(`score${i + 2}`, LABELS[length - 1 - i], `${formatProgress(deltaThresholds[i] || 0)} to ${formatProgress(value)}`))
          .reverse()
          .value()}
        {this.renderLi('score1', LABELS[length], `${formatProgress(limits[1]) * -1} to ${formatProgress(deltaThresholds[0])}`)}
      </Fragment>
    )
  }

  render () {
    const { t } = this.props
    const label = this.props.type.plural
    const title = this.props.showProgress ? t('thresholds_legend.progress', { type: label }) : t('thresholds_legend.levels', { type: label })

    return (
      <React.Fragment>
        <div className='ThresholdsLegend'>
          <h3>{title}</h3>
          <ul>
            {this.props.showProgress ? this.renderDeltaThresholds() : this.renderThresholds()}
          </ul>
        </div>
      </React.Fragment>)
  }
}

function mapStateToProps (state, ownProps) {
  return {
    headers: state.profile.headers || [],
    thresholds: _.get(state.profile, ['thresholds', ownProps.type.key], []),
    deltaThresholds: _.get(state.profile, ['deltaThresholds', ownProps.type.key], []),
    limits: _.get(state.profile, ['limits', ownProps.type.key], [])
  }
}

export default connect(mapStateToProps)(withTranslation()(ThresholdsLegend))
