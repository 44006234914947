
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { routeBuilder } from '../../../../utils/routing'
import * as ICONS from '../../../../utils/icons'

import './style.scss'

const CollapsedGrouping3 = props => {
  const { group, index, navigate, collapse } = props
  return (<div className={`Heatmap--Head-Grouping3-collapsed group-${index}`} key={`head_group_${group.id}`}>
    <span className='Heatmap--Grouping2-Expand' onClick={e => collapse(e, group.id)}>{ICONS.HM_EXPAND}</span>
    <div className={`Heatmap--Head-Grouping3-Container-collapsed`}>
      <span className='Heatmap--Head-Title-Tooltip'>{group.name}</span>
      <div className='Heatmap--Head-Subgroups-Header rotated' onClick={e => navigate('lever', group.id)}>
        <div className='Heatmap--Head-Grouping3-Container-collapsed-Title'>
          {group.name}
        </div>
      </div>
    </div>
  </div>
  )
}

const Subgroup = props => {
  const { group, navigate, isCollapsed, isTQ } = props
  const width = isTQ && group.questions ? '118px' : '148px'
  return (
    <div key={`group_${group.id}`} className='Heatmap--Head-Subgroup' style={{ height: width }} >
      <span className='Heatmap--Head-Title-Tooltip'>{group.name}</span>
      <div className={`Heatmap--Head-Subgroup-name${isTQ && !isCollapsed ? '' : ' noHeader'}`} onClick={e => navigate('lever', group.id)}>
        <div>
          {group.name}
        </div>
      </div>
    </div>)
}

const Grouping3 = props => {
  const { group, index, navigate, collapse, collapsed } = props
  const grouping2 = group.groups
  const width = group.groups.reduce((prev, curr) => {
    const current = collapsed.includes(curr.id) ? 38 - 1 + 4 : (((curr.groups.length * 38) - 1) + 4)
    return prev + current
  }, 0)
  return (<div className='Heatmap--Head-Grouping3' style={{ width: width, marginRight: '5px' }} key={`head_group_${group.id}`}>
    <div className='Heatmap--Head-Grouping3-Title'>
      <div className='Heatmap--Head-Title-Tooltip-Container'>
        <div className='Heatmap--Head-Title-Tooltip'>{group.name}</div>
        <div className='Heatmap--Head-GroupName' onClick={e => navigate('quadrant', group.id)}>{group.name}</div>
        <span className='Heatmap--Grouping3-Expand' onClick={e => collapse(e, group.id)}>{ICONS.HM_COLLAPSE}</span>
      </div>
    </div>
    <div className={`Heatmap--Head-Subgroups group-${index}`}>

      {grouping2.map(group2 => {
        const isCollapsed = collapsed.includes(group2.id)
        const group2Width = isCollapsed ? 41 : (group2.groups.length * 38) - 1 + 4
        return (
          <Fragment key={`${group2.id}`}>
            <div className='Heatmap--Head-Subgroup-Outer' style={{ width: group2Width }}>
              {group.isTQ && !isCollapsed && <span className='Heatmap--Grouping2-Expand'onClick={e => collapse(e, group2.id)}>{ICONS.HM_COLLAPSE}</span>}
              {group.isTQ && isCollapsed && <span className='Heatmap--Grouping2-Expand'onClick={e => collapse(e, group2.id)}>{ICONS.HM_EXPAND}</span>}

              <div className={`Heatmap--Head-Subgroup-Container ${group2.id}`}>

                {group.isTQ && !isCollapsed && <div className='Heatmap--Head-Subgroups-Header'>
                  <span className='Heatmap--Head-Title-Tooltip'>{group2.name}</span>
                  <div className='Heatmap--Head-Subgroup-Title' onClick={e => { navigate('sub', group2.id) }}>
                    {group2.name}
                  </div>
                </div>}
                <div>
                  {!isCollapsed && group2.groups.map(group3 => (
                    <Subgroup
                      isTQ={group.isTQ}
                      key={group3.id}
                      group={group3}
                      navigate={(...args) => navigate(...args)}
                    />))}
                  {isCollapsed &&
                  <Subgroup
                    isTQ={group.isTQ}
                    key={group2.id}
                    group={group2}
                    isCollapsed
                    toggleCollapsed={collapse}
                    navigate={(...args) => navigate(...args)}
                  />
                  }
                </div>
              </div>
            </div>
          </Fragment>
        )
      })}
    </div>
  </div>
  )
}

export default function Head ({ history, type, groups, collapsedQuadrants, toggleCollapsedQuadrant }) {
  function navigateToLeadersOf (level, id) {
    history.push({
      pathname: routeBuilder('listing', {
        type: type.key,
        level,
        value: id
      })
    })
  }

  const { t } = useTranslation()
  const length = groups.length

  return (
    <div className={`Heatmap--Head-${type.style}`}>
      <div className='Heatmap--Head-overall'>
        <span>{t('heatmap.overall')}</span>
      </div>
      {groups.map((group, index) => (
        collapsedQuadrants.includes(group.id)
          ? <CollapsedGrouping3
            key={group.id}
            group={group}
            index={groups.length < 6 ? length - index + 1 : length - index}
            navigate={navigateToLeadersOf}
            collapse={toggleCollapsedQuadrant}
          />
          : <Grouping3
            key={group.id}
            group={group}
            index={groups.length < 6 ? length - index + 1 : length - index}
            navigate={navigateToLeadersOf}
            collapse={toggleCollapsedQuadrant}
            collapsed={collapsedQuadrants}
          />
      ))}
    </div>
  )
}
