
import _ from 'lodash'

import * as types from './types'
import * as teamActions from '../teams/actions'
import { idFromMetadata } from '../../utils/metadata'
import { prepareSurveyId } from '../../utils/surveys'
import { calculateProgress } from '../../utils/calculations'
import { isProgress, baseType, totalsPath } from '../../utils/type'

function mapTeamsProgress (latest, previous, thresholds) {
  return latest.map(team => {
    const relatedTeam = team.id ? _.find(previous, { id: team.id }) : _.find(previous, t => !t.id)

    const progress = _.pick(team, ['name', 'level', 'id'])
    progress.score = calculateProgress(team.score, relatedTeam && relatedTeam.score, thresholds)
    progress.teams = mapTeamsProgress(
      _.get(team, 'teams', []),
      _.get(relatedTeam, 'teams', []),
      thresholds)

    return progress
  })
}

export function totals (metadata, xType, xLevel, xId = null, yType, yLevel, yId = null) {
  return (dispatch, getState) => {
    const tasks = [
      dispatch(loadTeams(metadata)),
      dispatch(loadTeamTotals('latest', metadata, baseType(xType), xLevel, xId)),
      dispatch(loadTeamTotals('latest', metadata, baseType(yType), yLevel, yId)),
      null,
      null
    ]

    if (isProgress(xType)) {
      tasks[3] = dispatch(loadTeamTotals('previous', metadata, baseType(xType), xLevel, xId))
    }

    if (isProgress(yType)) {
      tasks[4] = dispatch(loadTeamTotals('previous', metadata, baseType(yType), yLevel, yId))
    }

    return Promise.all(tasks).then(results => {
      const deltaThresholds = getState().profile.deltaThresholds

      const totals = {
        [totalsPath('latest', metadata, baseType(xType), xLevel, xId)]: _.get(results, '1.payload.data.teams'),
        [totalsPath('latest', metadata, baseType(yType), yLevel, yId)]: _.get(results, '2.payload.data.teams')
      }

      if (tasks[3]) {
        totals[totalsPath('previous', metadata, baseType(xType), xLevel, xId)] = _.get(results, '3.payload.data.teams')
        totals[totalsPath('progress', metadata, baseType(xType), xLevel, xId)] = mapTeamsProgress(_.get(results, '1.payload.data.teams'), _.get(results, '3.payload.data.teams'), deltaThresholds[baseType(xType)])
      }

      if (tasks[4]) {
        totals[totalsPath('previous', metadata, baseType(yType), yLevel, yId)] = _.get(results, '4.payload.data.teams')
        totals[totalsPath('progress', metadata, baseType(yType), yLevel, yId)] = mapTeamsProgress(_.get(results, '2.payload.data.teams'), _.get(results, '4.payload.data.teams'), deltaThresholds[baseType(yType)])
      }

      const payload = {
        teams: results[0].payload.data.teams,
        totals
      }

      dispatch({ type: types.TOTALS_SUCCESS, payload })
    })
  }
}

export function loadTeamTotals (surveyId, metadata, type, lever, value) {
  const path = totalsPath(surveyId, metadata, type, lever, value)
  return (dispatch, getState) => {
    const state = getState()
    const currentData = state.metrics.totals && state.metrics.totals[path]
    if (currentData && !currentData.loading) {
      return new Promise(resolve => resolve({
        payload: {
          data: {
            teams: currentData
          }
        }
      }))
    }

    const suffix = [lever]
    if (value) {
      suffix.push(value)
    }

    const url = `/data/${prepareSurveyId(surveyId)}/${idFromMetadata(metadata)}/scores/${type}/${suffix.join('/')}`

    return dispatch({
      types: [
        types.TEAMS_TOTALS_REQUEST,
        types.TEAMS_TOTALS_SUCCESS,
        types.TEAMS_TOTALS_FAILURE
      ],
      payload: {
        path,
        request: {
          method: 'GET',
          url
        }
      }
    })
  }
}

function getUrl (metadata, teamId = null, surveyId = 'latest') {
  const baseUrl = `/data/${prepareSurveyId(surveyId)}`

  if (!teamId) {
    return baseUrl + `/${idFromMetadata(metadata)}/summary`
  }

  return baseUrl + `/${idFromMetadata(metadata)}/team/${teamId}/summary`
}

export function retrieveLevels (metadata, teamId = null, surveyId = 'latest') {
  const url = getUrl(metadata, teamId, surveyId)

  const ignoreError = surveyId === 'previous'
  const success = surveyId === 'previous' ? types.RETRIEVE_PREVIOUS_SUCCESS : types.RETRIEVE_CURRENT_SUCCESS
  return {
    types: [ types.RETRIEVE_REQUEST, success, types.RETRIEVE_FAILURE ],
    payload: {
      request: {
        method: 'GET',
        url: url,
        ignoreNoSurvey: ignoreError,
        ignore404: ignoreError
      }
    }
  }
}

export function retrieveOrgLevels (metadata) {
  return function (dispatch, getState) {
    const state = getState().metrics

    if (state.orgLevels) {
      // already cached
      return Promise.resolve()
    }

    const url = getUrl(metadata, null, 'latest')

    return dispatch({
      types: [ types.RETRIEVE_REQUEST, types.RETRIEVE_ORG_SUCCESS, types.RETRIEVE_FAILURE ],
      payload: {
        request: {
          method: 'GET',
          url: url,
          ignoreNoSurvey: false,
          ignore404: false
        }
      }
    })
  }
}

export function loadTeams (metadata) {
  return (dispatch, getState) => {
    const teams = getState().teams.teams || []
    if (teams.length) {
      return new Promise(resolve => resolve({ payload: { data: { teams } } }))
    } else {
      return dispatch(teamActions.retrieve(metadata))
    }
  }
}

export function group (grouped) {
  return {
    type: types.CHANGE_GROUP, payload: grouped
  }
}
