import * as types from './types'
import { idFromMetadata } from '../../utils/metadata'

export function retrieve (metadata, type, level, value) {
  const url = value
    ? `/data/{latest}/${idFromMetadata(metadata)}/scores/${type}/${level}/${value}`
    : `/data/{latest}/${idFromMetadata(metadata)}/scores/${type}/${level}`

  return {
    types: [ types.RETRIEVE_REQUEST, types.RETRIEVE_SUCCESS, types.RETRIEVE_FAILURE ],
    payload: {
      request: {
        method: 'GET',
        url
      }
    }
  }
}

export function retrieveHistory (metadata, type, level, value) {
  const url = value
    ? `/data/{previous}/${idFromMetadata(metadata)}/scores/${type}/${level}/${value}`
    : `/data/{previous}/${idFromMetadata(metadata)}/scores/${type}/${level}`

  return {
    types: [ types.RETRIEVE_HISTORY_REQUEST, types.RETRIEVE_HISTORY_SUCCESS, types.RETRIEVE_HISTORY_FAILURE ],
    payload: {
      request: {
        method: 'GET',
        url,
        ignoreNoSurvey: true,
        ignore404: true
      }
    }
  }
}

export function sort (order) {
  return {
    type: types.SORT,
    order
  }
}

export function group (group) {
  return {
    type: types.GROUP,
    group
  }
}
