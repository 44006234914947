
import React, { Component } from 'react'
import SearchInput, { createFilter } from 'react-search-input'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { isLeaf } from '../../utils/helpers'
import { EXPAND } from '../../utils/icons'
import ContentWidth from '../../components/ContentWidth'
import Sidebar from '../../components/Sidebar/right'
import Nav from '../../components/Nav'
import LegendsIcon from '../../components/HeaderMenu/components/LegendsIcon'
import TeamCell from '../../components/TeamCell'
import Toggle from '../../components/Toggle'
import RestrictTo from '../../components/RestrictTo'
import ContentPortal from '../../components/HeaderMenu/components/ContentPortal'
import Legend from './components/RespondentsLegend'

import './style.scss'

export const MIN_THRESHOLD = 4

function getResponses (value) {
  if (value.responses > 99) {
    return '99+'
  }

  return value.responses || 0
}

class AdminQuestions extends Component {
  constructor (props) {
    super(props)
    this.state = {
      search: '',
      ready: false,
      type: props.questionTypes && props.questionTypes[0].key,
      showAll: true,
      expanded: []
    }
  }

  componentWillMount () {
    const { metadata } = this.props
    const { surveyId } = this.props.match.params
    Promise.all([
      this.props.retrieveQuestions(surveyId, metadata),
      this.props.retrieveTeams(surveyId, metadata)
    ]).then(() => {
      this.setState({
        ready: true,
        expanded: this.props.teams.filter(it => !isLeaf(it)).map(it => it.id)
      })
    })

    if (!this.props.survey.id && this.props.isAdmin) {
      this.props.retrieveSurveys()
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.questionTypes !== nextProps.questionTypes) {
      if (!_.some(nextProps.questionTypes, { key: this.state.type })) {
        this.setState({
          type: nextProps.questionTypes[0].key
        })
      }
    }
  }

  expand (team) {
    if (this.isExpanded(team.id)) {
      this.setState({
        expanded: this.state.expanded.filter(id => id !== team.id && id !== team.parentId)
      })
    } else {
      this.setState({
        expanded: this.state.expanded.concat(team.id)
      })
    }
  }

  toggleExpandAll (expandableTeams) {
    if (this.state.expanded.length === expandableTeams.length) {
      this.setState({ expanded: [] })
    } else {
      this.setState({ expanded: expandableTeams.map(it => it.id) })
    }
  }

  isExpanded (teamId) {
    return this.state.expanded.indexOf(teamId) >= 0
  }

  isVisible (team) {
    return !team.parentId || this.isExpanded(team.parentId)
  }

  isShown (team) {
    if (this.state.showAll === true) {
      return true
    }

    if (!isLeaf(team)) {
      return _.get(this.props.warnings, [team.id, this.state.type], null) !== null
    }

    const questions = _.get(this.props.questions, [team.id, this.state.type], [])
    const teamResponsesMinimum = _.chain(questions)
      .map(value => _.map(value, value => value))
      .flatten()
      .min()
      .value()

    return teamResponsesMinimum < MIN_THRESHOLD
  }

  renderTeam (team) {
    if (!this.isVisible(team) || !this.isShown(team)) {
      return
    }

    const groups = this.props.groups3[this.state.type]
    const { maxLevel } = this.props
    const classNames = ['RespondentsScene--Team']
    const isParent = team.teams && team.teams.length
    if (isParent) {
      classNames.push('bold')
    }

    return (
      <tr key={`team_${team.id}`}>
        <td className={classNames.join(' ')}>
          <TeamCell
            key={`team_${team.id}`}
            isCollapsed={!this.isExpanded(team.id)}
            isSelected={false}
            team={team}
            nested={(maxLevel - team.level) || 0}
            onMouseOver={() => {}}
            onMouseOut={() => {}}
            onClick={() => {}}
            onExpand={e => this.expand(team)}
          />
        </td>
        {groups.map(it => this.renderQuestions(team.id, it))}
      </tr>
    )
  }

  renderQuestions (teamId, groupName) {
    const questions = _.get(this.props.questions, [teamId, this.state.type, groupName], {})
    const groups2 = _.get(this.props.groups2, [this.state.type, groupName])
    const warnings = this.props.warnings

    return (
      <td key={`g_${teamId}_${groupName}`}>
        <div className='RespondentsScene--bubble-container'>
          {_.map(groups2, group => {
            const value = questions[group] || { responses: 0, respondents: 0 }
            const classNames = ['RespondentsScene--bubble']

            const hasLowResponses = value.responses < MIN_THRESHOLD
            classNames.push(hasLowResponses ? 'low' : 'high')

            const hasWarning = _.get(warnings, [teamId, this.state.type, groupName, group], false)
            const badge = hasWarning && !hasLowResponses ? <div className='warning-badge' /> : null
            const responses = getResponses(value)

            return <div key={`q_${teamId}_${group}`} className={classNames.join(' ')}>
              {responses}
              {badge}
            </div>
          })}
        </div>
      </td>
    )
  }

  renderNav () {
    const links = _.map(this.props.questionTypes, (questionType) => {
      return {
        text: questionType.plural,
        onClick: () => this.setState({ type: questionType.key }),
        selected: this.state.type === questionType.key
      }
    })

    return <ContentPortal right><Nav links={links} /></ContentPortal>
  }

  render () {
    const { t } = this.props

    if (!this.state.ready) {
      return <div>{t('respondents.loading')}</div>
    }

    const groups = this.props.groups3[this.state.type]
    const searchFilter = createFilter(this.state.search, ['name', 'teams.name'])
    const teams = this.props.teams.filter(searchFilter)

    const expandableTeams = this.props.teams.filter(it => !isLeaf(it))

    return (
      <ContentWidth>
        <h1 className='RespondentsScene--Headline'>
          {this.props.survey.name}
        </h1>
        <div className='RespondentsScene--actions'>
          <div className='RespondentsScene--actions-left'>
            <RestrictTo userRole='admin'>
              <Link to='/admin/surveys' className='RespondentsScene--ReturnLink'>
                {EXPAND}
                {t('respondents.all_surveys')}
              </Link>
            </RestrictTo>
          </div>
          <div className='RespondentsScene--actions-right'>
            <SearchInput className='RespondentsScene--Search' inputClassName='form-control' placeholder={t('respondents.search_team')} onChange={search => this.setState({ search })} />
          </div>
        </div>
        <table className='RespondentsScene mt2'>
          <thead>
            <tr>
              <th className='left-align'>{t('respondents.team_name')}</th>
              {groups.map(it => <th key={`group_${it}`}className='center'>{it}</th>)}
            </tr>
          </thead>
          <tbody>
            {teams.map(team => this.renderTeam(team))}
          </tbody>
        </table>
        <Sidebar>
          <LegendsIcon />
          <hr className='RespondentsScene--Spacer' />
          <div className='RespondentsScene--FilterLegend'>
            <h3>{t('respondents.filter.title')}</h3>
            <label>
              {t('respondents.filter.red_teams_only')}
              <Toggle
                checked={!this.state.showAll}
                onChange={e => this.setState({ showAll: !e.target.checked })}
              />
            </label>
            <label>
              {t('respondents.filter.expand_all')}
              <Toggle
                checked={this.state.expanded.length === expandableTeams.length}
                onChange={e => this.toggleExpandAll(expandableTeams)}
              />
            </label>
          </div>
          <Legend />
        </Sidebar>
        {this.renderNav()}
      </ContentWidth>
    )
  }
}

export default withTranslation()(AdminQuestions)
