
import * as types from './types'
import { idFromMetadata } from '../../utils/metadata'

const THROTTLE_TIME = 100

export function setMetadata (metadata) {
  return {
    type: types.SET_METADATA,
    metadata
  }
}

function loadRespondents (metadata) {
  return {
    types: [types.RESPONDENTS_REQUEST, types.RESPONDENTS_SUCCESS, types.RESPONDENTS_FAILURE],
    payload: {
      request: {
        method: 'GET',
        url: `/data/{latest}/${idFromMetadata(metadata)}/questions/statistics`,
        ignoreNoSurvey: true,
        ignore404: true
      }
    },
    metadata
  }
}

let throttle

export function checkMetadataRespondents (metadata) {
  return function (dispatch, getState) {
    if (throttle) {
      window.clearTimeout(throttle)
    }

    const metadataId = idFromMetadata(metadata)
    const minRespondents = getState().metadata.minRespondents

    // already cached: do nothing
    if (minRespondents && (metadataId in minRespondents)) {
      return
    }

    throttle = window.setTimeout(_ => {
      dispatch(loadRespondents(metadata))
    }, THROTTLE_TIME)
  }
}
