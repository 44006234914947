
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import EmptyScene from '../EmptyScene'
import ContentWidth from '../../components/ContentWidth'
import HighlightProgressToggle from '../../components/HighlightProgressToggle'
import { TeamProfileContainer } from '../../containers'
import Tiles from './components/Tiles'
import Groups from './components/Groups'
import ShareView from './components/ShareView'
import Sidebar from '../../components/Sidebar/left'
import SidebarRight from '../../components/Sidebar/right'
import LegendsIcon from '../../components/HeaderMenu/components/LegendsIcon'
import ThresholdsLegend from '../../components/ThresholdsLegend'
import Teams from '../../components/Sidebar/components/Teams'
import QuestionsList from './components/QuestionList'
import RadioSelect from '../../components/RadioSelect'
import Nav from '../Results/components/Nav'
import ContentPortal from '../../components/HeaderMenu/components/ContentPortal'
import { routeBuilder } from '../../utils/routing'

import './style.scss'

export class ResultsDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sort: 'default'
    }
  }

  componentWillUnmount () {
    this.props.reset()
    this.props.resetSelectedQuestions()
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.type !== nextProps.type) {
      this.props.reset()
    }

    if (this.props.team.id !== nextProps.team.id || this.props.type !== nextProps.type) {
      this.props.resetSelectedQuestions()
    }
  }

  url (team = this.props.team) {
    const options = {}
    if (this.props.filter || this.props.lever) {
      options.query = _.pick(this.props, ['filter', 'lever'])
    }

    return routeBuilder('teamDetails', {
      type: this.props.type.key,
      teamId: team.id || 'organisation'
    }, options)
  }

  hasProgress () {
    return this.props.selectedSurveys.length > 1
  }

  filterByQuadrant (name) {
    const options = {}

    if (name && this.props.filter !== name) {
      options.query = { filter: name }
    }

    const url = routeBuilder('teamDetails', {
      type: this.props.type.key,
      teamId: this.props.team.id
    }, options)

    this.props.history.push(url)
  }

  renderHighlightToggle () {
    if (!this.hasProgress()) {
      return null
    }

    return (
      <HighlightProgressToggle
        value={this.props.highlightProgress}
        onChange={(value) => this.props.changeHighlightProgress(value)}
      />)
  }

  renderLegend () {
    const { t } = this.props

    const sortingOptions = [
      { name: t('team_details.sorting.default'), value: 'default' },
      { name: t('team_details.sorting.score_desc'), value: 'score-desc' },
      { name: t('team_details.sorting.score_asc'), value: 'score-asc' }
    ]

    if (this.hasProgress()) {
      sortingOptions.push({ name: t('team_details.sorting.progress_desc'), value: 'progress-desc' })
      sortingOptions.push({ name: t('team_details.sorting.progress_asc'), value: 'progress-asc' })
    }

    return (
      <SidebarRight>
        <LegendsIcon />
        {this.renderHighlightToggle()}
        <div className='TeamProfile--legend'>
          <h3>{t('team_details.sorting.title')}</h3>
          <RadioSelect
            options={sortingOptions}
            selected={this.state.sort}
            onChange={sort => this.setState({ sort })}
          />
        </div>
        <ThresholdsLegend showProgress={this.props.highlightProgress} type={this.props.type} />
      </SidebarRight>
    )
  }

  renderSelector () {
    if (!this.props.team) {
      return null
    }

    return (
      <ContentPortal right>
        <Nav team={this.props.team} selected={this.props.type.key} questionTypes={this.props.questionTypes} />
      </ContentPortal>)
  }

  renderMain () {
    const team = this.props.team || {}
    if (!team.id) {
      return null
    }

    let list
    if (this.state.sort === 'default') {
      list = <Groups
        highlightProgress={this.props.highlightProgress}
        filter={this.props.filter}
        highlightedLever={this.props.lever}
      />
    } else {
      list = <QuestionsList
        sort={this.state.sort}
        filter={this.props.filter}
        highlightProgress={this.props.highlightProgress}
        highlightedLever={this.props.lever}
      />
    }

    return (
      <TeamProfileContainer type={this.props.type} team={team}>
        <Tiles
          filter={this.props.filter}
          highlightProgress={this.props.highlightProgress}
          onChange={name => this.filterByQuadrant(name)}
          highlightedLever={this.props.lever}
        />
        {list}
        <ShareView team={this.props.team} />
      </TeamProfileContainer>)
  }

  render () {
    return (
      <ContentWidth>
        <EmptyScene>
          {this.renderSelector()}
          <div className='ResultsDetails'>
            {this.renderMain()}
            <Sidebar>
              <Teams url={this.url.bind(this)} match={this.props.match} />
            </Sidebar>
            {this.renderLegend()}
          </div>
        </EmptyScene>
      </ContentWidth>
    )
  }
}

export default withTranslation()(ResultsDetails)
