import * as types from './types'
import Immutable from 'seamless-immutable'

const initialState = Immutable({
  leftPopupOpen: false,
  rightPopupOpen: false
})

export default function reducer (state = initialState, action = {}) {
  switch (action.type) {
    case types.TOGGLE_LEFT_POPUP:
      return state.set('leftPopupOpen', !state.leftPopupOpen)
    case types.TOGGLE_RIGHT_POPUP:
      return state.set('rightPopupOpen', !state.rightPopupOpen)
    case types.TOGGLE_BOTH_POPUP:
      return state.merge({
        leftPopupOpen: !state.leftPopupOpen,
        rightPopupOpen: !state.leftPopupOpen
      })
    default:
      return state
  }
}
