import React, { Component } from 'react'
import _ from 'lodash'

export default class Levels extends Component {
  shouldComponentUpdate (nextProps, nextState) {
    const next = _.omit(nextProps, ['levels', 'prevLevels'])
    const curr = _.omit(this.props, ['levels', 'prevLevels'])
    return !_.isEqual(next, curr)
  }

  componentWillMount () {
    this.mounted = true
    this.retrieve()
  }

  componentWillUnmount () {
    this.mounted = false
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.team !== this.props.team || nextProps.metadata !== this.props.metadata) {
      this.retrieve(nextProps)
    }
  }

  hasHistory () {
    return this.props.surveys && this.props.surveys.length > 1
  }

  retrieve (props = this.props) {
    const { team } = props
    if (team) {
      const tasks = [
        props.retrieve(props.metadata, team.id),
        props.preloadOrg(props.metadata)
      ]

      if (this.hasHistory()) {
        tasks.push(props.retrieve(props.metadata, team.id, 'previous'))
      }

      Promise.all(tasks).then(() => this.mounted && this.forceUpdate())
    }
  }

  render () {
    return React.Children.map(this.props.children, child => {
      return React.cloneElement(child, _.omit(this.props, ['children']))
    })
  }
}
