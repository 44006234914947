import React, { Component } from 'react'
import { select } from 'd3-selection'
import { svg } from 'd3-svg'
import { arc } from 'd3-shape'
import { format } from 'd3-format'
import { interpolateNumber, interpolateString } from 'd3-interpolate'
// import Dimensions from 'react-dimensions'
import 'd3-transition'
import _ from 'lodash'

import { isNotAsked } from '../../../../utils'

const INNER_MARGIN = 65
const OUTER_MARGIN = 20
const ARC_MARGIN = 1.5
const WIDTH = 400
const ARC_BACKGROUND = '#EBEFF3'
const ARC_DISABLED = 'url(#diagonalHatch)'
const HOVER_TRANSITION_DURATION = 60

function radiansToDegrees (radians) {
  return radians * (180 / Math.PI)
}

function arcLabelMarginByLengthBefore (length) {
  if (length > 2) {
    return 16
  }

  return 12
}

function arcLabelMarginByLengthAfter (length) {
  if (length > 2) {
    return 18
  }

  return 12
}

function arcTween (v) {
  return d => {
    var i = interpolateNumber(d.previousValue || 0, d.value || 0)
    return t => {
      d.value = i(t)
      return v(d)
    }
  }
}

function radialValue (value, radius, textLength) {
  return (value * 360) + radiansToDegrees(arcLabelMarginByLengthAfter(textLength) / radius)
}

function radialValueBefore (value, radius, textLength) {
  return (value * 360) - radiansToDegrees(arcLabelMarginByLengthBefore(textLength) / radius)
}

function tween () {
  return function (d, i, a) {
    const value = d.value || 0
    const previousValue = d.previousValue || 0
    return interpolateString('rotate(' + ((previousValue * 360) - 90) + ')', 'rotate(' + ((value * 360) - 90) + ')')
  }
}

function histTween () {
  return function (d, i, a) {
    const pv = d.histPreviousValue || 0
    const hv = d.histValue || 0
    return interpolateString('rotate(' + ((pv * 360) - 90) + ')', 'rotate(' + ((hv * 360) - 90) + ')')
  }
}

function retrieveHistValue (values, index) {
  return _.get(values, [index, 'score', 'progress'])
}

function retrieveHistText (values, index) {
  return _.get(values, [index, 'score', 'value'], '')
}

function renderTriangle (field, className) {
  return field
    .append('g')
    .attr('transform', 'translate(-30,9)')
    .append('polygon')
    .attr('points', '-5,-5 0,5 5,-5')
    .attr('transform', 'rotate(180)')
    .attr('class', className)
    .style('fill', 'black')
    .style('opacity', 0)
}

function renderText (field, className) {
  return field
    .append('text')
    .attr('class', className)
    .attr('transform', 'rotate(180)')
    .style('font-size', '14px')
    .style('font-weight', 600)
    .style('text-anchor', 'middle')
}

function renderGauge (field, className) {
  return field
    .append('line')
    .attr('class', className)
    .attr('x1', d => d.radius)
    .attr('x2', d => d.outerRadius)
    .attr('y1', 0)
    .attr('y2', 0)
    .style('stroke', '#000000')
    .attr('transform', 'rotate(-90)')
}

function renderGaugeLabels (field, values) {
  return field
    .append('g')
    .attr('class', 'textG')
    .append('g')
    .attr('class', 'textGG')
    .attr('transform', d => `translate(${d.centerRadius},0)`)
    .append('text')
    .attr('dx', 0)
    .attr('dy', 0)
    .text(0)
    .attr('transform', 'rotate(180)')
    .attr('class', 'arc-text')
    .style('font-size', values && values.length > 5 ? '10px' : '14px')
    .style('opacity', 0.0)
    .style('text-anchor', 'middle')
    .style('dominant-baseline', 'middle')
}

function renderPath (field, className) {
  return field
    .append('path')
    .attr('class', className)
}

function makeTransition (el) {
  return el.transition().duration(1550)
}

function currentScoreTextOutside (d) {
  if (d.histValue === null || d.histValue === undefined) {
    return false
  }

  return d.value > d.histValue
}

function currentScoreTextOffset (d) {
  if (d.value === 0) {
    return -15
  }

  return 0
}

function currentScoreTextPosition (d) {
  if (d.value === 0) {
    return -90
  }

  if (currentScoreTextOutside(d)) {
    return radialValue(d.value, d.centerRadius, d.text.length) - 90
  }

  return radialValueBefore(d.value, d.centerRadius, d.text.length) - 90
}

function currentScoreTextOrientation (d) {
  if (d.value === 0) {
    return -90
  }

  if (currentScoreTextOutside(d)) {
    return 270 - radialValue(d.value, d.centerRadius, d.text.length)
  }

  return 270 - radialValueBefore(d.value, d.centerRadius, d.text.length)
}

function currentScoreTextColor (d) {
  if (d.value === 0) {
    return '#000'
  }

  if (currentScoreTextOutside(d)) {
    return '#000'
  }

  return d.color.text
}

function historicScoreTextOutside (d) {
  return d.value <= d.histValue
}

function historicScoreTextPosition (d) {
  if (historicScoreTextOutside(d)) {
    return radialValue(d.histValue, d.centerRadius, d.histText.length) - 90
  }

  return radialValueBefore(d.histValue, d.centerRadius, d.histText.length) - 90
}

function historicScoreTextOrientation (d) {
  if (historicScoreTextOutside(d)) {
    return 270 - radialValue(d.histValue, d.centerRadius, d.histText.length)
  }

  return 270 - radialValueBefore(d.histValue, d.centerRadius, d.histText.length)
}

function historicScoreTextColor (d) {
  if (historicScoreTextOutside(d)) {
    return '#000'
  }

  return d.color.text
}

function historicScoreGaugeColor (d) {
  if (historicScoreTextOutside(d) || d.color.text === '#000') {
    return '#000'
  }

  return '#ebeff3'
}

function fieldTransition () {
  const field = makeTransition(select(this))
  const base = select(this)

  field.select('.arc-body')
    .attrTween('d', arcTween(arcBody))
    .style('fill', d => d.color.back)

  field.select('.arc-center')
    .attrTween('d', arcTween(arcCenter))

  field.select('.arc-placeholder')
    .attrTween('d', arcTween(arcPlaceholder))

  base.select('.arc-placeholder')
    .style('fill', d => {
      return d.hasRespondents ? ARC_BACKGROUND : ARC_DISABLED
    })

  field.select('.textG')
    .attr('transform', d => `rotate(${currentScoreTextPosition(d)}),translate(0,${currentScoreTextOffset(d)})`)

  field.select('.main-gauge')
    .attrTween('transform', tween())
    .style('stroke-width', (d) => d.value ? 2 : 0)

  field.select('.arc-text')
    .text(d => d.text)
    .attr('transform', d => `rotate(${currentScoreTextOrientation(d)})`)
    .style('fill', currentScoreTextColor)
    .on('end', t => {
      select(this)
        .transition()
        .select('.arc-text')
        .style('opacity', 1)
        .style('font-weight', 'bold')
    })
}

function histFieldTransition () {
  var field = makeTransition(select(this))

  field.select('.hist-gauge')
    .attrTween('transform', histTween())
    .style('stroke', historicScoreGaugeColor)
    .style('opacity', d => {
      return d.histValue && d.histValue !== d.value ? 1 : 0
    })

  field.select('.textG')
    .attr('transform', d => `rotate(${historicScoreTextPosition(d)})`)

  field.select('.arc-text')
    .text(d => d.histText)
    .attr('transform', d => `rotate(${historicScoreTextOrientation(d)})`)
    .style('fill', historicScoreTextColor)
    .on('end', t => {
      select(this)
        .transition()
        .select('.arc-text')
        .style('opacity', d => d.histValue && d.histValue !== d.value ? 1 : 0)
    })
}

const arcPlaceholder = arc()
  .startAngle(0)
  .endAngle(d => 2 * Math.PI)
  .innerRadius(d => d.radius)
  .outerRadius(d => d.outerRadius)
  .cornerRadius(0)

const arcBody = arc()
  .startAngle(0)
  .endAngle(d => d.value * 2 * Math.PI)
  .innerRadius(d => d.radius)
  .outerRadius(d => d.outerRadius)
  .cornerRadius(0)

const arcCenter = arc()
  .startAngle(0)
  .endAngle(d => d.value * 2 * Math.PI)
  .innerRadius(d => d.centerRadius)
  .outerRadius(d => d.centerRadius)

class RadialChart extends Component {
  componentDidMount () {
    const key = this.props.id
    const svgContainer = select(this.node).select('.current')
    svgContainer.append('g').classed('center', true)

    // Main labels
    renderText(svgContainer.select('.center'), 'overview-value')
      .attr('x', '0.55em')
      .attr('y', '0.35em')
      .style('text-anchor', 'end')
      .style('font-size', '40px')
      .text(d => 0)

    // Main circles
    this.field = svgContainer
      .selectAll('g.current')
      .data(this.fields(this.props.values, this.props.histValues, this.props.colors, true))
      .enter()
      .append('g')

    renderPath(this.field, 'arc-placeholder')
      .style('fill', ARC_BACKGROUND)
      .on('mouseover', (d) => d && this.select(d.id))
      .on('mouseout', (d) => this.select(null))

    renderPath(this.field, 'arc-body')
      .style('fill', ARC_BACKGROUND)
      .on('mouseover', (d) => d && this.select(d.id))
      .on('mouseout', (d) => this.select(null))

    renderPath(this.field, 'arc-center')
      .attr('id', (d, i) => `arc-center-${key}-${i}`)

    renderGauge(this.field, 'main-gauge')
      .style('stroke-width', (d) => d.value ? 2 : 0)
      .on('mouseover', (d) => d && this.select(d.id))
      .on('mouseout', (d) => this.select(null))

    renderGaugeLabels(this.field, this.props.values)
      .on('mouseover', (d) => d && this.select(d.id))
      .on('mouseout', (d) => this.select(null))

    // Historical
    this.histContainer = select(this.node).select('.history')
    this.histContainer.style('opacity', !this.props.histOverall ? 0 : 1.0)
    this.histContainer.append('g').classed('center', true)

    // Historical labels
    renderText(this.histContainer.select('.center'), 'last')
      .attr('dy', 40)
      .style('font-weight', 300)
      .text(d => 'Last 0')
      .on('mouseover', (d) => d && this.select(d.id))
      .on('mouseout', (d) => this.select(null))

    // Historical circles
    this.histField = this.histContainer
      .selectAll('g:not(.center)')
      .data(this.fields(this.props.values, this.props.histValues, this.props.colors, true))
      .enter()
      .append('g')

    renderGauge(this.histField, 'hist-gauge')
      .style('stroke-dasharray', '2,2')
      .on('mouseover', (d) => d && this.select(d.id))
      .on('mouseout', (d) => this.select(null))

    renderTriangle(this.histContainer.select('.center'), 'hist-triangle')
    renderGaugeLabels(this.histField, this.props.values)
      .on('mouseover', (d) => d && this.select(d.id))
      .on('mouseout', (d) => this.select(null))

    // Initial animation
    setTimeout(() => {
      const values = this.fields(this.props.values, this.props.histValues, this.props.colors)
      this.transitionCurrent(values)
      this.transitionHistory(values)
      this.transitionOverall(0, this.props.overall, this.props.overallNotAsked, this.numberFormat())
      this.transitionLast(0, this.props.histOverall, this.numberFormat())
      this.transitionTriangle(this.props.overall, this.props.histOverall)
    }, 50)
  }

  select (id) {
    if (!this.props.onSelect) {
      return
    }

    clearTimeout(this.selectTimer)

    if (!id) {
      this.selectTimer = setTimeout(() => this.clearSelection(), 60)
      return
    }

    const parent = select(this.node)

    parent
      .selectAll('.arc-body')
      .transition(HOVER_TRANSITION_DURATION)
      .style('opacity', d => d.id === id ? 1 : 0.4)

    parent
      .selectAll('.arc-placeholder')
      .transition(HOVER_TRANSITION_DURATION)
      .style('opacity', d => d.id === id ? 1 : 0.4)

    parent
      .selectAll('.textGG')
      .transition(HOVER_TRANSITION_DURATION)
      .style('opacity', d => d.id === id ? 1 : 0.3)

    parent
      .selectAll('.main-gauge')
      .transition(HOVER_TRANSITION_DURATION)
      .style('opacity', d => d.id === id ? 1 : 0.2)

    parent
      .selectAll('.center')
      .transition(HOVER_TRANSITION_DURATION)
      .style('opacity', 0.5)

    this.props.onSelect(id)
  }

  clearSelection () {
    const parent = select(this.node)

    parent
      .selectAll('.arc-body')
      .transition(HOVER_TRANSITION_DURATION)
      .style('opacity', 1)

    parent
      .selectAll('.arc-placeholder')
      .transition(HOVER_TRANSITION_DURATION)
      .style('opacity', 1)

    parent
      .selectAll('.textGG')
      .transition(HOVER_TRANSITION_DURATION)
      .style('opacity', 1)

    parent
      .selectAll('.main-gauge')
      .transition(HOVER_TRANSITION_DURATION)
      .style('opacity', 1)

    parent
      .selectAll('.center')
      .transition(HOVER_TRANSITION_DURATION)
      .style('opacity', 1)

    this.props.onSelect(null)
  }

  numberFormat () {
    return this.props.integers ? ',d' : '.1f'
  }

  transitionOverall (current, next, notAsked, numberFormat = ',d') {
    const el = select(this.node).select('.overview-value')
    makeTransition(el)
      .tween('text', function () {
        let that = select(this)
        if (!next) {
          const score = notAsked ? '' : 'N/A'
          return t => that.text(score)
        }

        let i = interpolateNumber(current || 0, next)
        let formatNumber = format(numberFormat)
        return function (t) { that.text(formatNumber(i(t))) }
      })
  }

  transitionCurrent (values) {
    this.field
      .each(function (d) { this._value = d.value })
      .data(values)
      .each(function (d) { d.previousValue = this._value })
      .each(fieldTransition)
  }

  transitionHistory (values) {
    this.histField
      .each(function (d) { this._histValue = d.histValue })
      .data(values)
      .each(function (d) { d.histPreviousValue = this._histValue })
      .each(histFieldTransition)
  }

  fields (values, histValues, colors, isEmpty = false, width = WIDTH) {
    const arcWidth = Math.floor((width / 2 - INNER_MARGIN - OUTER_MARGIN) / (values.length))

    return _.map(values, (value, index) => {
      const radius = INNER_MARGIN + arcWidth * index + ARC_MARGIN * index

      return {
        id: value.id,
        index,
        text: isEmpty === true ? '0' : _.get(value, 'score.value', '').toString(),
        value: isEmpty === true ? 0.0 : _.get(value, 'score.progress'),
        color: colors[index],
        radius: radius,
        centerRadius: radius + arcWidth / 2,
        outerRadius: radius + arcWidth,
        histText: isEmpty === true ? '0' : String(retrieveHistText(histValues, index)),
        histValue: isEmpty === true ? 0.0 : retrieveHistValue(histValues, index),
        hasRespondents: !isNotAsked(value.score)
      }
    })
  }

  transitionHistoryContainer (current, next) {
    if ((!current && next) || (current && !next)) {
      this.histContainer.style('opacity', !next ? 0 : 1)
    }
  }

  transitionLast (current, next, numberFormat = ',d', histLabel = this.props.histLabel) {
    const label = histLabel || 'Last'
    const lastScore = select(this.node).select('.last')
    makeTransition(lastScore)
      .tween('text', function () {
        let that = select(this)
        let i = interpolateNumber(current || 0, next || 0)
        let formatNumber = format(numberFormat)
        let formatString = d => `${label.substring(0, 14)}${label.length > 15 ? '\u2026' : ''} ` + formatNumber(d)
        return function (t) { that.text(formatString(i(t))) }
      })
  }

  transitionTriangle (overall, histOverall) {
    const triangle = select(this.node).select('.hist-triangle')
    makeTransition(triangle)
      .attr('transform', overall > histOverall ? 'rotate(0)' : 'rotate(180)')
      .style('opacity', overall === histOverall ? 0 : 1)
  }

  componentWillReceiveProps (nextProps) {
    const changedValues = this.props.values !== nextProps.values
    const changedHistoryValues = this.props.histValues !== nextProps.histValues && JSON.stringify(this.props.histValues) !== JSON.stringify(nextProps.histValues)

    if (changedValues || changedHistoryValues) {
      this.transitionCurrent(this.fields(nextProps.values, nextProps.histValues, nextProps.colors))
      this.transitionHistory(this.fields(nextProps.values, nextProps.histValues, nextProps.colors))
    }

    if (this.props.histOverall !== nextProps.histOverall) {
      this.transitionHistoryContainer(this.props.histOverall, nextProps.histOverall)
      this.transitionLast(this.props.histOverall, nextProps.histOverall, this.numberFormat(), nextProps.histLabel)
    }

    if (this.props.overall !== nextProps.overall || this.props.histOverall !== nextProps.histOverall) {
      this.transitionTriangle(nextProps.overall, nextProps.histOverall)
    }

    if (this.props.overall !== nextProps.overall || this.props.histOverall !== nextProps.histOverall) {
      this.transitionOverall(this.props.overall, nextProps.overall, nextProps.overallNotAsked, this.numberFormat())
    }
  }

  render () {
    const translate = `translate(${WIDTH / 2}, ${WIDTH / 2}) rotate(180)`
    return (
      <svg
        width={this.props.containerWidth}
        height={this.props.containerWidth}
        ref={node => { this.node = node }}
        viewBox={`0 0 ${WIDTH} ${WIDTH}`}>
        <defs>
          <pattern id='diagonalHatch' patternUnits='userSpaceOnUse' width='4' height='4'>
            <path d='M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2' style={{
              stroke: '#E5E3E4',
              strokeWidth: 1
            }} />
          </pattern>
        </defs>
        <g transform={translate} className='current' />
        <g transform={translate} className='history' />
      </svg>
    )
  }
}

// export default Dimensions()(RadialChart)
export default RadialChart
