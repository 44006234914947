
import React, { Component } from 'react'
import { connect } from 'react-redux'

import './style.scss'

const STYLE_CLASSES = {
  left: 'left-layout',
  right: 'right-layout',
  full: 'full-layout'
}

class ContentWidth extends Component {
  renderChildren () {
    return React.Children.map(this.props.children, child => {
      return React.cloneElement(child)
    })
  }

  render () {
    const classes = ['App-container']

    if (this.props.layout && this.props.layout in STYLE_CLASSES) {
      let className = STYLE_CLASSES[this.props.layout]
      if (this.props.always) {
        className += '-always'
      }

      classes.push(className)
    }

    if (this.props.noFooterMargin) {
      classes.push('no-footer-margin')
    }

    if (this.props.leftOpen) {
      classes.push('expand-left')
    }

    if (this.props.rightOpen) {
      classes.push('expand-right')
    }

    return <div className={classes.join(' ')}>{this.renderChildren()}</div>
  }
}

function mapStateToProps (state, ownProps) {
  return {
    leftOpen: state.app.leftPopupOpen,
    rightOpen: state.app.rightPopupOpen
  }
}
export default connect(mapStateToProps)(ContentWidth)
