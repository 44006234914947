
import React from 'react'
import './style.scss'

export function SelectionScaleDefault ({ level }) {
  const classes = [
    'SelectionScale'
  ]

  if (level > 0.98) {
    classes.push('full')
  }

  if (level < 0.03) {
    classes.push('empty')
  }

  return (
    <div className={classes.join(' ')}>
      <div className='SelectionScale--progress' style={{ width: `${level}%` }} />
    </div>)
}

export default function SelectionScale (props) {
  return <SelectionScaleDefault {...props} />
}
