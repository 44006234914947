
import I18n from '../utils/i18n'
import * as types from '../services/profile/types'

const populateI18n = store => next => action => {
  if (action.type === types.SETTINGS_SUCCESS) {
    const data = action.payload.data.localisation || {}
    I18n.addResourceBundle('survey_specific', 'translation', data, true, true)
  }

  return next(action)
}

export default populateI18n
