import { connect } from 'react-redux'
import _ from 'lodash'
import Component from './Component'
import * as actions from '../../../../services/questions/actions'

function checkGrouping1 ({ questions, totals }) {
  if (_.isEmpty(questions)) {
    return false
  }

  const subGroups = _.chain(totals)
    .get('groups', [])
    .map(group => group.groups)
    .flatten()
    .value()

  return subGroups.length !== questions.length
}

function mapStateToProps (state, ownProps) {
  const hasGrouping1 = checkGrouping1(ownProps)

  return {
    hasGrouping1,
    selected: state.questions.selected,
    filter: ownProps.filter ? { name: ownProps.filter } : null
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleQuestion: (...args) => dispatch(actions.toggleQuestion(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
