import React, { PureComponent } from 'react'
import * as icons from '../../utils/icons'
import './style.scss'

export default class SidebarCell extends PureComponent {
  render () {
    const {
      disabled, children, isRoot, isChild, isExpand, isOpen, isSelected, isMedium,
      level, pin, onMouseOver, onMouseOut, onClick, onExpand
    } = this.props

    const left = []
    const right = []

    if (isRoot) {
      left.push(icons.HOME)
    }

    if (isChild && !isExpand) {
      if (level === 0) {
        left.push(icons.CHILD_DOT_ONLY)
      } else {
        left.push(icons.CHILD_DOT)
      }
    }

    if (isChild && isExpand) {
      left.push(icons.CHILD)
    }

    if (isExpand) {
      const expandClassName = ['SidebarCell--expandContainer', isOpen ? 'expanded' : 'collapsed']
      if (onExpand) {
        expandClassName.push('pointer')
      }

      left.push(<div key='expand-container' className={expandClassName.join(' ')} onClick={onExpand}>
        {icons.EXPAND}
      </div>)
    }

    if (pin && !disabled) {
      right.push(pin)
    }

    const classNames = ['SidebarCell']
    if (isSelected) {
      classNames.push('SidebarCell--selected')
    }
    if (isMedium) {
      classNames.push('SidebarCell--medium')
    }

    if (level !== undefined && level >= 0) {
      classNames.push(`SidebarCell--level${level}`)
    }

    const centerProps = {}
    const centerClassNames = ['SidebarCell--centerContainer']
    if (onClick && !disabled) {
      centerClassNames.push('pointer')
      centerProps.onClick = onClick
    }

    if (disabled) {
      classNames.push('SidebarCell--disabled')
    }

    centerProps.className = centerClassNames.join(' ')

    return (
      <div className={classNames.join(' ')} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
        <div className='SidebarCell--leftContainer'>
          {left}
        </div>
        <div {...centerProps}>
          {children}
        </div>
        <div className='SidebarCell--rightContainer'>
          {right}
        </div>
      </div>
    )
  }
}
