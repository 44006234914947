import { connect } from 'react-redux'
import * as actions from '../../services/profile/actions'
import * as metadataActions from '../../services/metadata/actions'

import Component from './Component'

function mapStateToProps (state) {
  return {
    metadata: state.metadata.selectedMetadata,
    surveys: state.profile.selectedSurveyIds,
    questionTypes: state.profile.questionTypes
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setMetadata: (metadata) => dispatch(metadataActions.setMetadata(metadata)),
    setSurveys: (surveys) => dispatch(actions.setSurveys(surveys)),
    retrieve: () => dispatch(actions.retrieve()),
    settings: () => dispatch(actions.settings())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
