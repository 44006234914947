import * as types from './types'
import Immutable from 'seamless-immutable'
import _ from 'lodash'

const initialState = Immutable({
  levels: null,
  prevLevels: null,
  error: null,
  loading: false,
  grouped: null
})

export default function metrics (state = initialState, action) {
  switch (action.type) {
    case types.RETRIEVE_REQUEST:
      return state.merge({
        loading: true,
        error: null
      })

    case types.RETRIEVE_CURRENT_SUCCESS:
      return state.merge({
        loading: false,
        error: null,
        levels: action.payload.data
      })

    case types.RETRIEVE_PREVIOUS_SUCCESS:
      return state.merge({
        loading: false,
        error: null,
        prevLevels: action.payload.data
      })

    case types.RETRIEVE_ORG_SUCCESS:
      return state.merge({
        loading: false,
        error: null,
        orgLevels: action.payload.data
      })

    case types.RETRIEVE_FAILURE:
      return state.merge({
        loading: false,
        error: _.get(action.error, 'response.data.message', action.error.message)
      })

    case types.TOTALS_SUCCESS:
      return state.merge(action.payload, { deep: true })

    case types.TEAMS_TOTALS_REQUEST:
      if (state.totals && state.totals[action.payload.path]) {
        return state
      }

      return state.merge({
        totals: {
          [action.payload.path]: { loading: true }
        }
      }, { deep: true })

    case types.CHANGE_GROUP:
      return state.merge({ grouped: action.payload })

    default:
      return state
  }
}

function assignTotal (team, total, parentId = null) {
  return Object.assign({}, team, total, { parentId })
}

export function joinTotals (teams, totals, parentId) {
  return _.chain(teams)
    .flatMapDeep(it => {
      const id = it.id
      const total = _.find(totals, { id })
      const subTeams = it.teams || []
      const mappedTeams = joinTotals(subTeams, totals, it.id)
      return [ assignTotal(it, total, parentId), mappedTeams ]
    })
    .compact()
    .value()
}
