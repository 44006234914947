import { connect } from 'react-redux'
import Component from './Component'

function mapStateToProps (state) {
  return {
    surveys: state.profile.selectedSurveys,
    benchmark: !!(state.profile.benchmark && state.profile.benchmark.length)
  }
}

export default connect(mapStateToProps)(Component)
