import React from 'react'
import { Link } from 'react-router-dom'

import ContentWidth from '../../../components/ContentWidth'

const AdminScene = props => {
  return (
    <ContentWidth layout='full'>
      <div>
        <h1>Admin</h1>
        <p><Link to='/admin/surveys'>Surveys</Link></p>
      </div>
    </ContentWidth>
  )
}

export default AdminScene
