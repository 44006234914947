
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import ContentWidth from '../../components/ContentWidth'
import RadialChart from './components/RadialChart'
import Nav from './components/Nav'
import EmptyScene from '../EmptyScene'
import ResultsLegend from './components/ResultsLegend'
import Sidebar from '../../components/Sidebar/left'
import SidebarRight from '../../components/Sidebar/right'
import LegendsIcon from '../../components/HeaderMenu/components/LegendsIcon'
import Teams from '../../components/Sidebar/components/Teams'
import ContentPortal from '../../components/HeaderMenu/components/ContentPortal'
import Scores from './components/Scores'
import * as constants from '../../utils/constants'
import { routeBuilder } from '../../utils/routing'
import { isNotAsked, isNotAnswered } from '../../utils'
import { getReferenceScores } from './utils'
import I18n from '../../utils/i18n'

import './style.scss'

const CAPABILITY_COLORS = [{
  back: '#0096d1',
  text: '#fff'
}, {
  back: '#00ADEF',
  text: '#fff'
}, {
  back: '#33BDF2',
  text: '#000'
}, {
  back: '#66CEF5',
  text: '#000'
}, {
  back: '#99DEF9',
  text: '#000'
}, {
  back: '#CCEFFC',
  text: '#000'
}]

const OUTCOME_COLORS = [{
  back: '#002966',
  text: '#fff'
}, {
  back: '#003481',
  text: '#fff'
}, {
  back: '#335D9A',
  text: '#fff'
}, {
  back: '#6685B3',
  text: '#fff'
}, {
  back: '#99AECD',
  text: '#fff'
}, {
  back: '#CCD6E6',
  text: '#000'
}]

const CHART_COLORS = {
  maturity: CAPABILITY_COLORS,
  outcome: OUTCOME_COLORS,
  default: CAPABILITY_COLORS
}

const CHART_COLOR_OVERFLOW = {
  maturity: {
    back: '#007FB3',
    text: '#fff'
  },
  outcome: {
    back: '#001E4B',
    text: '#fff'
  },
  default: {
    back: '#007FB3',
    text: '#fff'
  }
}

const TEXTS = {
  maturity: {
    [constants.CALCULATION_LEGACY]: 'Your capabilities score indicates your teams\' overall level of maturity in adoption of DevOps and Agile practices. The capabilities score is the linear average of your scores across the key maturity dimensions. Each dimension is rated as a percentage, where 100% is the level achieved by teams with fully developed DevOps and Agile practices.',
    [constants.CALCULATION_2]: 'Your capabilities score indicates your teams\' overall level of maturity in adoption of DevOps and Agile practices. The capabilities score is the linear average of your scores across the key maturity dimensions. Each dimension is scored from 1 to 5, where 1 is low and 5 is very high.',
    [constants.CALCULATION_AVERAGE_UPTO_FIVE]: 'Your capabilities score indicates your teams\' overall level of maturity in adoption of DevOps and Agile practices. The capabilities score is the linear average of your scores across the key maturity dimensions. Each dimension is rated as a percentage, where 100% is the level achieved by teams with fully developed DevOps and Agile practices.',
    [constants.CALCULATION_AVERAGE_UPTO_FIVE_V2]: 'Your capabilities score indicates your teams\' overall level of maturity in adoption of DevOps and Agile practices. The capabilities score is the linear average of your scores across the key maturity dimensions. Each dimension is rated as a percentage, where 100% is the level achieved by teams with fully developed DevOps and Agile practices.',
    [constants.CALCULATION_NPS_OUTCOME_TEN]: 'Your capabilities score indicates your teams\' overall level of maturity in adoption of DevOps and Agile practices. The capabilities score is the linear average of your scores across the key maturity dimensions. Each dimension is rated as a percentage, where 100% is the level achieved by teams with fully developed DevOps and Agile practices.',
    [constants.CALCULATION_TQ]: 'Your capabilities score indicates your teams\' Technology Quotient. The capabilities score is the linear average of your scores across the key maturity dimensions. Each dimension is rated as a percentage, where 100% is the level achieved by true technology North Star companies.'
  },
  outcome: {
    [constants.CALCULATION_LEGACY]: 'Your outcomes score is calculated as a linear average of your scores across key performance groups. Each performance group is scored as a percentage, where 100% represents the level achieved by teams with fully developed DevOps and Agile practices.',
    [constants.CALCULATION_2]: 'Your outcomes score is calculated as a linear average of your scores across key performance groups. Each performance group is scored from 1 to 5, where 1 is low and 5 is high.',
    [constants.CALCULATION_AVERAGE_UPTO_FIVE]: 'Your outcomes score is calculated as a linear average of your scores across key performance groups. Each performance group is scored from 1-10, where 10 represents the level achieved by teams with fully developed DevOps and Agile practices.',
    [constants.CALCULATION_AVERAGE_UPTO_FIVE_V2]: 'Your outcomes score is calculated as a linear average of your scores across key performance groups. Each performance group is scored from 1-10, where 10 represents the level achieved by teams with fully developed DevOps and Agile practices.',
    [constants.CALCULATION_NPS_OUTCOME_TEN]: 'Your outcomes score is calculated as a linear average of your scores across key performance groups. Each performance group is scored as a percentage, where 10 represents the level achieved by teams with fully developed DevOps and Agile practices.',
    [constants.CALCULATION_TQ]: 'Your capabilities score indicates your teams\' Technology Quotient. The capabilities score is the linear average of your scores across the key maturity dimensions. Each dimension is rated as a percentage, where 100% is the level achieved by true technology North Star companies.'
  }
}

export class ResultsScene extends Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  path (type) {
    const team = this.props.team

    if (!type) {
      return routeBuilder('team', {
        teamId: team.id
      }, { noTeam: !team.id })
    }

    return routeBuilder('teamDetails', {
      teamId: team.id,
      type
    }, { noTeam: !team.id })
  }

  navigateTo (type) {
    this.props.history.push(this.path(type))
  }

  selectByType (type, group) {
    this.setState({
      [`selected${type}`]: group
    })
  }

  renderScores (type) {
    const selected = this.state[`selected${type}`]
    const levels = _.get(this.props.levels, type)
    const prevLevels = _.get(this.props.prevLevels, type)
    const orgLevels = _.get(this.props.orgLevels, type)

    const props = getReferenceScores(this.props.team, selected, levels, prevLevels, orgLevels)
    return <Scores {...props} surveys={this.props.selectedSurveys} />
  }

  selectedGroupTitle (type) {
    const selected = this.state[`selected${type}`]
    if (!selected) {
      return null
    }

    const selectedGroup = _.find(this.props.levels[type].groups, { id: selected })

    if (!selectedGroup) {
      return ''
    }

    return selectedGroup.name
  }

  renderGroup (options) {
    const { name, link, type, values, overall, histOverall, overallNotAsked, title, histValues, histLabel, integers } = options
    const colors = options.colors.slice(0, values.length).reverse()
    const histValuesMapped = !histValues || (histValues && !histValues[0].groups) ? histValues : histValues.reduce((prev, curr) => {
      prev.push(...curr.groups)
      return prev
    }, [])
    return (
      <div key={`group-${type}`} className='Results--group' onClick={e => this.navigateTo(link)}>
        <h2>{this.selectedGroupTitle(type) || title}</h2>
        <h3>({this.props.limits[type][0]} - {this.props.limits[type][1]})</h3>
        {this.renderScores(type)}
        <RadialChart
          id={name}
          title={title}
          colors={colors}
          values={values}
          overall={overall}
          overallNotAsked={overallNotAsked}
          histOverall={histOverall}
          histValues={histValuesMapped}
          histLabel={histLabel}
          integers={integers}
          onSelect={(group) => this.selectByType(type, group)}
        />
      </div>
    )
  }

  getReferenceLevelsForCharts () {
    if (this.hasHistory() && (this.props.comparisonType === 'survey' || !this.props.comparisonType)) {
      return this.props.prevLevels
    }
    if (this.hasBenchmarks()) {
      const selectedBenchmark = /^benchmark-([0-9]+)/.exec(this.props.comparisonType)
      if (selectedBenchmark && this.hasBenchmarks()) {
        const index = parseInt(selectedBenchmark[1])
        return this.props.benchmark[index].questions
      }
    }

    if (this.props.comparisonType === 'organisation' && this.props.team.id) {
      return this.props.orgLevels
    }

    return undefined
  }

  getReferenceLabelForCharts () {
    if (this.hasHistory() && (this.props.comparisonType === 'survey' || !this.props.comparisonType)) {
      if (this.props.selectedSurveys && this.props.selectedSurveys[1]) {
        return this.props.selectedSurveys[1].name + ':'
      }
      return 'Last'
    }

    if (this.hasBenchmarks()) {
      const selectedBenchmark = /^benchmark-([0-9]+)/.exec(this.props.comparisonType)
      if (selectedBenchmark && this.hasBenchmarks()) {
        const index = parseInt(selectedBenchmark[1])
        return this.props.benchmark[index].name || I18n.t('shared.benchmark')
      }
    }

    if (this.props.comparisonType === 'none') {
      return ''
    }

    return 'Org'
  }

  selectColors (type) {
    const baseColor = CHART_COLORS[type.style] || CHART_COLORS.default
    if (this.props.levels[type.key].groups.length < baseColor.length) {
      return baseColor.slice(1)
    }

    if (this.props.levels[type.key].groups.length > baseColor.length) {
      const overflowColor = new Array(this.props.levels[type.key].groups.length - baseColor.length)
      _.fill(overflowColor, CHART_COLOR_OVERFLOW[type.style] || CHART_COLOR_OVERFLOW.default)
      return [...overflowColor, ...baseColor]
    }

    return baseColor
  }

  renderActualCharts () {
    const levels = this.props.levels
    const histLevels = this.getReferenceLevelsForCharts()
    const histLabel = this.getReferenceLabelForCharts()
    return this.props.questionTypes.map(type => {
      return this.renderGroup({
        name: type.key,
        link: type.key,
        type: type.key,

        title: type.plural,

        colors: this.selectColors(type),
        integers: _.get(this.props.format, type.key) === 'integer',

        values: levels[type.key].groups,
        overall: _.get(levels, [type.key, 'score', 'value']),
        overallNotAsked: isNotAsked(_.get(levels, [type.key, 'score'])),

        histLabel,
        histValues: histLevels && histLevels[type.key].groups,
        histOverall: histLevels && _.get(histLevels, [type.key, 'score', 'value'])
      })
    })
  }

  renderCharts () {
    if (!this.props.levels) {
      return null
    }

    return (
      <div className='Results--charts'>
        {this.renderActualCharts()}
      </div>
    )
  }

  shouldShowNa () {
    const levels = this.props.levels

    return _.some(this.props.questionTypes, (type) => {
      const hasNa =
        isNotAsked(_.get(levels, [type.key, 'score'])) ||
        isNotAnswered(_.get(levels, [type.key, 'score'])) ||
        _.some(levels[type.key].groups, group => isNotAsked(group.score) || isNotAnswered(group.score))

      return hasNa
    })
  }

  hasHistory () {
    return this.props.selectedSurveys.length > 1
  }

  hasBenchmarks () {
    return this.props.benchmark &&
      this.props.benchmark.length
  }

  renderLegend () {
    if (!this.props.levels) {
      return null
    }

    return <ResultsLegend
      levels={this.props.levels}
      hasHistory={this.hasHistory() && !!this.props.prevLevels}
      surveys={this.props.selectedSurveys}
      showNa={this.shouldShowNa()}

      setComparisonType={value => this.props.setComparisonType(value)}
      questionTypes={this.props.questionTypes}

      comparisonType={this.props.comparisonType}

      showCompareSurvey={this.hasHistory()}
      showCompareOrganisation={this.props.team.id}
      showCompareBenchmark={this.hasBenchmarks()}
      benchmarks={this.props.benchmark}
    />
  }

  renderMainText () {
    const texts = this.props.questionTypes.map(type => {
      if (!TEXTS[type.style]) {
        return null
      }

      return <p key={`text-${type.key}`} className='Results--description'>{this.props.t(`teams.main_text.${type.style}`, { default: TEXTS[type.style][this.props.calculation] })}</p>
    })

    const classNames = ['Results--descriptions']
    if (texts.length === 1) {
      classNames.push('single')
    }

    return (
      <div className={classNames.join(' ')}>
        <h3>{this.props.t('teams.main_text.title')}</h3>
        <div>
          {texts}
        </div>
      </div>)
  }

  renderSelector () {
    if (!this.props.team) {
      return null
    }

    return (
      <ContentPortal right>
        <Nav team={this.props.team} questionTypes={this.props.questionTypes} />
      </ContentPortal>)
  }

  render () {
    return (
      <ContentWidth>
        <EmptyScene>
          {this.renderSelector()}
          {this.renderCharts()}
          <hr />
          {this.renderMainText()}
          <Sidebar>
            <Teams url='/results/:teamId' match={this.props.match} />
          </Sidebar>
          <SidebarRight>
            <LegendsIcon />
            {this.renderLegend()}
          </SidebarRight>
        </EmptyScene>
      </ContentWidth>
    )
  }
}

export default withTranslation()(ResultsScene)
