
import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { routeBuilder } from '../../../../utils/routing'
import RestrictTo from '../../../../components/RestrictTo'

export default function ProfileMenu (props) {
  function navigate () {
    props.onNavigate && props.onNavigate()
  }
  const { t } = useTranslation()

  return (
    <ul className='Header--menu-links'>
      <RestrictTo userRole='admin'>
        <li><Link onClick={navigate} to={routeBuilder('adminSurveys')}>{t('profile_menu.manage_surveys')}</Link></li>
        <li><a onClick={navigate} href='./user-management/' target='_blank'>{t('profile_menu.manage_users')}</a></li>
        <li><a onClick={navigate} href='./tenant-management/' target='_blank'>{t('profile_menu.manage_tenants')}</a></li>
      </RestrictTo>
      <li><button className='alert' onClick={() => props.logout()}>{t('profile_menu.sign_out')}</button></li>
    </ul>
  )
}
