
import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'

import { CLOSE, CARD_ICONS } from '../../../../utils/icons'
import { Tile } from '../../../ResultsDetails/components/Tiles'
import SidebarCell from '../../../../components/SidebarView/Cell'

import './style.scss'

function TooltipTiles (props) {
  const { colored, x, y, selected } = props

  return (
    <div className='TeamTooltip--tiles'>
      <div onClick={() => props.onClick('capability')}>
        <Tile
          group={{ name: selected.x.shortName, score: x }}
          isColored={colored.x}
        />
      </div>
      <div onClick={() => props.onClick('outcome')}>
        <Tile
          group={{ name: selected.y.shortName, score: y }}
          isColored={colored.y}
        />
      </div>
    </div>
  )
}

export class TooltipTeamsPlain extends Component {
  renderParents (teams) {
    if (!teams || !teams.length) {
      return
    }

    return teams.map((team, level) => {
      return (
        <div key={`team_${team.id}`} className='TeamTooltip--team'>
          <SidebarCell
            key={`team-cell-${team.id}`}
            level={level}
            isRoot={false}
            isChild={level > 0}
            isExpand
            isOpen
            isSelected={false}
            isMedium={false}
            onExpand={() => {}}
          >
            {team.name}
          </SidebarCell>
        </div>
      )
    })
  }

  renderTeam (team) {
    return (
      <Fragment key={`fragment-team-${team.id}`}>
        {this.renderParents(team.parents || [])}
        <div key={`team_${team.id}`} className='TeamTooltip--team'>
          <SidebarCell
            key={team.id}
            level={team.level}
            isRoot={false}
            isChild={team.parents && team.parents.length}
            isExpand={false}
            isOpen={false}
            isSelected={false}
            isMedium={false}
            onClick={e => this.props.onTeam(team)}
            onExpand={() => {}}
          >
            <span>{team.name}</span>
          </SidebarCell>
        </div>
      </Fragment>
    )
  }

  render () {
    const { teams, x, y, onClose } = this.props

    return (
      <div className='TeamTooltip'>
        <div className='TeamTooltip--head'>
          <div className='TeamTooltip--close' onClick={e => onClose(e)}>
            {CLOSE}
          </div>
          <h2>{this.props.t('distribution.tooltip.teams', { teams: teams.length })}</h2>
        </div>
        <div className='TeamTooltip--body'>
          <TooltipTiles
            onClick={() => {}}
            x={x} y={y}
            selected={this.props.selected}
            colored={{
              x: true,
              y: true
            }}
          />
          <div className='TeamTooltip--teamList'>
            {teams.map(team => this.renderTeam(team))}
          </div>
        </div>
      </div>
    )
  }
}

export class TooltipTeamPlain extends Component {
  constructor (props) {
    super(props)
    this.state = {
      type: 'capability'
    }
  }

  componentWillMount () {
    this.retrieve()
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.team !== nextProps.team) {
      this.retrieve(nextProps)
    }
  }

  retrieve (props = this.props) {
    if (!this.isDefault(props)) {
      return
    }

    if (!props.totals[this.state.type]) {
      props.retrieve(
        'latest',
        props.metadata,
        props.team.id,
        this.state.type
      )
    }
  }

  onClick (event) {
    event.preventDefault()
    this.props.history.push(`/results/${this.state.type}/${this.props.team.id}`)
  }

  onTypeChange (type) {
    if (!this.isDefault()) {
      return
    }

    this.setState({ type }, () => {
      this.retrieve()
    })
  }

  renderGroup (group) {
    const score = group.score && group.score.responses > 0 ? group.score.value : 'N/A'
    const iconName = group.name || 'default'
    const icon = CARD_ICONS[iconName.toLowerCase()]

    return (
      <div key={`group_${group.id}`} className='TeamTooltip--category'>
        <div className='TeamTooltip--icon'>{icon}</div>
        <div className='TeamTooltip--name'>{group.name}</div>
        <div className='TeamTooltip--score'>{score}</div>
      </div>
    )
  }

  isDefault (props = this.props) {
    const { x, y } = props.selected
    return x.type === 'capability' &&
      x.selectedLevel === 'total' &&
      y.type === 'outcome' &&
      y.selectedLevel === 'total'
  }

  renderGroups () {
    if (!this.isDefault()) {
      return null
    }

    const typeTotals = this.props.totals[this.state.type] || {}
    const groups = typeTotals.groups || []
    return groups.map(group => this.renderGroup(group))
  }

  render () {
    const { team, x, y, onClose } = this.props
    const parents = (team.parents || []).map(t => t.name)

    return (
      <div className='TeamTooltip'>
        <div className='TeamTooltip--head'>
          <div className='TeamTooltip--close' onClick={e => onClose(e)}>
            {CLOSE}
          </div>
          <h2>{team.name}</h2>
          <p>
            {this.props.t('distribution.tooltip.as_part_of')} <strong>{parents.join(', ') || this.props.t('shared.organisation')}</strong>
          </p>
        </div>
        <div className='TeamTooltip--body'>
          <TooltipTiles
            onClick={type => this.onTypeChange(type)}
            x={x}
            y={y}
            selected={this.props.selected}
            colored={{
              x: this.state.type === 'capability' || !this.isDefault(),
              y: this.state.type === 'outcome' || !this.isDefault()
            }}
          />
          {this.renderGroups()}
          <div className='center TeamTooltip--buttonContainer'>
            <input type='submit' className='btn btn-primary' value={this.props.t('distribution.tooltip.view_full_profile')} onClick={e => this.onClick(e)} />
          </div>
        </div>
      </div>
    )
  }
}

const TooltipTeam = withTranslation()(TooltipTeamPlain)
const TooltipTeams = withTranslation()(TooltipTeamsPlain)

export { TooltipTeam, TooltipTeams }
