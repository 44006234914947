
import Immutable from 'seamless-immutable'
import _ from 'lodash'

import * as types from './types'
import * as profileTypes from '../profile/types'
import { idFromMetadata, getDefaultMetadata } from '../../utils/metadata'

const initialState = Immutable({})

function getMetadata (hierarchies, ids) {
  if (!ids || ids.length === 0) {
    return getDefaultMetadata(hierarchies)
  }

  const metadata = _.filter(hierarchies, level => ids.indexOf(level.id) >= 0)
  if (metadata.length !== ids.length) {
    return getDefaultMetadata(hierarchies)
  }

  return _.sortBy(metadata, level => ids.indexOf(level.id))
}

function handleNewSettings (state, action) {
  const hierarchies = action.payload.data.hierarchies

  if (!hierarchies) {
    return state
  }

  const stateChanges = {
    hierarchies,
    defaultHierarchy: getDefaultMetadata(hierarchies)
  }

  if (!state.selectedMetadata) {
    stateChanges.selectedMetadata = stateChanges.defaultHierarchy
  }

  return state.merge(stateChanges)
}

function handleRespondentsSuccess (state, action) {
  const metadata = action.meta.previousAction.metadata
  const groups = action.payload.data.questions
  const minRespondents = _.chain(groups)
    .filter(group => group.responses > 0)
    .minBy('responses')
    .value().responses

  return state.setIn(['minRespondents', idFromMetadata(metadata)], minRespondents)
}

export default function profile (state = initialState, action) {
  switch (action.type) {
    case profileTypes.SETTINGS_SUCCESS:
      return handleNewSettings(state, action)

    case types.RESPONDENTS_SUCCESS:
      return handleRespondentsSuccess(state, action)

    case types.SET_METADATA:
      return state.set('selectedMetadata', getMetadata(state.hierarchies, action.metadata))
    default:
      return state
  }
}
