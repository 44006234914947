
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import EmptyScene from '../EmptyScene'
import ContentWidth from '../../components/ContentWidth'
import HighlightProgressToggle from '../../components/HighlightProgressToggle'
import Sidebar from '../../components/Sidebar/left'
import SidebarRight from '../../components/Sidebar/right'
import SidebarIcon from '../../components/HeaderMenu/components/SidebarIcon'
import LegendsIcon from '../../components/HeaderMenu/components/LegendsIcon'
import Legend from '../../components/ThresholdsLegend'
import RadioSelect from '../../components/RadioSelect'
import GroupsList from '../../components/GroupsList'
import GroupedTable from './components/GroupedTable'
import ContentPortal from '../../components/HeaderMenu/components/ContentPortal'
import Nav from './components/Nav'
import { routeBuilder } from '../../utils/routing'

import './style.scss'

export class TeamsList extends Component {
  constructor (props) {
    super(props)

    this.url = this.url.bind(this)
  }

  componentWillMount () {
    if (this.hasData()) {
      this.load()
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.hasData(nextProps) &&
        (nextProps.type !== this.props.type ||
        nextProps.level !== this.props.level ||
        nextProps.value !== this.props.value)
    ) {
      this.load(nextProps)
    }

    if (this.props.grouped !== nextProps.grouped && (this.props.order === 'parent1' || this.props.order === 'parent2')) {
      this.props.sort('name')
    }
  }

  hasData (props = this.props) {
    return !!(props.type && props.level)
  }

  load (props = this.props) {
    if (!props.scores && props.metadata.length !== 0) {
      this.props.retrieve(props.metadata, props.type.key, props.level, props.value)
    }
  }

  group (grouped) {
    const url = routeBuilder('listing', {
      type: this.props.type.key,
      level: this.props.level,
      value: this.props.value
    }, {
      query: { grouped }
    })

    this.props.routerHistory.push(url)
  }

  changeHighlightProgress (value) {
    this.props.changeHighlightProgress(value)
  }

  url (type, level, id) {
    const options = {
      query: {
        grouped: this.props.grouped
      }
    }

    if (this.props.highlightedTeamId) {
      options.query.teamId = this.props.highlightedTeamId
    }

    return routeBuilder('listing', {
      type: type.key,
      level,
      value: id
    }, options)
  }

  renderSidebar () {
    return (
      <Sidebar>
        <SidebarIcon />
        <GroupsList
          groups={this.props.groups}
          type={this.props.type}
          value={this.props.value}
          url={this.url}
          renderBreadcrumbs
        />
      </Sidebar>
    )
  }

  renderProgressToggle () {
    if (this.props.selectedSurveys.length < 2) {
      return null
    }

    return (
      <HighlightProgressToggle
        value={this.props.highlightProgress}
        onChange={(value) => this.changeHighlightProgress(value)}
      />)
  }

  renderLegend () {
    return (
      <SidebarRight>
        <LegendsIcon />
        {this.renderProgressToggle()}
        {this.renderGroupSelect()}
        <Legend type={this.props.type} showProgress={this.props.highlightProgress} />
      </SidebarRight>
    )
  }

  navigate (team) {
    const options = {}
    if (this.props.currentGroups) {
      options.query = { filter: this.props.currentGroups[0].name }
      if (this.props.currentGroups.length > 1) {
        options.query.lever = this.props.currentGroups[1].name
      }
    }

    const url = routeBuilder('teamDetails', {
      type: this.props.type.key,
      teamId: team.id || 'organisation'
    }, options)

    this.props.history.push(url)
  }

  renderTables () {
    return <GroupedTable
      teams={this.props.scores}
      grouped={this.props.grouped}
      sort={this.props.sort}
      order={this.props.order}
      direction={this.props.direction}
      hierarchies={this.props.metadata}
      highlightProgress={this.props.highlightProgress}
      highlightedTeamId={this.props.highlightedTeamId}
      onNavigate={team => this.navigate(team)}
    />
  }

  renderGroupSelect () {
    const hierarchies = this.props.metadata
      .map((it, index) => {
        return {
          name: it.name,
          value: String(index + 1)
        }
      })

    return (
      <div className='TeamList--Legend'>
        <h3>{this.props.t('leaders.group_by')}</h3>
        <RadioSelect
          options={hierarchies}
          selected={this.props.grouped}
          onChange={hierarchy => this.group(hierarchy)}
        />
      </div>
    )
  }

  renderTypeToggle () {
    return (
      <ContentPortal right>
        <Nav selected={this.props.type.key} questionTypes={this.props.questionTypes} />
      </ContentPortal>)
  }

  render () {
    return (
      <ContentWidth noFooterMargin>
        <EmptyScene>
          {this.renderTypeToggle()}
          {this.renderTables()}
          {this.renderSidebar()}
          {this.renderLegend()}
        </EmptyScene>
      </ContentWidth>
    )
  }
}

export default withTranslation()(TeamsList)
