import * as types from './types'
import Immutable from 'seamless-immutable'
import _ from 'lodash'

const initialState = Immutable({
  loading: false,
  surveys: [],
  error: null
})

export default function surveys (state = initialState, action) {
  switch (action.type) {
    case types.RETRIEVE_REQUEST:
      return state.merge({
        loading: true,
        error: null
      })
    case types.RETRIEVE_SUCCESS:
      const data = action.payload.data || {}
      return state.merge({
        loading: false,
        surveys: data.surveys,
        error: null
      })
    case types.RETRIEVE_FAILURE:
      return state.merge({
        loading: false,
        error: _.get(action.error, 'response.data.message') || action.error.message
      })
    case types.SAVE_REQUEST:
      return state.merge({
        loading: true,
        error: false
      })
    case types.SAVE_SUCCESS:
      if (_.find(state.surveys, { id: action.payload.data.id })) {
        return state.merge({
          loading: false,
          surveys: _.map(state.surveys, s => s.id === action.payload.data.id ? action.payload.data : s)
        })
      }

      return state.merge({
        loading: false,
        surveys: state.surveys.concat([ action.payload.data ])
      })
    case types.SAVE_FAILURE:
      return state.merge({
        loading: false,
        error: _.get(action.error, 'response.data.message') || action.error.message
      })
    case types.REMOVE_REQUEST:
      return state.merge({
        loading: true
      })
    case types.REMOVE_SUCCESS:
      const surveyId = action.meta.previousAction.surveyId
      return state.merge({
        loading: false,
        surveys: _.reject(state.surveys, it => it.id === surveyId)
      })
    case types.REMOVE_FAILURE:
      return state.merge({
        loading: false,
        error: _.get(action.error, 'response.data.message') || action.error.message
      })
    default:
      return state
  }
}
