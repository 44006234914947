
import React from 'react'

import Nav from '../../../../components/Nav'
import { routeBuilder } from '../../../../utils/routing'

function path (type) {
  return routeBuilder('listing', {
    type: type.key,
    level: 'total'
  })
}

export default function TeamsListNav (props) {
  const links = props.questionTypes.map(type => {
    return {
      text: type.plural,
      href: path(type),
      selected: props.selected === type.key
    }
  })

  return <Nav links={links} />
}
