
import _ from 'lodash'

function getSelectedReferenceScores (team, selected, levels, prevLevels, orgLevels) {
  const selectedGroup = _.find(levels.groups, { id: selected })
  const current = selectedGroup.score
  const historyGroup = prevLevels ? _.find(prevLevels.groups, { id: selected }) : undefined
  const organisation = team.id && orgLevels ? _.find(orgLevels.groups, { id: selected }) : undefined

  const result = {
    current
  }

  if (historyGroup) {
    result.previous = historyGroup.score
  }

  if (organisation) {
    result.organisation = organisation.score
  }

  return result
}

export function getReferenceScores (team, selected, levels, prevLevels, orgLevels) {
  if (selected) {
    return getSelectedReferenceScores(team, selected, levels, prevLevels, orgLevels)
  }

  // it's total

  const isOrg = !team.id
  const overall = _.get(levels, 'score')
  const previous = _.get(prevLevels, 'score')
  const organisation = !isOrg && orgLevels ? _.get(orgLevels, 'score') : undefined

  return {
    current: overall,
    previous,
    organisation
  }
}
