
import * as types from './types'
import { idFromMetadata } from '../../utils/metadata'
import { prepareSurveyId } from '../../utils/surveys'

export function retrieveQuestions (surveyId = 'latest', metadata) {
  return {
    surveyId,
    types: [types.QUESTIONS_RETRIEVE_REQUEST, types.QUESTIONS_RETRIEVE_SUCCESS, types.QUESTIONS_RETRIEVE_FAILURE],
    payload: {
      request: {
        method: 'GET',
        url: `/data/${prepareSurveyId(surveyId)}/${idFromMetadata(metadata)}/questions/statistics`
      }
    }
  }
}

export function retrieveTeams (surveyId = 'latest', metadata) {
  return {
    surveyId,
    types: [types.TEAMS_RETRIEVE_REQUEST, types.TEAMS_RETRIEVE_SUCCESS, types.TEAMS_RETRIEVE_FAILURE],
    payload: {
      request: {
        method: 'GET',
        url: `/data/${prepareSurveyId(surveyId)}/${idFromMetadata(metadata)}/teams`
      }
    }
  }
}
