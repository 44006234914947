
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'

import * as navigationActions from '../../services/navigation/actions'

function RememberType (props) {
  const childProps = _.omit(props, ['children'])

  const type = _.get(props, 'match.params.type')

  if (type) {
    const actualType = _.find(props.questionTypes, { key: type }) || props.questionTypes[0]
    props.changeType(actualType)
  }

  return React.Children.map(props.children, child => {
    return React.cloneElement(child, childProps)
  })
}

function mapStateToProps (state) {
  return {
    questionTypes: state.profile.questionTypes
  }
}

function mapDispatchToProps (dispatch) {
  return {
    changeType: (...args) => dispatch(navigationActions.changeType(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RememberType)
