import { connect } from 'react-redux'

import * as teamActions from '../../services/teams/actions'
import * as exportActions from '../../services/export/actions'
import Component from './Component'

function mapStateToProps (state) {
  return {
    teams: state.teams.flatTeams,
    isLoading: state.export.powerpoint.loading,
    metadata: state.metadata.selectedMetadata,
    surveys: state.profile.selectedSurveys
  }
}

function mapDispatchToProps (dispatch) {
  return {
    retrieve: (...args) => dispatch(teamActions.retrieve(...args)),
    generate: (...args) => dispatch(exportActions.generatePowerpoint(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
