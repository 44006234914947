
import _ from 'lodash'
export const METADATA_PATH_REGEXP = '[hm]\\d+-[hm]\\d+-[hm]\\d+|[hm]\\d+-[hm]\\d+|[hm]\\d+'

function metadataMapper (value) {
  return value.id
}

function rawMetadataMapper (value) {
  const suffix = value.main ? 'h' : 'm'
  return `${suffix}${value.index}`
}

export function idFromMetadata (metadata) {
  return metadata.map(metadataMapper).join('-')
}

export function pathFromMetadata (hierarchy) {
  return hierarchy.map(metadataMapper).join('-')
}

export function pathFromRawMetadata (hierarchy) {
  return hierarchy.map(rawMetadataMapper).join('-')
}

export function metadataFromPath (metadata) {
  return metadata.split('-')
}

export function getDefaultMetadata (hierarchies) {
  return _.chain(hierarchies)
    .filter({ main: true })
    .sortBy('level')
    .reverse()
    .value()
}
