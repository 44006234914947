
import _ from 'lodash'
import I18n from './i18n'

import { isProgress, baseType } from './type'

function defaultGroupNames (type, questionTypes) {
  const actualType = _.find(questionTypes, { key: baseType(type) }) || questionTypes[0]

  if (isProgress(type)) {
    return {
      name: I18n.t('shared.groups.overall_progress', { type: actualType.plural }),
      shortName: actualType.plural
    }
  }

  return {
    name: I18n.t('shared.groups.overall', { type: actualType.plural.toLowerCase() }),
    shortName: actualType.plural
  }
}

export function groupNames (group, type, questionTypes) {
  if (!group || !group.name) {
    return defaultGroupNames(type, questionTypes)
  }

  if (isProgress(type)) {
    return {
      name: I18n.t('shared.groups.group_progress', { group: group.name }),
      shortName: I18n.t('shared.groups.group_progress', { group: group.name })
    }
  }

  return {
    name: group.name,
    shortName: group.name
  }
}
