import { connect } from 'react-redux'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import * as Components from './Component'
import * as actions from '../../../../services/team-profile/actions'

function mapStateToProps (state, ownProps) {
  return {
    metadata: state.metadata.selectedMetadata,
    totals: {
      outcome: _.get(state.teamProfile.totals, ['outcome', ownProps.team.id, 'latest', 'totals']),
      capability: _.get(state.teamProfile.totals, ['capability', ownProps.team.id, 'latest', 'totals'])
    }
  }
}

function mapDispatchToProps (dispatch) {
  return {
    retrieve: (...args) => dispatch(actions.retrieve(...args))
  }
}

const TooltipTeam = withRouter(connect(mapStateToProps, mapDispatchToProps)(Components.TooltipTeam))
const TooltipTeams = Components.TooltipTeams

export {
  TooltipTeam,
  TooltipTeams
}
