import { connect } from 'react-redux'
import Component from './Component'
import * as actions from '../../../../services/questions/actions'

function mapStateToProps (state, ownProps) {
  return {
    selected: state.questions.selected,
    thresholds: state.profile.thresholds[ownProps.question.type],
    limits: state.profile.limits[ownProps.question.type]
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleQuestion: (...args) => dispatch(actions.toggleQuestion(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
