import { connect } from 'react-redux'
import Component from './Component'
import _ from 'lodash'

const ALLOWED = { allowed: true }
const NOT_ALLOWED = { allowed: false }

const METHODS = {
  userRole: (user, props) => _.includes(user.roles, props.userRole),
  userRoles: (user, props) => !!_.find(user.roles, (role) => props.userRoles.indexOf(role) >= 0),
  default: () => false
}

function getMethod (ownProps) {
  for (let methodName in METHODS) {
    if (ownProps[methodName]) {
      return METHODS[methodName]
    }
  }

  return METHODS.default
}

function mapStateToProps (state, ownProps) {
  if (!state.profile) {
    return NOT_ALLOWED
  }

  if (!state.profile.user) {
    return NOT_ALLOWED
  }

  if (!state.profile.user.roles) {
    return NOT_ALLOWED
  }

  const restrictionMethod = getMethod(ownProps)
  if (!restrictionMethod(state.profile.user, ownProps)) {
    return NOT_ALLOWED
  }

  return ALLOWED
}

export default connect(mapStateToProps)(Component)
