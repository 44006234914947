
import React from 'react'
import { useTranslation } from 'react-i18next'

import * as icons from '../../../../utils/icons'
import { routeBuilder } from '../../../../utils/routing'
import Nav from '../../../../components/Nav'

function isOrg (team) {
  return !team.id || team.id === 'organisation'
}

function path (team, type) {
  if (!type) {
    return routeBuilder('team', {
      teamId: team.id
    }, { noTeam: isOrg(team) })
  }

  return routeBuilder('teamDetails', {
    teamId: team.id,
    type
  }, { noTeam: isOrg(team) })
}

export default function ResultsNav (props) {
  const { t } = useTranslation()

  const links = [{
    text: t('teams.nav.overview'),
    icon: icons.TARGET,
    href: path(props.team),
    selected: !props.selected
  }]

  if (props.questionTypes) {
    props.questionTypes.forEach(type => {
      links.push({
        text: type.plural,
        href: path(props.team, type.key),
        selected: props.selected === type.key
      })
    })
  }

  return <Nav links={links} />
}
