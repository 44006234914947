import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Component from './Component'

export function mapStateToProps (state, ownProps) {
  return {
    groups: state.profile.groups[ownProps.type.key]
  }
}

export default withRouter(connect(mapStateToProps)(Component))
