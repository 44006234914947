import { connect } from 'react-redux'
import Component from './Component'

function mapStateToProps (state, ownProps) {
  return {
    filter: ownProps.filter ? { name: ownProps.filter } : null
  }
}

export default connect(mapStateToProps)(Component)
