
import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { createFilter } from 'react-search-input'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { SidebarCell, SidebarSearch } from '../SidebarView'
import Breadcrumbs from '../Breadcrumbs'
import ContentPortal from '../HeaderMenu/components/ContentPortal'
import * as ICONS from '../../utils/icons'

import './style.scss'

function quadrantKey (quadrant) {
  return `quadrant-${quadrant.id}`
}

function leverKey (lever) {
  return `lever-${lever.id}`
}

function subKey (sub) {
  return `sub-${sub.id}`
}

function questionKey (question) {
  return `question-${question.id}`
}

class GroupsList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      search: '',
      expanded: {},
      path: []
    }
  }

  search (value) {
    this.setState({
      search: value
    })
  }

  componentDidMount () {
    this.autoExpandAndSetPath(this.props.value)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.value !== this.props.value) {
      this.autoExpandAndSetPath(nextProps.value)
    }
  }

  autoExpandAndSetPath (value) {
    if (!value) {
      this.setState({
        path: []
      })
      return
    }

    const type = this.props.type
    const expand = {}
    const path = []

    for (let quadrant of this.props.groups[type.key]) {
      let foundQuadrant = false

      for (let sub of quadrant.groups) {
        let foundSub = false
        if (sub.id === value) {
          foundQuadrant = true
          foundSub = true
        }
        for (let lever of sub.groups) {
          let foundLever = false
          for (let question of lever.questions) {
            if (question.id === value) {
              foundLever = true
              foundSub = true

              path.push({
                id: question.id,
                text: question.name,
                link: `/teams-score/${type.key}/question/${question.id}`
              })
            }
          }

          if (foundLever || lever.id === value) {
            foundQuadrant = true
            foundSub = true

            expand[leverKey(lever)] = true
            path.unshift({
              id: lever.id,
              text: lever.name,
              link: `/teams-score/${type.key}/lever/${lever.id}`
            })
          }
        }
        if (foundSub) {
          expand[subKey(sub)] = true
          path.unshift({
            id: sub.id,
            text: sub.name,
            link: `/teams-score/${type.key}/sub/${sub.id}`
          })
        }
      }

      if (foundQuadrant || quadrant.id === value) {
        expand[quadrantKey(quadrant)] = true
        path.unshift({
          id: quadrant.id,
          text: quadrant.name,
          link: `/teams-score/${type.key}/quadrant/${quadrant.id}`
        })
      }
    }

    this.setState({
      expanded: _.assign({}, this.state.expanded, expand),
      path
    })
  }

  isInPath (id) {
    return _.some(this.state.path, { id })
  }

  isSelected (id) {
    return this.props.value === id
  }

  getCurrentPath () {
    const { type, t } = this.props
    const path = this.state.path.filter(p => !p.text.match(/.*~none~.*/))

    if (path.length > 0) {
      return [{
        id: type.key,
        text: type.plural,
        link: `/teams-score/${type.key}/total`
      }, ...path]
    }

    const title = t('shared.groups.overall', { type: type.plural.toLowerCase() })
    return [{
      id: type.key,
      text: title,
      link: `/teams-score/${type.key}/total`
    }]
  }

  renderBreadcrumbs () {
    if (!this.props.renderBreadcrumbs) {
      return null
    }

    return <ContentPortal><Breadcrumbs path={this.getCurrentPath()} /></ContentPortal>
  }

  toggle (ev, key) {
    ev.stopPropagation()

    const expand = ev.target.nodeName === 'A' || !this.isExpanded(key)
    this.setState({
      expanded: _.assign({}, this.state.expanded, { [key]: expand })
    })
  }

  isExpanded (key) {
    return !!this.state.expanded[key]
  }

  renderLever (group, filter) {
    const key = leverKey(group)
    const expanded = this.isExpanded(key)
    const hasChild = group.questions.length > 1

    const icons = [
      ICONS.CHILD
    ]

    if (hasChild) {
      icons.push(ICONS.PARENT)
    }

    const classes = ['GroupsList--lever']
    if (expanded) {
      classes.push('expanded')
    }
    if (hasChild) {
      classes.push('has-child')
    }
    if (this.isInPath(group.id)) {
      classes.push('active-path')
    }
    if (this.isSelected(group.id)) {
      classes.push('active')
    }

    const lever = (
      <SidebarCell
        key={leverKey(group)}
        level={1}
        isRoot={false}
        isChild
        isExpand={hasChild}
        isOpen={expanded}
        isSelected={this.isSelected(group.id)}
        isMedium={this.isInPath(group.id)}
        onClick={e => this.toggle(e, key)}
        onExpand={e => this.toggle(e, key)}
      >
        <Link to={this.props.url(this.props.type, 'lever', group.id)} title={group.name}>{group.name}</Link>
      </SidebarCell>
    )

    if (!expanded || !hasChild) {
      return [lever]
    }

    return [
      lever,
      ...group.questions.filter(filter)
        .filter(q => !q.category)
        .map(g => this.renderQuestion(g))
    ]
  }

  renderGroup (group, filter) {
    const key = quadrantKey(group)
    const expanded = this.isExpanded(key)
    const classes = ['GroupsList--quadrant']
    if (expanded) {
      classes.push('expanded')
    }
    if (this.isInPath(group.id)) {
      classes.push('active-path')
    }
    if (this.isSelected(group.id)) {
      classes.push('active')
    }

    const quadrant = (
      <SidebarCell
        key={quadrantKey(group)}
        level={0}
        isRoot={false}
        isChild={false}
        isExpand
        isOpen={expanded}
        isMedium={this.isInPath(group.id)}
        isSelected={this.isSelected(group.id)}
        onClick={e => this.toggle(e, key)}
        onExpand={e => this.toggle(e, key)}
      >
        <Link to={this.props.url(this.props.type, 'quadrant', group.id)} title={group.name}>{group.name}</Link>
      </SidebarCell>
    )

    if (!expanded) {
      return [quadrant]
    }
    const levers = group.groups.reduce((prev, curr) => {
      if (group.isTQ) {
        prev.push(this.renderSub(curr, filter))
      } else {
        const items = curr.groups.filter(filter).map(g => this.renderLever(g, filter))
        prev.push(...items)
      }
      return prev
    }, [])
    return [
      quadrant,
      ...levers
    ]
  }

  renderSub (group, filter) {
    const key = subKey(group)
    const expanded = this.isExpanded(key)

    const hasChild = group.groups.length > 1
    const icons = [
      ICONS.CHILD
    ]

    if (hasChild) {
      icons.push(ICONS.PARENT)
    }

    const classes = ['GroupsList--lever']
    if (expanded) {
      classes.push('expanded')
    }
    if (hasChild) {
      classes.push('has-child')
    }
    if (this.isInPath(group.id)) {
      classes.push('active-path')
    }
    if (this.isSelected(group.id)) {
      classes.push('active')
    }
    const sub = (
      <SidebarCell
        key={subKey(group)}
        level={1}
        isRoot={false}
        isChild
        isExpand={hasChild}
        isOpen={expanded}
        isSelected={this.isSelected(group.id)}
        isMedium={this.isInPath(group.id)}
        onClick={e => this.toggle(e, key)}
        onExpand={e => this.toggle(e, key)}
      >
        <Link to={this.props.url(this.props.type, 'sub', group.id)} title={group.name}>{group.name}</Link>
      </SidebarCell>
    )

    if (!expanded || !hasChild) {
      return [sub]
    }
    return [
      sub,
      ...group.groups.filter(filter)
        .filter(q => !q.category)
        .map(g => this.renderLever(g, filter))
    ]
  }

  renderQuestion (question) {
    const classes = ['GroupsList--question']

    if (this.isInPath(question.id)) {
      classes.push('active-path')
    }
    if (this.isSelected(question.id)) {
      classes.push('active')
    }

    return (
      <SidebarCell
        key={questionKey(question)}
        level={2}
        isRoot={false}
        isChild
        isExpand={false}
        isOpen={false}
        isMedium={this.isInPath(question.id)}
        isSelected={this.isSelected(question.id)}
      >
        <Link to={this.props.url(this.props.type, 'question', question.id)} title={question.name}>{question.name}</Link>
      </SidebarCell>
    )
  }

  renderTitle () {
    const type = this.props.type
    const title = this.props.t('shared.groups.overall', { type: type.plural.toLowerCase() })

    return (
      <SidebarCell
        key='title'
        level={0}
        isRoot
        isChild={false}
        isExpand={false}
        isOpen={false}
        isSelected={!this.props.value}
      >
        <Link to={this.props.url(type, 'total')}>{title}</Link>
      </SidebarCell>
    )
  }

  render () {
    const filter = createFilter(this.state.search, ['name', 'groups.name', 'questions.name', 'groups.questions.name'])
    const type = this.props.type

    return (
      <Fragment>
        <div className='GroupsList'>
          {this.renderBreadcrumbs()}
          <SidebarSearch onChange={value => this.search(value)} />
        </div>
        {this.renderTitle()}
        {this.props.groups[type.key].filter(filter).map(g => this.renderGroup(g, filter))}
      </Fragment>
    )
  }
}

export default withTranslation()(GroupsList)
