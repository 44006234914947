
import { connect } from 'react-redux'
import Component from './Component'

import * as SidebarActions from '../../services/app/actions'

function mapDispatchToProps (dispatch) {
  return {
    toggleSidebars: () => dispatch(SidebarActions.toggleBothPopup())
  }
}

export default connect(null, mapDispatchToProps)(Component)
