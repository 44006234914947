import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import defaultTranslation from './localisation/default.json'

const resources = {
  default: {
    translation: defaultTranslation
  },
  survey_specific: {
    translation: {}
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'survey_specific',
    fallbackLng: 'default',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
