
import * as types from './types'
import { idFromMetadata } from '../../utils/metadata'

export function generatePowerpoint (metadata, teamIds = [], compareTo) {
  let url = `/export/{latest}/${idFromMetadata(metadata)}/powerpoint`

  if (compareTo) {
    url += '?compareTo={previous}'
  }

  return {
    types: [types.POWERPOINT_REQUEST, types.POWERPOINT_SUCCESS, types.POWERPOINT_FAILURE],
    payload: {
      request: {
        method: 'POST',
        url,
        responseType: 'blob',
        data: { teamIds }
      }
    }
  }
}

export function generateExcel (metadata) {
  let url = `/export/{latest}/${idFromMetadata(metadata)}/details`

  return {
    types: [types.EXCEL_REQUEST, types.EXCEL_SUCCESS, types.EXCEL_FAILURE],
    payload: {
      request: {
        method: 'GET',
        url,
        responseType: 'blob'
      }
    }
  }
}
