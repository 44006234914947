
import { compose, createStore, applyMiddleware } from 'redux'
import axiosMiddleware from 'redux-axios-middleware'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'

import axiosConfig from './axios'
import populateI18n from './i18n'
import rootReducer from './reducers'

// Debug
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore (initialStore = undefined, mid) {
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    rootReducer(mid),
    initialStore,
    composeEnhancers(
      applyMiddleware(
        thunk,
        axiosMiddleware(...(axiosConfig())),
        sagaMiddleware,
        populateI18n
      )
    )
  )

  store.runSaga = sagaMiddleware.run

  return store
}
