import * as types from './types'
import Immutable from 'seamless-immutable'
import _ from 'lodash'

const initialState = Immutable({
  sort: 'desc',
  filter: null,
  questions: [],
  previousQuestions: {},
  selected: [],
  loading: false,
  error: null,

  isEmailSending: false,
  emailError: null
})

export default function questions (state = initialState, action) {
  let questions, selectedQuestions
  switch (action.type) {
    case types.UPDATE:
      questions = action.payload.key === 'sort' ? sort(state.questions, action.payload.value) : state.questions
      return state.merge({
        [action.payload.key]: action.payload.value,
        questions: questions
      })
    case types.TOGGLE_QUESTION:
      if (isSelected(state.selected, action.payload)) {
        selectedQuestions = removeQuestion(state.selected, action.payload)
      } else {
        selectedQuestions = state.selected.concat([ action.payload ])
      }
      return state.merge({
        selected: selectedQuestions
      })
    case types.RESET_QUESTIONS:
      return state.merge({
        selected: []
      })
    case types.EMAIL_REQUEST:
      return state.merge({
        isEmailSending: true,
        emailError: null
      })
    case types.EMAIL_SUCCESS:
      return state.merge({
        isEmailSending: false,
        emailError: null
      })
    case types.EMAIL_FAILURE:
      return state.merge({
        isEmailSending: false,
        emailError: _.get(action.error, 'response.data.message') || action.error.message
      })
    default:
      return state
  }
}

function isSelected (questions, current) {
  return questions.map(it => it.id).indexOf(current.id) >= 0
}

export function removeQuestion (questions, q) {
  return _.filter(questions, it => it.id !== q.id)
}

export function mapQuestions (questions) {
  const keys = _.map(questions, item => item.question_text)
  const scores = _.map(questions, 'score')
  return _.zipObject(keys, scores)
}

export function sort (questions, direction) {
  const sortDirection = direction === 'asc' ? 1 : -1
  return [].concat(questions).sort((a, b) => {
    if (a.responses === 0 && b.responses !== 0) {
      return 1
    }

    if (b.responses === 0 && a.responses !== 0) {
      return -1
    }

    return (a.total - b.total) * sortDirection
  })
}
