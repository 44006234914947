export const RETRIEVE_REQUEST = 'metrics.RETRIEVE_REQUEST'
export const RETRIEVE_ORG_SUCCESS = 'metrics.RETRIEVE_ORG_SUCCESS'
export const RETRIEVE_PREVIOUS_SUCCESS = 'metrics.RETRIEVE_PREVIOUS_SUCCESS'
export const RETRIEVE_CURRENT_SUCCESS = 'metrics.RETRIEVE_CURRENT_SUCCESS'
export const RETRIEVE_FAILURE = 'metrics.RETRIEVE_FAILURE'
export const QUARTILE_REQUEST = 'metrics.QUARTILE_REQUEST'
export const QUARTILE_SUCCESS = 'metrics.QUARTILE_SUCCESS'
export const QUARTILE_FAILURE = 'metrics.QUARTILE_FAILURE'
export const TEAMS_TOTALS_REQUEST = 'metrics.TEAMS_TOTALS_REQUEST'
export const TEAMS_TOTALS_SUCCESS = 'metrics.TEAMS_TOTALS_SUCCESS'
export const TEAMS_TOTALS_FAILURE = 'metrics.TEAMS_TOTALS_FAILURE'
export const TOTALS_SUCCESS = 'metrics.TOTALS_SUCCESS'
export const CHANGE_COMPARE = 'metrics.CHANGE_COMPARE'
export const CHANGE_GROUP = 'metrics.CHANGE_GROUP'
