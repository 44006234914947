
import _ from 'lodash'
import { matchPath } from 'react-router-dom'
import qs from 'query-string'

const ROUTES = [{
  name: 'team',
  path: '/results/:teamId?',
  base: '/results',
  noTeam: '/results',
  exact: true,
  root: true
}, {
  name: 'teamDetails',
  path: '/results/:type/:teamId',
  noTeam: '/results/:type/organisation',
  exact: true
}, {
  name: 'heatmap',
  path: '/patterns/:type',
  base: '/patterns',
  exact: true
}, {
  name: 'comparison',
  path: '/distribution/:teamId?/:xType(type1|type2|type1-progress|type2-progress)/:xLevel/:xId?/:yType(type1|type2|type1-progress|type2-progress)/:yLevel/:yId?',
  base: '/distribution',
  exact: true
}, {
  name: 'listing',
  path: '/teams-score/:type/:level/:value?',
  exact: true
}, {
  name: 'respondents',
  path: '/admin/surveys/:surveyId/questions',
  exact: true
}, {
  name: 'adminSurveys',
  path: '/admin/surveys',
  exact: true
}, {
  name: 'adminNewSurvey',
  path: '/admin/surveys/new',
  exact: true
}, {
  name: 'adminEditSurvey',
  path: '/admin/surveys/:surveyId',
  exact: true
}, {
  name: 'adminNewImport',
  path: '/admin/surveys/:id/import',
  exact: true
}, {
  name: 'powerpoint',
  path: '/admin/powerpoint',
  exact: false
}, {
  name: 'admin',
  path: '/admin',
  exact: true
}]

export const RouteMap = {}
for (let route of ROUTES) {
  RouteMap[`${route.name}Path`] = route.path
  if (route.base) {
    RouteMap[`${route.name}BasePath`] = route.base
  }
}

function pathToUse (route, options) {
  const path = options.base ? route.base || route.path : route.path

  if (options.noTeam) {
    return route.noTeam || path
  }

  return path
}

function buildRoute (route, params = {}, options = {}) {
  const path = pathToUse(route, options)
  const url = []

  path.split('/').forEach(part => {
    if (part.charAt(0) !== ':') {
      url.push(part)
      return
    }

    const param = part.substring(1).replace(/\(.+?\)?$/, '').replace(/\?$/, '')
    if (params[param]) {
      url.push(params[param])
      return
    }

    const optional = part.substr(-1, 1) === '?'
    if (!optional) {
      url.push(part)
    }
  })

  return url.join('/') + buildQuery(options.query)
}

function buildQuery (query) {
  if (!query) {
    return ''
  }

  return '?' + qs.stringify(query)
}

export function routeBuilder (name, params = {}, options = {}) {
  const route = _.find(ROUTES, { name })
  if (!route) {
    return null
  }

  return buildRoute(route, params, options)
}

function buildRoot () {
  const route = _.find(ROUTES, { root: true })
  if (!route) {
    return null
  }

  return buildRoute(route)
}

routeBuilder.root = buildRoot

export function routeFromPath (path) {
  return _.find(ROUTES, { path })
}

export function routeFromBasePath (path) {
  return _.find(ROUTES, { base: path })
}

export function findRouteMatch (url) {
  for (let route of ROUTES) {
    const match = matchPath(url, {
      path: route.path,
      exact: route.exact
    })

    if (match) {
      return {
        route,
        params: match.params
      }
    }
  }
}
