import * as types from './types'

export function changeType (type) {
  return {
    type: types.CHANGE_TYPE,
    newType: type
  }
}

export function highlightProgress (value) {
  return {
    type: types.HIGHLIGHT_PROGRESS,
    value
  }
}
