// /* eslint-disable security/detect-object-injection */

const prefix = 'REACT_APP_'

function env (key, defaultValue) {
  const envVal =
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? process.env[`${prefix}${key}`] : window._env_[key]) || defaultValue
  if (!envVal) {
    throw new Error(`Environment variable ${key} missing with no default value`)
  }
  return envVal
}

export default {
  baseUrl: env('BASE_URL', 'false'),
  apiUrl: env('API_URL', 'false'),
  enableCookieAuth: env('ENABLE_COOKIE_AUTH', 'false') === 'true'
}
