
import React, { Component } from 'react'
import SearchInput, { createFilter } from 'react-search-input'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import './style.scss'

export class AutocompleteInput extends Component {
  constructor (props) {
    super(props)

    this.state = {
      search: '',
      editing: false
    }

    this.clickClose = this.clickClose.bind(this)
  }

  componentDidMount () {
    window.addEventListener('click', this.clickClose)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.clickClose)
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: nextProps.value
      })
    }
  }

  currentValue () {
    return this.state.value || this.props.value
  }

  disabledOptions () {
    if (!this.props.disabledOptions) {
      return []
    }

    return _.map(this.props.disabledOptions, o => o.id.toString())
  }

  clickClose (ev) {
    if (this.state.editing && !this.refs.main.contains(ev.target)) {
      this.close()
    }
  }

  close () {
    this.setState({
      search: '',
      editing: false,
      value: null
    })
  }

  edit () {
    this.setState({
      search: '',
      editing: true
    })
  }

  select (value) {
    this.setState({
      search: '',
      editing: false,
      value
    })

    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  clickOption (ev, value) {
    ev.preventDefault()
    ev.stopPropagation()

    this.select(value)
  }

  renderFixed () {
    const value = this.currentValue()
    const name = value ? value.name : 'None'

    return (<div className='AutocompleteInput--text' onClick={e => this.edit()}>{name}</div>)
  }

  renderNull () {
    if (this.props.hideNull) {
      return null
    }

    return <li key='option-none' onClick={e => this.clickOption(e, null)}>None</li>
  }

  renderOption (option) {
    const value = this.currentValue()
    const disabledOptions = this.disabledOptions()
    const classNames = []
    let selectable = true

    if (value && option.id.toString() === value.id.toString()) {
      selectable = false
      classNames.push('selected')
    }

    if (disabledOptions.indexOf(option.id.toString()) >= 0) {
      selectable = false
      classNames.push('disabled')
    }

    const props = {
      key: `option-${option.id}`
    }

    if (classNames.length > 0) {
      props.className = classNames.join(' ')
    }

    if (selectable) {
      props.onClick = e => this.clickOption(e, option)
    }

    return (<li {...props}>{option.name}</li>)
  }

  renderSearchInput () {
    if (this.props.hideSearch) {
      return null
    }

    return (<SearchInput
      placeholder={this.props.t('shared.search')}
      className='AutocompleteInput--search'
      inputClassName='AutocompleteInput--input'
      onChange={search => this.setState({ search })}
    />)
  }

  renderEditing () {
    const searchFilter = createFilter(this.state.search, ['name'])
    const options = _.chain(this.props.options)
      .filter(searchFilter)
      .sortBy(this.props.sortBy || 'name')
      .value()

    return (
      <div className='AutocompleteInput--autocomplete'>
        {this.renderSearchInput()}
        <ul>
          {this.renderNull()}
          {options.map(l => this.renderOption(l))}
        </ul>
      </div>
    )
  }

  renderByState () {
    if (this.state.editing) {
      return this.renderEditing()
    }

    return null
  }

  render () {
    const classNames = ['AutocompleteInput']
    if (this.props.defaultStyle) {
      classNames.push('AutocompleteInput--default')
    }

    return (
      <div className={classNames.join(' ')} ref='main'>
        {this.renderFixed()}
        {this.renderByState()}
      </div>
    )
  }
}

export default withTranslation()(AutocompleteInput)
