import React from 'react'
import RestrictTo from './index'

export default (Component, options) => {
  return props => (
    <RestrictTo {...options}>
      <Component {...props} />
    </RestrictTo>
  )
}
