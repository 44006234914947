import React, { Component } from 'react'
import Dimensions from 'react-dimensions'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import EmptyScene from '../EmptyScene'

import ContentWidth from '../../components/ContentWidth'
import DotsChart from './components/DotsChart'
import AxisLegend from './components/AxisLegend'
import AxisLegendPortal from '../../components/HeaderMenu/components/ContentPortal'
import { isParent, findPin } from './utils'

import Sidebar from '../../components/Sidebar/left'
import Teams from '../../components/Sidebar/components/Teams'
import Tick from '../../components/Sidebar/components/Teams/Tick'
import './style.scss'

const MAX_PIN_TEAMS = 12

const Chart = Dimensions()(DotsChart)
// const Chart = DotsChart

function urlParams (params) {
  return _.pick(params, [
    'teamId',
    'xType',
    'xLevel',
    'xId',
    'yType',
    'yLevel',
    'yId'
  ])
}

export function determineGrouping (props) {
  const { metadata } = props

  // Not applicable when looking at the organisation
  if (!props.team) {
    return
  }

  // Not applicable when the leaf hierarchy is already selected
  const level = props.grouped - 1
  if (!metadata[level]) {
    return
  }

  // Only if there are teams in next hierarchy
  const filteredTeams = props.flatTeams.filter(team => {
    return team.level === level && isParent(team, props.team)
  })

  if (filteredTeams.length) {
    props.group(props.grouped + 1)
  }
}

export class ComparisonScene extends Component {
  constructor (props) {
    super(props)
    this.state = {
      redirect: null,
      pinned: [],
      reference: 'default'
    }
  }

  componentWillMount () {
    if (!this.props.totals.length) {
      this.load(this.props)
    }

    const { team } = this.props
    if (team) {
      this.group(team.level)
    } else {
      this.group(1)
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)
  }

  componentWillReceiveProps (nextProps) {
    const currentParams = this.props.params
    const nextParams = nextProps.params

    if (this.props.team !== nextProps.team) {
      let newLevel = 1
      if (nextProps.team) {
        newLevel = 5 - nextProps.team.level
      }

      if (newLevel !== nextProps.grouped) {
        this.group(newLevel)
      }
    }

    if (!_.isEqual(urlParams(currentParams), urlParams(nextParams))) {
      this.load(nextProps)
    }

    if (currentParams.teamId !== nextParams.teamId) {
      this.setState({ pinned: [] })
    }

    if (!nextProps.totals.length && nextProps.grouped) {
      determineGrouping(nextProps)
    }
  }

  setReference (value) {
    this.setState({
      reference: value
    })
  }

  load (props = this.props) {
    if (props.metadata.length !== 0) {
      this.props.load(
        props.metadata,
        props.params.xType,
        props.params.xLevel,
        props.params.xId,
        props.params.yType,
        props.params.yLevel,
        props.params.yId
      )
    }
  }

  isVisible (id) {
    const teams = this.props.totals || []
    return !!_.find(teams, { id })
  }

  onPin (event, team) {
    const pin = _.find(this.state.pinned, { teamId: team.id })
    if (pin) {
      this.setState({
        pinned: this.state.pinned.filter(pin => pin.teamId !== team.id)
      })
    } else {
      const usedColors = this.state.pinned.map(it => it.color)
      let color = 0
      while (usedColors.indexOf(color) >= 0) {
        color++
      }

      const newPin = {
        teamId: team.id,
        path: team.path,
        color
      }

      this.setState({ pinned: this.state.pinned.concat(newPin) })
    }

    this.forceUpdate()
  }

  group (hierarchy) {
    this.props.group(hierarchy)
  }

  renderPin (team) {
    if (team.id === undefined) {
      return null
    }

    if (this.props.params.teamId !== team.parentId) {
      return null
    }

    const pin = findPin(team, this.state.pinned)
    const isChecked = !!pin

    // Max you can pin 12 teams
    if (!isChecked && this.state.pinned.length >= MAX_PIN_TEAMS) {
      return
    }

    const classNames = ['Comparison--Tick']
    if (isChecked) {
      classNames.push(`color${pin.color}`)
    }

    return (
      <Tick
        key={`tick_${team.id}`}
        className={classNames.join(' ')}
        onChange={event => this.onPin(event, team)}
        checked={isChecked}
      />
    )
  }

  renderNoTeams () {
    if (this.props.totals.loading) {
      return (<div className='Comparison--noTeams'>
        {this.props.t('distribution.loading')}
      </div>)
    }

    if (this.props.totals.length === 0) {
      return (<div className='Comparison--noTeams'>
        {this.props.t('distribution.no_data')}
      </div>)
    }

    return null
  }

  xReference () {
    if (this.state.reference === 'none') {
      return undefined
    }

    if (this.props.progress.x) {
      return 0
    }

    const benchmarkMatch = /benchmark-([0-9]+)/.exec(this.state.reference)
    if (benchmarkMatch) {
      return this.props.benchmark[parseInt(benchmarkMatch[1])].x.value
    }

    if (this.props.orgTotals && this.props.orgTotals.x) {
      return this.props.orgTotals.x.value
    }

    return undefined
  }

  yReference () {
    if (this.state.reference === 'none') {
      return undefined
    }

    if (this.props.progress.y) {
      return 0
    }

    const benchmarkMatch = /benchmark-([0-9]+)/.exec(this.state.reference)
    if (benchmarkMatch) {
      return this.props.benchmark[parseInt(benchmarkMatch[1])].y.value
    }

    if (this.props.orgTotals && this.props.orgTotals.y) {
      return this.props.orgTotals.y.value
    }

    return undefined
  }

  render () {
    // Don't render while there are no teams
    if (!this.props.sidebarTeams) {
      return (
        <EmptyScene>
          <div />
        </EmptyScene>
      )
    }

    const data = this.props.totals.loading ? [] : this.props.totals

    return (
      <ContentWidth layout='right'>
        <EmptyScene>
          {this.state.redirect ? <Redirect to={this.state.redirect} /> : undefined}
          <Chart
            limits={this.props.limits}
            thresholds={this.props.thresholds}
            data={data}
            labels={this.props.labels}
            pinned={this.state.pinned}
            xReference={this.xReference()}
            yReference={this.yReference()}
            selected={this.props.selected}
          />
          {this.renderNoTeams()}
          <Sidebar>
            <Teams
              excludeOrganisation
              teams={this.props.sidebarTeams}
              selectedIds={[this.props.params.teamId]}
              disabledIds={this.props.disabledTeams}
              match={this.props.match}
              renderPin={team => this.renderPin(team)}
            />
          </Sidebar>
          <AxisLegendPortal right>
            <AxisLegend
              groups={this.props.groups}
              team={this.props.team}
              metadata={this.props.metadata}
              reference={this.state.reference}
              setReference={(value) => this.setReference(value)}
              showBenchmark={!!this.props.benchmark}
            />
          </AxisLegendPortal>
        </EmptyScene>
      </ContentWidth>
    )
  }
}

export default withTranslation()(ComparisonScene)
