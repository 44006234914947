import { connect } from 'react-redux'
import _ from 'lodash'

import Component from './Component'
import * as actions from '../../../services/surveys/actions'

function mapStateToProps (state, ownProps) {
  let survey

  if (ownProps.match.params.surveyId) {
    survey = _.find(state.surveys.surveys, { id: ownProps.match.params.surveyId })
  }

  return {
    loading: state.surveys.loading,
    error: state.surveys.error,
    survey
  }
}

function mapDispatchToProps (dispatch) {
  return {
    save: (survey) => {
      if (survey.id) {
        return dispatch(actions.update(survey))
      }

      return dispatch(actions.save(survey))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
