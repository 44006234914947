
import React, { PureComponent, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import Tick from '../../../../components/Sidebar/components/Teams/Tick'
import Scale from '../../../../components/SimpleScale'

import ProgressIndicator from '../../../../components/ProgressIndicator'
import { CHILD, EXPAND } from '../../../../utils/icons'
import { routeBuilder } from '../../../../utils/routing'

import './style.scss'

export function RegularScore (props) {
  const question = props.question

  return (
    <Fragment>
      <span className='QuestionCard--score'>{_.get(question, 'score.value', 'N/A')}</span>
      <Scale
        key={`scale_${question.id}`}
        level={_.get(question, 'score.progress')}
        index={_.get(question, 'score.index')}
        history={_.get(question, 'historyScore.progress')}
      />
      <span className='QuestionCard--label'>{_.get(question, 'score.label')}</span>
    </Fragment>)
}

export class QuestionCard extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isExpanded: props.question.grouping1 === props.highlightedLever
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.highlightedLever !== this.props.highlightedLever) {
      this.setState({
        isExpanded: nextProps.question.grouping1 === nextProps.highlightedLever
      })
    }
  }

  onTick (e) {
    e.preventDefault()
    e.stopPropagation()

    this.props.onSelect(this.props.question)
  }

  onExpand (event) {
    event.stopPropagation()
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  leadersUrl () {
    const question = this.props.question
    const options = {}
    if (this.props.team.id) {
      options.query = { teamId: this.props.team.id }
    }

    return routeBuilder('listing', {
      type: question.type,
      level: 'question',
      value: question.id
    }, options)
  }

  renderGrouping3 () {
    const { question, scores } = this.props
    return (
      <Fragment>
        {this.props.t('team_details.as_part_of', { quadrant: question.grouping3 })} <strong>{scores.grouping3}</strong>
      </Fragment>
    )
  }

  renderGrouping2 () {
    const { question, scores } = this.props
    return (
      <Fragment>
        <span className='QuestionCard--arrow'>&gt;</span>
        {question.grouping2} <strong>{scores.grouping2}</strong>
      </Fragment>
    )
  }

  renderGrouping1 () {
    if (this.props.hasGrouping1) {
      const { question, scores } = this.props
      return (
        <Fragment>
          <span className='QuestionCard--arrow'>&gt;</span>
          {question.grouping1} <strong>{scores.grouping1}</strong>
        </Fragment>
      )
    }
  }

  renderExpandedCard () {
    const { question } = this.props
    const { isExpanded } = this.state

    if (isExpanded) {
      return (
        <div className='QuestionCard--expansion'>
          <p>
            {question.question_text}
          </p>
        </div>
      )
    }
  }

  renderScore () {
    if (this.props.highlightProgress) {
      return <ProgressIndicator value={this.props.question} />
    }

    return <RegularScore question={this.props.question} />
  }

  render () {
    const { isSelected, question, hasGrouping1 } = this.props
    const { isExpanded } = this.state

    const classNames = ['QuestionCard']
    if (isSelected) {
      classNames.push('QuestionCard--selected')
    }

    const expandClassNames = ['QuestionCard--expand']
    if (isExpanded) {
      expandClassNames.push('QuestionCard--expanded')
    }

    return (
      <div className={classNames.join(' ')} onClick={e => this.onTick(e)}>
        <div className='clearfix'>
          <div className='col col-6  QuestionCard--Details QuestionCard--cell'>
            <Tick onChange={e => this.onTick(e)} checked={isSelected} className='QuestionCard--tick' />
            <span className={expandClassNames.join(' ')} onClick={e => this.onExpand(e)}>
              {CHILD}
              {EXPAND}
            </span>
            <div>
              <div className='QuestionCard--title'>{hasGrouping1 ? question.question_title : question.grouping1}</div>
              <div className='QuestionCard--subtitle'>
                {this.renderGrouping3()}
                {this.props.scores.grouping2 && this.renderGrouping2()}
                {this.renderGrouping1()}
              </div>
            </div>
          </div>
          <div className='col col-4 QuestionCard--Score QuestionCard--cell'>
            {this.renderScore()}
          </div>
          <div className='QuestionCard--cell QuestionCard--link col col-2 right'>
            <Link to={this.leadersUrl()}>{this.props.t('team_details.view_leaders')}</Link>
          </div>
        </div>
        {this.renderExpandedCard()}
      </div>
    )
  }
}

export default withTranslation()(QuestionCard)
