import * as types from './types'

export function retrieve () {
  return {
    types: [
      types.RETRIEVE_REQUEST,
      types.RETRIEVE_SUCCESS,
      types.RETRIEVE_FAILURE
    ],
    payload: {
      request: {
        url: '/user/info'
      }
    }
  }
}

export function settings () {
  return {
    types: [
      types.SETTINGS_REQUEST,
      types.SETTINGS_SUCCESS,
      types.SETTINGS_FAILURE
    ],
    payload: {
      request: {
        url: `/data/{latest}/settings`
      }
    }
  }
}

export function logout () {
  return { type: types.LOGOUT }
}

export function setSurveys (surveys) {
  return {
    type: types.SET_SURVEYS,
    surveys
  }
}

export function setComparisonType (value) {
  return {
    type: types.SET_COMPARISON_TYPE,
    value
  }
}
