import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'

import ContentWidth from '../../../components/ContentWidth'
import { Alert } from '../../../components'
import Radio from '../../../components/Radio'
import { calculationTypeName } from '../../../utils/calculations'

import './style.scss'

const MAX_SIZE = 20 * 1024 * 1024

function clickFile (target) {
  target.querySelector('input').click()
}

export default class ImportForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      file: null,
      error: null,
      selected: 'linear-avg-1-5-alternate',
      hasPossibleResponses: false,
      hasCompressedHeaders: true,
      showDetails: false
    }
  }

  onSubmit (event) {
    event.preventDefault()
    if (this.state.error) {
      return null
    }

    if (!this.state.csv) {
      this.setState({
        error: 'Please select a file to upload'
      })
      return
    }

    const { csv, interpretationKey, localisationFile, selected: calculation } = this.state
    const survey = this.props.match.params.id
    const data = new window.FormData()
    data.append('csv', csv)
    data.append('calculation', calculation)

    if (interpretationKey) {
      data.append('interpretationKey', interpretationKey)
    }

    if (localisationFile) {
      data.append('localisationFile', localisationFile)
    }

    if (this.state.hasPossibleResponses) {
      data.append('hasPossibleResponses', true)
    }
    if (this.state.hasCompressedHeaders) {
      data.append('hasCompressedHeaders', true)
    }

    this.props.upload(survey, data)
      .then(response => {
        if (response.payload && response.payload.status === 200) {
          this.setState({ redirect: true })
        }
      })
  }

  addFile (csv, prefix = 'csv') {
    const reader = new window.FileReader()

    if (!reader) {
      this.setState({ file: null, error: 'Access to local files is impossible on this browser' })
    } else if (!csv) {
      this.setState({ file: null, error: 'File is not accessible' })
    } else if (!csv.name.match(/\.csv$/)) {
      this.setState({ file: null, error: 'Wrong file format: upload a csv file' })
    } else if (csv.size && csv.size > MAX_SIZE) {
      this.setState({ file: null, error: 'The file size is too big' })
    } else {
      this.setState({ error: null })

      reader.onloadend = () => {
        this.setState({
          [prefix]: csv,
          error: null
        })
      }
      reader.readAsDataURL(csv)
    }
  }

  onFile (event, prefix = 'csv') {
    const csv = event.target.files[0]
    this.addFile(csv, prefix)
  }

  changeSelectedCalculation (type) {
    this.setState({ selected: type })
  }

  changeHasCompressedHeaders (value) {
    const hasCompressedHeaders = value === 'true'
    const state = { hasCompressedHeaders }
    if (hasCompressedHeaders) {
      state.hasPossibleResponses = false
    }

    this.setState(state)
  }

  changeHasPossibleResponses (value) {
    const hasPossibleResponses = value === 'true'
    const state = { hasPossibleResponses }
    if (hasPossibleResponses) {
      state.interpretationKey = undefined
    }

    this.setState(state)
  }

  renderError () {
    const error = this.state.error || this.props.error
    if (!error) {
      return null
    }

    return (
      <div className='Form--error'>
        <Alert message={error} />
      </div>)
  }

  dropFile (ev, prefix = 'csv') {
    ev.stopPropagation()
    ev.preventDefault()
    this.setState({ [`${prefix}DragOver`]: false })
    this.addFile(ev.dataTransfer.files[0], prefix)
  }

  dragOver (ev, prefix = 'csv') {
    ev.stopPropagation()
    ev.preventDefault()

    this.setState({ [`${prefix}DragOver`]: true })
  }

  dragLeave (ev, prefix = 'csv') {
    ev.stopPropagation()
    ev.preventDefault()

    this.setState({ [`${prefix}DragOver`]: false })
  }

  toggleDetails () {
    this.setState({
      showDetails: !this.state.showDetails
    })
  }

  renderFile () {
    if (!this.state.csv) {
      return null
    }

    return (
      <div className='Form--input Form--fake ImportForm--file'>
        <label>Selected file</label>
        <div>{this.state.csv.name}</div>
      </div>)
  }

  renderFileUpload () {
    const classes = ['ImportForm--fileupload']
    if (this.state.csvDragOver) {
      classes.push('dragover')
    }

    return (
      <div
        className={classes.join(' ')}
        onDrop={e => this.dropFile(e)}
        onDragOver={e => this.dragOver(e)}
        onDragLeave={e => this.dragLeave(e)}
        onClick={e => clickFile(e.currentTarget)}
      >
        <input id='file' type='file' accept='.csv' onClick={ev => ev.stopPropagation()} onChange={event => this.onFile(event)} />
        <span>Drop or Browse a file</span>
      </div>)
  }

  renderDetailsLink () {
    const message = this.state.showDetails ? 'Hide details' : 'Show details'
    return <span onClick={e => this.toggleDetails()}>{message}</span>
  }

  renderDetails () {
    if (!this.state.showDetails) {
      return null
    }

    return (
      <div className='Form--panel ImportForm--details'>
        <table>
          <colgroup>
            <col style={{ width: '12%' }} />
            <col style={{ width: '9%' }} />
            <col style={{ width: '13%' }} />
            <col style={{ width: '16%' }} />
            <col style={{ width: '12%' }} />
            <col style={{ width: '9%' }} />
            <col style={{ width: '13%' }} />
            <col style={{ width: '16%' }} />
          </colgroup>
          <thead>
            <tr>
              <th colspan='4'>Capabilities</th>
              <th colspan='4'>Outcomes</th>
            </tr>
            <tr>
              <th>Input</th>
              <th>Output</th>
              <th>Thresholds</th>
              <th>Calculation</th>
              <th>Input</th>
              <th>Output</th>
              <th>Thresholds</th>
              <th>Calculation</th>
            </tr>
          </thead>
          <tbody hidden={this.state.selected !== 'legacy'}>
            <tr>
              <td>1-6 (7-8)</td>
              <td>0-100</td>
              <td>30-45-60-75</td>
              <td>NPS</td>
              <td>0-10 (11-12)</td>
              <td>0-100</td>
              <td>50-60-80-90</td>
              <td>Linear Average</td>
            </tr>
          </tbody>
          <tbody hidden={this.state.selected !== 'linear-avg-1-5'}>
            <tr>
              <td>1-5 (6-7)</td>
              <td>0-100</td>
              <td>30-45-60-75</td>
              <td>Linear Average</td>
              <td>0-10 (11-12)</td>
              <td>0-100</td>
              <td>5-6-8-9</td>
              <td>Linear Average</td>
            </tr>
          </tbody>
          <tbody hidden={this.state.selected !== 'linear-avg-1-5-alternate'}>
            <tr>
              <td>1-5 (6-7)</td>
              <td>0-100</td>
              <td>30-45-60-75</td>
              <td>Linear Average</td>
              <td>0-10 (11-12)</td>
              <td>0-10</td>
              <td>4-6-8-9</td>
              <td>Linear Average</td>
            </tr>
          </tbody>
          <tbody hidden={this.state.selected !== '2'}>
            <tr>
              <td>1-5 (6-7)</td>
              <td>0-5</td>
              <td>2-3-4-4.5</td>
              <td>Linear Average</td>
              <td>0-10 (11-12)</td>
              <td>0-5</td>
              <td>3-3.5-4-4.5</td>
              <td>Linear Average</td>
            </tr>
          </tbody>
          <tbody hidden={this.state.selected !== 'nps-outcome-1-10'}>
            <tr>
              <td>1-6 (7-8)</td>
              <td>0-100</td>
              <td>30-45-60-75</td>
              <td>NPS</td>
              <td>0-10 (11-12)</td>
              <td>0-10</td>
              <td>4-6-8-9</td>
              <td>Linear Average</td>
            </tr>
          </tbody>
          <tbody hidden={this.state.selected !== 'tq'}>
            <tr>
              <td>1-5 (6-7)</td>
              <td>1-5</td>
              <td>2-3-4-4.5</td>
              <td>Linear Average</td>
              <td>1-10 (11-12)</td>
              <td>1-10</td>
              <td>5-6-8-9</td>
              <td>Linear Average</td>
            </tr>
          </tbody>
          <tbody hidden={this.state.selected !== 'dnaq'}>
            <tr>
              <td>1-5 (6-7)</td>
              <td>0-100</td>
              <td>20-30-40-50</td>
              <td>Linear Average</td>
              <td>1-5 (6-7)</td>
              <td>0-100</td>
              <td>20-30-40-50</td>
              <td>Linear Average</td>
            </tr>
          </tbody>
        </table>
      </div>)
  }

  renderInterpretationKeyForm () {
    const classes = ['ImportForm--fileupload']
    let selectedFile = null

    if (this.state.interpretationKeyDragOver) {
      classes.push('dragover')
    }

    if (this.state.interpretationKey) {
      selectedFile = (
        <div className='Form--input Form--fake ImportForm--file'>
          <label>Selected interpretation key file: </label>
          <div>{this.state.interpretationKey.name}</div>
        </div>)
    }

    return (
      <React.Fragment>
        {selectedFile}
        <div
          className={classes.join(' ')}
          onDrop={e => this.dropFile(e, 'interpretationKey')}
          onDragOver={e => this.dragOver(e, 'interpretationKey')}
          onDragLeave={e => this.dragLeave(e, 'interpretationKey')}
          onClick={e => clickFile(e.currentTarget)}
        >
          <input id='interpretationKey' type='file' accept='.csv' onClick={ev => ev.stopPropagation()} onChange={event => this.onFile(event, 'interpretationKey')} />
          <span>Upload the interpretation key file if you want to import the possible responses text</span>
        </div>
      </React.Fragment>)
  }

  renderLegacyPossibleResponsesForm () {
    return (
      <div className='Form--fieldset ImportForm--format'>
        <div className='Form--caption'>Does the upload file include detailed responses for each of the multiple choice questions?</div>
        <label>
          <Radio
            name='possibleResponses'
            value='true'
            onChange={e => this.changeHasPossibleResponses(e.target.value)}
            checked={this.state.hasPossibleResponses}
          />
          <span>Yes</span>
        </label>
        <label>
          <Radio
            name='possibleResponses'
            value='false'
            onChange={e => this.changeHasPossibleResponses(e.target.value)}
            checked={!this.state.hasPossibleResponses}
          />
          <span>No</span>
        </label>
      </div>)
  }

  renderPossibleResponsesForm () {
    if (this.state.hasCompressedHeaders) {
      return this.renderInterpretationKeyForm()
    }

    return this.renderLegacyPossibleResponsesForm()
  }

  renderLocalisationFileForm () {
    const classes = ['ImportForm--fileupload']
    let selectedFile = null

    if (this.state.localisationFileDragOver) {
      classes.push('dragover')
    }

    if (this.state.localisationFile) {
      selectedFile = (
        <div className='Form--input Form--fake ImportForm--file'>
          <label>Selected localisation file: </label>
          <div>{this.state.localisationFile.name}</div>
        </div>)
    }

    return (
      <div className='Form--fieldset ImportForm--lone-upload'>
        {selectedFile}
        <div
          className={classes.join(' ')}
          onDrop={e => this.dropFile(e, 'localisationFile')}
          onDragOver={e => this.dragOver(e, 'localisationFile')}
          onDragLeave={e => this.dragLeave(e, 'localisationFile')}
          onClick={e => clickFile(e.currentTarget)}
        >
          <input id='localisationFile' type='file' accept='.csv' onClick={ev => ev.stopPropagation()} onChange={event => this.onFile(event, 'localisationFile')} />
          <span>Upload a localisation file if you want to customise the user interface</span>
        </div>
      </div>)
  }

  render () {
    const { redirect } = this.state
    if (redirect) {
      return <Redirect to='/admin/surveys' />
    }

    return (
      <ContentWidth layout='full'>
        <div>
          <h1>Import survey</h1>
          <form onSubmit={event => this.onSubmit(event)} className='Form'>
            {this.renderError()}
            {this.renderFile()}
            {this.renderFileUpload()}
            <div className='Form--fieldset ImportForm--calculation'>
              <div className='Form--caption'>What calculation format is used?{this.renderDetailsLink()}</div>
              <label htmlFor='linear-avg-1-5-alternate'>
                <Radio
                  id='linear-avg-1-5-alternate'
                  name='calculation'
                  value='linear-avg-1-5-alternate'
                  onChange={e => this.changeSelectedCalculation(e.target.value)}
                  checked={this.state.selected === 'linear-avg-1-5-alternate'}
                />
                <span>{calculationTypeName('linear-avg-1-5-alternate')}</span>
              </label>
              <label htmlFor='nps-outcome-1-10'>
                <Radio
                  id='nps-outcome-1-10'
                  name='calculation'
                  value='nps-outcome-1-10'
                  onChange={e => this.changeSelectedCalculation(e.target.value)}
                  checked={this.state.selected === 'nps-outcome-1-10'}
                />
                <span>{calculationTypeName('nps-outcome-1-10')}</span>
              </label>
              <label htmlFor='tq'>
                <Radio
                  id='tq'
                  name='calculation'
                  value='tq'
                  onChange={e => this.changeSelectedCalculation(e.target.value)}
                  checked={this.state.selected === 'tq'}
                />
                <span>{calculationTypeName('tq')}</span>
              </label>
              <label htmlFor='dnaq'>
                <Radio
                  id='dnaq'
                  name='calculation'
                  value='dnaq'
                  onChange={e => this.changeSelectedCalculation(e.target.value)}
                  checked={this.state.selected === 'dnaq'}
                />
                <span>{calculationTypeName('dnaq')}</span>
              </label>
            </div>
            {this.renderDetails()}
            <div className='Form--fieldset ImportForm--format'>
              <div className='Form--caption'>Does the upload file use compressed headers?</div>
              <label>
                <Radio
                  name='compressedHeaders'
                  value='true'
                  onChange={e => this.changeHasCompressedHeaders(e.target.value)}
                  checked={this.state.hasCompressedHeaders}
                />
                <span>Yes</span>
              </label>
              <label>
                <Radio
                  name='compressedHeaders'
                  value='false'
                  onChange={e => this.changeHasCompressedHeaders(e.target.value)}
                  checked={!this.state.hasCompressedHeaders}
                />
                <span>No</span>
              </label>
            </div>
            {this.renderPossibleResponsesForm()}
            {this.renderLocalisationFileForm()}
            <div className='Form--actions'>
              <Link className='Form--cancel' to={'/admin/surveys'}>Cancel</Link>
              <input type='submit' className='Form--button' value='Save' />
            </div>
          </form>
        </div>
      </ContentWidth>
    )
  }
}
