
export function isNotAsked (score) {
  return !score
}

export function isNotAnswered (score) {
  return !score || !('value' in score) || score.responses === 0
}

export function formatProgress (progress) {
  if (progress > 0) {
    return `+${progress}`
  }

  return String(progress)
}

export function formatScore (score) {
  if (isNotAsked(score)) {
    return ''
  }

  if (isNotAnswered(score)) {
    return 'N/A'
  }

  if (score.isProgress) {
    return formatProgress(score.value)
  }

  return String(score.value)
}

export function isNoSurveyError (err) {
  return err.data === 'No survey'
}
