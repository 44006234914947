
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import Autocomplete from '../AutocompleteInput'
import { HIERARCHY_LEVELS } from '../../utils/icons'
import { routeBuilder } from '../../utils/routing'
import { pathFromMetadata } from '../../utils/metadata'
import { pathFromSurveys } from '../../utils/surveys'
import { fullBasename } from '../../utils/dom'

import './style.scss'

const RESPONDENTS_ALERT_MIN = 4

function isSelectionValid (metadata) {
  if (_.uniq(metadata).length !== metadata.length) {
    return false
  }

  if (metadata.length === 0) {
    return false
  }

  return true
}

class MetadataSelector extends Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  url () {
    const match = this.props.match
    if (!match) {
      return routeBuilder.root()
    }

    const options = { noTeam: true }
    const params = _.omit(match.params, 'teamId')
    return routeBuilder(match.route.name, params, options)
  }

  buildPageUrl (metadata) {
    return fullBasename(`${pathFromSurveys(this.props.surveys)}/${pathFromMetadata(metadata)}${this.url()}`)
  }

  buildRespondentsUrl () {
    const metadata = this.currentSelection()
    const url = routeBuilder('respondents', { surveyId: 'latest' })
    return fullBasename(`${pathFromSurveys(this.props.surveys)}/${pathFromMetadata(metadata)}${url}`)
  }

  currentSelection () {
    return _.compact(this.state.selected || this.props.selectedMetadata)
  }

  checkMetadataRespondents (metadata) {
    this.props.checkMetadataRespondents(metadata)
  }

  select (index, value) {
    if (value === null) {
      const selected = this.currentSelection().slice(0, index)
      this.setState({ selected })
      this.checkMetadataRespondents(selected)
      return
    }

    const metadata = _.clone(this.currentSelection())
    metadata[index] = value
    const selected = _.compact(metadata)

    this.setState({ selected })
    this.checkMetadataRespondents(selected)
  }

  renderLink (currentSelection = this.currentSelection()) {
    if (isSelectionValid(currentSelection)) {
      return <a className='MetadataSelector--link' href={this.buildPageUrl(currentSelection)}>{this.props.t('metadata_selector.apply')}</a>
    }
  }

  renderDefaultReset () {
    return (<p className='MetadataSelector--reset'>
      <a href={this.buildPageUrl(this.props.defaultHierarchy)}>{this.props.t('metadata_selector.reset')}</a>
    </p>)
  }

  renderSelector (currentSelection, index) {
    if (currentSelection.length < index) {
      return null
    }

    const disabledOptions = currentSelection[index] ? _.reject(currentSelection, { id: currentSelection[index].id }) : currentSelection
    return (
      <li>
        {HIERARCHY_LEVELS[`L${index + 1}`]}
        <label>{this.props.t('metadata_selector.metadata')}</label>
        <Autocomplete
          value={currentSelection[index]}
          options={this.props.hierarchies}
          onChange={value => this.select(index, value)}
          hideNull={index === 0}
          disabledOptions={disabledOptions}
          defaultStyle
        />
      </li>)
  }

  renderMetadataMessage () {
    const metadataId = pathFromMetadata(this.currentSelection())

    if (!this.props.minRespondents || !this.props.minRespondents[metadataId]) {
      return null
    }

    if (this.props.minRespondents[metadataId] >= RESPONDENTS_ALERT_MIN) {
      return null
    }

    return (
      <div className='MetadataSelector--warning'>
        <p>{this.props.t('metadata_selector.respondents_warning')}</p>
        <a href={this.buildRespondentsUrl()}>{this.props.t('metadata_selector.learn_more')}</a>
      </div>)
  }

  render () {
    const currentSelection = this.currentSelection()

    return (
      <div className='MetadataSelector'>
        <p className='MetadataSelector--description'>
          {this.props.t('metadata_selector.introduction')}
        </p>
        <ul>
          {this.renderSelector(currentSelection, 0)}
          {this.renderSelector(currentSelection, 1)}
          {this.renderSelector(currentSelection, 2)}
        </ul>
        {this.renderMetadataMessage()}
        <div className='MetadataSelector--actions'>
          {this.renderDefaultReset()}
          {this.renderLink(currentSelection)}
        </div>
      </div>)
  }
}

export default withTranslation()(MetadataSelector)
