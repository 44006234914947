import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import './style.scss'
import InfinityLogo from './infinity.svg'

import ICON_403 from './403.svg'
import ICON_404 from './404.svg'
import ICON_408 from './408.svg'
import ICON_500 from './500.svg'

export default class ErrorView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      redirect: null
    }

    this.onRouterChange = this.onRouterChange.bind(this)

    if (props.history) {
      this.unlisten = props.history.listen(this.onRouterChange)
    }
  }

  componentWillReceiveProps (props) {
    if (this.unlisten) {
      this.unlisten()
    }

    if (props.history) {
      this.unlisten = props.history.listen(this.onRouterChange)
    }
  }

  componentWillUnmount () {
    if (this.unlisten) {
      this.unlisten()
    }
  }

  onRouterChange () {
    this.props.dismiss()
  }

  details (status) {
    switch (status) {
      case 403:
        return {
          title: 'Forbidden',
          subject: 'You do not have the necessary permissions to access this page.',
          icon: ICON_403
        }

      case 408:
        return {
          title: 'The request took too much time.',
          subject: <span>It took too much time to complete the action. Please <a href='#retry' onClick={e => this.onRetry(e)}>retry</a>.</span>,
          icon: ICON_408
        }

      case 404:
        return {
          title: 'The page was not found.',
          subject: <span>Use the navigation above, <a href='#retry' onClick={e => this.onRetry(e)}>retry</a> or go back to the <a href='/'>homepage</a>.</span>,
          icon: ICON_404
        }

      case 500:
      default:
        return {
          title: 'An unexpected error occured.',
          subject: <span>Please <a href='#retry' onClick={e => this.onRefresh(e)}>refresh</a> the page to try again or contact your administrator to report the problem.</span>,
          icon: ICON_500
        }
    }
  }

  onRetry (e) {
    e.preventDefault()
    e.stopPropagation()

    if (this.props.action) {
      this.props.dismiss()
      this.props.retry(this.props.action)
    }
  }

  onImport (e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ redirect: '/admin/surveys' }, () => {
      this.setState({ redirect: null })
      this.props.dismiss()
    })
  }

  onRefresh (e) {
    e.preventDefault()
    e.stopPropagation()
    window.location.reload()
  }

  onDismiss (e) {
    this.props.dismiss()
  }

  render () {
    if (!this.props.error || this.props.status <= 101) {
      return null
    }

    const { redirect } = this.state
    const { status } = this.props
    const { title, subject, icon } = this.details(status)

    return (
      <div className='ErrorView' onClick={e => this.onDismiss(e)} ref='view'>
        <div className='ErrorView--container' ref='container'>
          { redirect ? <Redirect to={redirect} /> : undefined }
          { icon ? <img src={icon} className='ErrorView--infinity block' alt={this.props.status} /> : undefined }
          <img src={InfinityLogo} className='ErrorView--infinity mt3 block' alt='Error' />
          <span className='ErrorView--heading block center mt3'>{title}</span>
          <span className='ErrorView--subject block center mt1'>{subject}</span>
        </div>
      </div>
    )
  }
}
