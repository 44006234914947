
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { support } from '@mid/sdk'

import ContentWidth from '../../components/ContentWidth'
import Toggle from '../../components/Toggle'
import Teams from '../../components/Sidebar/components/Teams'
import { CLOSE } from '../../utils/icons'
import { downloadFile } from '../../utils/files'

import './style.scss'

class Powerpoint extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isIncludeSubteams: false,
      teams: [],
      compareTo: false,
      tenant: support.getTenant()
    }
  }

  componentWillMount () {
    this.props.retrieve(this.props.metadata)
  }

  onClick (team) {
    if (team.id === undefined && this.props.teams) {
      const teams = this.state.isIncludeSubteams ? this.props.teams : _.filter(this.props.teams, { parentId: undefined })
      this.addTeams(teams)
    } else if (this.state.isIncludeSubteams && team.teams && team.teams.length) {
      this.addTeams(team.teams)
    } else {
      this.addTeams([team])
    }

    // No redirect
    return null
  }

  addTeams (teams) {
    const newTeams = []
    teams.forEach(team => {
      if (!_.find(this.state.teams, { id: team.id })) {
        newTeams.push(team)
      }
    })

    this.setState({
      teams: this.state.teams.concat(newTeams)
    })
  }

  onRemove (team) {
    this.setState({
      teams: _.reject(this.state.teams, it => it === team)
    })
  }

  onSubmit (event) {
    event.preventDefault()

    if (this.state.teams.length > 50) {
      window.alert(this.props.t('powerpoint.teams_count_alert'))
      return
    }

    const teamIds = this.state.teams.map(it => it.id)
    this.props.generate(this.props.metadata, teamIds, this.state.compareTo)
      .then(response => {
        if (response.payload && response.payload.data) {
          downloadFile(response.payload.data, this.state.tenant.displayName, 'pptx')
        }
      })
  }

  clear (event) {
    event.preventDefault()
    this.setState({ teams: [] })
  }

  renderOptions () {
    if (this.props.surveys.length < 2) {
      return
    }

    return (
      <div className='Powerpoint--options'>
        <label className='Powerpoint--ToggleLabel'>
          {this.props.t('powerpoint.include_comparison', { survey: this.props.surveys[1].name })}
          <div className='Powerpoint--Toggle'>
            <Toggle
              checked={this.state.compareTo}
              onChange={e => this.setState({ compareTo: e.target.checked })}
            />
          </div>
        </label>
      </div>)
  }

  render () {
    const { teams, isLoading, t } = this.props
    if (!teams) {
      return <div>{t('loading')}</div>
    }

    return (
      <ContentWidth>
        <div className='clearfix mt-head'>
          <div>
            <h1>{t('powerpoint.title')}</h1>
            <p>{t('powerpoint.description')}</p>
          </div>
          <hr className='mt3 mb3 hr' />
          <h2>{t('powerpoint.action')}</h2>
          <div className='clearfix'>
            <div className='col col-6'>
              <p>{t('powerpoint.teams_count_message')}</p>
            </div>
            <div className='col col-6'>
              <label className='block right-align Powerpoint--ToggleLabel'>
                {t('powerpoint.include_children')}
                <div className='Powerpoint--Toggle'>
                  <Toggle
                    checked={this.state.isIncludeSubteams}
                    onChange={e => this.setState({ isIncludeSubteams: e.target.checked })}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className='Powerpoint--TeamContainer col col-6'>
            <Teams url={team => this.onClick(team)} selectedIds={this.state.teams.map(it => it.id)} />
          </div>
          <div className='Powerpoint--SelectedTeamContainer col col-6'>
            <p>
              <span>{t('powerpoint.selected_teams', { teams: this.state.teams.length })} </span>
              {this.state.teams.length
                ? <a href='#clear' onClick={e => this.clear(e)}>{t('powerpoint.clear_teams')}</a>
                : null}
            </p>
            {this.state.teams.map(team => {
              return (
                <div key={`team_${team.id}`}>
                  <div className='Powerpoint--TeamBullet'>
                    <span className='Powerpoint--TeamName'>{team.name}</span>
                    <span className='Powerpoint--TeamRemove' onClick={e => this.onRemove(team)}>{CLOSE}</span>
                  </div>
                </div>
              )
            })}
          </div>
          {this.renderOptions()}
          <div className='Powerpoint--action'>
            <input
              type='submit'
              onClick={e => this.onSubmit(e)}
              id='submit'
              className='mt2 btn btn-primary'
              value={isLoading ? t('powerpoint.preparing_deck') : t('powerpoint.download_deck')}
              disabled={isLoading} />
          </div>
        </div>
      </ContentWidth>
    )
  }
}

export default withTranslation()(Powerpoint)
