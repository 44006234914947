import React, { PureComponent } from 'react'
import { nextAnimationFrame } from '../../../utils/dom'

export default class Sticky extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      sticky: false
    }

    this.onScroll = this.onScroll.bind(this)
    this.checkScroll = this.checkScroll.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll () {
    this.scrollLocation = window.scrollY || window.scrollTop || window.pageYOffset

    if (!this.scrollTimer) {
      this.scrollTimer = true
      nextAnimationFrame(this.checkScroll)
    }
  }

  checkScroll () {
    this.scrollTimer = false
    this.setState({
      sticky: this.scrollLocation > this.props.height
    })
  }

  render () {
    return (
      <div className={this.state.sticky ? this.props.className : ''}>
        {this.props.children}
      </div>
    )
  }
}
