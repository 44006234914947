
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import RadioSelect from '../../../RadioSelect'

import './style.scss'

class Surveys extends Component {
  surveyOptions () {
    const t = this.props.t

    const surveys = [...this.props.surveys.map((survey, index) => {
      return {
        name: <span>
          <span className='Surveys--label'>{t('heatmap.selector.score')}</span>
          <span>{survey.name}</span>
        </span>,
        value: index === 0 ? 'latest' : 'previous'
      }
    })]

    if (surveys.length >= 1) {
      surveys.push({
        name: <span className='Surveys--label'>{t('heatmap.selector.progress')}</span>,
        value: 'progress'
      })
    }

    if (this.props.benchmark) {
      surveys.push({
        name: <span className='Surveys--label'>{t('heatmap.selector.benchmark')}</span>,
        value: 'benchmark'
      })
    }

    return surveys
  }

  render () {
    if (this.props.surveys.length < 2) {
      return null
    }

    return (
      <div className='Heatmap-table--surveys'>
        <h3>{this.props.t('heatmap.selector.title')}</h3>
        <RadioSelect
          options={this.surveyOptions()}
          selected={this.props.currentSurvey}
          onChange={value => this.props.onChange(value)}
        />
      </div>
    )
  }
}

export default withTranslation()(Surveys)
