import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { actions as midActions } from '@cs/mid-react-saga'
import Component from './Component'

function mapStateToProps (state) {
  const type = state.navigation.type || (state.profile.questionTypes && state.profile.questionTypes[0])

  return {
    type: type.key,
    isDataAvailable: state.metadata.hierarchies && state.metadata.hierarchies.length > 0,
    showDistribution: state.profile.questionTypes && state.profile.questionTypes.length > 1
  }
}

function mapDispatchToProps (dispatch) {
  return {
    logout: () => dispatch(midActions.logout())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))
