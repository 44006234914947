
import React, { PureComponent, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { routeBuilder } from '../../../../utils/routing'
import { formatScore, isNotAsked, isNotAnswered } from '../../../../utils'
import Radio from '../../../../components/Radio'

import './style.scss'

function displayScore (item) {
  if (isNotAsked(item.score)) {
    return <div className='scoreNotAsked' />
  }

  if (isNotAnswered(item.score)) {
    return <div className='scoreNa'>N/A</div>
  }

  return (
    <div className={`score${item.score.index}`}>
      {formatScore(item.score)}
    </div>
  )
}

export class ResultsLegend extends PureComponent {
  getTeamId () {
    return this.props.match.params.teamId || 'organisation'
  }

  goto (url) {
    this.props.history.push(url)
  }

  navigate (type, item) {
    const options = {}
    if (item) {
      options.query = { filter: item.name }
    }

    this.goto(routeBuilder('teamDetails', {
      type,
      teamId: this.getTeamId()
    }, options))
  }

  renderLabel (type, total, item, index) {
    if (total < 6) {
      index = total - index
    } else {
      index = total - (index + (total - 5))
    }

    const props = {
      key: `label-${item.name}-${index}`
    }

    if (!isNotAsked(item.score) && !isNotAnswered(item.score)) {
      props.onClick = () => this.navigate(type.key, item)
      props.className = 'clickable'
    } else {
      props.className = 'disabled'
    }
    const className = index < 0 ? `${type.style}-overflow` : `${type.style}${index}`

    return (
      <li {...props}>
        <i className={className} />
        <span>{item.name}</span>
        {displayScore(item)}
      </li>
    )
  }

  renderComparisonSelector () {
    const surveys = this.props.surveys
    if (this.props.showCompareSurvey && !this.props.showCompareBenchmark) {
      return (<span>{surveys[1].name}</span>)
    }

    const options = []

    if (this.props.showCompareSurvey) {
      options.push(
        <span key='compare-survey'>
          <label>
            <Radio
              value='survey'
              checked={this.props.comparisonType === 'survey' || !this.props.comparisonType}
              onChange={e => this.props.setComparisonType(e.target.value)}
            />
            {surveys[1].name}
          </label>
        </span>
      )
    } else {
      options.push(
        <span key='compare-none'>
          <label>
            <Radio
              value='none'
              checked={this.props.comparisonType === 'none' || !this.props.comparisonType}
              onChange={e => this.props.setComparisonType(e.target.value)}
            />
            {this.props.t('teams.legend.none')}
          </label>
        </span>
      )
    }

    if (this.props.showCompareOrganisation) {
      options.push(
        <span key='compare-organisation'>
          <label>
            <Radio
              value='organisation'
              checked={this.props.comparisonType === 'organisation'}
              onChange={e => this.props.setComparisonType(e.target.value)}
            />
            {this.props.t('shared.organisation')}
          </label>
        </span>
      )
    }

    if (this.props.showCompareBenchmark) {
      options.push(...this.props.benchmarks.map((benchmark, i) => {
        return <span key={`compare-benchmark-${i}`}>
          <label>
            <Radio
              value={`benchmark-${i}`}
              checked={
                this.props.comparisonType === `benchmark-${i}`}
              onChange={e => this.props.setComparisonType(e.target.value)}
            />
            {benchmark.name || this.props.t('shared.benchmark')}
          </label>
        </span>
      })
      )
    }

    return options
  }

  renderDisplay () {
    if (!this.props.showCompareSurvey && !this.props.showCompareOrganisation && !this.props.showCompareBenchmark) {
      return null
    }

    const surveys = this.props.surveys
    return (
      <Fragment>
        <h3>{this.props.t('teams.legend.display')}</h3>
        <ul className='ResultsLegend--display'>
          <li>
            <label>{this.props.t('teams.legend.main')}</label>
            <span>{surveys[0].name}</span>
          </li>
          <li>
            <label>{this.props.t('teams.legend.comparison')}</label>
            {this.renderComparisonSelector()}
          </li>
        </ul>
      </Fragment>)
  }

  renderNa () {
    if (!this.props.showNa) {
      return null
    }

    return (
      <Fragment>
        <h3>Legend</h3>
        <ul className='ResultsLegend--legend'>
          <li>
            <div className='scoreNa'>N/A</div>
            <span>{this.props.t('shared.not_aplicable')}</span>
          </li>
          <li>
            <div className='scoreNotAsked' />
            <span>{this.props.t('shared.not_asked')}</span>
          </li>
        </ul>
      </Fragment>)
  }

  renderTypeScore (type) {
    const levels = this.props.levels[type.key]
    const groups = levels.groups

    return (
      <Fragment key={`legend-for-${type.key}`}>
        <h3>{type.plural}</h3>
        <ul className='ResultsLegend--legend'>
          <li
            key={`label-${type.key}-total`}
            className='clickable'
            onClick={() => this.navigate(type.key)}
          >
            <i className={`${type.style}-total`} />
            <span>Overall</span>
            {displayScore(levels)}
          </li>
          {_.map(groups, this.renderLabel.bind(this, type, groups.length))}
        </ul>
      </Fragment>)
  }

  renderScores () {
    return this.props.questionTypes.map(type => {
      return this.renderTypeScore(type)
    })
  }

  render () {
    return (
      <div className='ResultsLegend'>
        {this.renderDisplay()}
        {this.renderScores()}
        {this.renderNa()}
      </div>
    )
  }
}

export default withRouter(withTranslation()(ResultsLegend))
