import React, { Component, StrictMode } from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'

import * as scenes from './scenes'
import AppContainer from './components/AppContainer'
import KeyboardManager from './components/KeyboardManager'
import Error from './components/Error'
import Header from './components/Header'
import HeaderMenu from './components/HeaderMenu'
import Footer from './components/Footer'
import AlertLongRequest from './components/AlertLongRequest'
import configureStore from './redux/configureStore'
import withRestriction from './components/RestrictTo/withRestriction'
import withRememberType from './components/RememberType/withRememberType'
import { RouteMap as Routes, routeBuilder } from './utils/routing'
import i18n from './utils/i18n'

import { actions as midActions, watcher as midWatcher } from '@cs/mid-react-saga'
import { all, fork } from 'redux-saga/effects'

import './App.scss'

function * rootWatcher () {
  yield all([
    fork(midWatcher)
  ])
}

class App extends Component {
  constructor (props) {
    super(props)

    this.store = this.props.store || configureStore({}, props.mid)
    this.store.dispatch(midActions.login())
    this.store.runSaga(rootWatcher)
  }

  render () {
    // const { mid } = this.store.getState().mid
    const adminOptions = { userRole: 'admin', action: 'redirect' }
    const presenterOptions = { userRole: 'presenter', action: 'redirect' }

    const questionTypes = this.store.getState().profile.questionTypes
    const defaultType = questionTypes[0]

    const baseUrl = '/'

    return (
      <StrictMode>
        <I18nextProvider i18n={i18n}>
          <scenes.Layout store={this.store}>
            <KeyboardManager>
              <AppContainer>
                <BrowserRouter basename={baseUrl}>
                  <div className='AppRoot App-relativeContainer'>
                    <Header />
                    <HeaderMenu />
                    <Error />
                    <AlertLongRequest />
                    <Switch>
                      <Route exact path={Routes.teamPath} component={scenes.ResultsScene} />
                      <Route exact path={Routes.teamDetailsPath} component={withRememberType(scenes.ResultsDetailsScene)} />

                      <Route exact path={Routes.heatmapBasePath}>
                        <Redirect to={routeBuilder('heatmap', { type: defaultType.key })} />
                      </Route>
                      <Route exact path={Routes.heatmapPath} component={withRememberType(scenes.PatternsScene)} />

                      <Route exact path={Routes.comparisonPath} component={scenes.ComparisonScene} />
                      <Route path={Routes.comparisonBasePath} component={scenes.ComparisonScene} />

                      <Route exact path={Routes.listingPath} component={withRememberType(scenes.TeamsListScene)} />

                      <Route path={Routes.respondentsPath} component={scenes.RespondentsScene} />
                      <Route path={Routes.powerpointPath} component={withRestriction(scenes.PowerpointScene, presenterOptions)} />

                      <Route exact path={Routes.adminPath} component={withRestriction(scenes.Admin.Hoem, adminOptions)} />
                      <Route path={Routes.adminNewSurveyPath} component={withRestriction(scenes.Admin.SurveyForm, adminOptions)} />
                      <Route exact path={Routes.adminEditSurveyPath} component={withRestriction(scenes.Admin.SurveyForm, adminOptions)} />
                      <Route path={Routes.adminNewImportPath} component={withRestriction(scenes.Admin.ImportForm, adminOptions)} />
                      <Route path={Routes.adminSurveysPath} component={withRestriction(scenes.Admin.SurveyList, adminOptions)} />

                      <Route>
                        <Redirect to={routeBuilder.root()} />
                      </Route>
                    </Switch>
                    <Footer />
                  </div>
                </BrowserRouter>
              </AppContainer>
            </KeyboardManager>
          </scenes.Layout>
        </I18nextProvider>
      </StrictMode>
    )
  }
}

export default App
