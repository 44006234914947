import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Radio from '../Radio'
import './style.scss'

class RadioSelect extends Component {
  renderOption (option) {
    let classNames
    if (option.disabled) {
      classNames = 'radio-disabled'
    } else {
      classNames = 'pointer'
    }

    return (
      <li key={`radioSelect${option.value}`} className={classNames}>
        <label>
          <Radio
            className='input'
            value={option.value}
            checked={this.props.selected === option.value}
            onChange={e => this.props.onChange(e.target.value)}
            disabled={option.disabled}
          />
          {option.name}
        </label>
      </li>
    )
  }

  render () {
    const { options, className } = this.props
    const classNames = ['RadioSelect']
    if (className) {
      classNames.push(className)
    }

    return (
      <div className={classNames.join(' ')}>
        <ul>
          {options.map(it => this.renderOption(it))}
        </ul>
      </div>
    )
  }
}

RadioSelect.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default RadioSelect
