import { createSelector } from 'reselect'
import _ from 'lodash'
import { MIN_THRESHOLD } from './Component'

const questions = (state, props) => {
  const { surveyId } = props.match.params
  return state.admin.questions[surveyId] || []
}

const teams = (state, props) => {
  const { surveyId } = props.match.params
  return state.admin.teams[surveyId] || []
}

const parentMap = (state, props) => {
  const { surveyId } = props.match.params
  return state.admin.parentMap[surveyId] || {}
}

export const groups2 = createSelector(
  [questions],
  questions => {
    const results = {}
    questions.forEach((question) => {
      const key = [question.type, question.grouping3]
      const current = _.get(results, key, [])
      current.push(question.grouping1 || question.grouping2)
      _.set(results, key, _.uniq(current))
    })

    return results
  }
)

export const groups3 = createSelector(
  [questions],
  questions => {
    return _.chain(questions)
      .groupBy(it => it.type)
      .mapValues(group => _.chain(group).map(it => it.grouping3).uniq().value())
      .value()
  }
)

export const groupQuestions = createSelector(
  [questions, teams, parentMap],
  (questions, teams, parentMap) => {
    if (!teams || !parentMap || !questions) {
      return {}
    }

    const results = {}
    questions.forEach(row => {
      const rowPath = row.grouping1 ? [row.teamId, row.type, row.grouping3, row.grouping1] : [row.teamId, row.type, row.grouping3, row.grouping2]
      _.set(results, rowPath, {
        responses: row.responses,
        respondents: row.respondents
      })

      let parentId = parentMap[row.teamId]
      while (parentId) {
        const path = row.grouping1 ? [parentId, row.type, row.grouping3, row.grouping2, row.grouping1] : [parentId, row.type, row.grouping3, row.grouping2]
        const current = _.get(results, path, {
          responses: 0,
          respondents: 0
        })

        _.set(results, path, {
          responses: current.responses + row.responses,
          respondents: current.respondents + row.respondents
        })

        parentId = parentMap[parentId]
      }
    })

    return results
  }
)

export const warnings = createSelector(
  [groupQuestions, teams],
  (questions, teams) => {
    const warnings = {}
    _.each(teams, team => {
      if (!team.teams) {
        return
      }

      _.each(['capability', 'outcome'], type => {
        questions[team.id] && _.each(questions[team.id][type], (group, key) => {
          _.each(group, (g, idx) => {
            _.each(g, (l, idx2) => {
              const needWarning = !!_.some(team.teams, t => {
                const responses = _.get(questions, [t.id, type, key, idx, idx2, 'responses'], 0)
                return responses < MIN_THRESHOLD
              })

              if (needWarning) {
                _.set(warnings, [team.id, type, key, idx, idx2], true)
              }
            })
          })
        })
      })
    })

    return warnings
  }
)
