
import _ from 'lodash'
import { createSelector } from 'reselect'

import { calculateProgress } from '../../utils/calculations'
import { isNotAsked } from '../../utils'
import I18n from '../../utils/i18n'

const MIN_SCORE = -1000
const ACTUAL_SORTING = {
  name: item => item.name.toLowerCase(),
  score: item => _.get(item, 'score.value', MIN_SCORE),
  progress: item => _.get(item, 'progressScore.value', MIN_SCORE),
  parent1: item => _.get(item, 'parents.1', '').toLowerCase(),
  parent2: item => _.get(item, 'parents', []).slice(0, 3).reverse().join(' ').toLowerCase()
}

function isCurrent (data, type, level, value) {
  if (!data) {
    return false
  }

  if (value) {
    return data.type === type && data.level === level && data.value === value
  }

  return data.type === type && data.level === level && !data.value
}

function getTeams (state, type, level, value, grouped) {
  if (!isCurrent(state.scores.current, type, level, value)) {
    return null
  }

  return _.get(state, ['scores', 'current', 'teams', grouped || 0])
}

function getHistory (state, type, level, value) {
  if (!isCurrent(state.scores.history, type, level, value)) {
    return null
  }

  return state.scores.history.teams
}

function getSortOrder (state) {
  return state.scores.order
}

function getSortDirection (state) {
  return state.scores.sortDir
}

function getThresholds (state, type) {
  return _.get(state, ['profile', 'deltaThresholds', type])
}

function getHighlightProgress (state) {
  return state.navigation.highlightProgress
}

function notAsked (team) {
  return !isNotAsked(team.score)
}

const finder = {
  total: (type, value) => type,
  quadrant: (type, value) => _.find(type.groups, { id: value }),
  sub: (type, value) => _(type.groups).map('groups').flatten().find({ id: value }),
  lever: (type, value) => _(type.groups).map('groups').flatten().find({ id: value }),
  question: (type, value) => _(type.groups).map('groups').flatten().map('questions').flatten().find({ id: value })
}

function getBenchmark (state, type, level, value) {
  if (!state.profile.benchmark || !state.profile.benchmark.length) {
    return null
  }
  return state.profile.benchmark.map((b, i) => (
    {
      ...finder[level](state.profile.benchmark[i].questions[type], value),
      name: b.name
    }))
}

export const scoresSelector = createSelector(
  getTeams,
  getHistory,
  getSortOrder,
  getSortDirection,
  getThresholds,
  getHighlightProgress,
  getBenchmark,
  (teams, history, sortOrder, sortDir, thresholds, highlightProgress, benchmark) => {
    if (!teams) {
      return teams
    }

    if (sortOrder === 'score' && highlightProgress && history) {
      sortOrder = 'progress'
    }

    if (!history) {
      history = {}
    }

    const sortParams = [
      ['shadow', ACTUAL_SORTING[sortOrder], 'benchmark', ACTUAL_SORTING.name],
      ['asc', sortDir, 'asc', 'asc']
    ]

    function withBenchmark (teams) {
      if (!benchmark || sortOrder === 'progress' || highlightProgress || teams.length === 0) {
        return teams
      }
      const benchmarks = benchmark.map((b, i) => ({
        id: `benchmark-${i}`,
        name: b.name || I18n.t('shared.benchmark'),
        score: b.score,
        shadow: false,
        benchmark: true,
        parents: []
      }
      ))
      return [...teams, ...benchmarks]
    }

    function mapTeam (team) {
      const mapped = {
        id: team.id,
        name: team.name,
        parents: team.parents,
        shadow: team.shadow,
        score: team.score,
        benchmark: !!team.benchmark
      }

      if ('level' in team) {
        mapped.level = team.level
      }

      if ('teams' in team) {
        mapped.teams = _.chain(withBenchmark(team.teams))
          .filter(notAsked)
          .map(mapTeam)
          .orderBy(...sortParams)
          .value()
      }

      const relatedTeamScore = history[team.id]
      if (relatedTeamScore) {
        mapped.historyScore = relatedTeamScore
        mapped.progressScore = calculateProgress(team.score, relatedTeamScore, thresholds)
      }

      return mapped
    }

    return _.chain(teams)
      .filter(notAsked)
      .map(mapTeam)
      .orderBy(...sortParams)
      .value()
  }
)
