
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Component from './Component'

function mapStateToProps (state) {
  return {
    metadata: state.metadata.selectedMetadata,
    respondents: state.profile.respondents,
    surveys: state.profile.selectedSurveys,
    availableSurveys: state.profile.surveys,
    isDataAvailable: state.metadata.hierarchies && state.metadata.hierarchies.length > 0,
    isAdmin: state.profile.isAdmin
  }
}

export default withRouter(connect(mapStateToProps)(Component))
