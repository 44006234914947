import * as types from './types'

export function toggleLeftPopup () {
  return { type: types.TOGGLE_LEFT_POPUP }
}

export function toggleRightPopup () {
  return { type: types.TOGGLE_RIGHT_POPUP }
}

export function toggleBothPopup () {
  return { type: types.TOGGLE_BOTH_POPUP }
}
