import React, { Component } from 'react'
import _ from 'lodash'
import QuestionCard from '../QuestionCard'
import { formatScore } from '../../../../utils/index'
import './style.scss'

const MIN_SCORE = -1000

function sortScore (item) {
  return _.get(item, 'score.value', MIN_SCORE)
}

function sortProgress (item) {
  return _.get(item, 'progressScore.value', MIN_SCORE)
}

export default class QuestionsList extends Component {
  onSelect (question) {
    this.props.toggleQuestion(question)
  }

  isSelected (question) {
    return _.some(this.props.selected, { id: question.id })
  }

  isLoading () {
    return !this.props.questions
  }

  renderQuestion (question, key) {
    const { totals, hasGrouping1 } = this.props
    const group = _.find(totals.groups, { name: question.grouping3 }) || {}
    const subgroup = _.find(group.groups, { name: question.grouping2 }) || {}

    const scores = {
      grouping3: formatScore(group.score),
      grouping1: _.chain(subgroup.groups).find({ name: question.grouping1 }).get(['score', 'value'], 'N/A').value()
    }
    if (group.isTQ) {
      scores.grouping2 = _.chain(group.groups).find({ name: question.grouping2 }).get(['score', 'value'], 'N/A').value()
    }

    return (
      <QuestionCard
        key={`question_${question.id}`}
        question={question}
        isSelected={this.isSelected(question)}
        onSelect={() => this.onSelect(question)}
        scores={scores}
        hasGrouping1={hasGrouping1}
        highlightProgress={this.props.highlightProgress}
        highlightedLever={this.props.highlightedLever}
        team={this.props.team}
      />
    )
  }

  sortOptions () {
    const [value, direction] = String(this.props.sort).split('-')

    return [
      [value === 'progress' ? sortProgress : sortScore],
      [direction || 'desc']
    ]
  }

  questions () {
    let res = _.chain(this.props.questions)
    res = res.orderBy(...this.sortOptions())

    const filter = this.props.filter
    if (filter && filter.name) {
      const [parent, child] = filter.name.split('/')
      if (child) {
        res = res.filter({ grouping3: parent, grouping2: child })
      } else {
        res = res.filter({ grouping3: parent })
      }
    }
    return res.value()
  }

  render () {
    if (this.isLoading()) {
      return null
    }

    return (
      <div className='QuestionList'>
        {this.questions().map(question => this.renderQuestion(question))}
      </div>
    )
  }
}
