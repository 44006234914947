import axios from 'axios'
import uuid from 'uuid'
import { actions as midActions } from '@cs/mid-react-saga'
import * as actions from '../services/errors/actions'
import nprogress from 'nprogress'
import _ from 'lodash'
import 'nprogress/nprogress.css'
import { support } from '@mid/sdk'
import config from '../config'

const LONG_REQUEST_WARNING = 5000

let requestCounter = {
  requests: [],
  count: 0,
  increment: (id) => {
    requestCounter.count++
    requestCounter.requests.push(id)
    if (requestCounter.count === 1) {
      nprogress.start()
    }
  },
  decrement: () => {
    requestCounter.count--
    if (requestCounter.count <= 0) {
      nprogress.done()
      nprogress.remove()
      requestCounter.count = 0
      requestCounter.requests = []
    }
  }
}

nprogress.configure({
  showSpinner: false,
  trickle: false
})

function updateDownloadProgress (e) {
  const max = _.uniq(requestCounter.requests).length
  nprogress.set(max - requestCounter.count / max)
}

function updateUploadProgress (e) {
  if (requestCounter.count === 1) {
    nprogress.set(e.loaded / e.total)
  } else {
    updateDownloadProgress(e)
  }
}

function handleTimeout (request, dispatch) {
  if (request.isCompleted === false) {
    dispatch(actions.longRequest(request.requestId))
  }
}

function dismissLongRequest (longRequests, requestId, dispatch) {
  if ((longRequests || []).indexOf(requestId) >= 0) {
    dispatch(actions.dismissLongRequest(requestId))
  }
}

function showLoading (action) {
  return !action.payload.request.skipLoading
}

function injectSurveyInUrl (url, state) {
  return url
    .replace(/\{latest\}/g, state.profile.selectedSurveyIds[0] || 'latest')
    .replace(/\{previous\}/g, state.profile.selectedSurveyIds[1] || 'previous')
}

function injectSurvey ({ getState }, req) {
  req.url = injectSurveyInUrl(req.url, getState())
  return req
}

function injectMid ({ getState }, req) {
  const mid = getState().mid

  req.headers['x-tenant'] = support.getTenant().tenantId
  req.headers['authorization'] = `bearer ${mid.mid.core.accessToken()}`

  return req
}

const requestInterceptor = ({ getState, dispatch, getSourceAction }, req) => {
  req.requestId = uuid()
  req.isCompleted = false
  setTimeout(handleTimeout.bind(null, req, dispatch), LONG_REQUEST_WARNING)

  if (showLoading(getSourceAction(req))) {
    req.onDownloadProgress = updateDownloadProgress
    req.onUploadProgress = updateUploadProgress
    requestCounter.increment(req.requestId)
  }

  return req
}

const successInterceptor = ({ getState, dispatch, getSourceAction }, resp) => {
  const action = getSourceAction(resp.config)
  const state = getState()
  const previousStatus = state.errors.status

  if (showLoading(action)) {
    requestCounter.decrement()
  }

  resp.config.isCompleted = true
  dismissLongRequest(state.errors.longRequests, resp.config.requestId, dispatch)

  if (resp.status === 204) {
    if (!action.payload.request.ignoreNoSurvey && previousStatus !== 101) {
      dispatch(actions.noSurvey())
    }

    return Promise.reject(new Error('No survey'))
  }

  return resp
}

const errorInterceptor = ({ getState, dispatch, getSourceAction }, err) => {
  const action = getSourceAction(err.config)
  const state = getState()

  if (showLoading(action)) {
    requestCounter.decrement()
  }

  err.config.isCompleted = true
  dismissLongRequest(state.errors.longRequests, err.config.requestId, dispatch)

  const status = (err.response || {}).status
  if (status === 404) {
    if (!action.payload.request.ignore404) {
      dispatch(actions.error(err, 404, action))
    }
  } else if (err.code === 'ECONNABORTED' || status === 408 || status === 504) {
    dispatch(actions.error(err, 408, action))
  } else if (status >= 500) {
    dispatch(actions.error(err, 500, action))
  } else if (status === 403) {
    dispatch(actions.error(err, 403, action))
  } else if (status === 401) {
    return dispatch(midActions.logout()).then(() => window.location.reload())
  }

  return Promise.reject(err)
}

const baseURL = config.apiUrl
export default function axiosConfig () {
  const client = axios.create({
    baseURL: baseURL,
    responseType: 'json'
  })

  const config = {
    interceptors: {
      request: [
        injectSurvey,
        requestInterceptor,
        injectMid
      ],
      response: [
        {
          success: successInterceptor,
          error: errorInterceptor
        }
      ]
    }
  }

  return [ client, config ]
}
