import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

import ComparisonScene from './Component'
import * as actions from '../../services/metrics/actions'
import * as selectors from './selectors'
import { getSelectedAxisData, parseParams } from './utils'

function mapStateToProps (state, ownProps) {
  const flatTeams = state.teams.flatTeams
  const totals = state.metrics.totals
  const metadata = state.metadata.selectedMetadata
  const grouped = state.metrics.grouped
  const params = parseParams(state, ownProps.match.params)
  const team = _.find(flatTeams, { id: params.teamId })

  if (!flatTeams || !totals) {
    return {
      team,
      groups: state.profile.groups,
      totals: [],
      disabledTeams: {},
      thresholds: state.profile.thresholds,
      metadata,
      benchmark: state.profile.benchmark,
      params
    }
  }

  const args = [
    totals,
    params,
    metadata,
    flatTeams,
    grouped,
    team
  ]

  const filteredTotals = selectors.filteredTotalsSelector(...args)
  const orgTotals = selectors.organisationTotalsSelector(totals, params, metadata)
  const disabledTeams = selectors.disabledTeamsSelector(totals, params, metadata)

  return {
    groups: state.profile.groups,
    team,
    flatTeams,
    grouped,
    metadata,
    benchmark: state.profile.benchmark,

    params,
    ...getSelectedAxisData(state, params),
    totals: filteredTotals,
    orgTotals,
    sidebarTeams: selectors.sidebarTeams(...args),
    disabledTeams,
    labels: selectors.labels(filteredTotals)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    load: (...args) => dispatch(actions.totals(...args)),
    group: (...args) => dispatch(actions.group(...args))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComparisonScene))
