import React, { Component } from 'react'

import { nextAnimationFrame } from '../../utils/dom'

import './style.scss'

const HEADER_HEIGHT = 50

export default class HeaderMenu extends Component {
  constructor (props) {
    super(props)

    this.state = {
      sticky: false
    }

    this.onScroll = this.onScroll.bind(this)
    this.checkScroll = this.checkScroll.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll () {
    this.scrollLocation = window.scrollY || window.scrollTop || window.pageYOffset

    if (!this.scrollTimer) {
      nextAnimationFrame(this.checkScroll)
      this.scrollTimer = true
    }
  }

  checkScroll () {
    this.scrollTimer = false
    this.setState({
      sticky: this.scrollLocation > HEADER_HEIGHT
    })
  }

  render () {
    const classNames = ['HeaderMenu']
    if (this.state.sticky) {
      classNames.push('sticky')
    }

    return (
      <div className='HeaderMenu--wrapper'>
        <div className={classNames.join(' ')} id='HeaderMenu'>
          <div id='HeaderMenu--LeftIconPortal' className='HeaderMenu--Icon' />
          <div id='HeaderMenu--ContentPortalLeft' className='HeaderMenu--Content' />
          <div id='HeaderMenu--ContentPortalRight' className='HeaderMenu--Content' />
          <div id='HeaderMenu--RightIconPortal' className='HeaderMenu--Icon' />
        </div>
      </div>
    )
  }
}
