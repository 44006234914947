
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as actions from '../../../../services/export/actions'
import Component from './Component'

function mapStateToProps (state) {
  return {
    excelLoading: state.export.excel.loading,
    metadata: state.metadata.selectedMetadata,
    currentSurvey: state.profile.selectedSurveyIds[0]
  }
}

function mapDispatchToProps (dispatch) {
  return {
    download: (metadata) => dispatch(actions.generateExcel(metadata))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))
