import React from 'react'
import { TRIANGLE } from '../../../../utils/icons'
import './style.scss'

const ChangeSymbol = props => {
  const { score, hist, className } = props

  if (!score || !hist || score.value === hist.value) {
    return null
  }

  const classNames = ['ChangeSymbol']
  if (className) {
    classNames.push(className)
  }

  if (score.value >= hist.value) {
    classNames.push('rotate')
  }

  return <div className={classNames.join(' ')}>{TRIANGLE}</div>
}

export default ChangeSymbol
