
import React from 'react'
import { useTranslation } from 'react-i18next'
import Toggle from '../Toggle'

import './style.scss'

export default function HighlightProgressToggle (props) {
  function change (newState) {
    if (!props.onChange) {
      return
    }

    props.onChange(newState)
  }

  const { t } = useTranslation()

  return (
    <div className='HighlightProgress'>
      <h3>{t('shared.highlight_progress.title')}</h3>
      <div className='HighlightProgress--toggle'>
        <span onClick={e => change(!props.value)}>{t('shared.highlight_progress.label')}</span>
        <Toggle
          checked={props.value}
          onChange={e => change(e.target.checked)}
        />
      </div>
    </div>)
}
