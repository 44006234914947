import { connect } from 'react-redux'
import Container from './Container'

import * as actions from '../../services/profile/actions'

function mapStateToProps (state, ownProps) {
  return {
    loading: state.metrics.loading,
    error: state.metrics.error,
    calculation: state.profile.calculation,
    format: state.profile.format,
    selectedSurveys: state.profile.selectedSurveys,
    limits: state.profile.limits,
    comparisonType: state.profile.comparisonType,
    questionTypes: state.profile.questionTypes,
    benchmark: state.profile.benchmark
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setComparisonType: (value) => dispatch(actions.setComparisonType(value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
