
import React from 'react'
import { useTranslation } from 'react-i18next'

import './style.scss'

export default function RespondentsLegend (props) {
  const { t } = useTranslation()

  return (
    <div className='RespondentsLegend'>
      <h3>{t('respondents.legend.title')}</h3>
      <ul>
        <li>
          <div className='bubbleLow'>0</div>
          <span>{t('respondents.legend.low_respondents')}</span>
        </li>
        <li>
          <div className='bubbleWarning'>14</div>
          <span>{t('respondents.legend.child_low_respondents')}</span>
        </li>
      </ul>
    </div>)
}
