import { connect } from 'react-redux'
import Component from './Component'
import * as actions from '../../../services/surveys/actions'

function mapStateToProps (state) {
  return {
    surveys: state.surveys.surveys || [],
    loading: state.surveys.loading,
    metadata: state.metadata.selectedMetadata,
    selectedSurveys: state.profile.selectedSurveys
  }
}

function mapDispatchToProps (dispatch) {
  return {
    retrieve: (...args) => dispatch(actions.retrieve(...args)),
    activate: (surveyId, id) => dispatch(actions.activate(surveyId, id)),
    remove: (surveyId) => dispatch(actions.remove(surveyId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
