import React, { Component } from 'react'
import _ from 'lodash'

export default class TeamProfileContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      inProgress: false,
      inPrevProgress: false
    }
  }

  componentWillMount () {
    if (!this.props.hasTotals) {
      this.retrieve()
    }

    if (!this.props.hasHistory) {
      this.retrievePrevious()
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.type !== nextProps.type || this.props.team.id !== nextProps.team.id) {
      if (!nextProps.hasTotals) {
        this.retrieve(nextProps, true)
      }

      if (!nextProps.hasHistory) {
        this.retrievePrevious(nextProps, true)
      }
    }
  }

  retrieve (props = this.props, force = false) {
    const { team, type, metadata } = props
    if (team.id && (!this.state.inProgress || force)) {
      this.setState({ inProgress: true })
      this.props.retrieve('latest', metadata, team.id, type.key).then(() => this.setState({ inProgress: false }))
    }
  }

  retrievePrevious (props = this.props, force = false) {
    if (!props.shouldHaveHistory) {
      return
    }

    const { team, type, metadata } = props
    if (team.id && (!this.state.inPrevProgress || force)) {
      this.setState({ inPrevProgress: true })
      this.props.retrieve('previous', metadata, team.id, type.key).then(() => this.setState({ inPrevProgress: false }))
    }
  }

  render () {
    return React.Children.map(this.props.children, child => {
      return React.cloneElement(child, _.omit(this.props, ['children']))
    })
  }
}
