import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

import { SidebarCell } from '../../../../components/SidebarView'
import { routeBuilder } from '../../../../utils/routing'
import { OPEN as OPEN_ICON } from '../../../../utils/icons'
import I18n from '../../../../utils/i18n'

import './style.scss'

export default class TeamHead extends PureComponent {
  teamUrl () {
    const team = this.props.team

    return routeBuilder('teamDetails', {
      type: this.props.type.key,
      teamId: team.id || 'organisation'
    })
  }

  renderLink () {
    const { team } = this.props
    if (/benchmark-/.test(team.id)) {
      return
    }

    return (
      <Link key={`link_${team.id}`} to={this.teamUrl()} onClick={ev => ev.stopPropagation()} className='TeamHead--link'>
        {OPEN_ICON}
      </Link>
    )
  }

  render () {
    const { team, maxLevel, isCollapsed } = this.props
    const hasChildren = !!(team.teams && team.teams.length)
    const classNames = ['TeamHead Heatmap--row']
    if (/benchmark-/.test(team.id)) {
      classNames.push('Heatmap--row--benchmark')
    }

    return (
      <div
        className={classNames.join(' ')}
        data-team-id={team.id || 'organisation'}
        onClick={e => this.props.onClick(e)}
        onMouseEnter={e => this.props.hoverTeam(e.currentTarget, true)}
        onMouseLeave={e => this.props.hoverTeam(e.currentTarget, false)}
      >
        <SidebarCell
          level={(maxLevel - team.level) || 0}
          isRoot={team.id === undefined}
          isChild={!!team.parentId || (team.id && !team.parentId && !hasChildren)}
          isExpand={hasChildren}
          isOpen={!isCollapsed}
          onClick={e => this.props.onClick(e)}
          pin={this.renderLink()}
        >
          <span>{team.name || I18n.t('shared.benchmark')}</span>
        </SidebarCell>
      </div>
    )
  }
}
