
import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

export default class Breadcrumbs extends PureComponent {
  renderItem (t) {
    return <li key={`breadcrumb-${t.id}`}><Link to={t.link}>{t.text}</Link></li>
  }

  render () {
    const className = this.props.className || ''

    return (<ul className={`Breadcrumbs ${className}`}>
      {this.props.path.map(t => this.renderItem(t))}
    </ul>)
  }
}
