
import { connect } from 'react-redux'
import _ from 'lodash'

import Component from './Component'

export function mapStateToProps (state, ownProps) {
  const scores = _.get(state.organisationDetails.data, [ownProps.currentSurvey, ownProps.type])

  return {
    groups: state.profile.groups[ownProps.type],
    scores
  }
}

export default connect(mapStateToProps)(Component)
