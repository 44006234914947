import React from 'react'
import { SidebarCell } from '../../components/SidebarView'

export default props => {
  const { team, nested, pin,
    onMouseOver, onMouseOut, onClick, onExpand,
    isCollapsed, isSelected, isInPath,
    disabled
  } = props

  const hasChildren = team.teams && team.teams.length
  const isHome = !team.id

  return (
    <SidebarCell
      level={nested}
      isRoot={isHome}
      isChild={!!team.parentId || (team.id && !team.parentId && !hasChildren)}
      isExpand={hasChildren}
      isOpen={!isCollapsed}
      isSelected={isSelected}
      isMedium={isInPath}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
      onExpand={onExpand}
      pin={pin || null}
      disabled={disabled}
    >
      <span>{team.symbol ? team.symbol : null} {team.name}</span>
    </SidebarCell>
  )
}
