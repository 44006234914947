// General
import ResultsScene from './Results'
import ResultsDetailsScene from './ResultsDetails'
import PatternsScene from './PatternsScene'
import ComparisonScene from './Comparison'
import TeamsListScene from './TeamsList'
import RespondentsScene from './Respondents'
import PowerpointScene from './Powerpoint'

// Admin
import AdminHome from './Admin/Home'
import AdminSurveyList from './Admin/SurveyList'
import AdminSurveyForm from './Admin/SurveyForm'
import AdminImportForm from './Admin/ImportForm'

// Misc
import Layout from './Layout'

const Admin = {
  Home: AdminHome,
  SurveyList: AdminSurveyList,
  SurveyForm: AdminSurveyForm,
  ImportForm: AdminImportForm
}

export {
  ResultsScene,
  ResultsDetailsScene,
  PatternsScene,
  ComparisonScene,
  TeamsListScene,
  RespondentsScene,
  PowerpointScene,

  Layout,

  Admin
}
