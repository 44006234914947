
import { connect } from 'react-redux'
import _ from 'lodash'

import Component from './Component'

function mapStateToProps (state, ownProps) {
  const type = _.find(state.profile.questionTypes, { key: ownProps.match.params.type }) || state.profile.questionTypes[0]

  const totalColumns = Math.max(..._.map(state.profile.groups, groupsByType => {
    return _(groupsByType).map('groups').map('length').sum()
  }))

  return {
    type,
    questionTypes: state.profile.questionTypes,
    totalColumns
  }
}

export default connect(mapStateToProps)(Component)
