export const RETRIEVE_REQUEST = 'surveys.RETRIEVE_REQUEST'
export const RETRIEVE_SUCCESS = 'surveys.RETRIEVE_SUCCESS'
export const RETRIEVE_FAILURE = 'surveys.RETRIEVE_FAILURE'
export const SAVE_REQUEST = 'surveys.SAVE_REQUEST'
export const SAVE_SUCCESS = 'surveys.SAVE_SUCCESS'
export const SAVE_FAILURE = 'surveys.SAVE_FAILURE'
export const UPLOAD_REQUEST = 'surveys.UPLOAD_REQUEST'
export const UPLOAD_SUCCESS = 'surveys.UPLOAD_SUCCESS'
export const UPLOAD_FAILURE = 'surveys.UPLOAD_FAILURE'
export const ACTIVATE_REQUEST = 'surveys.ACTIVATE_REQUEST'
export const ACTIVATE_SUCCESS = 'surveys.ACTIVATE_SUCCESS'
export const ACTIVATE_FAILURE = 'surveys.ACTIVATE_FAILURE'
export const REMOVE_REQUEST = 'surveys.REMOVE_REQUEST'
export const REMOVE_SUCCESS = 'surveys.REMOVE_SUCCESS'
export const REMOVE_FAILURE = 'surveys.REMOVE_FAILURE'
