import * as types from './types'
import Immutable from 'seamless-immutable'

const initialState = Immutable({
  error: null,
  errorType: null,
  action: null,
  longRequests: []
})

export default function errors (state = initialState, action) {
  switch (action.type) {
    case types.ERROR_REQUEST:
      return state.merge({
        error: action.payload.error,
        status: action.payload.status,
        action: action.payload.action
      })

    case types.ERROR_NO_SURVEY:
      return state.merge({
        error: { message: 'No survey' },
        action: {},
        status: 101
      })

    case types.ERROR_DISMISS:
      return initialState

    case types.ERROR_LONG_REQUEST:
      return state.merge({
        longRequests: state.longRequests.concat([ action.payload.requestId ])
      })

    case types.ERROR_DISMISS_LONG_REQUEST:
      let removeIds = action.payload.requestIds
      return state.merge({
        longRequests: state.longRequests.filter(requestId => removeIds.indexOf(requestId) < 0)
      })

    default:
      return state
  }
}
