import { connect } from 'react-redux'
import Component from './Component'
import * as teamActions from '../../services/teams/actions'
import * as organisationDetailsActions from '../../services/organisation-details/actions'

export function mapStateToProps (state) {
  return {
    metadata: state.metadata.selectedMetadata,
    teams: state.teams.flatTeams || [],
    benchmark: state.profile.benchmark
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    retrieveTeams: (...args) => dispatch(teamActions.retrieve(...args)),
    retrieveDetails: (...args) => dispatch(organisationDetailsActions.retrieve(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
