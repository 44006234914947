import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Component from './Component'
import * as actions from '../../services/errors/actions'

function mapStateToProps (state, ownProps) {
  return {
    action: ownProps.action || state.errors.action,
    error: ownProps.error || state.errors.error,
    status: ownProps.status || state.errors.status
  }
}

function mapDispatchToProps (dispatch) {
  return {
    dismiss: () => dispatch(actions.dismiss()),
    retry: method => dispatch(method)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Component))
