
import React, { Component } from 'react'

import { connect } from 'react-redux'
import * as actions from '../../../../services/app/actions'

import LeftIconPortal from '../LeftIconPortal'
import * as ICONS from '../../../../utils/icons'

import './style.scss'

class SidebarIcon extends Component {
  constructor (props) {
    super(props)

    this.click = this.click.bind(this)
  }

  click (ev) {
    ev.preventDefault()
    ev.stopPropagation()
    this.props.toggle()
  }

  render () {
    const classNames = ['SidebarIcon']
    if (this.props.isOpen) {
      classNames.push('isOpen')
    }

    return (
      <LeftIconPortal>
        <div className={classNames.join(' ')} onClick={this.click}>
          {ICONS.SIDEBAR}
          {ICONS.SIDEBAR_ARROW}
        </div>
      </LeftIconPortal>)
  }
}

function mapStateToProps (state, ownProps) {
  return {
    isOpen: state.app.leftPopupOpen
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggle: () => dispatch(actions.toggleLeftPopup())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarIcon)
