
import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

import { routeBuilder } from '../../utils/routing'

import './style.scss'

import SVG_NOSURVEY from './no-survey.svg'
import SVG_NEWSURVEY from './new-survey.svg'

export default class EmptyScene extends Component {
  constructor (props) {
    super(props)
    this.uploadPath = routeBuilder('adminSurveys')
  }

  renderAdmin () {
    return (
      <Fragment>
        <img src={SVG_NEWSURVEY} alt='New survey' />
        <div className='EmptyScene--heading mt3'>Let the story begin...</div>
        <div className='EmptyScene--subject mt1'>
          <Link to={this.uploadPath}>Upload</Link> your first survey results now to learn more about your teams
        </div>
      </Fragment>
    )
  }

  renderUser () {
    return (
      <Fragment>
        <img src={SVG_NOSURVEY} alt='New survey' />
        <div className='EmptyScene--heading mt3'>There are no results available yet</div>
        <div className='EmptyScene--subject mt1'>Your results will be visible here when the administrator loads them into the system</div>
      </Fragment>
    )
  }

  renderContent () {
    if (this.props.isAdmin) {
      return this.renderAdmin()
    }

    return this.renderUser()
  }

  renderEmpty () {
    return (
      <div className='EmptyScene'>
        { this.renderContent() }
      </div>)
  }

  render () {
    if (this.props.isEmpty) {
      return this.renderEmpty()
    }

    return this.props.children
  }
}
