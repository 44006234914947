
import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'

import './style.scss'

import LOADING_SVG from './loading.svg'
import DISMISS_SVG from './close.svg'

export class AlertLongRequest extends PureComponent {
  constructor (props) {
    super(props)
    this.alertRef = React.createRef()
  }

  dismiss (e) {
    e.preventDefault()
    this.props.dismiss(this.props.requestIds)
  }

  componentDidMount () {
    this.focus()
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.isLongRequest && this.props.isLongRequest) {
      this.focus()
    }
  }

  focus () {
    if (this.alertRef && this.alertRef.current) {
      this.alertRef.current.scrollIntoView()
    }
  }

  render () {
    if (!this.props.isLongRequest) {
      return null
    }

    return (
      <div className='AlertLongRequest--container'>
        <div className='AlertLongRequest' ref={this.alertRef}>
          <img src={LOADING_SVG} alt='Spinner' className='mr1 AlertLongRequest--Spinner' />
          {this.props.t('alerts.long_request')} <img src={DISMISS_SVG} alt='Dismiss' className='right AlertLongRequest--Close' onClick={e => this.dismiss(e)} />
        </div>
      </div>
    )
  }
}

export default withTranslation()(AlertLongRequest)
