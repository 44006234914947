import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logoAgile360 from '../../logo-agile360.svg'
import './style.scss'

export default class Logo extends Component {
  render () {
    return (
      <Link className='Logo' to='/'>
        <img src={logoAgile360} alt='Agile360' />
      </Link>
    )
  }
}
