export const RETRIEVE_REQUEST = 'score.RETRIEVE_REQUEST'
export const RETRIEVE_SUCCESS = 'score.RETRIEVE_SUCCESS'
export const RETRIEVE_FAILURE = 'score.RETRIEVE_FAILURE'

export const RETRIEVE_HISTORY_REQUEST = 'score.RETRIEVE_HISTORY_REQUEST'
export const RETRIEVE_HISTORY_SUCCESS = 'score.RETRIEVE_HISTORY_SUCCESS'
export const RETRIEVE_HISTORY_FAILURE = 'score.RETRIEVE_HISTORY_FAILURE'

export const SORT = 'score.SORT'
export const GROUP = 'score.GROUP'
