
import React from 'react'
import './style.scss'

export function SimpleScaleDefault (props) {
  const { index, level } = props
  let history = null

  const progress = Math.round(level * 100)

  if (props.history) {
    const historyProgress = Math.round(props.history * 100)
    history = <div className='SimpleScale--history' style={{ left: `${historyProgress}%` }} />
  }

  const classes = [
    'SimpleScale',
    `score${index || 'Na'}`
  ]

  if (level > 0.98) {
    classes.push('full')
  }

  if (level < 0.03) {
    classes.push('empty')
  }

  return (
    <div className={classes.join(' ')}>
      <div className='SimpleScale--progress' style={{ width: `${progress}%` }} />
      {history}
    </div>)
}

export function SimpleScaleProgress (props) {
  const { level, progressIndex: index } = props
  let history = null

  const progress = Math.round(level * 100)

  const classes = [
    'SimpleScale',
    'SimpleScale--highlight-progress'
  ]

  if (props.history) {
    const historyProgress = Math.round(props.history * 100)
    const left = Math.min(progress, historyProgress)
    const right = 100 - Math.max(progress, historyProgress)

    if (historyProgress > progress) {
      classes.push('decrease')
    }

    if (Math.abs(progress - historyProgress) <= 3) {
      classes.push('small-change')
    }

    history = <div className={`SimpleScale--change score${index || 'Na'}`} style={{ left: `${left}%`, right: `${right}%` }} />
  }

  return (
    <div className={classes.join(' ')}>
      <div className='SimpleScale--progress' style={{ width: `${progress}%` }} />
      {history}
    </div>)
}

export default function SimpleScale (props) {
  if (props.highlightProgress) {
    return <SimpleScaleProgress {...props} />
  }

  return <SimpleScaleDefault {...props} />
}
