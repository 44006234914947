import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Component from './Component'
import { findRouteMatch } from '../../utils/routing'
import * as actions from '../../services/metadata/actions'

function mapStateToProps (state, ownProps) {
  return {
    defaultHierarchy: state.metadata.defaultHierarchy,
    surveys: state.profile.selectedSurveys,
    selectedMetadata: state.metadata.selectedMetadata,
    hierarchies: state.metadata.hierarchies,
    match: findRouteMatch(ownProps.location.pathname),
    minRespondents: state.metadata.minRespondents
  }
}

function mapDispatchToProps (dispatch) {
  return {
    checkMetadataRespondents: (metadata) => dispatch(actions.checkMetadataRespondents(metadata))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))
