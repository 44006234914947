
import _ from 'lodash'
import { createSelector } from 'reselect'

import I18n from '../../utils/i18n'
import { totalsPath, baseType, isProgress } from '../../utils/type'
import { isNotAsked, isNotAnswered } from '../../utils'
import { isParent, urlBuilder } from './utils'
import { isLeaf } from '../../utils/helpers'

const MAX_LABELS = 200

function surveyIdFromType (type) {
  if (!type) {
    return 'latest'
  }

  if (isProgress(type)) {
    return 'progress'
  }

  return 'latest'
}

export const labels = createSelector(
  totals => totals,
  totals => {
    if (totals && !totals.loading && totals.length > MAX_LABELS) {
      return _.uniqBy(totals, it => [_.get(it, 'x.value'), _.get(it, 'y.value')]).slice(0, MAX_LABELS)
    }

    return totals
  }
)

export const filteredTotalsSelector = createSelector(
  (totals, params, metadata, flatTeams, grouped, team) => ({
    params,
    totals,
    metadata,
    flatTeams,
    grouped,
    team
  }),
  ({ totals, metadata, flatTeams, params, grouped, team }) => {
    const xKey = totalsPath(surveyIdFromType(params.xType), metadata, baseType(params.xType), params.xLevel, params.xId)
    const xScores = totals[xKey] || []

    const yKey = totalsPath(surveyIdFromType(params.yType), metadata, baseType(params.yType), params.yLevel, params.yId)
    const yScores = totals[yKey] || []

    if (xScores.loading || yScores.loading) {
      return { loading: true }
    }

    const results = {}
    function iterate (teams, name) {
      for (const team of teams) {
        _.set(results, [team.id, name], team.score)

        if (team.teams) {
          iterate(team.teams, name)
        }
      }
    }

    iterate(xScores, 'x')
    iterate(yScores, 'y')

    return _.chain(flatTeams)
      .map(team => {
        return {
          ...team,
          ...(results[team.id] || { x: {}, y: {} })
        }
      })
      .filter(t => {
        return t.level === 4 - grouped &&
          isParent(t, team) &&
          t.x &&
          t.x.responses > 0 &&
          t.y &&
          t.y.responses > 0
      })
      .value()
  }
)

export const organisationTotalsSelector = createSelector(
  (totals, params, metadata) => ({
    params,
    totals,
    metadata
  }),
  ({ totals, metadata, params }) => {
    const xKey = totalsPath(surveyIdFromType(params.xType), metadata, baseType(params.xType), params.xLevel || 'total', params.xId)
    const xScores = totals[xKey]

    const yKey = totalsPath(surveyIdFromType(params.yType), metadata, baseType(params.yType), params.yLevel || 'total', params.yId)
    const yScores = totals[yKey]

    const results = {}

    if (xScores && xScores.length) {
      results.x = xScores[0].score
    }

    if (yScores && yScores.length) {
      results.y = yScores[0].score
    }

    return results
  }
)

export const disabledTeamsSelector = createSelector(
  (totals, params, metadata) => ({
    params,
    totals,
    metadata
  }),
  ({ totals, metadata, flatTeams, params }) => {
    const xKey = totalsPath(surveyIdFromType(params.xType), metadata, baseType(params.xType), params.xLevel || 'total', params.xId)
    const xScores = totals[xKey] || []

    const yKey = totalsPath(surveyIdFromType(params.yType), metadata, baseType(params.yType), params.yLevel || 'total', params.yId)
    const yScores = totals[yKey] || []

    if (xScores.loading || yScores.loading) {
      return {}
    }

    const disabled = {}

    function iteratee (teams) {
      for (let team of teams) {
        if (isNotAsked(team.score) || isNotAnswered(team.score)) {
          disabled[team.id] = true
        }

        if (team.teams) {
          iteratee(team.teams)
        }
      }
    }

    iteratee(xScores || [])
    iteratee(yScores || [])

    return disabled
  }
)

function organisationTeam () {
  return {
    id: undefined,
    name: I18n.t('shared.organisation')
  }
}

function resolveUrl (team, params) {
  if (team.url) {
    return { url: team.url }
  }

  if (team.id && isLeaf(team)) {
    return {}
  }

  return {
    url: urlBuilder({
      ...params,
      teamId: team.id
    })
  }
}

export const sidebarTeams = createSelector(
  (totals, params, metadata, flatTeams) => ({
    params,
    flatTeams
  }),
  ({ params, flatTeams }) => {
    return [organisationTeam(), ...flatTeams]
      .map(team => {
        return {
          ...team,
          ...resolveUrl(team, params)
        }
      })
  }
)

export const groupsById = createSelector(
  state => {
    return {
      groups: state.profile.groups,
      questionTypes: state.profile.questionTypes
    }
  },
  ({ groups, questionTypes }) => {
    const results = {}
    const iterate = groups => {
      for (let group of groups) {
        results[group.id] = group

        if (group.groups) {
          iterate(group.groups)
        }
        if (group.questions) {
          iterate(group.questions)
        }
      }
    }

    for (let questionType of questionTypes) {
      iterate(groups[questionType.key])
    }

    return results
  }
)
