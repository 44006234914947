import React from 'react'
import './style.scss'

export default props => {
  const symbol = props.isSuccess ? <span>&#x2714;</span> : <span>&#9888;</span>
  const classNames = [ 'Alert' ]
  if (props.className) {
    classNames.push(props.className)
  }
  if (props.isSuccess) {
    classNames.push('alert-success')
  } else {
    classNames.push('alert-warning')
  }
  return (
    <div className={classNames.join(' ')}>
      {symbol} {props.message}
    </div>
  )
}
