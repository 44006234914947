import _ from 'lodash'

import { routeBuilder } from '../../utils/routing'
import { groupNames } from '../../utils/groups'
import { baseType, isProgress } from '../../utils/type'
import * as selectors from './selectors'

export function urlBuilder (params) {
  return routeBuilder('comparison', params)
}

export function isParent (team, parent) {
  const teamPath = _.get(team, 'path')
  const parentPath = _.get(parent, 'path')
  return _.startsWith(teamPath, parentPath ? `${parentPath}/` : '/')
}

function getLimits (profile, type) {
  const limit = profile.limits[baseType(type)]

  if (!isProgress(type)) {
    return limit
  }

  return [-limit[1], limit[1]]
}

function getThresholds (profile, type) {
  const thresholds = isProgress(type) ? profile.deltaThresholds : profile.thresholds
  return thresholds[baseType(type)]
}

export function parseParams (state, params) {
  const questionTypes = state.profile.questionTypes
  const defaultXType = questionTypes[0]
  const defaultYType = questionTypes[1] || questionTypes[0]

  const xType = params.xType || defaultXType.key
  const yType = params.yType || defaultYType.key

  return {
    teamId: params.teamId,
    xType: xType,
    xLevel: params.xLevel || 'total',
    xId: params.xId || null,
    yType: yType,
    yLevel: params.yLevel || 'total',
    yId: params.yId || null
  }
}

function getBenchmarkValue (benchmark, type, level, value) {
  type = baseType(type)

  if (level === 'quadrant') {
    return _.chain(benchmark[type].groups).find({ id: value }).get('score').value()
  }

  if (level === 'lever') {
    return _.chain(benchmark[type].groups)
      .map('groups')
      .flatten()
      .find({ id: value })
      .get('score')
      .value()
  }

  return benchmark[type].score
}

export function getSelectedAxisData (state, params) {
  const questionTypes = state.profile.questionTypes
  const groupsById = selectors.groupsById(state)

  const data = {
    limits: {
      x: getLimits(state.profile, params.xType),
      y: getLimits(state.profile, params.yType)
    },
    thresholds: {
      x: getThresholds(state.profile, params.xType),
      y: getThresholds(state.profile, params.yType)
    },
    progress: {
      x: isProgress(params.xType),
      y: isProgress(params.yType)
    },
    selected: {
      x: {
        ...groupNames(groupsById[params.xId], params.xType, questionTypes),
        type: params.xType,
        selectedLevel: params.xLevel,
        selectedId: params.xId
      },
      y: {
        ...groupNames(groupsById[params.yId], params.yType, questionTypes),
        type: params.yType,
        selectedLevel: params.yLevel,
        selectedId: params.yId
      }
    }
  }

  if (state.profile.benchmark) {
    data.benchmark =
      state.profile.benchmark.map(b => (
        {
          x: getBenchmarkValue(b.questions, params.xType, params.xLevel, params.xId),
          y: getBenchmarkValue(b.questions, params.yType, params.yLevel, params.yId)
        }))
  }

  return data
}

export function findPin (team, pinnedTeams) {
  if (!pinnedTeams) {
    return
  }

  for (let pin of pinnedTeams) {
    if (pin.teamId === team.id || isParent(team, pin)) {
      return pin
    }
  }
}
