
import React, { Component } from 'react'

import { connect } from 'react-redux'
import * as actions from '../../../../services/app/actions'

import RightIconPortal from '../RightIconPortal'
import * as ICONS from '../../../../utils/icons'

import './style.scss'

class LegendsIcon extends Component {
  constructor (props) {
    super(props)

    this.click = this.click.bind(this)
  }

  click (ev) {
    ev.preventDefault()
    ev.stopPropagation()
    this.props.toggle()
  }

  render () {
    const classNames = ['LegendsIcon']
    if (this.props.isOpen) {
      classNames.push('isOpen')
    }

    return (
      <RightIconPortal>
        <div className={classNames.join(' ')} onClick={this.click}>
          {ICONS.GEAR}
          {ICONS.ARROW_WITH_BORDER}
        </div>
      </RightIconPortal>)
  }
}

function mapStateToProps (state, ownProps) {
  return {
    isOpen: state.app.rightPopupOpen
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggle: () => dispatch(actions.toggleRightPopup())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LegendsIcon)
