
import React from 'react'
import { Link } from 'react-router-dom'

import './style.scss'

function linkId (link, i) {
  const id = link.id || link.href || i

  return `nav-link-${id}`
}

function renderLink (link, i) {
  if (link.selected) {
    return <span key={linkId(link, i)} className='selected'>{link.icon}{link.text}</span>
  }

  if (!link.href && link.onClick) {
    return <button key={linkId(link, i)} onClick={link.onClick}>{link.icon}{link.text}</button>
  }

  return <Link key={linkId(link, i)} to={link.href}>{link.icon}{link.text}</Link>
}

export default function Nav (props) {
  if (!props.links || props.links.length === 1) {
    return null
  }

  return (
    <nav className='Nav'>
      {props.links.map(renderLink)}
    </nav>
  )
}
