import React from 'react'
import './style.scss'

export default function delayUnmount (Component) {
  return class DelayedAnimation extends React.PureComponent {
    constructor (props) {
      super(props)
      this.state = {
        shouldRender: this.props.isMounted
      }
    }

    componentDidUpdate (prevProps) {
      if (prevProps.isMounted && !this.props.isMounted) {
        this.timer = setTimeout(
          () => this.setState({ shouldRender: false }),
          this.props.delayTime
        )
      } else if (!prevProps.isMounted && this.props.isMounted) {
        this.timer = setTimeout(
          () => this.setState({ shouldRender: true }),
          this.props.delayTime
        )
      }
    }

    componentWillUnmount () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    }

    render () {
      const { isMounted, className } = this.props
      const { shouldRender } = this.state

      const classNames = ['Animation-height']

      if (className) {
        classNames.push(className)
      }

      if (!isMounted && shouldRender) {
        classNames.push('Animation-height-out')
      }

      if (isMounted && shouldRender) {
        classNames.push('Animation-opacity-in')
      }

      return isMounted || shouldRender ? <div className={classNames.join(' ')}>
        {isMounted && shouldRender ? <Component {...this.props} /> : null}
      </div> : null
    }
  }
}
