import React from 'react'

export const OPEN = <svg className='ico-open' width='11' height='13' xmlns='http://www.w3.org/2000/svg'><path d='M9.08 3.936l-4.05 4.05-1.414-1.414 4.067-4.066-3.17.012L4.435.52h6.524v6.524l-1.998-.076.119-3.032zM7.923 8.669h2v3.713H0V4h2v6.382h5.922V8.669z' fill='#0A1EAA' /></svg>
export const CLOSE = <svg xmlns='http://www.w3.org/2000/svg' width='13' height='14' viewBox='0 0 25 26'><use xlinkHref='#path0_fill' transform='rotate(45 .014 2.172)' fill='#333' fillOpacity='.3' /><use xlinkHref='#path0_fill' transform='rotate(135 12.015 6.172)' fill='#333' fillOpacity='.3' /><defs><path id='path0_fill' d='M0 0h33v2H0V0z' /></defs></svg>
export const GEAR = <svg xmlns='http://www.w3.org/2000/svg' width='17' height='17'><path d='M8.5 10.6c1.1 0 2.1-1 2.1-2.1 0-1.2-1-2.1-2.1-2.1-1.2 0-2.1.9-2.1 2.1 0 1.1.9 2.1 2.1 2.1zm-6.3-3h1.4c.2 0 .3-.1.4-.3l.4-1.4c.1-.1.1-.3-.1-.4l-1-1 1.2-1.2 1 1c.1.2.3.2.4.1L7.3 4c.2-.1.3-.2.3-.4V2.2h1.8v1.4c0 .2.1.3.3.4l1.3.4c.2.1.3.1.5-.1l1-1 1.1 1.2-1 1c-.1.1-.2.3-.1.4l.4 1.4c.1.2.3.3.5.3h1.3v1.8h-1.3c-.2 0-.4.1-.5.3l-.4 1.3c-.1.2 0 .3.1.5l1 1-1.1 1.1-1-1c-.2-.1-.3-.2-.5-.1l-1.3.4c-.2.1-.3.3-.3.5v1.3H7.6v-1.3c0-.2-.1-.4-.3-.5l-1.4-.4c-.1-.1-.3 0-.4.1l-1 1-1.2-1.1 1-1c.2-.2.2-.3.1-.5L4 9.7c-.1-.2-.2-.3-.4-.3H2.2V7.6zm.1 3l.3.9-1.4 1.4c-.2.2-.2.5 0 .7l2.1 2.1c.2.2.6.2.8 0l1.3-1.3 1 .3v1.7c0 .3.2.5.5.5h3.2c.3 0 .5-.2.5-.5v-1.7l.9-.3 1.4 1.3c.2.2.5.2.7 0l2.1-2.1c.2-.2.2-.5 0-.7l-1.3-1.4.3-.9h1.7c.3 0 .5-.2.5-.5V6.9c0-.3-.2-.5-.5-.5h-1.7l-.3-1 1.3-1.3c.2-.2.2-.6 0-.8l-2.1-2.1c-.2-.2-.5-.2-.7 0l-1.4 1.4-.9-.3V.5c0-.3-.2-.5-.5-.5H6.9c-.3 0-.5.2-.5.5v1.8l-1 .3-1.3-1.4c-.2-.2-.6-.2-.8 0L1.2 3.3c-.2.2-.2.6 0 .8l1.4 1.3-.3 1H.5c-.3 0-.5.2-.5.5v3.2c0 .3.2.5.5.5h1.8z' /></svg>
export const ARROW_WITH_BORDER = <svg xmlns='http://www.w3.org/2000/svg' width='7' height='14'><g strokeLinecap='round'><path d='M1 11V3l4 3.79z' /><path stroke='#FFF' d='M.5 12.25V1.84l5.21 4.93L.5 12.25z' /></g></svg>
export const TICK_OFF = <svg width='22' height='22' xmlns='http://www.w3.org/2000/svg'><path d='M2 2v18h18V2H2zM0 0h22v22H0V0z' fill='#EEF1F4' fillRule='nonzero' /></svg>
export const TICK_ON = <svg width='22' height='22' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h22v22H0V0zm16.299 6.287l-7.429 7.31-3.169-3.118A1 1 0 0 0 4.3 11.905l3.87 3.808a1 1 0 0 0 1.402 0l8.13-8A1 1 0 0 0 16.3 6.287z' fill='#0D1BA8' fillRule='evenodd' /></svg>
export const TICK_SEMI = <svg width='22' height='22' xmlns='http://www.w3.org/2000/svg'><path fill='#0A1EAA' d='M0 0h22v22H0V2h2v8h18v2H2v8h18V2H0z' fillRule='evenodd' /></svg>
export const CHILD = <svg key='ico-child' className='ico-child' width='7' height='12' xmlns='http://www.w3.org/2000/svg'><g fillRule='nonzero'><circle cx='1' cy='11' r='1' /><circle cx='1' cy='6' r='1' /><circle cx='1' cy='1' r='1' /><circle cx='6' cy='11' r='1' /></g></svg>
export const CHILD_DOT = <svg key='ico-child-dot' className='ico-child-dot' width='14' height='14' xmlns='http://www.w3.org/2000/svg'><path d='M11 14a3 3 0 1 1 0-6 3 3 0 0 1 0 6zM0 0h2v2H0V0zm0 5h2v2H0V5zm0 5h2v2H0v-2zm5 0h2v2H5v-2z' /></svg>
export const CHILD_DOT_ONLY = <svg key='ico-child-dot-only' className='ico-child-dot-only' width='6' height='6' xmlns='http://www.w3.org/2000/svg'><circle cx='3' cy='3' r='3' /></svg>
export const EXPAND = <svg key='ico-expand' className='ico-back' xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 10 15'><g strokeWidth='1' fill='none' fillRule='evenodd' transform='translate(-16.000000, -488.000000)'><polyline strokeWidth='2' transform='translate(21.495633, 495.629533) rotate(-270.000000) translate(-21.495633, -495.629533)' points='14.87 492.13 21.5 499.13 28.13 492.13' /></g></svg>
export const HOME = <svg key='ico-home' className='ico-home' xmlns='http://www.w3.org/2000/svg' width='10' height='13' viewBox='0 0 10 13'><g strokeWidth='1' fill='none' transform='translate(-17.000000, -434.000000)'><polygon strokeWidth='2' points='18 446 18 439.95 21.95 436 26 440.05 26 446' /></g></svg>
export const BACK = <svg key='ico-back' className='ico-back' xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 10 15'><g strokeWidth='1' fill='none' fillRule='evenodd' transform='translate(-16.000000, -488.000000)'><polyline strokeWidth='2' transform='translate(21.495633, 495.629533) rotate(-270.000000) translate(-21.495633, -495.629533)' points='14.87 492.13 21.5 499.13 28.13 492.13' /></g></svg>
export const PARENT = <svg key='ico-parent' className='ico-parent' xmlns='http://www.w3.org/2000/svg' width='10' height='15' viewBox='0 0 10 15'><g strokeWidth='1' fill='none' fillRule='evenodd' transform='translate(-16.000000, -488.000000)'><polyline strokeWidth='2' transform='translate(21.495633, 495.629533) rotate(-270.000000) translate(-21.495633, -495.629533)' points='14.87 492.13 21.5 499.13 28.13 492.13' /></g></svg>
export const SORT = <svg width='19' height='13' xmlns='http://www.w3.org/2000/svg'><path d='M.138 2.234v-2h18v2h-18zm0 5v-2h12v2h-12zm0 5v-2h6v2h-6z' /></svg>
export const CHANGE = <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 11'><path d='M0 0h11L5 11 0 0z' /></svg>
export const SIDEBAR = <svg xmlns='http://www.w3.org/2000/svg' width='16' height='12'><path d='M16 11c0-.55-.45-1-1-1H1c-.55 0-1 .45-1 1s.45 1 1 1h14c.55 0 1-.45 1-1zm-3-5c0-.55-.45-1-1-1H1c-.55 0-1 .45-1 1s.45 1 1 1h11c.55 0 1-.45 1-1zm0-5c0-.55-.45-1-1-1H1C.45 0 0 .45 0 1s.45 1 1 1h11c.55 0 1-.45 1-1z' /></svg>
export const SIDEBAR_ARROW = <svg xmlns='http://www.w3.org/2000/svg' width='4' height='8'><path d='M0 0l4 4-4 4z' /></svg>
export const SIDEBAR_SETTINGS = <svg width='21' height='20' xmlns='http://www.w3.org/2000/svg'><path d='M19.337 3.999a3.524 3.524 0 0 0-.245-.606l1.188-1.187L18.864.791l-1.165 1.166a3.43 3.43 0 0 0-.697-.294V0h-2v1.662a3.331 3.331 0 0 0-.651.27L13.209.792l-1.415 1.415 1.141 1.141a3.222 3.222 0 0 0-.273.652H11v2h1.662c.072.246.172.479.295.698l-1.165 1.163 1.413 1.416 1.188-1.188c.192.102.395.182.605.245V10H17V8.335c.229-.067.445-.158.65-.271l1.213 1.213 1.414-1.416-1.213-1.211c.111-.205.201-.422.271-.65H21V3.999h-1.663zm-3.338 2.5a1.503 1.503 0 0 1-1.501-1.5 1.505 1.505 0 0 1 1.501-1.501 1.503 1.503 0 0 1 1.5 1.501 1.502 1.502 0 0 1-1.5 1.5zM16 17H0v-2h16v2zm-5-5H0v-2h11v2zM8 7H0V5h8v2z' /></svg>
export const SIDEBAR_CLOSE = <svg width='15' height='15' xmlns='http://www.w3.org/2000/svg'><path d='M5.918 7.392L0 1.374 1.374 0l5.968 5.967.004-.004.004.004L13.21.098l1.441 1.44-5.866 5.866 5.994 6.001-1.374 1.374L7.35 8.84l-5.825 5.825L.09 13.228l5.83-5.836z' fillRule='nonzero' /></svg>
export const ZOOM = <svg width='34' height='34' xmlns='http://www.w3.org/2000/svg'><path d='M19 24h7V10H9v6h10v8zM7 8h21v18H7V8zm2 10v6h8v-6H9z' fill='#8E8E93' fillRule='nonzero' /></svg>
export const ZOOM_IN = <svg width='34' height='34' xmlns='http://www.w3.org/2000/svg'><path fill='#8E8E93' d='M16 16v-6h2v6h6v2h-6v6h-2v-6h-6v-2z' fillRule='evenodd' /></svg>
export const ZOOM_OUT = <svg width='34' height='34' xmlns='http://www.w3.org/2000/svg'><path fill='#8E8E93' d='M10 16h14v2H10z' fillRule='evenodd' /></svg>
export const ZOOM_CROP = <svg width='34' height='34' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'><defs><path d='M14.177 22.046L11.814 25 10 23.5l2.461-3.077a7.151 7.151 0 0 1-1.438-4.318c0-3.924 3.13-7.105 6.989-7.105C21.872 9 25 12.181 25 16.105c0 3.924-3.129 7.106-6.988 7.106a6.874 6.874 0 0 1-3.835-1.165zm3.835-1.44c2.444 0 4.426-2.015 4.426-4.5 0-2.486-1.982-4.5-4.426-4.5-2.445 0-4.426 2.014-4.426 4.5 0 2.485 1.981 4.5 4.426 4.5z' id='a' /></defs><use fill='#0A1EAA' transform='matrix(-1 0 0 1 35 0)' xlinkHref='#a' /></svg>
export const ZOOM_MOVE = <svg width='34' height='34' xmlns='http://www.w3.org/2000/svg'><path d='M16.513 26.129V20.04h1.998v6.079l1.007-1.055 1.49 1.491-3.496 3.439-3.52-3.439 1.496-1.49 1.025 1.063zM18.51 8.88v6.133h-2.004V8.877l-1.019 1.058-1.496-1.491 3.52-3.439 3.496 3.439-1.49 1.49L18.51 8.88zm7.623 9.634H20v-1.998h6.125l-1.055-1.007 1.491-1.49L30 17.515l-3.439 3.52-1.49-1.496 1.063-1.025zm-17.26-1.998H15v1.998H8.866L9.93 19.54l-1.491 1.495L5 17.515l3.439-3.496 1.49 1.49-1.054 1.007z' fill='#0A1EAA' fillRule='evenodd' /></svg>
export const TARGET = <svg width='16' height='16' xmlns='http://www.w3.org/2000/svg'><path d='M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm0-2A6 6 0 1 0 8 2a6 6 0 0 0 0 12zm0-2a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' /></svg>
export const TOP = <svg width='14' height='20' xmlns='http://www.w3.org/2000/svg'><path d='M8 3.986V20H6V3.986L1.418 9 0 7.661 7 0l7 7.661L12.582 9 8 3.986z' /></svg>
export const COMPLIANCE = <svg width='17' height='19' xmlns='http://www.w3.org/2000/svg'><path d='M11.22 6.005a7.478 7.478 0 0 0-2.132.391V2.137H5.404v2.344c0 .518-.42.938-.935.938H2.132v7.444h1.895A7.6 7.6 0 0 0 4.15 15H.935A.937.937 0 0 1 0 14.062V4.22c0-.385.197-.86.468-1.132L3.079.469A1.749 1.749 0 0 1 4.208 0h6.077c.516 0 .935.42.935.938v5.067zM3.273 3.281v-1.68L1.596 3.28h1.677zM11.5 19a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm1.485-7.7l-2.509 2.563-.89-.644a1 1 0 0 0-1.173 1.62l2.287 1.656 3.714-3.796a1 1 0 1 0-1.43-1.398z' /></svg>
export const QUESTION = <svg xmlns='http://www.w3.org/2000/svg' width='56' height='55'><path d='M61.954 12.48c0 6.228-1.01 11.622-3.032 16.181-2.02 4.56-4.851 8.178-8.492 10.856L62 48.32 54.41 55 40.063 43.941c-1.882.391-3.903.587-6.063.587-5.43 0-10.282-1.302-14.555-3.905-4.274-2.603-7.575-6.312-9.905-11.126C7.211 24.682 6.03 19.13 6 12.84v-3.7C6 2.729 7.165-2.928 9.494-7.833c2.33-4.905 5.616-8.66 9.858-11.262C23.595-21.7 28.446-23 33.907-23c5.554 0 10.452 1.286 14.695 3.86 4.242 2.572 7.52 6.274 9.834 11.104 2.314 4.83 3.487 10.45 3.518 16.859v3.656zM52 9.726c0-7.454-1.398-13.106-4.194-16.954C45.01-11.076 41.06-13 35.954-13c-4.953 0-8.835 1.947-11.646 5.84C21.497-3.264 20.06 2.228 20 9.319v3.864c0 7.151 1.405 12.742 4.217 16.773C27.027 33.985 30.97 36 36.046 36c5.135 0 9.078-1.94 11.829-5.818 2.75-3.879 4.125-9.546 4.125-17V9.727z' transform='translate(-6)' fillRule='evenodd' /></svg>

export const TOGGLE_CHECKED = <svg width='12' height='10' xmlns='http://www.w3.org/2000/svg'><path strokeWidth='2' d='M1 4.63L4.25 8 11 1' fill='none' /></svg>
export const TOGGLE_UNCHECKED = <svg width='9' height='9' xmlns='http://www.w3.org/2000/svg'><path strokeWidth='2' fill='none' d='M1 8l6.953-7M1 1l6.953 7' /></svg>
export const TRIANGLE = <svg viewBox='0 0 11 11' xmlns='http://www.w3.org/2000/svg'><use xlinkHref='#path0_fill' /><defs><path id='path0_fill' d='M0 0h11L5 11 0 0z' /></defs></svg>

export const PROFILE = <svg xmlns='http://www.w3.org/2000/svg' width='14' height='18'><path d='M7 0a4.5 4.5 0 1 0 0 9.001A4.5 4.5 0 0 0 7 0m0 2c1.38 0 2.5 1.122 2.5 2.5S8.38 7 7 7a2.503 2.503 0 0 1-2.5-2.5C4.5 3.121 5.622 2 7 2zm7 16h-2c0-2.757-2.243-5-5-5s-5 2.243-5 5H0c0-3.86 3.14-7 7-7 3.859 0 7 3.14 7 7z' /></svg>
export const SHARE = <svg className='ico-share' width='22' height='28' xmlns='http://www.w3.org/2000/svg'><path d='M7.49 11.136l6.12-4.372a4.5 4.5 0 1 1 1.38 1.472l-6.337 4.527c.223.534.347 1.12.347 1.736a4.48 4.48 0 0 1-.303 1.627l5.726 4.09a4.5 4.5 0 1 1-1.12 1.657l-5.726-4.09A4.501 4.501 0 0 1 0 14.5a4.5 4.5 0 0 1 7.49-3.363zm8.091-5.042c.459.55 1.149.901 1.92.901A2.498 2.498 0 0 0 19.995 4.5a2.498 2.498 0 0 0-2.494-2.494A2.498 2.498 0 0 0 15.005 4.5c0 .473.132.916.361 1.293l.215.302zm-11.08 5.91A2.498 2.498 0 0 0 2.005 14.5 2.499 2.499 0 0 0 4.5 16.995 2.498 2.498 0 0 0 6.995 14.5a2.498 2.498 0 0 0-2.494-2.494zm13 9a2.498 2.498 0 0 0-2.496 2.495 2.499 2.499 0 0 0 2.496 2.496 2.498 2.498 0 0 0 2.494-2.496 2.498 2.498 0 0 0-2.494-2.494z' /></svg>
export const TENANT = <svg xmlns='http://www.w3.org/2000/svg' width='22' height='17'><path d='M14.688 13.725v1.588c0 .517-.42.937-.938.937H6.875a.937.937 0 0 1-.938-.938v-.624h-5A.937.937 0 0 1 0 13.75V4.375c0-.518.42-.938.938-.938h5v-2.5c0-.517.42-.937.937-.937h6.875c.518 0 .938.42.938.938v1.694l-1.938 1.63V1.938H7.875v12.374h4.875v-2.166l1.938 1.579zm1.371-1.635a2.546 2.546 0 0 1-.776-.64c-1.429 0-2.586-1.075-2.586-2.403a2.28 2.28 0 0 1-.518-1.441c0-1.217.974-2.223 2.238-2.381.42-1.131 1.575-1.944 2.935-1.944 1.714 0 3.104 1.29 3.104 2.883 0 .224-.027.441-.079.65.956.353 1.631 1.22 1.631 2.233 0 1.328-1.158 2.403-2.586 2.403a2.546 2.546 0 0 1-.776.64v3.038c0 .664-.58 1.202-1.294 1.202-.714 0-1.293-.538-1.293-1.202V12.09z' /></svg>
export const SURVEY = <svg xmlns='http://www.w3.org/2000/svg' width='19' height='15'><path fillRule='evenodd' d='M18.195 14.063V.937A.937.937 0 0 0 17.26 0h-6.077c-.383 0-.858.197-1.129.469L7.443 3.087a1.759 1.759 0 0 0-.468 1.132v9.843c0 .518.42.938.935.938h9.35c.516 0 .935-.42.935-.938zm-2.327-1.2V2.137h-3.684v2.344c0 .518-.42.938-.935.938H8.912v7.444h6.956zM10.053 3.28H8.376l1.677-1.68v1.68zM6.95 4.07v8.906H3.62c-.33 0-.739-.165-.972-.391L.402 10.402A1.441 1.441 0 0 1 0 9.459V4.381c0-.43.36-.781.805-.781h6.281c-.03.157-.135.315-.135.469zm-2.164 7.93V9.6H2.394L4.787 12z' /></svg>

export const RIGHT_ARROW = <svg className='ico-right-arrow' width='14' height='8' xmlns='http://www.w3.org/2000/svg'><path d='M13.36 4.219L9.983 7.594V4.797H.828V3.64h9.156V.844z' /></svg>

export const HIERARCHY_LEVELS = {
  L1: <svg width='18' height='12' xmlns='http://www.w3.org/2000/svg'><path d='M7.3 1.8L5.3 0H0v12h14V1.8H7.3zM12 10H2V2h2.6l1.9 1.8H12V10z' /></svg>,
  L2: <svg width='18' height='17' xmlns='http://www.w3.org/2000/svg'><path d='M7.3 1.8L5.3 0H0v12h14V1.8H7.3zM12 10H2V2h2.6l1.9 1.8H12V10z' /><path d='M13 8h3v6.2H6v-3H4v5h14V6h-5z' /></svg>,
  L3: <svg width='22' height='21' xmlns='http://www.w3.org/2000/svg'><path d='M7.3 1.8L5.3 0H0v12h14V1.8H7.3zM12 10H2V2h2.6l1.9 1.8H12V10z' /><path d='M13 8h3v6.2H6v-3H4v5h14V6h-5z' /><path d='M18 12h2v6.2H10v-2H8v4h14V10h-4z' /></svg>
}

export const PROGRESS = {
  1: <svg className='ico-progress-1' width='16' height='11' xmlns='http://www.w3.org/2000/svg'><path d='M14.093 9.884H8v1h7.423v-.758l.012-.007-.012-.018v-7.44h-1v5.937C10.403 2.924 5.345 0 0 0v1c5.214 0 10.174 2.994 14.093 8.884z' /></svg>,
  2: <svg className='ico-progress-2' width='16' height='11' xmlns='http://www.w3.org/2000/svg'><path d='M14.093 9.884H8v1h7.423v-.758l.012-.007-.012-.018v-7.44h-1v5.937C10.403 2.924 5.345 0 0 0v1c5.214 0 10.174 2.994 14.093 8.884z' /></svg>,
  3: <svg className='ico-progress-3' width='16' height='10' xmlns='http://www.w3.org/2000/svg'><path d='M13.142 5.3H0v-1h13.274L9.6.716 10.297 0l4.772 4.654-4.75 5.278-.743-.67L13.142 5.3z' /></svg>,
  4: <svg className='ico-progress-4' width='16' height='11' xmlns='http://www.w3.org/2000/svg'><path d='M14.093 1H8V0h7.423v.758l.012.007-.012.018v7.44h-1V2.286C10.403 7.96 5.345 10.884 0 10.884v-1C5.214 9.884 10.174 6.89 14.093 1z' /></svg>,
  5: <svg className='ico-progress-5' width='16' height='11' xmlns='http://www.w3.org/2000/svg'><path d='M14.093 1H8V0h7.423v.758l.012.007-.012.018v7.44h-1V2.286C10.403 7.96 5.345 10.884 0 10.884v-1C5.214 9.884 10.174 6.89 14.093 1z' /></svg>,
  Na: <svg className='ico-progress-na' width='16' height='2' xmlns='http://www.w3.org/2000/svg'><path d='M0 1h18.34' strokeDasharray='1,4' strokeLinecap='round' /></svg>
}

export const SCORE_NA = <svg className='ico-score-na' width='4' height='4' xmlns='http://www.w3.org/2000/svg'><circle cx='2' cy='2' r='2' /></svg>

export const CARD_ICONS = {
  default: <svg width='60' height='60' xmlns='http://www.w3.org/2000/svg'><g fill='none' fillRule='evenodd'><rect width='60' height='60' rx='30' /><path d='M30.086 36.5h-2.088c-2.514 0-4.498 2.124-4.498 4.31a1.25 1.25 0 1 1-2.5 0c0-3.53 3.064-6.81 6.998-6.81h4.176c3.935 0 6.998 3.28 6.998 6.81a1.25 1.25 0 1 1-2.5 0c0-2.186-1.984-4.31-4.498-4.31h-2.088zm-10.836-10a4.25 4.25 0 1 1 0-8.5 4.25 4.25 0 0 1 0 8.5zm0-2.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5zm22 2.5a4.25 4.25 0 1 1 0-8.5 4.25 4.25 0 0 1 0 8.5zm0-2.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5zm-11 7.5a4.25 4.25 0 1 1 0-8.5 4.25 4.25 0 0 1 0 8.5zm0-2.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5zm17.422 6.81c0-2.186-1.984-4.31-4.498-4.31H39.25a1.25 1.25 0 1 1 0-2.5h3.924c3.935 0 6.998 3.28 6.998 6.81a1.25 1.25 0 1 1-2.5 0zm-35.172 0a1.25 1.25 0 1 1-2.5 0c0-3.53 3.064-6.81 6.998-6.81h3.924a1.25 1.25 0 1 1 0 2.5h-3.924c-2.514 0-4.498 2.125-4.498 4.31z' fillRule='nonzero' /></g></svg>,
  people: <svg width='60' height='60' xmlns='http://www.w3.org/2000/svg'><g fill='none' fillRule='evenodd'><rect width='60' height='60' rx='30' /><path d='M30.086 36.5h-2.088c-2.514 0-4.498 2.124-4.498 4.31a1.25 1.25 0 1 1-2.5 0c0-3.53 3.064-6.81 6.998-6.81h4.176c3.935 0 6.998 3.28 6.998 6.81a1.25 1.25 0 1 1-2.5 0c0-2.186-1.984-4.31-4.498-4.31h-2.088zm-10.836-10a4.25 4.25 0 1 1 0-8.5 4.25 4.25 0 0 1 0 8.5zm0-2.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5zm22 2.5a4.25 4.25 0 1 1 0-8.5 4.25 4.25 0 0 1 0 8.5zm0-2.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5zm-11 7.5a4.25 4.25 0 1 1 0-8.5 4.25 4.25 0 0 1 0 8.5zm0-2.5a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5zm17.422 6.81c0-2.186-1.984-4.31-4.498-4.31H39.25a1.25 1.25 0 1 1 0-2.5h3.924c3.935 0 6.998 3.28 6.998 6.81a1.25 1.25 0 1 1-2.5 0zm-35.172 0a1.25 1.25 0 1 1-2.5 0c0-3.53 3.064-6.81 6.998-6.81h3.924a1.25 1.25 0 1 1 0 2.5h-3.924c-2.514 0-4.498 2.125-4.498 4.31z' fillRule='nonzero' /></g></svg>,
  processes: <svg width='60' height='60' xmlns='http://www.w3.org/2000/svg'><g fill='none' fillRule='evenodd'><rect width='60' height='60' rx='30' /><path d='M13.7745 24.8284l-3.005 1.3719c-.628.2867-1.3696.01-1.6563-.618-.2867-.628-.01-1.3696.618-1.6563l5.7834-2.6403c.615-.2807 1.3415-.0218 1.6404.5845l3.0112 6.1092c.3053.6192.0507 1.3686-.5685 1.6738-.6192.3053-1.3686.0507-1.6739-.5685l-1.7483-3.547c-.8784 3.7821-.1936 8.2352 1.7928 11.414 2.2313 3.571 4.1504 4.9873 9.3262 6.6517.6572.2113 1.0187.9154.8073 1.5726-.2113.6572-.9154 1.0187-1.5726.8073-5.7177-1.8386-8.124-3.6144-10.681-7.7067-2.3679-3.7893-3.1518-8.9934-2.0737-13.4482zm22.183 21.5903l2.7236 1.8693c.5692.3906.714 1.1687.3233 1.738-.3906.5691-1.1687.7139-1.738.3232l-5.2419-3.5975c-.5573-.3825-.7096-1.1386-.3438-1.707l3.6857-5.7277c.3736-.5805 1.147-.7483 1.7276-.3747.5805.3735.7483 1.147.3747 1.7276l-2.1399 3.3254c3.6944-1.195 7.1587-4.0754 8.8605-7.4154 1.9116-3.7517 2.1372-6.1262.8981-11.42-.1573-.6722.26-1.3446.9323-1.502.6721-.1573 1.3446.26 1.502.9323 1.3687 5.848 1.0858 8.8251-1.1049 13.1247-2.0285 3.9813-6.0855 7.3335-10.4593 8.7038zm6.8612-29.0508l.429-3.2754c.0897-.6845.7172-1.1667 1.4017-1.0771.6846.0897 1.1668.7172 1.0771 1.4017l-.8256 6.3039c-.0878.6702-.6924 1.1492-1.3649 1.0813l-6.7767-.6837c-.6868-.0693-1.1875-.6823-1.1182-1.3691.0693-.6869.6823-1.1875 1.3692-1.1182l3.9345.397C38.2028 16.279 34.0636 14.5 30.315 14.5c-4.2107 0-6.4288.877-10.583 4.3843-.5276.4454-1.3162.3788-1.7616-.1487-.4453-.5275-.3787-1.3162.1488-1.7615C22.7085 13.0995 25.4896 12 30.315 12c4.4682 0 9.297 2.0929 12.5036 5.3679z' fillRule='nonzero' /></g></svg>,
  productivity: <svg width='60' height='60' xmlns='http://www.w3.org/2000/svg'><g fill='none' fillRule='evenodd'><rect width='60' height='60' rx='30' /><path d='M45.195 15H45.3c.6904 0 1.25.5597 1.25 1.25v8.027c0 .6904-.5596 1.25-1.25 1.25-.6903 0-1.25-.5596-1.25-1.25v-5.3858L32.0443 29.2214c-.5687.4893-1.4373.371-1.8543-.2527l-3.1826-4.7589-10.8728 10.8896c-.4878.4885-1.2793.4891-1.7678.0013-.4885-.4877-.4891-1.2792-.0014-1.7677L26.315 21.3652c.557-.558 1.4853-.4671 1.9236.1883l3.245 4.8523L41.8338 17.5H37.25c-.6904 0-1.25-.5596-1.25-1.25 0-.6903.5596-1.25 1.25-1.25h7.945zM19.5 44.25c0 .6904-.5596 1.25-1.25 1.25S17 44.9404 17 44.25v-5c0-.6903.5596-1.25 1.25-1.25s1.25.5597 1.25 1.25v5zm16 0c0 .6904-.5596 1.25-1.25 1.25S33 44.9404 33 44.25v-8c0-.6903.5596-1.25 1.25-1.25s1.25.5597 1.25 1.25v8zm8 0c0 .6904-.5596 1.25-1.25 1.25S41 44.9404 41 44.25v-16c0-.6903.5596-1.25 1.25-1.25s1.25.5597 1.25 1.25v16zm-16 0c0 .6904-.5596 1.25-1.25 1.25S25 44.9404 25 44.25v-11c0-.6903.5596-1.25 1.25-1.25s1.25.5597 1.25 1.25v11z' fillRule='nonzero' /></g></svg>,
  quality: <svg width='60' height='60' xmlns='http://www.w3.org/2000/svg'><g fill='none' fillRule='evenodd'><rect width='60' height='60' rx='30' /><path d='M23.5 41.0361v6.4974l6.5743-1.7095a1.25 1.25 0 0 1 .6034-.0064l7.3715 1.7508v-7.0589C35.734 41.7785 33.0763 42.5 30.25 42.5c-2.4083 0-4.6942-.5239-6.75-1.4639zm-2.5-1.424c-4.23-2.9337-7-7.8244-7-13.3621C14 17.2754 21.2754 10 30.25 10c8.9746 0 16.25 7.2754 16.25 16.25 0 5.0931-2.343 9.639-6.0101 12.6184.0385.1203.0593.2485.0593.3816v9.9c0 .8072-.7536 1.4028-1.5389 1.2162l-8.6078-2.0444-7.838 2.038c-.7917.206-1.5645-.3917-1.5645-1.2097v-9.538zM30.25 40C37.844 40 44 33.844 44 26.25S37.844 12.5 30.25 12.5 16.5 18.656 16.5 26.25 22.656 40 30.25 40zm7.7865-17.7185c.5349-.4364 1.3223-.3567 1.7588.1782.4365.5348.3567 1.3223-.1782 1.7588l-11.1926 9.1339c-.5215.4256-1.2864.3618-1.7302-.1443l-4.3841-4.9996c-.4552-.519-.4034-1.3088.1157-1.764.519-.4551 1.3088-.4033 1.764.1157l3.5887 4.0925 10.2579-8.3712z' fillRule='nonzero' /></g></svg>,
  speed: <svg width='60' height='60' xmlns='http://www.w3.org/2000/svg'><g fill='none' fillRule='evenodd'><rect width='60' height='60' rx='30' /><path d='M30.25 48.5C21.2754 48.5 14 41.2246 14 32.25 14 23.2754 21.2754 16 30.25 16c8.9746 0 16.25 7.2754 16.25 16.25 0 8.9746-7.2754 16.25-16.25 16.25zm0-2.5C37.844 46 44 39.844 44 32.25S37.844 18.5 30.25 18.5 16.5 24.656 16.5 32.25 22.656 46 30.25 46zm-3-32.5c-.6904 0-1.25-.5596-1.25-1.25S26.5596 11 27.25 11h7c.6904 0 1.25.5596 1.25 1.25s-.5596 1.25-1.25 1.25h-7zm3 10c-.6904 0-1.25-.5596-1.25-1.25S29.5596 21 30.25 21c5.7202 0 11.7005 4.9807 11.7005 11.1554 0 .6904-.5597 1.25-1.25 1.25h-9.9923c-.6903 0-1.25-.5596-1.25-1.25 0-.6903.5597-1.25 1.25-1.25h8.6291C38.591 26.7871 34.2798 23.5 30.25 23.5z' fillRule='nonzero' /></g></svg>,
  structure: <svg width='60' height='60' xmlns='http://www.w3.org/2000/svg'><g fill='none' fillRule='evenodd'><rect width='60' height='60' rx='30' /><path d='M13.5 34.5V38H19v-3.5h-5.5zM12.25 32h8c.6904 0 1.25.5596 1.25 1.25v6c0 .6904-.5596 1.25-1.25 1.25h-8c-.6904 0-1.25-.5596-1.25-1.25v-6c0-.6904.5596-1.25 1.25-1.25zm14 0h8c.6904 0 1.25.5596 1.25 1.25v6c0 .6904-.5596 1.25-1.25 1.25h-8c-.6904 0-1.25-.5596-1.25-1.25v-6c0-.6904.5596-1.25 1.25-1.25zm1.25 2.5V38H33v-3.5h-5.5zM26.25 20h8c.6904 0 1.25.5596 1.25 1.25v6c0 .6904-.5596 1.25-1.25 1.25h-8c-.6904 0-1.25-.5596-1.25-1.25v-6c0-.6904.5596-1.25 1.25-1.25zm1.25 2.5V26H33v-3.5h-5.5zM39.25 32h8c.6904 0 1.25.5596 1.25 1.25v6c0 .6904-.5596 1.25-1.25 1.25h-8c-.6904 0-1.25-.5596-1.25-1.25v-6c0-.6904.5596-1.25 1.25-1.25zm1.25 2.5V38H46v-3.5h-5.5z' fillRule='nonzero' /></g></svg>,
  technology: <svg width='60' height='60' xmlns='http://www.w3.org/2000/svg'><g fill='none' fillRule='evenodd'><rect width='60' height='60' rx='30' /><path d='M23.8955 21.5764l2.6119 4.2557h7.6206l2.4484-4.2783C35.6147 20.7747 35 19.5841 35 18.25c0-2.3472 1.9028-4.25 4.25-4.25s4.25 1.9028 4.25 4.25-1.9028 4.25-4.25 4.25a4.3094 4.3094 0 0 1-.3276-.0124l-2.9846 5.2154a1.25 1.25 0 0 1-1.085.6291h-9.045a1.25 1.25 0 0 1-1.0653-.5962l-3.2188-5.2446a4.3144 4.3144 0 0 1-.2737.0087c-2.3472 0-4.25-1.9028-4.25-4.25S18.9028 14 21.25 14s4.25 1.9028 4.25 4.25c0 1.347-.6267 2.5478-1.6045 3.3264zm-2.4718 15.427l3.3188-5.4075a1.25 1.25 0 0 1 1.0654-.5961h9.045a1.25 1.25 0 0 1 1.0849.6291l3.0774 5.3775A4.3183 4.3183 0 0 1 39.25 37c2.3472 0 4.25 1.9028 4.25 4.25s-1.9028 4.25-4.25 4.25S35 43.5972 35 41.25c0-1.3645.643-2.5787 1.6425-3.3564l-2.5145-4.3938h-7.6206l-2.6817 4.3694c1.0177.7764 1.6743 2.002 1.6743 3.3808 0 2.3472-1.9028 4.25-4.25 4.25S17 43.5972 17 41.25 18.9028 37 21.25 37c.0582 0 .1161.0012.1737.0035zM21.25 20c.9665 0 1.75-.7835 1.75-1.75s-.7835-1.75-1.75-1.75-1.75.7835-1.75 1.75.7835 1.75 1.75 1.75zm-8 14.5C10.9028 34.5 9 32.5972 9 30.25S10.9028 26 13.25 26s4.25 1.9028 4.25 4.25-1.9028 4.25-4.25 4.25zm0-2.5c.9665 0 1.75-.7835 1.75-1.75s-.7835-1.75-1.75-1.75-1.75.7835-1.75 1.75.7835 1.75 1.75 1.75zm34 2.5c-2.3472 0-4.25-1.9028-4.25-4.25S44.9028 26 47.25 26s4.25 1.9028 4.25 4.25-1.9028 4.25-4.25 4.25zm0-2.5c.9665 0 1.75-.7835 1.75-1.75s-.7835-1.75-1.75-1.75-1.75.7835-1.75 1.75.7835 1.75 1.75 1.75zm-26 11c.9665 0 1.75-.7835 1.75-1.75s-.7835-1.75-1.75-1.75-1.75.7835-1.75 1.75.7835 1.75 1.75 1.75zm18-23c.9665 0 1.75-.7835 1.75-1.75s-.7835-1.75-1.75-1.75-1.75.7835-1.75 1.75.7835 1.75 1.75 1.75zm0 23c.9665 0 1.75-.7835 1.75-1.75s-.7835-1.75-1.75-1.75-1.75.7835-1.75 1.75.7835 1.75 1.75 1.75z' fillRule='nonzero' /></g></svg>,
  effectiveness: <svg width='60' height='60' xmlns='http://www.w3.org/2000/svg'><g fill='none' fillRule='evenodd'><rect width='60' height='60' rx='30' /><path d='M40.8403 21.5337L30.1291 32.1306c-.4907.4855-1.2822.4813-1.7677-.0095-.4855-.4908-.4813-1.2822.0095-1.7678L39 19.8376V14.25c0-.6904.5596-1.25 1.25-1.25s1.25.5596 1.25 1.25v4.7837h4.75c.6904 0 1.25.5596 1.25 1.25 0 .6903-.5596 1.25-1.25 1.25h-5.4097zm-4.0448-4.679L35.633 19.068c-1.9519-1.025-4.127-1.568-6.3831-1.568-7.594 0-13.75 6.156-13.75 13.75S21.656 45 29.25 45 43 38.844 43 31.25c0-2.2246-.5279-4.3708-1.5258-6.3021l2.221-1.1476C44.8755 26.0843 45.5 28.6237 45.5 31.25c0 8.9746-7.2754 16.25-16.25 16.25S13 40.2246 13 31.25 20.2754 15 29.25 15c2.6634 0 5.2372.6424 7.5455 1.8546zm-5.781 5.3136L30.54 24.623a6.7912 6.7912 0 0 0-1.29-.1229c-3.728 0-6.75 3.022-6.75 6.75S25.522 38 29.25 38 36 34.978 36 31.25a6.787 6.787 0 0 0-.1342-1.347l2.4504-.4957A9.287 9.287 0 0 1 38.5 31.25c0 5.1086-4.1414 9.25-9.25 9.25S20 36.3586 20 31.25 24.1414 22 29.25 22c.5967 0 1.1868.0567 1.7645.1683z' fillRule='nonzero' /></g></svg>,
  process: <svg width='60' height='60' xmlns='http://www.w3.org/2000/svg'><g fill='none' fillRule='evenodd'><rect width='60' height='60' rx='30' /><path d='M13.5 34.5V38H19v-3.5h-5.5zM12.25 32h8c.6904 0 1.25.5596 1.25 1.25v6c0 .6904-.5596 1.25-1.25 1.25h-8c-.6904 0-1.25-.5596-1.25-1.25v-6c0-.6904.5596-1.25 1.25-1.25zm14 0h8c.6904 0 1.25.5596 1.25 1.25v6c0 .6904-.5596 1.25-1.25 1.25h-8c-.6904 0-1.25-.5596-1.25-1.25v-6c0-.6904.5596-1.25 1.25-1.25zm1.25 2.5V38H33v-3.5h-5.5zM26.25 20h8c.6904 0 1.25.5596 1.25 1.25v6c0 .6904-.5596 1.25-1.25 1.25h-8c-.6904 0-1.25-.5596-1.25-1.25v-6c0-.6904.5596-1.25 1.25-1.25zm1.25 2.5V26H33v-3.5h-5.5zM39.25 32h8c.6904 0 1.25.5596 1.25 1.25v6c0 .6904-.5596 1.25-1.25 1.25h-8c-.6904 0-1.25-.5596-1.25-1.25v-6c0-.6904.5596-1.25 1.25-1.25zm1.25 2.5V38H46v-3.5h-5.5z' fillRule='nonzero' /></g></svg>,
  'business value': <svg width='62' height='62' xmlns='http://www.w3.org/2000/svg'><g fillRule='nonzero' fill='none'><circle cx='30' cy='30' r='30' fill='#EFF1F4' stroke='#EBECEE' transform='translate(1 1)' /><path d='M29.498 45.18c-2.247-.125-4.665-.918-6.962-2.508a1.25 1.25 0 0 1 1.423-2.056c5.384 3.727 11.115 2.11 12.111-1.874.788-3.153-.371-4.81-4.544-6.896a79.88 79.88 0 0 1-2.389-1.224c-3.844-2.075-5.58-3.994-5.58-7.171 0-3.858 2.422-6.292 5.94-6.814V13.25a1.25 1.25 0 0 1 2.5 0v3.327c1.995.179 4.194.874 6.426 2.15a1.25 1.25 0 0 1-1.24 2.17c-6.163-3.521-11.127-1.867-11.127 2.554 0 2.018 1.149 3.288 4.268 4.971.596.322 1.104.58 2.319 1.188 5.103 2.552 6.978 5.23 5.852 9.738-.765 3.059-3.292 5.114-6.498 5.687v3.215a1.25 1.25 0 0 1-2.5 0v-3.07h.001zm9.894 1.67a1.25 1.25 0 1 1-1.362-2.097c2.244-1.459 3.568-4.157 3.467-6.958a1.25 1.25 0 1 1 2.498-.09c.133 3.658-1.603 7.194-4.603 9.144v.001zM24.56 12.582a1.25 1.25 0 1 1 .897 2.334c-4.024 1.546-6.1 6.53-4.307 10.57a1.25 1.25 0 0 1-2.285 1.014c-2.373-5.349.338-11.858 5.695-13.917v-.001z' fill='#B2B9C3' /></g></svg>,
  team: <svg width='62' height='62' xmlns='http://www.w3.org/2000/svg'><g fill='none' fillRule='evenodd'><circle cx='30' cy='30' r='30' fill='#EFF1F4' fillRule='nonzero' stroke='#EBECEE' transform='translate(1 1)' /><path d='M32.25 17.25a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0zm-4.488 19.124c-.168.237-.338.472-.512.705-3.82 5.095-7.498 6.408-12.643 3.321a1.25 1.25 0 0 1 1.286-2.144c3.886 2.331 6.228 1.495 9.357-2.678.126-.168.254-.344.384-.526.713-1 1.469-2.191 2.253-3.535a92.692 92.692 0 0 0 2.487-4.578c.288-.565.553-1.095.82-1.637.075-.152.34-.695.412-.839-.674-.37-1.34-.753-1.999-1.15-3.717-2.228-8.117-.674-10.317 2.624a1.25 1.25 0 0 1-2.08-1.387c2.878-4.317 8.697-6.37 13.683-3.378 6.922 4.153 10.663 4.493 14.34-.655a1.25 1.25 0 1 1 2.034 1.453c-2.563 3.589-5.63 5.087-9.13 4.769-1.415-.13-2.77-.503-4.29-1.164-.128.265-.839 1.705-1.246 2.502a95.122 95.122 0 0 1-2.555 4.702 63.237 63.237 0 0 1-.906 1.507c4.386 2.637 5.778 3.615 6.994 4.831l.144.144 4.09 8.181a1.25 1.25 0 0 1-2.236 1.118l-3.907-7.814c-1.018-.984-2.365-1.91-6.463-4.371v-.001zM38.25 17.25a1.75 1.75 0 1 1-3.501-.001 1.75 1.75 0 0 1 3.501.001zm-7.237 27.41a1.25 1.25 0 1 1 2.124-1.32c.43.694.753 1.235 1.028 1.72.148.26.494.887.523.939l.064.112c.168.292.344.57.62.972a1.25 1.25 0 0 1-2.06 1.415c-.258-.37-.5-.75-.726-1.14l-.075-.131c-.038-.068-.381-.69-.519-.931-.259-.455-.566-.97-.98-1.637l.001.001z' fill='#B2B9C3' /></g></svg>
}

export const HM_EXPAND = <svg width='10' height='10' xmlns='http://www.w3.org/2000/svg' viewBox='7 7 20 20'><path fill='#0A1EAA' d='M15 15v-7h4v7h7v4h-7v7h-4v-7h-7v-4z' fillRule='evenodd' /></svg>
export const HM_COLLAPSE = <svg width='10' height='10' xmlns='http://www.w3.org/2000/svg' viewBox='7 7 20 20'><path fill='#0A1EAA' d='M10 15h14v4H10z' fillRule='evenodd' /></svg>
