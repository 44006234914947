
import _ from 'lodash'
import { createSelector } from 'reselect'
import { calculateProgress } from '../../utils/calculations'
import { isNotAsked } from '../../utils'

function notAsked (item) {
  return !isNotAsked(item.score) || item.category
}

const mapQuestions = createSelector(
  (questions) => questions,
  questions => {
    return _.chain(questions)
      .groupBy('id')
      .mapValues(questions => questions[0])
      .value()
  }
)

const mapGroups = createSelector(
  (total) => total,
  total => {
    if (!total) {
      return {}
    }

    return _.chain(total.groups)
      .map(group => [group, ...group.groups, ..._.chain(group.groups).map('groups').flatten().value()])
      .flatten()
      .groupBy('id')
      .mapValues(groups => groups[0])
      .value()
  }
)

export const questionsProgressScore = createSelector(
  (state, type, teamId) => _.get(state, ['teamProfile', 'totals', type, teamId, 'latest', 'questions']),
  (state, type, teamId) => _.get(state, ['teamProfile', 'totals', type, teamId, 'previous', 'questions']),
  (state, type) => _.get(state, ['profile', 'deltaThresholds', type]),
  (questions, history, thresholds) => {
    if (!questions) {
      return questions
    }

    const mappedQuestions = mapQuestions(history)
    return _.chain(questions)
      .filter(notAsked)
      .map(question => {
        const res = {
          id: question.id,
          type: question.type,
          grouping1: question.grouping1,
          grouping2: question.grouping2,
          grouping3: question.grouping3,
          score: question.score,
          question_text: question.question_text,
          question_title: question.question_title,
          responsesText: question.responsesText,
          selections: question.selections,
          selectionCount: question.selectionCount,
          category: question.category,
          selection: question.selection,
          questionId: question.questionId,
          possibleResponseCount: question.possibleResponseCount
        }

        const relatedQuestion = mappedQuestions[question.id]
        if (relatedQuestion) {
          res.historyScore = relatedQuestion.score
          res.progressScore = calculateProgress(question.score, relatedQuestion.score, thresholds)
        }

        return res
      })
      .value()
  }
)

export const totalsProgressScore = createSelector(
  (state, type, teamId) => _.get(state, ['teamProfile', 'totals', type, teamId, 'latest', 'totals']),
  (state, type, teamId) => _.get(state, ['teamProfile', 'totals', type, teamId, 'previous', 'totals']),
  (state, type) => _.get(state, ['profile', 'deltaThresholds', type]),
  (totals, history, thresholds) => {
    if (!totals) {
      return totals
    }

    const mappedGroups = mapGroups(history)

    function mapGroup (group) {
      const res = {
        id: group.id,
        name: group.name,
        type: group.type,
        score: group.score
      }
      if (group.isTQ) {
        res.isTQ = group.isTQ
      }

      if (group.groups) {
        res.groups = _.chain(group.groups)
          .filter(notAsked)
          .map(mapGroup)
          .value()
      }

      const relatedGroup = mappedGroups[group.id]
      if (relatedGroup) {
        res.historyScore = relatedGroup.score
        res.progressScore = calculateProgress(group.score, relatedGroup.score, thresholds)
      }

      return res
    }

    const res = {
      score: totals.score,
      groups: _.chain(totals.groups)
        .filter(notAsked)
        .map(mapGroup)
        .value()
    }

    if (history) {
      res.historyScore = history.score
      res.progressScore = calculateProgress(totals.score, history.score, thresholds)
    }

    return res
  }
)
