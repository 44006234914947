import React from 'react'
import RememberType from './index'

export default (Component, options = {}) => {
  return props => (
    <RememberType {...options} {...props}>
      <Component />
    </RememberType>
  )
}
