import * as types from './types'
import _ from 'lodash'

export function metadataId (metadata) {
  return _.map(metadata, 'id').join('-')
}

export function selectTeam (team) {
  return (dispatch, getState) => {
    const { selectedTeams } = getState().teams
    const newTeams = selectedTeams.concat(team)
    dispatch({ type: types.UPDATE_TEAMS, payload: newTeams })
  }
}

export function selectOne (team) {
  const payload = team.id === 0 ? [ ] : [ team ]
  return { type: types.UPDATE_TEAMS, payload: payload }
}

export function unselectTeam (team) {
  return (dispatch, getState) => {
    const { selectedTeams } = getState().teams
    const newTeams = _.reject(selectedTeams, itr => itr.id === team.id)
    dispatch({ type: types.UPDATE_TEAMS, payload: newTeams })
  }
}

export function updateSelected (teams) {
  return {
    type: types.UPDATE_TEAMS, payload: teams
  }
}

export function retrieve (selectedMetadata) {
  return {
    types: [ types.RETRIEVE_REQUEST, types.RETRIEVE_SUCCESS, types.RETRIEVE_FAILURE ],
    payload: {
      request: {
        method: 'GET',
        url: `/data/{latest}/${metadataId(selectedMetadata)}/teams`,
        ignoreNoSurvey: true
      }
    }
  }
}
