import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import Component from './Component'
import * as adminActions from '../../services/admin/actions'
import * as surveyActions from '../../services/surveys/actions'
import {
  groups2,
  groups3,
  warnings,
  groupQuestions
} from './selectors'

function findSurvey (surveys, id) {
  return id === 'latest' ? _.last(surveys) : _.find(surveys, { id })
}

function findActiveImport (survey) {
  return _.find(survey.imports, { status: 'active' })
}

function mapStateToProps (state, ownProps) {
  const { surveyId } = ownProps.match.params
  const teams = state.admin.teams[surveyId] || []
  const maxLevel = _.max(teams.map(it => it.level))

  const survey = findSurvey(state.surveys.surveys, surveyId) || {}
  const activeImport = findActiveImport(survey)

  return {
    teams,
    maxLevel,
    questions: groupQuestions(state, ownProps),
    groups2: groups2(state, ownProps),
    groups3: groups3(state, ownProps),
    warnings: warnings(state, ownProps),
    survey,
    isAdmin: state.profile.isAdmin,

    metadata: state.metadata.selectedMetadata,
    questionTypes: _.get(activeImport, 'questionTypes', state.profile.questionTypes)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    retrieveTeams: (...args) => dispatch(adminActions.retrieveTeams(...args)),
    retrieveQuestions: (...args) => dispatch(adminActions.retrieveQuestions(...args)),
    retrieveSurveys: (...args) => dispatch(surveyActions.retrieve(...args))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))
