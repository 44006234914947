import { connect } from 'react-redux'
import Component from './Component'
import * as actions from '../../services/errors/actions'

function mapStateToProps (state) {
  return {
    isLongRequest: state.errors.longRequests.length > 0,
    requestIds: state.errors.longRequests
  }
}

function mapDispatchToProps (dispatch) {
  return {
    dismiss: (ids) => dispatch(actions.dismissLongRequest(ids))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
