import * as types from './types'
import Immutable from 'seamless-immutable'

const initialState = Immutable({
  highlightProgress: false
})

export default function teams (state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_TYPE:
      return state.set('type', action.newType)

    case types.HIGHLIGHT_PROGRESS:
      return state.set('highlightProgress', action.value)

    default:
      return state
  }
}
