
import { connect } from 'react-redux'
import _ from 'lodash'

import Component from './Component'

function prepareBenchmark (data) {
  const scores = {
    total: data.score
  }

  for (let quadrant of data.groups) {
    scores[quadrant.id] = quadrant.score
    for (let sub of quadrant.groups) {
      scores[sub.id] = sub.score
      for (let lever of sub.groups) {
        scores[lever.id] = lever.score
      }
    }
  }

  return scores
}

function getTeamScore (state, props) {
  const teamId = props.team.id
  const benchmarkMatch = /benchmark-([0-9]+)/.exec(teamId)
  if (benchmarkMatch) {
    const benchmarkIndex = benchmarkMatch[1]
    return prepareBenchmark(state.profile.benchmark[benchmarkIndex].questions[props.type.key])
  }

  return _.get(state.organisationDetails.data, [props.currentSurvey, props.type.key, teamId || 'org'])
}

export function mapStateToProps (state, ownProps) {
  const scores = getTeamScore(state, ownProps)

  return {
    isLoading: !scores,
    groups: state.profile.groups[ownProps.type.key],
    scores: scores || {}
  }
}

export default connect(mapStateToProps)(Component)
