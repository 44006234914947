
import React, { Fragment } from 'react'
import Markdown from 'react-markdown'

import { QUESTION } from '../../../../utils/icons'

import './style.scss'

function Indicator (props) {
  const max = props.limits[1]
  const numbers = [props.limits[0], ...props.thresholds, props.limits[1]]

  const parts = []
  for (let i = numbers.length - 1; i > 0; i--) {
    const height = (numbers[i] - numbers[i - 1]) / max * 100
    const styleHeight = i === 1 ? `${height}%` : `calc(${height}% - 2px)`
    parts.push(<div key={`part-${i}`} className={`part score${i}`} style={{ height: styleHeight }} />)
  }

  const pos = props.score.progress * 100

  return (
    <div className='QuestionDetailsVertical--indicator'>
      <label className='top'>{props.limits[1]}</label>
      <label className='bot'>{props.limits[0]}</label>
      {parts}
      <div className='QuestionDetailsVertical--current-indicator' style={{ bottom: `${pos}%` }}>{props.score.value}</div>
    </div>)
}

function Response (props) {
  if (props.plainTextResponse) {
    return props.text
  }

  return <Markdown children={props.text} skipHtml />
}

function getResponsesToShow ({ showAll, responsesText }) {
  if (showAll) {
    return [...responsesText].reverse()
  }

  const bottomQuestion = responsesText[0]
  const middleQuestion = responsesText[Math.round((responsesText.length - 1) / 2)]
  const topQuestion = responsesText[responsesText.length - 1]

  return [topQuestion, middleQuestion, bottomQuestion]
}

export default function QuestionDetailsVertical (props) {
  let question = null
  if (props.questionText) {
    const icon = (props.responsesText && props.responsesText.length) ? QUESTION : null
    question = (
      <Fragment>
        <h3>{props.questionText}</h3>
        {icon}
      </Fragment>)
  }

  let responses = null

  if (props.responsesText && props.responsesText.length) {
    const responsesToShow = getResponsesToShow(props)
    console.log(responsesToShow)
    responses = (
      <Fragment>
        <Indicator thresholds={props.thresholds} limits={props.limits} score={props.score} />
        <ul className='QuestionDetailsVertical--responses'>
          {responsesToShow.map((response, i) => <li key={`response-${i}`}><Response text={response.text} plainTextResponse={props.plainTextResponse} /></li>)}
        </ul>
      </Fragment>
    )
  }

  const classes = ['QuestionDetailsVertical']

  if (!props.responsesText || props.responsesText.length === 0) {
    classes.push('QuestionDetailsVertical--noQuestions')
  }

  if (props.showAll) {
    classes.push('QuestionDetailsVertical--showAll')
  }

  return (
    <div className={classes.join(' ')}>
      {question}
      {responses}
    </div>
  )
}
