
import { Component } from 'react'
import ReactDOM from 'react-dom'

export default class LeftIconPortal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      mounted: false
    }
  }

  componentDidMount () {
    this.setState({ mounted: true })
  }

  componentWillUnmount () {
    this.setState({ mounted: false })
  }

  render () {
    if (!this.state.mounted) {
      return null
    }

    const portal = document.getElementById('HeaderMenu--LeftIconPortal')
    if (!portal) {
      return null
    }

    return ReactDOM.createPortal(
      this.props.children,
      portal
    )
  }
}
