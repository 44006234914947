export const RETRIEVE_REQUEST = 'questions.RETRIEVE_REQUEST'
export const RETRIEVE_SUCCESS = 'questions.RETRIEVE_SUCCESS'
export const RETRIEVE_FAILURE = 'questions.RETRIEVE_FAILURE'

export const RETRIEVE_HISTORY_REQUEST = 'questions.RETRIEVE_HISTORY_REQUEST'
export const RETRIEVE_HISTORY_SUCCESS = 'questions.RETRIEVE_HISTORY_SUCCESS'
export const RETRIEVE_HISTORY_FAILURE = 'questions.RETRIEVE_HISTORY_FAILURE'
export const RESET_HISTORY = 'questions.RESET_HISTORY'

export const TOGGLE_QUESTION = 'questions.TOGGLE_QUESTION'
export const RESET_QUESTIONS = 'questions.RESET_QUESTIONS'
export const UPDATE = 'questions.UPDATE'

export const EMAIL_REQUEST = 'questions.EMAIL_REQUEST'
export const EMAIL_SUCCESS = 'questions.EMAIL_SUCCESS'
export const EMAIL_FAILURE = 'questions.EMAIL_FAILURE'
