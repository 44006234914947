import { connect } from 'react-redux'
import Component from './Component'

function noHierarchies (state) {
  return !state.metadata.hierarchies || state.metadata.hierarchies.length === 0
}

function mapStateToProps (state) {
  return {
    isEmpty: state.errors.status === 101 || noHierarchies(state),
    isAdmin: state.profile.isAdmin
  }
}

export default connect(mapStateToProps)(Component)
