import * as types from './types'
import { idFromMetadata } from '../../utils/metadata'
import { prepareSurveyId } from '../../utils/surveys'

export function update (key, value) {
  return {
    type: types.UPDATE,
    payload: {
      key: key,
      value: value
    }
  }
}

export function toggleQuestion (question) {
  return { type: types.TOGGLE_QUESTION, payload: question }
}

export function resetQuestions () {
  return { type: types.RESET_QUESTIONS }
}

export function email (surveyId, metadata, teamId, ids) {
  const url = `/email/weakness/${prepareSurveyId(surveyId)}/${idFromMetadata(metadata)}/${teamId}`
  return {
    types: [
      types.EMAIL_REQUEST,
      types.EMAIL_SUCCESS,
      types.EMAIL_FAILURE
    ],
    payload: {
      request: {
        method: 'POST',
        url: url,
        data: { ids: ids }
      }
    }
  }
}
