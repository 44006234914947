
import _ from 'lodash'
import React, { PureComponent } from 'react'
import { matchPath } from 'react-router-dom'
import { Trans, withTranslation } from 'react-i18next'

import { SURVEY_PATH_REGEXP, surveysFromPath } from '../../utils/surveys'
import { METADATA_PATH_REGEXP, metadataFromPath } from '../../utils/metadata'
import { getPathname, fullBasename } from '../../utils/dom'

import InfinityLogo from '../Error/infinity.svg'
import ICON_500 from '../Error/500.svg'

export class AppContainer extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      ready: false
    }
    this.boot()
  }

  componentDidUpdate (nextProps) {
    if (!this.props.isAuthed && nextProps.isAuthed) {
      this.boot(nextProps)
    }
  }

  setMetadata (props = this.props) {
    const path = getPathname()
    const match = matchPath(path, { path: `/:surveys?/:metadata(${METADATA_PATH_REGEXP})?`, exact: false })
    const metadata = match.params.metadata
    return props.setMetadata(metadata && metadataFromPath(metadata))
  }

  setSurveys (props = this.props) {
    const path = getPathname()
    const match = matchPath(path, { path: `/:surveys(${SURVEY_PATH_REGEXP})?`, exact: false })
    const surveys = match.params.surveys

    return props.setSurveys(surveys && surveysFromPath(surveys))
  }

  failed () {
    this.setState({ failed: true })
  }

  checkStatus (response) {
    const status = _.get(response, 'error.response.status')
    if (response.error && status === 401) {
      window.location.reload()
      return
    }

    if (response.error) {
      return Promise.reject(response.error)
    }

    return Promise.resolve(response)
  }

  boot (props = this.props) {
    this.props.retrieve()
      .then((response) => this.checkStatus(response))
      .then(() => this.setSurveys(props))
      .then(() => props.settings())
      .then(() => this.setMetadata(props))
      .then(() => this.setState({ ready: true }))
      .catch(() => this.failed())
  }

  getBasename () {
    const metadataPart = this.props.metadata && this.props.metadata.map(l => l.id).join('-')
    const surveysPart = this.props.surveys && this.props.surveys.join('-')

    return fullBasename(_.compact([surveysPart, metadataPart]).join('/'))
  }

  renderApp () {
    const childProps = {
      basename: this.getBasename()
    }

    return React.Children.map(this.props.children, child => {
      return React.cloneElement(child, childProps)
    })
  }

  renderFailed () {
    const { t } = this.props

    return (
      <div className='ErrorView ErrorView--full'>
        <div className='ErrorView--container'>
          <img src={ICON_500} className='ErrorView--infinity block' alt={this.props.status} />
          <img src={InfinityLogo} className='ErrorView--infinity mt3 block' alt='Error' />
          <span className='ErrorView--heading block center mt3'>{t('alerts.failed_login_title')}</span>
          <span className='ErrorView--subject block center mt1'>
            <Trans i18nKey='alerts.failed_login'>
              warning <a href='#retry' onClick={e => this.onRefresh(e)}>refresh</a>
            </Trans>
          </span>
        </div>
      </div>)
  }

  render () {
    if (this.state.failed) {
      return this.renderFailed()
    }

    if (this.state.ready) {
      return this.renderApp()
    }

    return null
  }
}

export default withTranslation()(AppContainer)
