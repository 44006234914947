import Immutable from 'seamless-immutable'
import _ from 'lodash'
import * as types from './types'

const initialState = Immutable({
  isLoading: false,
  totals: {}
})

export default function teamProfile (state = initialState, action) {
  switch (action.type) {
    case types.RETRIEVE_REQUEST:
      return state.set('isLoading', false)
    case types.RETRIEVE_SUCCESS:
      const { type, teamId, surveyId } = action.meta.previousAction.payload
      return state.setIn(['totals', type, teamId, surveyId], action.payload.data)
    case types.RETRIEVE_FAILURE:
      return state.merge({
        loading: false,
        error: _.get(action.error, ['response', 'data', 'message'], action.error.message)
      })
    default:
      return state
  }
}
