import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Component from './Component'
import * as actions from '../../services/app/actions'

function mapStateToProps (state, ownProps) {
  return {
    isOpen: state.app.leftPopupOpen
  }
}

function mapDispatchToProps (dispatch) {
  return {
    togglePopup: () => dispatch(actions.toggleLeftPopup())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))
