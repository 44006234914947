import { Component } from 'react'

const CHARS = /[a-z]/gi

export default class KeyboardManager extends Component {
  constructor (props) {
    super(props)

    this.handleEvent = this.handleEvent.bind(this)
  }

  componentDidMount () {
    window.addEventListener('keypress', this.handleEvent)
  }

  componentWillUnmount () {
    window.removeEventListener('keypress', this.handleEvent)
  }

  handleEvent (ev) {
    const nodeName = ev.target.nodeName.toUpperCase()
    if (nodeName === 'INPUT' || nodeName === 'TEXTAREA') {
      return
    }

    if (!ev.key.match(CHARS)) {
      return
    }

    this.handleKey(ev.key.toUpperCase())
  }

  handleKey (key) {
    if (key === 'O') {
      this.props.toggleSidebars()
    }
  }

  render () {
    return this.props.children
  }
}
