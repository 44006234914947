import React from 'react'
import { TICK_ON, TICK_OFF, TICK_SEMI } from '../../../../utils/icons'

function icon (value) {
  switch (value) {
    case 2: return TICK_SEMI
    case 1:
    case true: return TICK_ON
    case 0:
    case false:
    default: return TICK_OFF
  }
}

export default props => {
  return (
    <div onClick={e => props.onChange(e)} className={props.className}>
      {icon(props.checked)}
    </div>
  )
}
