import _ from 'lodash'

export function isLeaf (team) {
  return !team.teams || team.teams.length === 0
}

export function findTeam (teams, id) {
  if (!teams) {
    return
  }

  for (let team of teams) {
    if (team.id === id) {
      return team
    }

    if (team.teams) {
      const found = findTeam(team.teams, id)
      if (found) {
        return found
      }
    }
  }
}

const EMPTY_TEAM = {}
export function findURLTeam (teams, params) {
  if (!params.teamId) {
    return EMPTY_TEAM
  }

  return findTeam(teams, params.teamId)
}

export function flattenTeams (teams, leavesOnly, parentId, parents) {
  const result = []
  parents = parents || []

  for (let team of teams) {
    if (!leavesOnly || _.isEmpty(team.teams)) {
      result.push(_.assign({}, team, { parents, parentId }))
    }

    result.push(...flattenTeams(team.teams || [], leavesOnly, team.id, [...parents, team]))
  }

  return result
}
