import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Component from './Component'
import { findRouteMatch } from '../../utils/routing'
import { surveyFullName } from '../../utils/surveys'

function mapSurvey (survey) {
  return {
    id: survey.id,
    name: surveyFullName(survey),
    hierarchy: survey.hierarchy
  }
}

function mapStateToProps (state, ownProps) {
  const surveys = state.profile.surveys.map(mapSurvey)

  return {
    match: findRouteMatch(ownProps.location.pathname),
    surveys,
    selectedSurveys: state.profile.selectedSurveys.map(mapSurvey),
    defaultSurveys: surveys.slice(0, 2),
    selectedMetadata: state.metadata.selectedMetadata
  }
}

export default withRouter(connect(mapStateToProps)(Component))
