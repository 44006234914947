
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import Dropdown from './Dropdown'
import GroupByList from './GroupByList'
import Toggle from '../../../../components/Toggle'
import { urlBuilder } from '../../utils'
import { isProgress } from '../../../../utils/type'
import RadioSelect from '../../../../components/RadioSelect'

import './style.scss'

export class AxisLegend extends Component {
  constructor (props) {
    super(props)
    this.state = {}

    this.clickClose = this.clickClose.bind(this)
  }

  componentWillMount () {
    window.addEventListener('click', this.clickClose)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.clickClose)
  }

  clickClose (event) {
    if (this.state.type && !this.refs.main.contains(event.target)) {
      this.close()
    }
  }

  close () {
    this.setState({
      type: null
    })
  }

  url (type, level, id) {
    return urlBuilder({
      ...this.props.params,
      [`${this.state.type}Type`]: type,
      [`${this.state.type}Level`]: level,
      [`${this.state.type}Id`]: id
    })
  }

  percentage (type) {
    if (isProgress(this.props.selected[type].type)) {
      return null
    }

    return this.props.limits[type][1] === 100 ? ' (%)' : null
  }

  toggleReferenceGrid () {
    if (this.props.setReference) {
      this.props.setReference(this.props.reference === 'none' ? 'default' : 'none')
    }
  }

  renderReferenceLabel () {
    if (isProgress(this.props.selected.x.type) && isProgress(this.props.selected.y.type)) {
      return this.props.t('distribution.legend.show_baseline')
    }

    if (!isProgress(this.props.selected.x.type) && !isProgress(this.props.selected.y.type)) {
      return this.props.t('distribution.legend.show_organisation_score')
    }

    return this.props.t('distribution.legend.show_reference')
  }

  renderBenchmarkReferenceLabel () {
    if (isProgress(this.props.selected.x.type) && isProgress(this.props.selected.y.type)) {
      return this.renderReferenceLabel()
    }

    if (isProgress(this.props.selected.x.type) || isProgress(this.props.selected.y.type)) {
      return this.props.t('distribution.legend.show_benchmark_and_baseline')
    }

    const benchmarkMatch = /benchmark-([0-9]+)/.exec(this.props.reference)
    if (benchmarkMatch) {
      return this.props.benchmark[parseInt(benchmarkMatch[1])].name || this.props.t('distribution.legend.show_benchmark')
    }
    return this.props.t('distribution.legend.show_benchmark')
  }

  renderReferenceChoice () {
    if (this.props.showBenchmark) {
      return this.renderReferenceDrowpdown()
    }

    return this.renderReferenceToggle()
  }

  getReferenceDropdownLabel () {
    if (this.props.reference === 'none') {
      return this.props.t('distribution.legend.none')
    }

    if (this.props.reference === 'default') {
      return this.renderReferenceLabel()
    }

    if (/benchmark-[0-9]+/.test(this.props.reference)) {
      return this.renderBenchmarkReferenceLabel()
    }
  }

  renderReferenceDrowpdown () {
    return (
      <div className='AxisLegend--axis' onClick={() => this.setState({ type: 'benchmark' })}>
        <span className='AxisLegend--axisTitle'>{this.props.t('distribution.legend.reference')}</span>
        <span className='AxisLegend--axisValue'>
          {this.getReferenceDropdownLabel()}
        </span>
      </div>)
  }

  renderReferenceToggle () {
    return (
      <div className='AxisLegend--reference'>
        <label>{this.renderReferenceLabel()}</label>
        <Toggle
          onChange={() => this.toggleReferenceGrid()}
          checked={this.props.reference !== 'none'}
        />
      </div>)
  }

  renderReferenceSelectorProgressOnly (className) {
    const options = [{
      name: this.props.t('distribution.legend.none'),
      value: 'none'
    }, {
      name: this.renderReferenceLabel(),
      value: 'default'
    }]

    const translatedReference = /benchmark-[0-9]+/.test(this.props.reference) ? 'default' : this.props.reference

    return (
      <div className={className}>
        <RadioSelect
          options={options}
          selected={translatedReference}
          onChange={value => this.props.setReference(value)}
        />
      </div>)
  }

  renderReferenceSelector (className) {
    if (isProgress(this.props.selected.x.type) && isProgress(this.props.selected.y.type)) {
      return this.renderReferenceSelectorProgressOnly(className)
    }

    const options = [{
      name: this.props.t('distribution.legend.none'),
      value: 'none'
    }, {
      name: this.renderReferenceLabel(),
      value: 'default'
    }]
    const benchmarks = this.props.benchmark.map((b, i) => (
      {
        name: b.name || this.renderBenchmarkReferenceLabel(),
        value: `benchmark-${i}`
      }
    ))
    options.push(...benchmarks)

    return (
      <div className={className}>
        <RadioSelect
          options={options}
          selected={this.props.reference}
          onChange={value => this.props.setReference(value)}
        />
      </div>)
  }

  render () {
    const { type } = this.state
    const { t, groups, selected } = this.props

    const dropdownClass = ['AxisLegend--dropdown']
    if (type) {
      dropdownClass.push(`AxisLegend--dropdown-${type}`)
    }

    const currentGroupBy = this.props.metadata[this.props.grouped - 1]

    return (
      <div className='AxisLegend' ref='main'>
        {this.renderReferenceChoice()}

        <div className='AxisLegend--axis' onClick={() => this.setState({ type: 'x' })}>
          <span className='AxisLegend--axisTitle'>{t('distribution.legend.x_axis')}</span>
          <span className='AxisLegend--axisValue'>
            {this.props.selected.x.name}{this.percentage('x')}
          </span>
        </div>

        <div className='AxisLegend--axis' onClick={() => this.setState({ type: 'y' })}>
          <span className='AxisLegend--axisTitle'>{t('distribution.legend.y_axis')}</span>
          <span className='AxisLegend--axisValue'>
            {this.props.selected.y.name}{this.percentage('y')}
          </span>
        </div>

        <div className='AxisLegend--axis' onClick={() => this.setState({ type: 'group' })}>
          <span className='AxisLegend--axisTitle'>{t('distribution.legend.group_by')}</span>
          <span className='AxisLegend--axisValue'>
            {currentGroupBy.name}
          </span>
        </div>

        {type === 'x' || type === 'y' ? <div className={dropdownClass.join(' ')}>
          <Dropdown
            groups={groups}
            url={(...args) => this.url(...args)}
            type={selected[type].type}
            value={selected[type].selectedId}
            showProgress={this.props.selectedSurveys.length > 1}
            surveys={this.props.selectedSurveys}
            questionTypes={this.props.questionTypes}
          />
        </div> : undefined}

        {type === 'group' ? <div className={dropdownClass.join(' ')}>
          <GroupByList
            metadata={this.props.metadata}
            team={this.props.team}
            grouped={this.props.grouped}
            group={this.props.group}
          />
        </div> : undefined}

        {type === 'benchmark' ? this.renderReferenceSelector(dropdownClass.join(' ')) : undefined}
      </div>
    )
  }
}

export default withTranslation()(AxisLegend)
