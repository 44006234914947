import * as types from './types'
import Immutable from 'seamless-immutable'
import _ from 'lodash'

import { flattenTeams } from '../../utils/helpers'

const initialState = Immutable({
  teams: null,
  selectedTeams: [],
  loading: false,
  error: null
})

export default function teams (state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_TEAMS:
      return state.set('selectedTeams', action.payload)

    case types.RETRIEVE_REQUEST:
      return state.merge({
        loading: true
      })

    case types.RETRIEVE_SUCCESS:
      return state.merge({
        loading: false,
        teams: injectTeamPath(action.payload.data.teams),
        flatTeams: flattenTeams(action.payload.data.teams)
      })

    case types.RETRIEVE_FAILURE:
      return state.merge({
        loading: false,
        error: _.get(action.error, 'response.data.message') || action.error.message
      })

    default:
      return state
  }
}

export function mapTeamsByThePath (teams) {
  const mapped = {}

  let prefix = ''
  let prevPrefix = ''
  function parseTeam (team) {
    mapped[`${prefix}/${team.name}`] = team

    prevPrefix = prefix
    prefix = `${prefix}/${team.name}`
    _.each(team.teams, parseTeam)
    prefix = prevPrefix
  }

  _.each(teams, parseTeam)
  return mapped
}

export function injectTeamPath (teams) {
  const injectPath = (teams, path) => {
    for (let team of teams) {
      team.path = `${path}/${team.name}`
      if (team.teams) {
        injectPath(team.teams, team.path)
      }
    }
  }

  injectPath(teams, '')
  return teams
}
