export const RETRIEVE_REQUEST = 'profile.RETRIEVE_REQUEST'
export const RETRIEVE_SUCCESS = 'profile.RETRIEVE_SUCCESS'
export const RETRIEVE_FAILURE = 'profile.RETRIEVE_FAILURE'

export const SETTINGS_REQUEST = 'profile.SETTINGS_REQUEST'
export const SETTINGS_SUCCESS = 'profile.SETTINGS_SUCCESS'
export const SETTINGS_FAILURE = 'profile.SETTINGS_FAILURE'

export const LOGOUT = 'profile.LOGOUT'

export const SET_METADATA = 'profile.SET_METADATA'

export const SET_SURVEYS = 'profile.SET_SURVEYS'

export const SET_COMPARISON_TYPE = 'profile.SET_COMPARISON_TYPE'
