
import React from 'react'
import _ from 'lodash'
import { PROGRESS, SCORE_NA } from '../../utils/icons'
import { formatProgress, isNotAsked, isNotAnswered } from '../../utils'

import './style.scss'

function formatProgressScore (progress) {
  if (progress === null || progress === undefined) {
    return null
  }

  return formatProgress(progress)
}

function localFormatScore (score) {
  if (isNotAsked(score)) {
    return ''
  }

  if (isNotAnswered(score)) {
    return SCORE_NA
  }

  return score.value
}

export default function ProgressScore (props) {
  const value = props.value

  // not using formatScore as history may be missing for multiple reasons, not only not asked
  const history = _.get(value, 'historyScore.value', SCORE_NA)

  const score = localFormatScore(_.get(value, 'score'))
  const scoreIndex = _.get(value, 'score.index', 'Na')
  const progress = _.get(value, 'progressScore.value')
  const progressIndex = _.get(value, 'progressScore.index', 'Na')
  const progressIcon = PROGRESS[progressIndex] || null

  return (
    <div className='ProgressScore'>
      <div className='ProgressScore--history'>{history}</div>
      <div className={`ProgressScore--progress score${progressIndex}`}>{formatProgressScore(progress)}{progressIcon}</div>
      <div className={`ProgressScore--score score${scoreIndex}`}>{score}</div>
    </div>)
}
