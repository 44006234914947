
import React from 'react'

import { formatScore, getScoreClass } from './utils'
import { isNotAsked } from '../../../../utils'

import './style.scss'

export default function Cell ({ score, isLoading, onClick }) {
  const classes = ['Cell inline-block']
  const notAsked = isNotAsked(score)

  if (isLoading) {
    classes.push('loading')
  } else {
    classes.push(getScoreClass(score))
  }

  if (!isLoading && !notAsked && onClick) {
    classes.push('Cell--clickable')
  }

  const props = {
    className: classes.join(' ')
  }

  if (onClick && !notAsked) {
    props.onClick = onClick
  }

  return (
    <div {...props}>
      <span>
        {isLoading ? null : formatScore(score)}
      </span>
    </div>
  )
}
